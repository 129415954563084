import React, {Component} from 'react'
import Button from './Buttons'
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import {MdClose, MdContentCopy} from 'react-icons/md';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <MdClose />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};



function selectElementContents(el) {
  var range = document.createRange();
  range.selectNodeContents(el);
  var sel = window.getSelection();
  sel.removeAllRanges();
  sel.addRange(range);
}


export default class ShareComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { 
          CopyText: "Copy"
         };
      }


    componentDidMount = () => {
    }

    componentDidUpdate = (prevProps, prevState) => {
      if (this.props.open && !prevProps.open){
        setTimeout(() => {
          var el = document.getElementById("job_share_url");
          if(el){
            selectElementContents(el);
          }
        }, 200);
      }
    }

    copy = () => {
      navigator.clipboard.writeText(this.props.url)
      this.setState({CopyText: "Copied!"})
      setTimeout(() => {
        this.setState({CopyText: "Copy"})
      }, 1000);
    }

    handleClose = () => {
      this.props.setOpen(false)
    }

    render() {

        return (
          <BootstrapDialog
            onClose={this.handleClose}
            aria-labelledby="customized-dialog-title"
            open={this.props.open}
            className="DefaultModal"
        >
          <div className="DefaultModalContent">
            <div className="ModalClose"><MdClose className="CloseIcon" onClick={this.handleClose}></MdClose></div>
            <div className="DefaultModalTitle">{this.props.recruiter? "Share This Profile": "Share This Opportunity"}</div>
            <div className="DefaultModalSubTitle">{this.props.shortJobDesc}</div>
            <TextField
                variant="outlined"
                id={"job_share_url"}
                className="PostJobInput"
                type="text"
                value={this.props.url}
                />
              <div className="ModalButtons"><Button onClickFunc={this.copy} btnClassName="ModalButton"><MdContentCopy/>{this.state.CopyText}</Button></div>
          </div>
        </BootstrapDialog>
        )
    }
}