
import React from 'react'
import classNames from 'classnames';
import '../css/navbar.css'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";


class Team extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
      }

      componentDidMount = () => {
        window.scrollTo(0, 0)
      }

    render() {


      return (
        <div className="TeamPage">
            <div className="TeamContainer">
                <div className="TeamTitle">Meet the Team</div>
                <div className="WhyWorkWithUsSection">
                <p>Many months and hours have gone into designing and coding ArchJobs from scratch. Despite not being a large team, our skill sets and knowledge complement each other's, making us an incredible team. Plus, we have a dog who never misses his walks, making him the perfect well-being officer.</p>
                <div className="TeamInfoContainer">
                    <div className="TeamPhotos">
                        <img className="TeamPhoto" src="https://cdn.archjobs.co.uk/file/ArchJobs/backgrounds/rsz_sa.jpg" alt="Photo of Sam and Aylin"></img>
                        <img className="TeamPhoto Otto" src="https://cdn.archjobs.co.uk/file/ArchJobs/backgrounds/rsz_ottoteamphoto.jpg" alt="Photo of Otto the dog looking happy"></img>
                    </div>
                    <div className="TeamInfo">
                        <div className="TeamInfoSection">
                            <div className="TeamName">
                            <a target="_blank" href="https://www.linkedin.com/in/aylin-round/">Aylin Round</a>
                            </div>
                            <div className="TeamJob">
                               Owner & Founder
                            </div>
                            <div className="TeamDetails">
                                <p>Hi, I’m Aylin, Since relocating to Dubai in early 2024, I have started  <a target="_blank" href="https://archjobsrecruitment.com/">𝐀𝐫𝐜𝐡𝐉𝐨𝐛𝐬 𝐑𝐞𝐜𝐫𝐮𝐢𝐭𝐦𝐞𝐧𝐭</a>, a recruitment agency that helps architectural practices in Dubai hire top architectural talent, primarily from the UK.</p>
                                <p>With nearly 5 years of experience in the architectural industry and having personally relocated to Dubai, I have a unique understanding of both the challenges and opportunities involved in moving to a new country. I can offer valuable support to those considering relocation, ensuring they receive the guidance and insights they need for a smooth transition. This also helps me provide practices with candidates who are ready to relocate to Dubai and know what to expect.</p>
                                <p>While growing my recruitment business in Dubai, I'm also running 𝐀𝐫𝐜𝐡𝐉𝐨𝐛𝐬, an online platform launched in September 2022 to help architectural professionals advance their careers in the UK.</p>                              
                                <p>I’ve also had the opportunity to write articles for Building Design, Architects' Journal, CIAT | aspirATion magazine and Architecture Social.</p>
                            </div>
                        </div>
                        <div className="TeamInfoSection">
                            <div className="TeamName">
                                Sam
                            </div>
                            <div className="TeamJob">
                                Consulting Full-Stack Developer 
                            </div>
                            <div className="TeamDetails">
                                <p>
                                    I’m a Data Scientist/Full-Stack Developer that coded ArchJobs from scratch - so if you find a bug it’s probably my fault! I’ll keep adding new features to try to improve ArchJobs and make it the best, most user friendly job board out there. 
                                </p>
                                <p>
                                    When I’m not working on ArchJobs, I’m either travelling, dabbling in other projects or taking care of our part-time employee, Otto.
                                </p>
                            </div>
                        </div>
                        <div className="TeamInfoSection">
                            <div className="TeamName">
                                Otto
                            </div>
                            <div className="TeamJob">
                                Wellbeing Officer - Part-Time
                            </div>
                            <div className="TeamDetails">
                                <p>
                                    You can find me in the office most days, although I’m usually fast asleep on the sofa. To be honest I don’t work many hours but luckily Aylin and Sam are great employers and still give me an hour lunch break, private health insurance and plenty of biscuits.                                 
                                </p>
                                <p>
                                    I am working towards a full-time position and expanding my responsibilities but I keep falling asleep and struggle to focus for long periods of time. My software skills also leave a lot to be desired, but my people skills are great (if you’re not a delivery driver).
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
      )
    }
  }

  export default Team