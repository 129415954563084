
import React from 'react'
import classNames from 'classnames';
import '../css/navbar.css'
import {BsPlus} from 'react-icons/bs'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
  import { Collapse } from '@mui/material';
  import tags from '../data/tags';
  import Masonry from 'react-masonry-css'


const Questions = [
    {"Number": 1, 
    "Question": "Is career coaching confidential?",
    "Answer": <div className="FAQAnswer">
        <p>Yes, every single session we will have is 100% confidential.</p>
        </div>},

    {"Number": 2, 
    "Question": "Not sure if you need a career coach?",
    "Answer": <div className="FAQAnswer">
        <p>Let's find out. We can arrange a 15-minute free call for a quick chat. <a href="https://www.archjobs.co.uk/architectural-career-coaching/check-out?package=call" target="_blank" rel="noopener">Either click the link here</a> or use the chat function on the bottom right of this page.</p>
        </div>},

    {"Number": 3, 
    "Question": "How can a career coach help me?",
    "Answer": <div className="FAQAnswer">
    <p>A career coach can make a significant difference in your journey. By working with Aylin, you'll gain the confidence, guidance, and support you need to excel in your architectural career. She'll help you save time and effort by structuring your job search, improving your CV and portfolio, your LinkedIn profile and more. As the Founder of ArchJobs, she offers valuable market insights and the latest industry trends to ensure you're always one step ahead.</p>
    </div>},

    {"Number": 4, 
    "Question": "Can't find the topic you are looking for?",
    "Answer": <div className="FAQAnswer">
    <p>We can also help you with various other topics:</p>
      <ul>
        <li>Interview Preparation</li>
        <li>Salary Negotiation Tips</li>
        <li>How to ask for a Pay Increase</li>
        <li>Evaluating an Offer</li>
        <li>How to handle a Counter Offer</li>
        <li>How to Hand in your Notice/Resignation + Tips on how not to burn bridges</li>
        <li>Providing tips to Overseas Professionals on job markets, salaries and how to structure their job search</li>
      </ul>
      <p>Please contact us directly and we can disucss next steps. <a href="https://www.archjobs.co.uk/architectural-career-coaching/check-out?package=call" target="_blank" rel="noopener">You can either click the link here</a> or use the chat function on the bottom right of this page. </p>
    </div>},
   
    {"Number": 5, 
    "Question": "Which coaching packages do you offer?",
    "Answer": <div className="FAQAnswer">
               <p>We are offering 4 different coaching sessions.</p>
                <ul>
                  <li>CV Review</li>
                  <li>CV + Portfolio Review</li>
                  <li>Strategy Call | UK</li>
                  <li>Strategy Call | Dubai</li>
                </ul>
              </div>},
    
    {"Number": 6, 
    "Question": "Which industry do you specialise in?",
    "Answer": <div className="FAQAnswer">
        <p>Aylin's experiences and knowledge lies in Architectural Recruitment (Built Environment) in the UK and most recently Dubai. </p>
        </div>},
   
    {"Number": 8, 
    "Question": "How are career coaching sessions conducted?",
    "Answer": <div className="FAQAnswer">
        <p>Each session will be conducted either via Google Meets or Teams.</p>
    </div>},
 
    // {"Number": 9, 
    // "Question": "What can we discuss in the Strategy Call?",
    // "Answer": <div className="FAQAnswer">
    //     <p>The topic is entirely up to you. Whether you need help with structuring your job search, securing a job as an overseas qualified professional, or have questions about salaries, feel free to choose the topic that matters most to you. I'll provide actionable advice tailored to your needs.</p>
    // </div>},

    // {"Number": 10, 
    // "Question": "How many sessions does the Full Pack include?",
    // "Answer": <div className="FAQAnswer">
    // <p>It will depend on each candidate but there will be at least 5 x 1 hour long sessions.</p>
    // </div>},

    {"Number": 11, 
    "Question": "Will Aylin write my CV for me?",
    "Answer": <div className="FAQAnswer">
    <p>No, Aylin won't write your CV for you. Instead, she'll offer advice and explain areas for improvement. Her aim is to help you understand the principles of a strong CV and focus on developing the skills to create one yourself in the future.</p>
    </div>},

    {"Number": 12, 
    "Question": "After I purchased a package, can I cancel it?",
    "Answer": <div className="FAQAnswer">
    <p>No, every package is non refundable.</p>
    <p>If you are unsure whether booking a coaching session is the right decision, we can arrange a 15-minute free call. <a href="https://www.archjobs.co.uk/architectural-career-coaching/check-out?package=call" target="_blank" rel="noopener">You can either click the link here</a> or use the chat function on the bottom right of this page. </p>
    </div>},

    {"Number": 13, 
    "Question": "What's the difference between a Career Coach & a Mentor?",
    "Answer": <div className="FAQAnswer">
    <p>A Career Coach provides structured, goal-oriented guidance to achieve your desired career goals, while a Mentor is an experienced professional who offers voluntary, informal support based on their own experiences and expertise.
</p>
    </div>},

    // {"Number": 14, 
    // "Question": "Still have a question?",
    // "Answer": <div className="FAQAnswer">
    // <p>Please email your question to Aylin at <a href="mailto:hello@archjobs.co.uk">aylin@archjobs.co.uk</a>
    // </p>
    // </div>},
]


class FAQCoaching extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: [],
            layout: "Default"
        };  
    }    
    
      componentDidMount = () => {
        window.addEventListener("resize", this.getSize.bind(this));

      }
    
      getSize = () => {
        var pagewidth = window.innerWidth
        var layout = "Default"
        if (pagewidth < 600){
          layout = "Mobile"
        }
        if (this.state.layout !== layout){
          this.setState({layout: layout})
        }
      }

      toggleQuestion = (num) => {
        const open = this.state.open
        const index = open.indexOf(num);
        if (index > -1) { // only splice array when item is found
            open.splice(index, 1); // 2nd parameter means remove one item only
        } else {
            open.push(num)
        }
        this.setState({open: open})
      }

    render() {

        var question_list = []

        Questions.forEach(Question => {
            question_list.push(
                <div className="FAQSection">
                    <div className="FAQQuestion" onClick={() => {this.toggleQuestion(Question["Number"])}}>
                        <BsPlus className="PlusIcon"/> {Question["Question"]}
                    </div>
                    <Collapse in={this.state.open.includes(Question["Number"])}>
                        {Question["Answer"]}
                    </Collapse>
                </div>)
        })


      return (
        <div className="TeamPage">
            <div className="FAQContainer">
                
                <div className="FAQContent">
                    <div className="FAQTitle">FAQ - Coaching</div>
                    <p>We've collected the answers to some of our frequently asked questions below. Hopefully this helps clarify more about the Coaching Sessions but as always, feel <a target="_blank" href="mailto:hello@archjobs.co.uk">free to reach out to us</a> directly if you have any further questions.</p>
                    {/* <div className="FAQSubTitle">We've collected the answers to some of our frequently asked questions below. Hopefully this helps clarifiy more about our service but as always, feel free to reach out to us directly if your question isn't covered below.</div> */}
                    <Masonry
                        breakpointCols={this.state.layout == "Mobile"? 1: 2}
                        className="my-masonry-grid"
                        columnClassName="my-masonry-grid_column">
                        {question_list}
                    </Masonry>
                    {/* <div className="FAQContact">
                        Can't find what you're looking for? <a href="mailto:hello@archjobs.co.uk">Contact</a> the ArchJobs team.
                    </div> */}
                    
                </div>
                <div className="FAQBackground"> </div>
            </div>
        </div>
      )
    }
  }

  class FAQCoachingPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };  
    }    
    
    componentDidMount = () => {
        window.scrollTo(0, 0)
      }
      render() {


      return (
        <div className="TeamPage">
            <FAQCoaching/>
            <div className="WhyWorkWithUsSection">
              <div className="WhyWorkWithUsSectionTitle">Still have a question? Reach out to us via email</div>
              <div className="WhyWorkWithUsSectionSubtitle">Drop us an email at <a href="mailto:hello@archjobs.co.uk">hello@archjobs.co.uk</a></div>
              {/* <div className="WhyWorthWithUsHeaderButton">
                <Link to="/post">
                  <Button btnClassName="PostJobButton">Contact Us</Button>
                </Link>
              </div> */}
            </div>
            <div className="BottomPadding"></div> 
        </div>
      )
    }
  }

  export default FAQCoachingPage
  export { FAQCoaching }