import React, {Component} from 'react'
import Button from './Buttons'
import Autocomplete from '@mui/material/Autocomplete';
import tags from '../data/tags.js'
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import {BsSearch, BsGeoAlt, BsX} from 'react-icons/bs'
import axios from 'axios'
import {
  Navigate,
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";


const search_terms = [...tags.JobTitles, ...tags.WorkArrangements, ...tags.JobType, ...tags.ExperienceLevel, ...tags.Software, ...tags.Sectors] 

const tag_type_mappings = {job_type: "Job Type",  job_title: "Job Title",  work_arrangement: "Work Arrangement", experience: "Experience"} //sector: "Sector", software: "Software",

export default class SearchBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
          locations: [],
          location_data: [],
          tags: [],
          redirect: false,
          redirect_url: "",
          location_value: "",
          search_value: "",
         };
      }
      
    componentDidMount = () => {
      this.setValues(this.props)
      axios.get("https://api.archjobs.co.uk/search_counts").then(response => {
        var location_list = response.data.results.map(location => location.city+" ("+String(location.jobs)+")")
        var tags = response.data.tags
        tags = tags.filter(tag => tag.tag_type in tag_type_mappings)
        tags.sort((a,b) => b.jobs - a.jobs)
        this.setState({locations: location_list, default_locations: location_list, location_data: response.data.results, default_location_data: response.data.results, tags: tags})
        // console.log(location_list)
      })
      
    }

    componentDidUpdate = (prevProps, prevState) => {
      var changed_props = {}
      if (prevProps.location_value != this.props.location_value){
        changed_props["location_value"] = this.props.location_value
      }
      if (prevProps.search_value != this.props.search_value){
        changed_props["search_value"] = this.props.search_value
      }
      if (Object.keys(changed_props).length > 0){
        this.setValues(changed_props)
      }
    }

    setValues = (props) => {
      var new_state = {}
      if (props.location_value){
        new_state["location_value"] = props.location_value
      }
      if (props.search_value){
        new_state["search_value"] = props.search_value
      }
      if (Object.keys(new_state).length > 0){
        console.log("setting values: ", new_state)
        this.setState(new_state)
      }
    }

    handleLocationChange = (event) => {
      // console.log("handleLocationChange", event.target.value)
      
      if (event.target.value.length > 2 && this.state.location_value.length <= 2){
        axios.get("https://api.archjobs.co.uk/locations?search="+event.target.value).then(response => {
          var location_list = response.data.results.map(location => location.city+" ("+String(location.jobs)+")")
          this.setState({locations: location_list, location_data: response.data.results})
          // console.log(location_list)
        })
      } else {
        this.setState({locations: this.state.default_locations, location_data: this.state.default_location_data})
      }
      this.setState({location_value: event.target.value})
    }

    handleSearchChange = (event) => {
      var new_search = event.target.value
      if (!new_search){
        new_search = event.target.getAttribute("value")
      }      
      if (!new_search){
        new_search = event.target.textContent.split(" (")[0]
      }
      this.setState({search_value: new_search})
    }

    handleLocationChange2 = (event) => {
      var new_loc = event.target.value
      if (!new_loc){
        new_loc = event.target.textContent.split(" (")[0]
      }
      this.setState({location_value: new_loc})
    }

    clear = (box) => {
      if (box == "search"){
        this.setState({search_value: ""})
      } else {
        this.setState({location_value: ""})
      }
    }

    search = () => {
      //construct the search url
      //search term
      console.log("Searching")
      var term_input = document.getElementById("job_title_input")
      var search_term = term_input.value
      var search_url = ""
      var search_param = ""
      if (tags.JobTitles.includes(search_term)){
        search_url += search_term.replace(/[\W_]+/g, "-")+"-Jobs"
      } else if (search_terms.includes(search_term)){
        search_url += search_term+"-Architecture-Jobs"
      } else {
        search_url = "Architecture-Jobs"
        if (search_term!= ""){
          search_param = "?search="+search_term
        }
      }
      //location
      var location_input = document.getElementById("location_input")
      var location_search = location_input.value
      if (location_search != "") {
        search_url += "-in-"+location_search
        if (search_param == ""){
          search_param+="?"
        } else {
          search_param+="&"
        }
        var location_search_data = this.state.location_data.filter(x => x.city == location_search)[0]
        if (location_search_data){
          search_param+="lat="+location_search_data.lat+"&lng="+location_search_data.lng
        } else {
          search_param+="location_lookup=true"
        }
      } else {
        search_url += "-in-United-Kingdom"
      }
      search_url = search_url+search_param
      console.log("search: ", search_url)
      if (this.props.admin){
        this.setState({redirect: true, redirect_url: "/admin/"+search_url}, () => {this.setState({redirect: false, redirect_url: ""})})
      } else {
        this.setState({redirect: true, redirect_url: "/search/"+search_url}, () => {this.setState({redirect: false, redirect_url: ""})})
      }
      
    }

    render() {
        return (
            
            <div className="SearchBarContainer">
              {this.state.redirect && <Navigate to={this.state.redirect_url}></Navigate>}
              <Autocomplete
                className="SearchBar"
                id="job_title_input"
                freeSolo
                options={this.state.tags}
                getOptionLabel={(option) => {if (typeof option === 'object' && "tag_name" in option) {return option.tag_name} else {return option}}}
                renderOption={(props, option) => (
                  <div className="AutocompleteOptionSeparated" {...props} value={option.tag_name}><div value={option.tag_name}>{option.tag_name} ({option.jobs})</div> <div className="OptionTagType" value={option.tag_name}>{tag_type_mappings[option.tag_type]}</div> </div>
                )}
                value={this.state.search_value}
                onChange = {this.handleSearchChange}
                renderInput={(params) => <TextField {...params} onChange = {this.handleSearchChange} className="SearchBarTextField" placeholder="Job Title, Sector, Keywords..." id="job_title_input_inner"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: <InputAdornment position="start" className="SearchFieldText" id="SearchFieldWhatText">What</InputAdornment>,
                  endAdornment: <InputAdornment position="end"><BsSearch className="SearchIcon SearchIconDefault" /><BsX className="SearchIcon SearchIconX" onClick={() => this.clear("search")}/></InputAdornment>,
                }}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    this.search();
                  }
                }}
                />}
              />
              <Autocomplete
                className="SearchBar"
                id="location_input"
                freeSolo
                options={this.state.location_data}
                getOptionLabel={(option) => {if (typeof option === 'object' && "city" in option) {return option.city} else {return option}}}
                onChange = {this.handleLocationChange2}
                value={this.state.location_value}
                renderOption={(props, option) => (
                    <div className="AutocompleteOption" {...props}>{option.city} ({option.jobs} {(option.jobs > 1)? " Jobs": " Job"})</div>
                )}
                renderInput={(params) => <TextField  onChange = {this.handleLocationChange} {...params} className="SearchBarTextField" placeholder="United Kingdom" id="location_input_inner"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: <InputAdornment position="start" className="SearchFieldText" id="SearchFieldWhereText">Where</InputAdornment>,
                  endAdornment: <InputAdornment position="end"><BsGeoAlt className="SearchIcon SearchIconDefault" /><BsX className="SearchIcon SearchIconX" onClick={() => this.clear("location")}/></InputAdornment>,
                }}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    this.search();
                  }
                }}
                />}
              />
              <div style={{display: "flex", alignItems: "stretch"}} onClick={this.search}>
                <Button btnClassName="SearchButton" >
                  Search
                </Button>
              </div>
            </div>
        )
    }
}