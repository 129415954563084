import withRouter from "../withRouter";
import React, { Component } from 'react';
import Helmet from 'react-helmet'
import '../articles/articles.css'
import HiringGuideData from './HiringGuideData';
import { WeeklyEmail } from '../components/NewsLetter';
import { NewsLetterPopUp } from '../components/NewsLetter';
import {BsLinkedin, BsTwitter, BsFillBellFill} from 'react-icons/bs'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";

class HiringGuideFrame extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
      };
    }


    render(){

        let authorData

        const thisArticleData = HiringGuideData.HiringGuideData[this.props.ArticleDataName]

        console.log(this.props)
        const JSONschema = {
            "@context": "https://schema.org",
            "@type": "BlogPosting"
        }
        if (thisArticleData.article_title) JSONschema["headline"] = thisArticleData.article_title
        if (thisArticleData.article_title) JSONschema["name"] = thisArticleData.article_title
        if (thisArticleData.article_description) JSONschema["description"] = thisArticleData.article_description
        if (thisArticleData.article_url) JSONschema["url"] = thisArticleData.article_url
        if (thisArticleData.published) JSONschema["datePublished"] = thisArticleData.published.toISOString()
        if (thisArticleData.modified) JSONschema["dateModified"] = thisArticleData.modified.toISOString()

        const images = ["main_img_1x1", "main_img_4x3", "main_img_16x9"]

        for (const image_field of images){
            if (thisArticleData[image_field]){
                if (!(JSONschema.image)){
                    JSONschema.image = []
                }
                JSONschema.image.push(thisArticleData[image_field])
            }
        }
        if (!(JSONschema.image) && thisArticleData.main_img){
            JSONschema.image = [thisArticleData.main_img]
        }

        if (thisArticleData.author && HiringGuideData.AuthorData[thisArticleData.author]){
            const AuthorDetails = HiringGuideData.AuthorData[thisArticleData.author]
            JSONschema["author"] =  [{
                "@type": "Person",
                "name": AuthorDetails.name,
                "url": AuthorDetails.profile_page
              }]
              authorData = HiringGuideData.AuthorData[thisArticleData.author]
        }

        JSONschema["publisher"] =  {
            "name": "ArchJobs",
            "url": "https://www.archjobs.co.uk"
          }

        return <div className="ArticlePage">
        <Helmet>
        <meta charSet="utf-8" />
            <title>{thisArticleData.article_title}</title>
            <link rel="canonical" href={thisArticleData?.article_url} />
            <meta name="description" content={thisArticleData?.article_description}/>
            <meta name="og:title" content={thisArticleData?.article_title}/>
            <meta name="og:type" content={"article"}/>
            <meta name="og:image" content={thisArticleData?.main_img}/>
            <meta name="og:url" content={thisArticleData?.article_url}/>
            <script type="application/ld+json">{JSON.stringify(JSONschema)}</script>
        </Helmet>
        <div className="ArticleContainer">
            <img className="ArticleHeaderImg" src={thisArticleData?.main_img} alt={thisArticleData?.article_title+" Main Image"}></img>
            <div className="ArticleTitle">{thisArticleData?.article_title}</div>
            <div className="ArticleSubtitle">
                by {thisArticleData?.author}
            </div>
            {this.props.children} 
            <div className="ArticleCoaching">Advertise your architectural roles on ArchJobs. <Link to="/post-job" className="Link inline">Visit our page to get started</Link>.</div>
            {authorData && <div className="AuthorComponent">
                <div className="AuthorProfile">
                    <img src={authorData.profile_img} className="AuthorImg" alt={"Article Author: "+authorData.name}></img>
                    <a className="AuthorName Link" href={authorData.linkedin} target="_blank" rel="noreferrer">
                        <BsLinkedin className="LinkedinLogo"></BsLinkedin>
                        {authorData.name}
                    </a>
                </div>
                <div className="AuthorDescription">
                    {authorData.description}
                    
                </div>
            </div>}
            {/* <div className="NewsletterEmailContainer">
                <div className="NewsletterEmailTitle">Enjoy this article? Get our latest jobs and articles with our weekly newsletter</div>
                <WeeklyEmail/>
            </div> */}
        </div>
        {/* <NewsLetterPopUp /> */}
    </div>
    }
}

export default withRouter(HiringGuideFrame)