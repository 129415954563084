import React, { Component, useState } from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import MultipleSelectChip from './MultiSelect.js'
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { CircularProgress, Box, Typography  } from '@mui/material';
import Quill from 'quill';
import Button from '@mui/material/Button';
import tags from '../data/tags.js'

const SubmitUrl = "https://api.archjobs.co.uk/add_job"

const OverwriteUrl = "https://api.archjobs.co.uk/ammend_job"

const LocationURL = "https://api.archjobs.co.uk/get_location"

const JobTitles = tags.JobTitles

const Sectors = tags.Sectors

const Software = tags.Software

const WorkArrangements = tags.WorkArrangements

const JobType = tags.JobType

const OtherTags = tags.OtherTags

const AutoCompletePrompt = `I would like you to parse values from a Job Advert, for an architectural role.

For each Job Advert, return one block in this format, using '> ' to indent values. You can use your knowledge of the company to fill in anything that isn't specified, otherwise return "N/A":

company_name:
> <The Company Name (if specified)>
company_website:
> <The Company Website>
salary_min:
> <Minimum Salary (if specified, as an integer)>
salary_max:
> <Maximum Salary (if specified, as an integer)>
job_title:
> <The Job Title from the Advert>
usp:
> <A list of unique selling points about this role. Items should be short (2-3 words long) and separated with a comma (,)>
additional_info:
> <Suggest 5-10 keywords/phrases that an applicant should include in their application, based on the job description, to make them more likely to be picked up by a hiring manager. Items should be short (2-3 words long) and separated with a comma (,)
application_notes:
> <If there is anything in particular the applicant should look out for and add to their application, then detail it here>
hiring_name:
> <The Hiring Manager's Name>
Sectors:
> <A comma separated list of sectors that the applicant might work on. Choose only from these values: ${Sectors.map(tag => `'${tag}'`).join(", ")}>
Software:
> <A comma separated list of software that the applicant might use. Choose only from these values:  ${Software.map(tag => `'${tag}'`).join(", ")}>
WorkArrangements:
> <A comma separated list of working styles available. Choose only from these values: ${WorkArrangements.map(tag => `'${tag}'`).join(", ")}>
JobType:
> <A comma separated list of contract type. Choose only from these values: ${JobType.map(tag => `'${tag}'`).join(", ")}>
JobTitles:
> <A comma separated list of predefined Job Titles that might apply. Choose only from these values: ${JobTitles.map(tag => `'${tag}'`).join(", ")}>
OtherTags:
> <A comma separated list of other tags that might apply. Choose only from these values: ${OtherTags.map(tag => `'${tag}'`).join(", ")}>
experience:
> < AKA Experience Level (junior/senior). This is a comma-separated list of experience levels that might apply. Choose only from these values: "New Graduate", "Junior", "Mid-Level", "Senior">

`

const fields = [
  {id: "company_name", field_name:"company_name", required: true},
  {id: "company_website", field_name:"company_website", required: false},
  {id: "salary_min", field_name:"salary_min", required: false, type: "numeric"},
  {id: "salary_max", field_name:"salary_max", required: false, type: "numeric"},      
  {id: "job_title", field_name:"job_title", required: true},
  {id: "job_description", field_name:"job_description", required: true},
  {id: "usp", field_name:"usp", required: false},
  {id: "additional_info", field_name:"additional_info", required: false},
  {id: "reference_id", field_name:"reference_id", required: false},
  {id: "application_url", field_name:"apply_url", required: true},
  {id: "hiring_name", field_name:"hiring_name", required: false},
  {id: "application_notes", field_name:"application_notes", required: false},
  {id: "deadline", field_name:"deadline", required: false, type: "date"},
  {id: "Demo", field_name:"demo", required: false, type: "checkbox"},
  {id: "Urgent", field_name:"urgent", required: false, type: "checkbox"},
  {id: "Featured", field_name:"featured", required: false, type: "checkbox"},
  {id: "num_submissions", field_name:"num_positions", required: false, type: "numeric", default_value: 1},    
  {id: "recruiter_id", field_name:"recruiter_id", required: false, type: "select", value: ""},
]

let state_fields = {}
fields.forEach(field => {
  let value = ""
  if (field.type == "numeric") {
    value = 0
  }
  if (field.type == "checkbox") {
    value = false
  }
  if (field.default_value) {
    value = field.default_value
  }
  state_fields[field.id] = {...field, value: value}
})

const tag_fields = [
  {id: "Sectors", tag_type: "sector"},
  {id: "Software", tag_type: "software"},
  {id: "WorkArrangements", tag_type: "work_arrangement"},
  {id: "JobType", tag_type:"job_type"},
  {id: "JobTitles", tag_type:"job_title"},
  {id: "OtherTags", tag_type:"other"},
  {id: "experience", tag_type:"experience"},
]

let state_tag_fields = {}
tag_fields.forEach(field => {
  state_tag_fields[field.id] = {...field, value: []}
})


const application_fields = [
  {id: "ApplyWebsite", field_name:"apply_method", required: false, type: "checkbox", multiple: 16, default_value: false},
  {id: "ApplyCoverLetter", field_name:"apply_method", required: false, type: "checkbox", multiple: 4, default_value: false},
  {id: "ApplyPortfolio", field_name:"apply_method", required: false, type: "checkbox", multiple: 2, default_value: true},
  {id: "ApplyCV", field_name:"apply_method", required: false, type: "checkbox", multiple: 1, default_value: true},
]

let state_application_fields = {}
application_fields.forEach(field => {
  state_application_fields[field.id] = {...field, value: false}
})

function CompleteJobButton(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleClick = async () => {
    setIsLoading(true);
    setMessage('');
    let jobSubmission = {}
    jobSubmission.auth = props.password

    jobSubmission.job_description = props.getJobDescription()
    
    jobSubmission.prompt = AutoCompletePrompt
    
    try {
      const response = await axios.post('https://api.archjobs.co.uk/job_completion', {
        body: JSON.stringify(jobSubmission),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        }
      });
      if (response.data.choices) {
        props.parseData(response.data.choices[0].message.content)
      }
      console.log("response: ", response)
      setMessage(response.data.message);
    } catch (error) {
      setMessage('Error updating manual job pages');
    }
    setIsLoading(false);
  };

  return (
    <div>
      <Button onClick={handleClick} disabled={isLoading}  variant="contained" className="SubmitFormButton">
        AutoComplete
      </Button>
      {isLoading && <CircularProgress sx={{ ml: 2 }} size={20} />}
      {message && (
        <Typography variant="body2" sx={{ ml: 2, color: message.startsWith('Error') ? 'error.main' : 'success.main' }}>
          {message}
        </Typography>
      )}
    </div>
  );
}

class JobSubmission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locations: 1,
      validated: {},
      location_input: [""],
      deadline_date: null,
      job_submission_status: {},
      checkbox: {
        ApplyCV: true, 
        ApplyPortfolio: true,
        ApplyCoverLetter: false,
        ApplyWebsite: false,
        Demo: false,
        Urgent: false,
        Featured: false,
      },
      fields: state_fields,
      tag_fields: state_tag_fields,
      application_fields: state_application_fields,
      overwrite: null,
    };  
    this.quill = null
    this.multiSelectValues = {}
  }    

  onFieldChange = (event, value) => {
    console.log(event, value)
    const new_fields = {...this.state.fields} 
    let target_id = event.target.id
    if (target_id.includes("-option-")){
      target_id = target_id.split("-option-")[0]
    }
    if (typeof value == "undefined"){
      new_fields[target_id].value = event.target.value
    } else {
      if (value !== null){
        new_fields[target_id].value = value
      }
    }
    this.setState({fields: new_fields})
  }

  onTagFieldChange = (event, value) => {
    console.log(event, value)
    const new_fields = {...this.state.tag_fields} 
    let target_id = event.target.id
    if (target_id.includes("-option-")){
      target_id = target_id.split("-option-")[0]
    }
    new_fields[target_id].value = value
    this.setState({tag_fields: new_fields})
  }

  componentDidMount = () => {
    if (!this.quill){
      this.quill = new Quill('#jobdescriptioninput', {
        theme: 'snow',
        formats: [
          'bold',
          'header',
          'italic',
          'link',
          'list',
          'blockquote',
          'image',
          'indent'
        ],
        modules: {
          toolbar: [
            [{'header': 1}, {'header': 2}],
            ['bold', 'italic', 'link'],
            ['blockquote', {'list': 'ordered'}, {'list': 'bullet'}],
          ],
          clipboard: {
            matchVisual: false // https://quilljs.com/docs/modules/clipboard/#matchvisual
          }
        }
      });
    }
    if (Object.keys(this.props.job_data).length > 0){
      this.setJobData(this.props.job_data)
    }

  }

  validateLocation = (i) => {
    var input = document.getElementById("location"+String(i))
    var new_validated = {...this.state.validated}
    new_validated[i] = {status: "loading"}
    this.setState({validated: new_validated})
    axios.post(LocationURL, {
      body: JSON.stringify({location: input.value, auth: this.props.password}),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      }
    }).then(response => {
      console.log(response.data)
      if (response.data.data && response.data.data.length > 0 && Array.isArray(response.data.data[0]) && response.data.data[0].length == 0){
        new_validated[i] = {status: "not_found"}
      } else {
        new_validated[i] = {status: "success", data: response.data.data[0]}
      }
      this.setState({validated: new_validated})
    }).catch(error =>{
      console.log("error: ", error)
      new_validated[i] = {status: "error"}
      this.setState({validated: new_validated})
    })
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.job_data !== this.props.job_data){
      console.log("job_data changed", this.props.job_data)
      this.setJobData(this.props.job_data)
      
    }
  }

  updateMultiSelectValue = (id, value) => {
    this.multiSelectValues[id] = value
    const new_fields = {...this.state.tag_fields} 
    new_fields[id].value = value
    this.setState({tag_fields: new_fields})
  }

  setJobData = (job_data) => {
    console.log("job_data: ", job_data)
    const new_state = {}
    const new_state_fields = {}
    let checkbox = {}
    for (const field of fields){
      let value = ""
      if (field.type == "numeric") {
        value = 0
      }
      if (field.default_value) {
        value = field.default_value
      }
      new_state_fields[field.id] = {...field, value: value}
      var input = document.getElementById(field.id)
      if (input != null){
        if (job_data[field.field_name]) {
          if (field.type == "checkbox"){
            checkbox[field.id] = true
          } else {
            new_state_fields[field.id].value = job_data[field.field_name]
          }
        } else {
          console.log(field.field_name, " not in data ")
        }
      } else {
        console.log("Couldnt get input: ", field.id)
      }
    }

    new_state.fields = new_state_fields

    // salary_type
    
    for (var i = 1; i < 6; i++){
      var input = document.getElementById("salary_type_"+String(i))

      const selected_value = i
      if (job_data["salary_type"] == selected_value) {
        input.checked = true
      } else {
        input.checked = false
      }
    }
    
    //application details
    let application_int = job_data["apply_method"]
    application_fields.sort((a, b) => b.multiple - a.multiple)
    for (const field of application_fields){
        if (application_int >= field.multiple) {
          checkbox[field.id] = true
          application_int -= field.multiple
        } else {
          checkbox[field.id] = false
        }
      
    }
    //job description
    if (this.quill){
      this.quill.root.innerHTML = job_data["job_description"]
    }
    //deadline
    if(job_data["deadline"]){
      new_state.deadline_date = new Date(job_data["deadline"])
    }

    const new_tag_fields = {}
    //tags
    tag_fields.forEach(field => {
      new_tag_fields[field.id] = {...field, value: []}
      if (field.tag_type in job_data.parsed_tag_types){
        new_tag_fields[field.id].value = job_data.parsed_tag_types[field.tag_type]
        this.multiSelectValues[field.id] = job_data.parsed_tag_types[field.tag_type]
      }
    })
    new_state.tag_fields = new_tag_fields

    //locations
    new_state.locations = job_data.parsed_location_list.length
    var i = 0
    let new_validated = []
    let location_list = []
    job_data.parsed_location_list.forEach(location => {
      new_validated.push({status: "success", data: location})
      location_list.push(location.label)
    })
    new_state.validated = new_validated
    new_state.location_input = location_list
    new_state.overwrite = job_data.job_id
    new_state.checkbox = checkbox
    this.setState(new_state)
    console.log(new_state)
    
  }

  submitJob = (params) => {
    //assemble all the data from various fields
    console.log(params)
    this.setState({job_submission_status: {status: "loading"}})

    var jobSubmission = {data: {}, locations: [], tags: []}

    for (const field of fields){
      var input = document.getElementById(field.id)
      console.log("field input:", field, input, this.state)
      if (input != null){
        
        if (field.type == "checkbox"){
          jobSubmission.data[field.field_name] = this.state.checkbox[field.id] ? 1: 0
        } else if(field.type == "numeric") {
          var int_value = parseInt(input.value)
          if (int_value <= 0){
            int_value = null
          }
          jobSubmission.data[field.field_name] = int_value
        } else if(field.id == "recruiter_id") {
          var int_value = this.state.fields.recruiter_id.value
          if (int_value){
            jobSubmission.data[field.field_name] = int_value
          }
        } else {
          if ((input.value == "") && (field.required == true)){
            this.setState({job_submission_status: {status: "error", message: "Required field "+field.field_name+" not filled out"}})
            return
          } else {
            jobSubmission.data[field.field_name] = input.value.trim()
          }
          jobSubmission.data[field.field_name] = input.value.trim()
        }
      } else {
        console.log("Couldnt get input: ", field.id)
      }
    }

    // salary_type
 
    for (var i = 1; i < 6; i++){
      var input = document.getElementById("salary_type_"+String(i))
      console.log(input.checked)
      if (input.checked){
        const selected_value = i
        jobSubmission.data["salary_type"] = selected_value
      }
      // 
    }

    //application details


    var application_int = 0
    for (const field of application_fields){
      if (this.state.checkbox[field.id] == true){
          application_int+=1*field.multiple
      }
    }
    jobSubmission.data["apply_method"] = application_int
    //job description
    if (this.quill){
      var job_description_content = this.quill.root.innerHTML
      console.log(job_description_content)
      if (String(job_description_content) == "<p><br></p>"){
        this.setState({job_submission_status: {status: "error", message: "No Job Description"}})
        return
      }
      jobSubmission.data["job_description"] = job_description_content
    }
    //deadline
    if(this.state.deadline_date !== null){
      jobSubmission.data["deadline"] = new Date(this.state.deadline_date).toISOString().split("T")[0]
    }
    if (!(params && params.overwrite)){ 
      var submit_date = new Date()
      jobSubmission.data["posted"] = submit_date.toISOString().replace("T", " ").split(".")[0]
      submit_date.setMonth(submit_date.getMonth() + 1)
      jobSubmission.data["expires"] = submit_date.toISOString().split("T")[0]
    } else {
      jobSubmission.data.job_id = this.state.overwrite
    }
    
    //locations
    for (var i=0; i < Math.max(this.state.locations, 1); i++){
      const location_num = i
      if (!this.state.validated[location_num]){
        this.setState({job_submission_status: {status: "error", message: "Location "+String(location_num+1)+" hasn't been validated"}})
        return
      } else {
        if (this.state.validated[location_num].status == "success"){
          jobSubmission.locations.push(this.state.validated[location_num].data)
        } else {
          this.setState({job_submission_status: {status: "error", message: "Location "+String(location_num+1)+" wasn't successfully validated"}})
          return
        }
      }
    }

    //tags
    tag_fields.forEach(field => {
      if (field.id in this.multiSelectValues){
        for (const value of this.multiSelectValues[field.id]){
          jobSubmission.tags.push({tag_name: value, tag_type: field.tag_type})
        }
      }
    })

    jobSubmission.auth = this.props.password
    console.log(jobSubmission)
    if(params && params.overwrite){
      axios.post(OverwriteUrl, {
        body: JSON.stringify(jobSubmission),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        }
      }).then(response => {
        window.alert("Updated Job")
        console.log(response.data)
      }).catch(error =>{
        window.alert("Error updating Job: ", String(error))
        console.log("error: ", error)
      })
    }else if(params && params.delete){
      axios.post(SubmitUrl, {
        body: JSON.stringify(jobSubmission),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        }
      }).then(response => {
        console.log("added new job")
        axios.get("https://api.archjobs.co.uk/disable_job?job_id="+this.state.overwrite).then(response => {
          window.alert("Added Job & Deleted Last")
          console.log("deleted job id "+String(this.state.overwrite))
          console.log(response.data)
        }).catch(error =>{
          console.log("error: ", error)
        })
        
      }).catch(error =>{
        window.alert("Error adding Job: ", String(error))
        console.log("error: ", error)
      })


    } else {
      axios.post(SubmitUrl, {
        body: JSON.stringify(jobSubmission),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        }
      }).then(response => {
        window.alert("Added Job")
      }).catch(error =>{
        window.alert("Error adding Job: ", String(error))
        console.log("error: ", error)
      })
    }

  }

  getJobDescription = () => {
    if (this.quill){
      var job_description_content = this.quill.root.innerHTML
      console.log(job_description_content)
      return job_description_content
    }
  }

  deleteJob = () => {
    axios.get("https://api.archjobs.co.uk/disable_job?job_id="+this.state.overwrite).then(response => {
      window.alert("deleted job id "+String(this.state.overwrite))
      console.log(response.data)
    }).catch(error =>{
      console.log("error: ", error)
    })
  }

  getLocations = () => {
    var location_list = []
    for (var i=0; i < Math.max(this.state.locations, 1); i++){
      const location_num = i
      var status_info = <div className="LocationStatus"></div>
      var location_status = this.state.validated[location_num]
      if (location_status && location_status.status){
        status_info = <div className="LocationStatus">{location_status.status}</div>
        if (location_status.status == "success"){
          status_info = <div className="LocationStatus">{location_status.status} <a href={"http://maps.google.com/maps?q="+location_status.data.latitude+","+location_status.data.longitude} target="__blank">Check here</a></div>
        }
      }
      location_list.push(<div className="LocationInput">
        <TextField className="PostJobInput" key={"location"+String(location_num)} id={"location"+String(location_num)} value={this.state.location_input[i]} onChange={(event, value) => this.locationInputChange(event, value)}></TextField> <Button variant="contained" className="ValidateLocationButton" onClick = {() => {this.validateLocation(location_num)}}>Validate</Button>
        {status_info}
      </div>)
    }
    return location_list
  }

  locationInputChange= (event, value) => {
    const location_num = parseInt(event.target.id.replace("location", ""))
    const current_location_input = [...this.state.location_input]
    current_location_input[location_num] = event.target.value
    this.setState({location_input: current_location_input})
  }

  removeLocation = () => {
    this.setState({locations: this.state.locations-1})
  }

  addLocation = () => {
    const new_location_input = [...this.state.location_input]
    if (new_location_input.length < this.state.locations+1){
      new_location_input.push("")
    }
    this.setState({locations: this.state.locations+1, location_input: new_location_input})
  }

  handleChange = (event) => {
    this.setState({checkbox: {...this.state.checkbox, [event.target.id]: event.target.checked}});
  };

  parseData = (data) => {
    console.log(data)
    const new_fields = {...this.state.fields} 
    fields.forEach(field => {
      const match = data.match(new RegExp(`${field.id}:\n> (.*)(?=\n)`, "m"));
      console.log("match: ", match, field.id)
      if (match && match[1].trim() !== "N/A") {
        if (field.type=="numeric"){
          new_fields[field.id].value = parseInt(match[1].trim())
        } else {
          new_fields[field.id].value = match[1].trim();
        }
      }
    })

    const new_tag_fields = {...this.state.tag_fields}
    tag_fields.forEach(field => {
      const match = data.match(new RegExp(`${field.id}:\n> (.*)(?=\n)`, "m"));
      if (match && match[1].trim() !== "N/A") {
        new_tag_fields[field.id].value = match[1].trim().split(", ");
      }
    })


    this.setState({fields: new_fields, tag_fields: new_tag_fields})
  }

  render() {
    console.log("props:", this.props)
      return <div className="JobSubmissionContainer"  >
      <div className="JobSubmissionRow" hidden={this.props.hidden}>
        <div className="JobSubmissionHeader">Job Description</div>
        <div id="jobdescriptioninput" className="JobDescriptionInput" updateMultiSelectValue={this.updateMultiSelectValue.bind(this)}></div>
        <div className="AutocompleteButtonContainer">
          <CompleteJobButton getJobDescription={this.getJobDescription} parseData={this.parseData} password={this.props.password}></CompleteJobButton>
        </div>
      </div>
        
      <div className="JobSubmission" hidden={this.props.hidden}>
        <div className="JobSubmissionTitle">
          Submit a New Job
        </div>
        <div className="JobSubmissionRow">
            <div className="JobSubmissionHeader">Company Name</div>
            <Autocomplete
                required
                id="company_name"
                className="PostJobInput"
                value = {this.state.fields.company_name.value}
                onChange={(event, newValue) => this.onFieldChange(event, newValue)}
                inputValue = {this.state.fields.company_name.value}
                onInputChange= {(event, newValue) => this.onFieldChange(event, newValue)}
                freeSolo
                options={[]}
                renderInput={(params) => <TextField {...params} label="Company Name" />}
              />
          </div>
          <div className="JobSubmissionRow">
            <div className="JobSubmissionHeader">Company Website</div>
            <Autocomplete
                id="company_website"
                className="PostJobInput"
                value = {this.state.fields.company_website.value}
                onChange={(event, newValue) => this.onFieldChange(event, newValue)}
                inputValue = {this.state.fields.company_website.value}
                onInputChange= {(event, newValue) => this.onFieldChange(event, newValue)}
                freeSolo
                options={[]}
                renderInput={(params) => <TextField {...params} label="Company Website" />}
              />
          </div>
          <div className="JobSubmissionRow">
            <div className="JobSubmissionHeader">Location</div> 
            {this.getLocations()}
            <div className="JobSubmissionAlignedRow">
              <div className="AddLocation" onClick={this.addLocation}>Add Location</div>
              <div className="AddLocation" onClick={this.removeLocation}>Remove Location</div>
            </div>
          </div>
          <div className="JobSubmissionRow">
            <div className="JobSubmissionHeader">Job Title</div>
            <Autocomplete
                required
                id="job_title"
                className="PostJobInput"
                value = {this.state.fields.job_title.value}
                onChange={(event, newValue) => this.onFieldChange(event, newValue)}
                inputValue = {this.state.fields.job_title.value}
                onInputChange= {(event, newValue) => this.onFieldChange(event, newValue)}
                freeSolo
                options={JobTitles}
                renderInput={(params) => <TextField {...params} label="Job Title" />}
              />
          </div>
          <div className="JobSubmissionRow">
            <div className="JobSubmissionHeader">Job Title Tags (if Job Title doesn't match a job exactly, or there are other relevant keywords)</div>
            <MultipleSelectChip className="PostJobInput" values={JobTitles.sort()} id="JobTitles" updateMultiSelectValue={this.updateMultiSelectValue.bind(this)} value = {this.state.tag_fields.JobTitles.value}/>
          </div>

          
          <div className="JobSubmissionRow">
            <div className="JobSubmissionHeader">Job Type</div>
            <MultipleSelectChip className="PostJobInput" values={JobType.sort()} id="JobType" updateMultiSelectValue={this.updateMultiSelectValue.bind(this)} value = {this.state.tag_fields.JobType.value}/>
          </div>
          <div className="JobSubmissionRow">
            <div className="JobSubmissionHeader">Minimum Experience (leave empty or negative if not required)</div>
            <MultipleSelectChip className="PostJobInput" values={tags.ExperienceLevel} id="experience" updateMultiSelectValue={this.updateMultiSelectValue.bind(this)} value = {this.state.tag_fields.experience.value}/>
            </div>
          <div className="JobSubmissionRow">
            <div className="JobSubmissionHeader">Unique Selling Point (A short summary why this role, separate points with ", ")</div>
            <Autocomplete
                id="usp"
                freeSolo
                className="PostJobInput"
                value = {this.state.fields.usp.value}
                onChange={(event, newValue) => this.onFieldChange(event, newValue)}
                inputValue = {this.state.fields.usp.value}
                onInputChange= {(event, newValue) => this.onFieldChange(event, newValue)}
                options={[]}
                renderInput={(params) => <TextField {...params} multiline minRows={3} label="USP" />}
              />
          </div>
          <div className="JobSubmissionRow">
            <div className="JobSubmissionHeader">Sectors</div>
            <MultipleSelectChip values={Sectors.sort()} id="Sectors" updateMultiSelectValue={this.updateMultiSelectValue.bind(this)} value = {this.state.tag_fields.Sectors.value}/>
          </div>
          <div className="JobSubmissionRow">
            <div className="JobSubmissionHeader">Software</div>
            <MultipleSelectChip values={Software.sort()} id="Software" updateMultiSelectValue={this.updateMultiSelectValue.bind(this)} value = {this.state.tag_fields.Software.value}/>
          </div>
          <div className="JobSubmissionRow">
            <div className="JobSubmissionHeader">Work Style</div>
            <MultipleSelectChip values={WorkArrangements.sort()} id="WorkArrangements" updateMultiSelectValue={this.updateMultiSelectValue.bind(this)} value = {this.state.tag_fields.WorkArrangements.value}/>
          </div>
          <div className="JobSubmissionRow">
            <div className="JobSubmissionHeader">Other Tags</div>
            <MultipleSelectChip values={OtherTags.sort()} id="OtherTags" updateMultiSelectValue={this.updateMultiSelectValue.bind(this)} value = {this.state.tag_fields.OtherTags.value}/>
          </div>
          <div className="JobSubmissionRow">
            <div className="JobSubmissionHeader">Application Inclusions (A short list of items an applicant should include in their application, separated by ", ")</div>
            <Autocomplete
                id="additional_info"
                freeSolo
                className="PostJobInput"
                value = {this.state.fields.additional_info.value}
                onChange={(event, newValue) => this.onFieldChange(event, newValue)}
                inputValue = {this.state.fields.additional_info.value}
                onInputChange= {(event, newValue) => this.onFieldChange(event, newValue)}
                options={[]}
                renderInput={(params) => <TextField {...params} label="Additional Info" />}
              />
          </div>
          <div className="JobSubmissionRow">
            <div className="JobSubmissionHeader">Number of Roles</div>
                <OutlinedInput
                  type="number"
                  className="PostJobInput"
                  defaultValue={1}
                  value = {this.state.fields.num_submissions.value}
                  onChange={(event, newValue) => this.onFieldChange(event, newValue)}
                  id="num_submissions"
                  label="Number of Roles"
                />
          </div>
          <div className="JobSubmissionRow">
            <div className="JobSubmissionHeader">Application details</div>
            <FormGroup>
              <FormControlLabel control={<Checkbox id="ApplyWebsite"  checked={this.state.checkbox.ApplyWebsite} onChange={this.handleChange}/>} label="On Website" />
              <FormControlLabel control={<Checkbox id="ApplyCV"  checked={this.state.checkbox.ApplyCV} onChange={this.handleChange}/>} label="CV" />
              <FormControlLabel control={<Checkbox id="ApplyPortfolio"  checked={this.state.checkbox.ApplyPortfolio} onChange={this.handleChange}/>} label="Portfolio" />
              <FormControlLabel control={<Checkbox id="ApplyCoverLetter"  checked={this.state.checkbox.ApplyCoverLetter} onChange={this.handleChange}/>} label="Cover Letter" />
            </FormGroup>
          </div>
          <div className="JobSubmissionRow">
          <Autocomplete
                className="PostJobInput"
                id="application_url"
                value = {this.state.fields.application_url.value}
                onChange={(event, newValue) => this.onFieldChange(event, newValue)}
                inputValue = {this.state.fields.application_url.value}
                onInputChange= {(event, newValue) => this.onFieldChange(event, newValue)}
                freeSolo
                options={[]}
                renderInput={(params) => <TextField {...params} label="Application URL or email" />}
              />
          </div>
          <div className="JobSubmissionRow">
            <div className="JobSubmissionHeader">Hiring Manager's Name (if known)</div>
            <Autocomplete
                className="PostJobInput"
                id="hiring_name"
                value = {this.state.fields.hiring_name.value}
                onChange={(event, newValue) => this.onFieldChange(event, newValue)}
                inputValue = {this.state.fields.hiring_name.value}
                onInputChange= {(event, newValue) => this.onFieldChange(event, newValue)}
                freeSolo
                options={[]}
                renderInput={(params) => <TextField {...params} label="Hiring Manager's Name" />}
              />
          </div>
          <div className="JobSubmissionRow">
            <div className="JobSubmissionHeader">Application Notes (if anything to watch out for)</div>
            <Autocomplete
                className="PostJobInput"
                id="application_notes"
                value = {this.state.fields.application_notes.value}
                onChange={(event, newValue) => this.onFieldChange(event, newValue)}
                inputValue = {this.state.fields.application_notes.value}
                onInputChange= {(event, newValue) => this.onFieldChange(event, newValue)}
                freeSolo
                options={[]}
                renderInput={(params) => <TextField {...params} multiline minRows={3} label="Application Notes" />}
              />
          </div>
          <div className="JobSubmissionRow">
            <div className="JobSubmissionHeader">Company Job unique reference id (optional)</div>
            <Autocomplete
                id="reference_id"
                freeSolo
                className="PostJobInput"
                value = {this.state.fields.reference_id.value}
                onChange={(event, newValue) => this.onFieldChange(event, newValue)}
                inputValue = {this.state.fields.reference_id.value}
                onInputChange= {(event, newValue) => this.onFieldChange(event, newValue)}
                options={[]}
                renderInput={(params) => <TextField {...params} label="Reference ID" />}
              />
          </div>
          <div className="JobSubmissionRow">
          <div className="JobSubmissionHeader">Deadline (optional)</div>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              className="PostJobInput"
              value={this.state.deadline_date}
              onChange={(newValue) => {
                {this.setState({deadline_date: newValue})}
              }}
              inputFormat="dd/MM/yyyy"
              id="deadline"
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          </div>
          <div className="JobSubmissionRow">
            <div className="JobSubmissionHeader">Salary Range (add salary time - hourly, daily or yearly)</div>
            <div className="JobSubmissionAlignedRow">
              <div className="SalaryInput">
                <InputLabel htmlFor="salary_min">Min</InputLabel>
                <OutlinedInput
                  type="number"
                  className="PostJobInput"
                  value = {this.state.fields.salary_min.value}
                  onChange={(event, newValue) => this.onFieldChange(event, newValue)}
                  id="salary_min"
                  startAdornment={<InputAdornment position="start">£</InputAdornment>}
                  label="Salary Min"
                />
              </div>
              <div className="SalaryInput">
                <InputLabel htmlFor="salary_max">Max</InputLabel>
                <OutlinedInput
                  type="number"
                  className="PostJobInput"
                  id="salary_max"
                  value = {this.state.fields.salary_max.value}
                  onChange={(event, newValue) => this.onFieldChange(event, newValue)}
                  startAdornment={<InputAdornment position="start">£</InputAdornment>}
                  label="Salary Min"
                />
              </div>
            </div>
            <FormControl>
              <FormLabel id="salary_type-label">Salary Period</FormLabel>
              <RadioGroup
                aria-labelledby="salary_type-label"
                defaultValue={1}
                name="salary_type"
                id = "salary_type"
                className="JobSubmissionForm"
              >
                <FormControlLabel value={1} control={<Radio id = "salary_type_1"/>}  label="Annual" />
                <FormControlLabel value={2} control={<Radio id = "salary_type_2"/>} label="Monthly" />
                <FormControlLabel value={3} control={<Radio id = "salary_type_3"/>} label="Weekly" />
                <FormControlLabel value={4} control={<Radio id = "salary_type_4"/>} label="Daily" />
                <FormControlLabel value={5} control={<Radio id = "salary_type_5"/>} label="Hourly" />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="JobSubmissionRow">
            <div className="JobSubmissionHeader">Other Details</div>
            <FormGroup>
              <FormControlLabel control={<Checkbox id="Featured" checked={this.state.checkbox.Featured} onChange={this.handleChange}/>} label="Featured" />
              <FormControlLabel control={<Checkbox id="Urgent" checked={this.state.checkbox.Urgent} onChange={this.handleChange}/>} label="Urgent" />
              <FormControlLabel control={<Checkbox id="Demo" checked={this.state.checkbox.Demo} onChange={this.handleChange}/>} label="Demo" />      
            </FormGroup>
          </div>
          <div className="JobSubmissionRow">
            <div className="JobSubmissionHeader">Recruiter</div>
            <Select 
              id="recruiter_id" 
              value={this.state.fields.recruiter_id.value} 
              onChange={(event, newValue) => {
                let newEvent = event
                newEvent.target.id = "recruiter_id"
                this.onFieldChange(newEvent, event.target.value)
              }} 
              className="PostJobInput">
                {this.props.recruiter_list.map(recruiter => {
                  return <MenuItem value={recruiter.recruiter_id}>{recruiter.recruiter_name}</MenuItem>
                })}
            </Select>
          </div>
          <div className="JobSubmissionSubmitRow">
            <Button variant="contained" className="SubmitFormButton" color="success" onClick = {() => {this.submitJob()}}>Submit New</Button>
            <div className="ErrorMessage">
              {this.state.job_submission_status.status == "error" && this.state.job_submission_status.message}
            </div>
            <div className="SuccessMessage">
              {this.state.job_submission_status.status == "success" && this.state.job_submission_status.message}
            </div>
            <Button variant="contained" className="SubmitFormButton" color="success" onClick = {() => {this.submitJob({delete: true})}}>Submit New & Delete</Button>
            {this.state.overwrite && <div><Button variant="contained" className="SubmitFormButton" color="warning" onClick = {() => {this.submitJob({overwrite: true})}}>Overwrite</Button>
            <div className="ErrorMessage">
              {this.state.job_submission_status.status == "error" && this.state.job_submission_status.message}
            </div>
            <div className="SuccessMessage">
              {this.state.job_submission_status.status == "success" && this.state.job_submission_status.message}
            </div></div>}
            {this.state.overwrite && <div><Button variant="contained" className="SubmitFormButton" color="error" onClick = {() => {this.deleteJob()}}>Delete</Button></div>}
          </div>
      </div>
    </div>
    }
}

/* export the component to be used in other components */
export default JobSubmission