import ArticleFrame from "./ArticleFrame";
import React, { Component } from 'react';


export default class WriteCV extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
      };
    }
    componentDidMount = () => {
      window.scrollTo(0, 0)
    }

    render(){
        return <ArticleFrame ArticleDataName="SeniorCV" >
          <p>When you've been working in the architectural industry for a while, you know that you bring a wealth of experience and knowledge to the table. However, when it comes to looking for a new job, you may find that your CV needs to be tailored to reflect your seniority and experience. This article provides tips and guidance on what to focus on, what to avoid, and how to write an effective CV as a senior architectural professional. From highlighting your leadership experience to tailoring your CV to the job, this guide is a valuable resource for anyone looking to take their career to the next level.</p>
          <p/>
          <p/>
          <h2>General CV Formatting Tips</h2>
          <div>File type: PDF&nbsp;</div>
          <div>File size: Max 5MB</div>
          <div>Font type: Easy to read fonts are Arial, Times New Roman or Calibri</div>
          <div>Font size: 10-11</div>
          <div>Pages: 2 or less</div>
          <div>Structure: Always start with your most recent work experience</div>
          <div>Naming your file: First and last name - CV. (i.e. <em>Jane Doe - CV</em>)&nbsp;</div>
          <p/>
          <p/>
          <h3>Basic Structure of your CV</h3>
          <p>I&rsquo;ve looked through thousands of CVs in my time, and there is a particular CV structure which seems to be liked by most hiring managers/recruiters.</p>
          <p><strong>A rough outline is as follows:</strong></p>
          <ol>
          <li>Full name, current Job Title, member of an affiliation (ARB, ACIAT, MCIAT etc.)</li>
          <li>Personal Details (phone, email and city)&nbsp;</li>
          <li>About Me/Profile section (quick snapshot of who you are, your level of experience, what sectors you've worked on, software etc.)</li>
          <li>Soft-Skills section - for example, job running, presentation, leadership, design, and team player (only list the most relevant skills)</li>
          <li>Software &amp; Tools section - for example, Revit, AutoCAD, Photoshop, InDesign, SketchUp, ArchiCAD, Vectorworks, Microsoft Teams</li>
          <li>Working History - begin with your most recent experience (this is very important)</li>
          <div>6.1. Job Title, Company name, Location, Dates (start - finish)</div> 
          <div>6.2. Summarise your responsibilities and mention any work highlights and provide hard facts and figures (more below)</div>
          <li>Hobbies (optional but could be a nice ice breaker)</li>
          <li>Education/Awards&nbsp;</li>
          <li>References - Available upon Request</li>
          </ol>
          <p/>
          <p/>
          <h3>Focus on your achievements</h3>
          <p>One of the most important things to focus on when putting together your CV is highlighting your achievements. As a more experienced professional, you should be able to demonstrate how you've made a significant impact in your previous roles. This could include project wins, innovative designs, or cost savings. Make sure to be specific about what you accomplished and the results of your efforts. Use statistics, percentages, and &pound;&pound;&pound; amounts to illustrate the impact you've made.</p>
          <p/>
          <p><strong>Here is an example of what a hiring manager/recruiter would expect to read in your work history section:</strong></p>
          <p><em>Senior Architect, XYZ Architecture Firm [Dates + Location]</em></p>
          <ul>
          <li><em>Led a team of 10 architects and designers in the design and construction of large-scale residential schemes in London, including XYZ Project, a 200-unit development in the heart of the city.</em></li>
          <li><em>Worked across RIBA stages 2-6, taking the lead on design development, construction documentation, and on-site management.</em></li>
          <li><em>Collaborated with clients and contractors to ensure the project was delivered on time, within budget, and to the highest quality standards.</em></li>
          <li><em>Implemented innovative design solutions to improve energy efficiency, enhance the building's functionality, and meet the client's needs.</em></li>
          <li><em>Mentored junior architects, providing guidance on design and project management best practices.</em></li>
          </ul>
          <p/>
          <p/>
          <h3>Emphasise your technical skills</h3>
          <p>Architecture is a technical field, so it's essential to showcase your technical skills on your CV. This could include your proficiency with design software, your understanding of building codes and regulations, or your ability to work with clients and contractors. Be sure to highlight the specific tools and software you're experienced in using, as well as any certifications or awards you've earned.</p>
          <p/>
          <p/>
          <h3>Mention your leadership experience</h3>
          <p>As you move up the ranks, you'll likely have taken on more responsibility, including managing projects and teams. Make sure to highlight your leadership experience, including the size of the teams you've managed, the types of projects you've led, and any special initiatives you've undertaken. If you've been a mentor to junior architects and technicians, this is also worth mentioning.</p>
          <p/>
          <p/>
          <h3>Make your professional development a priority</h3>
          <p>Continuing education and professional development are crucial in any career. Make sure to highlight any courses, workshops, or conferences you've attended and what you learned from them. If you've been involved in professional organisations, this is also a great opportunity to showcase your commitment to your career and your industry.</p>
          <p/>
          <p/>
          <h3>Adding Images of previous projects (optional)&nbsp;</h3>
          <p>Including images of your projects on your CV can be an effective way to showcase your skills and achievements. Not only do images provide visual interest, but they also give potential employers a tangible example of your work. However, it's important to choose images that are clear and of high quality. Blurred or pixelated images can detract from your professional image, so be sure to choose images that are crisp and of high quality.&nbsp;</p>
          <p>Having said all of that, if you're applying via Application Tracking Systems (ATS), some systems might become confused. In this case, it will be better to avoid adding images to your CV and instead have everything in your Sample Portfolio.</p>
          <p>To learn more on this particular subject, read <a href="https://www.archjobs.co.uk/articles/how-to-create-an-ats-friendly-cv" target="_blank" rel="noopener">How to create an ATS-Friendly CV</a>.</p>
          <p/>
          <p/>
          <h3>Be mindful of what you include</h3>
          <p>It's important to remember that your CV is a marketing tool. While it's important to be honest and truthful, it's also essential to be mindful of what you include. For example, it may be tempting to list every project you've ever worked on, but it's more effective to focus on your most significant and relevant accomplishments. This way, you'll be able to give the reader a clear picture of your experience and expertise.</p>
          <p>If I'd received &pound;1 every time a senior professional said, "I've worked on so many projects over the years, I don't know how to choose," I'd be a very wealthy woman.</p>
          <p/>
          <p/>
          <h3>Which Keywords should you use?</h3>
          <p>As a senior architectural professional, you want to make sure that your CV is optimised for the keywords that are most relevant to your industry and your experience.</p>
          <p/>
          <p><strong>Some of the keywords that you should focus on include:</strong></p>
          <p><em>ARB/RIBA/MCIAT/AABC/CIOB, Job Running, Leadership, Project Delivery, Communication, UK Building Regulations, Design, Site Visits, Liaising with Clients and Contractors, Coordination, Time Management, Presentation, Technical Drawings, Mentoring, Business Development, Strategic, Technical skills (e.g. AutoCAD, Revit, SketchUp, Vectorworks)</em></p>
          <p>It's also important to include specific project names, awards, and certifications that demonstrate your experience and expertise. Make sure to research the job you're applying for and tailor your CV to reflect the keywords and qualifications that are most relevant to the role.</p>
          <p>💡 Tip: Before you submit your application on <a href="https://www.archjobs.co.uk/" target="_blank" rel="noopener">ArchJobs</a>, we provide you with the most relevant keywords?</p>
          <p> <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/ArchJobs keywords_ATS.JPG" alt="ArchJobs website screenshot displaying the most relevant keywords"></img></p>
          <p/>
          <p/>
          <h2>What to avoid?</h2>
          <p>Now that we've gone over what you should focus on when putting together your CV, let's take a look at what you should avoid.</p>
          <p/>
          <p/>
          <h3>Don't make it too long</h3>
          <p>While it may be tempting to include every detail about your experience and qualifications, it's essential to keep your CV concise. Aim for 2 pages or less, and avoid using small fonts (ideally font size 10-11) or cramped margins to fit everything in. Instead, focus on the most important information and make sure it's presented in an easy-to-read format.</p>
          <p/>
          <p/>
          <h3>Typos and grammatical errors</h3>
          <p>A well-written CV is essential to making a good impression on potential employers. Be sure to proofread your CV thoroughly before submitting it.</p>
          <p>💡 Tip: Have someone else proofread your CV.</p>
          <p/>
          <p/>
          <h3>Don&rsquo;t list every work experience</h3>
          <p>You do not need to list all of your previous jobs on your CV. That usually applies to candidates with more than 15-20 years of experience. For example, if you are applying for a Senior Architect/Associate role, would it be necessary to mention that you worked a short term contract back in the 90s? The short answer is no. Hiring managers and recruiters are more interested in your recent experience.</p>
          <p/>
          <p/>
          <h3>Using vague or generic language</h3>
          <p>Avoid using generic phrases or buzzwords, such as "excellent communication skills" or "strong team player." Instead, use specific examples to demonstrate your skills and experiences.<br /><br /><strong>For example:</strong></p>
          <ul>
          <li><em>I led a team of 5 architects and designers in the design and development of a new build large scale residential scheme in central Manchester.</em></li>
          <li><em>My responsibilities included overseeing the project from RIBA Stages 3-6, managing the project budget of &pound;15mil and coordinating with clients, consultants and contractors to ensure the project was delivered on time and to the highest standards.</em></li>
          </ul>
          <p/>
          <p/>
          <h3>Including irrelevant information</h3>
          <p>Focus on the information that is most relevant to the role you're applying for. Avoid including information that is not directly related to your experience as an architectural professional.</p>
          <p/>
          <p/>
          <h3>Neglecting the design</h3>
          <p>A well-designed CV can help you stand out from the crowd. Use a clean, professional format and choose a font that is easy to read. Don't forget, first impressions count.</p>
          <p/>
          <p/>
          <h2>Summary of Do&rsquo;s and Don&rsquo;ts</h2>
          <p/>
          <h3>Things you should do</h3>
          <div>✔ Focus on recent and relevant experience and qualifications</div>
          <div>✔ Highlight your leadership and mentorship skills</div>
          <div>✔ Use specific examples to demonstrate your skills and experiences</div>
          <div>✔ Tailor your CV to the job you're applying for</div>
          <div>✔ Use a clean, professional format and choose a font that is easy to read</div>
          <div>✔ Proofread your CV thoroughly for typos and grammatical errors</div>
          <p/>
          <p/>
          <h3>Things you should avoid doing:</h3>
          <div>✖ Include outdated information or irrelevant information</div>
          <div>✖ Overload your CV with information</div>
          <div>✖ Use vague or generic language</div>
          <div>✖ Neglect the design of your CV</div>
          <div>✖ Fail to tailor your CV to the job you're applying for</div>
          <div>✖ Include typos and grammatical errors.</div>
          <p/>
          <p/>
          <h2>Conclusion</h2>
          <p>As a senior architectural professional, your career is at a critical juncture, and the right CV can make all the difference in securing your next role (recruiters and experienced hiring managers can easily identify a suitable candidate). While it may be tempting to simply list your experience and qualifications, it's important to focus on the key areas that will set you apart from other candidates. By highlighting your leadership skills, demonstrating your technical expertise, and showcasing your passion for innovation, you can create a CV that showcases your value to potential employers.</p>
          <p>You can also check out our <a href="https://www.archjobs.co.uk/salary-guide/senior-architect-salary" target="_blank" rel="noopener">Salary Guide to see what senior professionals earn across the UK</a>. If you are an overseas qualified professional, I highly recommend reading our <a href="https://www.archjobs.co.uk/articles/moving-to-the-uk-as-an-overseas-architect" target="_blank" rel="noopener">Career Guide: Moving to the UK as an Overseas Architect</a>, which is full of useful advice and important tips.</p>
            </ArticleFrame>
    }
}