import ArticleFrame from "./ArticleFrame";
import React, { Component } from 'react';


export default class JobAd extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
      };
    }
    componentDidMount = () => {
      window.scrollTo(0, 0)
    }

    render(){
        return <ArticleFrame ArticleDataName="JobAd" >
          <p>Are you frustrated with writing job ads that fail to attract the right candidates? Not sure why your adverts aren't bringing in the desired results? Or maybe you're new to the game and feeling overwhelmed about where to start? Don't worry, we've got you covered. In this article, I'll walk you through everything you need to know about job adverts. From understanding the difference between a job ad and job description, to structuring a job advert (including an example), how gender bias impacts application rates, understanding what job seekers look for, and much more.</p> 
          <p>So, let's dive in and learn how to write job adverts that attract top talent.</p>
          <p/>
          <p/>
          <h2>Is a job description the same as a job advert?</h2>
          <p>A job advertisement and a job description are two different things, although they are often used together in the hiring process.</p>
          <p>A <strong>job advertisement</strong> is a public notice or announcement that a company creates to attract potential candidates for an available job position. It typically provides a brief overview of the job, including key responsibilities, qualifications, and any other relevant information, such as salary and benefits. The goal of a job ad is to generate interest in the position and encourage qualified candidates to apply.</p>
          <p>On the other hand, a <strong>job description</strong> is a more detailed document that outlines the duties, responsibilities, and requirements of a specific job position. It is often used internally by HR departments and managers to define job roles and responsibilities, set performance expectations, and evaluate employee performance.</p>
          <p>While a job advert is designed to attract potential candidates, a job description is used to provide a clear understanding of what is expected of the person who will be filling the position. A job ad may include some information from the job description, but it is typically shorter and more focused on attracting candidates.</p>
          <p/>
          <p/>
          <h2>How to stand out from the crowd?</h2>
          <p>Are you struggling to make your job advert stand out in the crowded online space? With so many positions being advertised, it's crucial to make your job advert eye-catching and easily understandable.</p>
          <p>Here are some tips to help you achieve that:</p>
          <ul>
          <li>Choose the right job title that accurately reflects the position and aligns with industry standards.</li>
          <li>Keep the length of your ad between 300 and 600 words, as this is a comfortable reading length.</li>
          <li>Clearly state what you're looking for in a candidate, including necessary qualifications, skills, and experience.</li>
          <li>Avoid using jargon or complex sentences that may be difficult to understand.</li>
          <li>Use bullet points to break up the text (about 25% of your ad should be in bullet points).</li>
          <li>Ensure that your ad is easy to scan quickly by using subheadings, short paragraphs, and bold text to emphasise key points.</li>
          <li>Check for gender bias in your ad, using available resources to ensure that it is inclusive and non-discriminatory.</li>
          </ul>
          <p/>
          <p/>
          <h2>What are some bad job advert examples?&nbsp;</h2>
          <p>As someone who has read through over a thousand architectural job adverts, I've come across five types of job ads that I see time and time again. It's important to recognise what's currently out there before we can improve things.</p>
          <p/>
          <p>🦄 <strong>The Unicorn</strong></p>
          <p>These job adverts are looking for a mythical beast - the practices have completely unrealistic expectations. Good luck trying to find someone who fits their bill.</p>
          <p>📘 <strong>The Essay</strong></p>
          <p>This type of job advert is far too long and has far too many expectations. You'll need to skim read through Chapter 1: History for a summary of the practice before diving into Chapter 2: The Role to see if you're interested and Chapter 3: The Requirements to see if you're qualified. After a couple of nights of reading, you discover that the Salary footnote is conveniently missing (or worse, "competitive").</p>
          <p>🎣 <strong>The One Size Fits All</strong></p>
          <p>"<em>We are looking for an Architectural Assistant/Architectural Technologist/Architect or Associate. Salary range &pound;20,000-&pound;60,000 (based on experience).</em>" These job adverts are often just trying to save money by putting multiple ads into one and might not even have a specific role in mind - they're just looking to see who comes through the door.</p>
          <p>🤷&zwj;♂️ <strong>The Vague Description</strong></p>
          <p>This type of job advert is probably written by a newly hired Personal Assistant who was just given the task of "Find us an Architect" There's little to no detail on what you'll actually be doing there, but a nice description of the practice and its history.</p>
          <p>✂️ <strong>The Copy and Paste</strong></p>
          <p>These job adverts are clearly copied from previous ads - sometimes the only difference is the job title. Expectations and responsibilities are the same for a junior role as their next experienced role. I know a lot of recruiters are guilty of this due to a lack of time but practices do the same.</p>
          <p>The good news is, things will get better from here. By understanding what makes a bad job advert, we can start to create better job adverts that attract the right candidates for the role.</p>
          <p/>
          <p/>
          <h2>What are job seekers looking for in an ad?</h2>
          <p>When it comes to writing a job advert, it's important to keep in mind what job seekers are looking for. By understanding their needs and preferences, we can increase the likelihood that they will submit their application.</p>
          <p>So, what are job seekers searching for in an ad?</p>
          <p>Firstly, they want to know about the <strong>requirements of the role</strong>, including the necessary <strong>skills and qualifications</strong>. They also look for information on the <strong>specific responsibilities</strong> that come with the job. Furthermore, candidates are interested in the <strong>location of the position</strong>, as this can impact their decision to apply.</p>
          <p><strong>Salary information</strong> is a crucial factor for many job seekers. A study by <a href="https://www.reed.co.uk/recruiter-advice/how-to-attract-the-right-candidate-for-your-organisation/" target="_blank" rel="nofollow noopener">Reed</a> found that 78% of candidates are less likely to apply to a position if they don't know what the company is offering in terms of pay. It's therefore important to be transparent about the salary range for the role.</p>
          <p><strong>Benefits</strong> are also a key consideration. According to the same Reed survey, 23% of respondents stated that benefits are also an important factor when applying for a job. Be sure to highlight any perks that come with the position, such as health insurance, pension contributions, or flexible working arrangements.</p>
          <p>Finally, give candidates an insight into the <strong>company culture and values</strong>. This can help them to determine whether your practice is a good fit for them. Consider including information about the company's mission, vision, and any relevant awards or recognition they have received.</p>
          <p/>
          <h3>Just to summarise, job seekers want to see the following details in your ads:</h3>
          <p>✔ requirements of the role</p>
          <p>✔ necessary skills and qualifications</p>
          <p>✔ location of the position</p>
          <p>✔ salary information</p>
          <p>✔ benefits&nbsp;</p>
          <p>✔ company culture and values</p>
          <p/>
          <p/>
          <h2>What do I need to know before I start drafting my ad?</h2>
          <p>Before you start drafting your job advert, it's important to know who you're writing it for. Which position are you trying to fill? Keep in mind that filling a junior position is very different from filling a senior position.</p>
          <p>For junior candidates, the focus is on gaining experience and joining a supportive team that shares knowledge. They want to know if they'll have a mentor and progress their career.</p>
          <p>Senior candidates, on the other hand, might be more interested in the types of projects they'll be working on, their responsibilities, and potential for growth. In terms of benefits, they're likely to prioritise items like pension schemes, holiday allowances (especially if they have a family), and health insurance.</p>
          <p>According to a survey by <a href="https://www.linkedin.com/company/perkbox/" target="_blank" rel="nofollow noopener">Perkbox</a> of 1,532 UK employees, each generation has different priorities when it comes to benefits.</p>
          <p><strong>Baby boomers (born between 1946-1964)</strong></p>
          <ul>
          <li>health insurance</li>
          <li>retirement and pension planning</li>
          <li>flexible working to help with caring duties</li>
          </ul>
          <p><strong>Generation X (born between 1965-1980)</strong></p>
          <ul>
          <li>additional holiday</li>
          <li>flexible working</li>
          <li>childcare assistance</li>
          <li>wellbeing coaching</li>
          <li>health insurance</li>
          </ul>
          <p><strong>Millennials (born between 1980-1995)</strong></p>
          <ul>
          <li>working from home or remotely</li>
          <li>personal recognition for their efforts</li>
          <li>opportunities to travel or work abroad</li>
          <li>free meals at work</li>
          <li>gym discounts</li>
          <li>casual dress code</li>
          </ul>
          <p><strong>Generation Z (born after 1996)</strong></p>
          <ul>
          <li>casual dress code</li>
          <li>health insurance</li>
          <li>mental health services</li>
          <li>a company smartphone or laptop</li>
          <li>flexible hours</li>
          <li>free coffee or meals</li>
          </ul>
          <p>By keeping these generational differences in mind, you can tailor your job advert to better appeal to your target audience and attract the right candidates for the job.</p>
          <p/>
          <p/>
          <h2>How to structure your job ad?&nbsp;</h2>
          <p>Writing and structuring an effective job advert is luckily not that difficult. Most people will only scan ads and won't read every single word (just like people don't read every single word in a CV). Therefore, it's important to break up the text. Avoid long sentences and aim for 13-18 words per sentence. Use bullet points when listing requirements and benefits. Provide a clear call to action and ideally, inform the candidate about your interview process.</p>
          <p/>
          <p>📍 <strong>Position and Location</strong></p>
          <p>Be clear about the job title and office location. It's important to let job seekers know where your office is located so they can consider if the commute is feasible. Use the correct job title and avoid abbreviations.&nbsp;</p>
          <p>For example, instead of "Pt II", write "Part 2 Architectural Assistant".</p>
          <p/>
          <p>⭐ <strong>About the Company</strong></p>
          <p>Tell the candidate a little about your company, what you're working on, your mission, recent awards and what's important to you. I suggest keeping this down to one short paragraph. If the job seeker is interested to find out more, they will check out your website and social media accounts.</p>
          <p/>
          <p>👩&zwj;🏫 <strong>About the Role</strong></p>
          <p>Explain the role in detail, including what the candidate would work on, any particular sectors, and a couple of responsibilities. Use "you will" or "you would" instead of "the candidate would" to make the job sound more appealing. Be careful to not overload job seekers with too much info.</p>
          <p>A <a href="https://www.forbes.com/sites/jackzenger/2018/04/08/the-confidence-gap-in-men-and-women-why-it-matters-and-how-to-overcome-it/" target="_blank" rel="nofollow noopener">Hewlett Packard report</a> states that men will apply for a job if they meet 60% of qualifications, yet women will only apply if they feel like they meet 100% of them</p>
          <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/HBR.png" alt="Stats from: Why women don't apply for jobs unless they're 100% qualified by Tara Sophia Mohr"></img>
          <p>Source: <a href="https://hbr.org/2014/08/why-women-dont-apply-for-jobs-unless-theyre-100-qualified" target="_blank" rel="nofollow noopener">HBR</a></p>
          <p/>
          <p>👩&zwj;🎓 <strong>Skills, Experience, and Qualifications (use bullet points)</strong></p>
          <p>List the most important requirements, but keep them to a minimum. Don't list every single one. Adding more than seven role responsibilities would discourage a reported <a href="https://insightsresources.seek.com.au/candidates-look-job-ad" target="_blank" rel="nofollow noopener">69% of candidates</a> from applying. If you have to, you can have two separate sections, one for "Requirements" and the other for "Desirable Skills".</p>
          <p/>
          <p>💷 <strong>Pay and Benefits</strong></p>
          <p>As mentioned earlier, 78% of job seekers are less likely to apply to a position if they don't know the company's salary, and 23% said benefits are one of the most important factors when applying for a job.&nbsp;</p>
          <p>Always aim to provide a salary range and list at least 3-4 benefits, such as clear career progression, paid membership fees, hybrid working, enhanced parental leave, etc.&nbsp;</p>
          <p>Additionally, mention the working hours, as offering a <a href="https://jobadder.com/blog/top-employee-retention-strategies" target="_blank" rel="nofollow noopener">good work-life balance</a>&nbsp;is significantly important to candidates, with 51% of job seekers attracted to a job because it offers flexible hours.&nbsp;</p>
          <p>Avoid listing benefits that are the legal requirement, such as 20 days plus bank holidays in the UK, as they are not worth highlighting in the ad.</p>
          <p/>
          <p>📃 <strong>How to Apply or Call to Action (CTA)</strong></p>
          <p>Explain how the candidate can apply and which documents they need to submit. Always include a clear call to action, whether they need to submit their details via email, recruitment portal, or the Apply Now button below.&nbsp;</p>
          <p>If job seekers have to apply via your recruitment portal, explain what they can expect, as they dislike applying via internal portals since they never know how long it will take (10, 20, 30min?).&nbsp;</p>
          <p>For example, "Please submit your CV and Sample Portfolio (max 10MB) via the Apply Now button below."</p>
          <p/>
          <h3>Here is an example of a job advert</h3>
          <p><em>ArchJobs, a dynamic and sociable team of 8 architects and technicians based in central Edinburgh, is seeking a talented Architect to join our creative studio.</em></p>
          <p><em>Established since 2017, we have been working on high-end residential schemes across the city, and due to our success in winning multiple new projects, we're looking for an ARB registered Architect to help us continue our growth.</em></p>
          <p><strong><em>About the Role</em></strong></p>
          <p><em>We're offering an opportunity for an experienced Architect to join us in Edinburgh and work on high-end residential projects. You will be responsible for overseeing 2-3 projects at the same time, visiting sites as needed, and producing technical detailing.</em></p>
          <p><strong><em>To be successful in this role you must have:&nbsp;</em></strong></p>
          <ul>
          <li><em>ARB registration</em></li>
          <li><em>2 years post qualification experience</em></li>
          <li><em>Experience working on residential schemes</em></li>
          <li><em>Good Revit &amp; Adobe Creative Suite skills</em></li>
          <li><em>Able to produce Tender and Construction packages&nbsp;</em></li>
          <li><em>Interpersonal skills and ability to build long lasting relationships</em></li>
          <li><em>Communication and presentation abilities&nbsp;&nbsp;</em></li>
          <li><em>A good eye for design detail &amp; creativity</em></li>
          </ul>
          <p><em>Please note you must be eligible to work and live in the UK without any additional approval.</em></p>
          <p><strong><em>Benefits</em></strong></p>
          <p><em>In return, we offer a salary of &pound;34,500 - &pound;36,500 per annum plus 24 days holiday + bank holidays plus paid membership fees, bonus scheme, enhanced parental leave, and private healthcare.</em><em>Hybrid working is also available. There is clear career progression within the company as we look to grow our business further over the coming years.</em></p>
          <p><em>If you'd like to find out more about us and our projects, head over to ArchJobs.co.uk.</em></p>
          <p><strong><em>How to Apply</em></strong></p>
          <p><em>If you think you would make a great addition to our team then please send your CV along with a Sample Portfolio (max 10MB) to Aylin at hello@archjobs.co.uk by Monday 3 April. </em></p>
          <p><em>We are an equal opportunities employer and aim to develop our team to reach their full career potential.</em></p>
          <p/>
          <p><strong>Remember&nbsp;</strong></p>
          <p>A job advertisement is primarily focused on promoting the job opening and attracting potential candidates. It should communicate the job's key responsibilities, qualifications, and skills required, but it should also highlight what makes the company an attractive place to work, such as its culture, values, benefits, and opportunities for career growth.</p>
          <p>An effective job ad should grab the attention of job seekers and encourage them to apply for the position. By presenting your company as an employer of choice and highlighting what sets it apart from competitors, you can help to attract top talent and build a strong candidate pool.</p>
          <p/>
          <p/>
          <h2>How can unconscious gender bias impact your job ads?</h2>
          <p>Are you wondering how to write a job advert that attracts a diverse pool of applicants? It's important to be aware of unconscious gender bias and its impact on your job ads.</p>
          <p>Gender bias refers to favouritism or prejudice towards a particular gender, often resulting from unconscious biases and stereotypes.&nbsp;</p>
          <p><strong>Here are some eye-opening statistics to consider:</strong></p>
          <ul>
          <li><a href="https://www.totaljobs.com/insidejob/decoding-gender-bias-in-job-adverts/#job-adverts-analysis" target="_blank" rel="nofollow noopener">Women are 50% less likely</a> to consider roles that have a coded gender bias in the advert.</li>
          <li>Creating job ads using <a href="https://www.ziprecruiter.com/blog/removing-gendered-keywords-gets-you-more-applicants/" target="_blank" rel="nofollow noopener">gender-neutral wording attracts 42%</a> more responses.&nbsp;</li>
          <li>Gender bias language can put off up to <a href="https://www.peoplemanagement.co.uk/article/1743030/masculine-language-job-adverts-deterring-female-candidates" target="_blank" rel="nofollow noopenernoopener">44% of female</a> applicants.</li>
          <li>LinkedIn found that <a href="https://business.linkedin.com/talent-solutions/blog/job-descriptions/2018/new-job-post-stats" target="_blank" rel="nofollow noopener">men apply 13% more often than women for jobs</a>, partly because of the use of gender-coded language in the job advert.</li>
          </ul>
          <p>To avoid unintentional gender bias in your job ads, you can use free online tools such as <a href="https://gender-decoder.katmatfield.com/" target="_blank" rel="nofollow noopener">Gender Decoder Kat Matfield</a> or&nbsp; <a href="https://www.totaljobs.com/insidejob/gender-bias-decoder/" target="_blank" rel="noopener">Totaljobs Gender Bias Decoder</a>. These resources can help you identify and eliminate gender-biased language.</p>
          <p/>
          <p/>
          <h2>Conclusion</h2>
          <p>Writing an effective job advertisement requires careful consideration and attention to detail. It's important to understand what job seekers are looking for in an advert and to ensure that your ad is inclusive, accurate, and informative.&nbsp;</p>
          <p>By avoiding common pitfalls such as using jargon, being too vague, or copying and pasting from old listings, you can create a job ad that stands out from the crowd and attracts the right candidates for the role.&nbsp;</p>
          <p>Remember to highlight the necessary requirements, responsibilities, salary, and benefits, and give candidates an insight into the company culture and values.</p>
          <p>Did you know that ArchJobs can write your <a href="https://www.archjobs.co.uk/post-job" target="_blank" rel="noopener">job advertisement for you</a>? Our team will optimise your ad to attract more applications, saving you time and potentially the expense of using a recruiter. To learn more, visit <a href="https://www.archjobs.co.uk/post-job" target="_blank" rel="noopener">ArchJobs</a> or email us on <a href="mailto:hello@archjobs.co.uk" target="_blank" rel="noopener">hello@archjobs.co.uk</a>.</p>
            </ArticleFrame>
    }
}