import ArticleFrame from "./ArticleFrame";
import React, { Component } from 'react';


//first go to ArticleData.js and add all the relavant data
//next, rename "YourArticle" below to what you named the article data section
const thisArticleData = "PayIncrease"


//rename YourArticle below to your article name
export default class PayIncrease extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
      };
    }

    componentDidMount = () => {
      window.scrollTo(0, 0)
    }
    render(){
        return <ArticleFrame ArticleDataName={thisArticleData}>
            <p>Are you looking for a pay increase, but you are not sure how to approach the subject? If so, you're in the right place. In this article, we will discuss how to negotiate your salary in depth. It is something most people don&rsquo;t feel confident talking about, but it&rsquo;s something we shouldn&rsquo;t avoid. With most things in life, preparation is key. We'll provide some tips on what to say and do when asking for a raise, as well as some common mistakes that people make during salary negotiations.</p>
            <p/>
            <h3>Let&rsquo;s set the scene</h3>
            <p>You&rsquo;re an Architect with 4 years of post qualification experience. You joined your current practice 2.5 years ago and everything is going well, you run your own projects, the team is great, you're the go to person for any Revit questions and you&rsquo;ve become an integral part of the team. Overall you can see yourself with the practice for a long time.&nbsp;</p>
            <p>But there is one small thing which bugs you - during those 2.5 years your salary hasn&rsquo;t changed at all. Even with all the extra responsibilities you took on.</p>
            <p>So yes, maybe you should ask for an increase. But what is the best way to do this and what should you say? Let&rsquo;s get into it.</p>
            <p/>
            <p/>
            <h3>Do your research</h3>
            <p>One of the most important things to do when preparing for a salary negotiation is to research what your current market value is - aka how much are you worth. This means doing some research on different sites like Glassdoor or RIBA, as well as talking with other Architects in your area to get an idea of the average salary for your role. Having this information is absolutely crucial and will help validate your request for an increase.&nbsp;</p>
            <p><strong>Tip: </strong>You can also talk to recruiters who specialise in your sector. They will be able to provide you with a salary range.&nbsp;</p>
            <p/>
            <p/>
            <h3>Build your case</h3>
            <p>My secret negotiation tip is to always come prepared with a list of your accomplishments and concrete examples that demonstrate why you deserve a pay increase. By doing this, you can not only make a strong case for yourself, but also avoid getting nervous during the meeting.</p>
            <p>Think of everything you&rsquo;ve achieved and what you&rsquo;re doing right now. Are you mentoring junior staff, have you helped to secure other projects due to your excellent client-facing skills, are you running multiple projects, are you reliable, do you produce high quality work, have you gone above and beyond and so on.</p>
            <p>Companies usually reward loyalty, so you want to showcase times that you took the initiative when things get tough.&nbsp;</p>
            <p/>
            <p/>
            <h3>Know the Salary you want</h3>
            <p>You&rsquo;ve done your market research and wrote down all of your accomplishments. Next you need to figure out what salary you&rsquo;re trying to achieve. Don&rsquo;t just go in and ask for a 2-5k increase but give them some precise number/range.&nbsp;</p>
            <p>Tell them you&rsquo;ve done your market research and the going rate for a job running architect in your area is between 36.2-38.6k - and your current salary doesn&rsquo;t reflect current market rate.</p>
            <p><strong>Tip: </strong>Keep in mind, if you give them a range, they will most likely look at the lowest figure - in this case &pound;36.2k. Make sure you would be happy with that figure because you can&rsquo;t go back and say, actually, I&rsquo;m looking for more like &pound;39k. This will look incredibly unprofessional.&nbsp;</p>
            <p>Did you know only 7% of women attempt to negotiate their first salary, while 57% of men did? Source: Study by Linda Babcock</p>
            <p/>
            <p/>
            <h3>Prepare for a No</h3>
            <p>Always prepare for a no. Let&rsquo;s say your boss won&rsquo;t give you a salary increase. What do you do? Walk out and be upset? No, think outside the box - what is something which won&rsquo;t cost your company much but could be of high value to you? Maybe it&rsquo;s working from home 2 or 3 times a week, asking for a more flexible schedule or even increasing your holiday allowance by a day or two etc.&nbsp;</p>
            <p>If they won&rsquo;t increase your salary, it's also worth thinking about whether you would consider looking elsewhere, or if you are happy to wait for a potential increase in a year or two?&nbsp;</p>
            <p/>
            <p/>
            <h3>Practice what you want to say</h3>
            <p>Another key tip for negotiating your salary is to practice what you're going to say ahead of time. It can be helpful to write down bullet points that you can use during the meeting, so that you stay focused and don't get sidetracked by emotions or other distractions. I know a lot of people get very nervous and find it very stressful. Preparation is key.</p>
            <p/>
            <p/>
            <h3>When to have a conversation?</h3>
            <p>I would highly recommend scheduling a face to face meeting with your boss. You don&rsquo;t want to have this conversation in the hallway or in the kitchen. Ask for at least 20-30min of their time.&nbsp;</p>
            <p>Also studies have shown you&rsquo;re more likely to get a raise if you ask on Thursdays and Fridays.</p>
            <p><em>&ldquo;Thursdays and Fridays find us most open to negotiation and compromise because we want to finish our work before the week is out.&rdquo;</em> Source: <a href="http://www.psychologytoday.com/articles/199809/if-its-thursday-ask-raise" target="_blank" rel="nofollow noopener"> Psychology Today</a>.</p>
            <p/>
            <p/>
            <h3>You&rsquo;re not looking to leave</h3>
            <p>When you have the meeting make it crystal clear you&rsquo;re not looking for a new job as you really enjoy working for them and this is a long term career move.</p>
            <p>Most practices much rather invest in their staff if they know you&rsquo;re in for the long ride.&nbsp;</p>
            <p/>
            <p/>
            <h3>Don&rsquo;t give them an ultimatum</h3>
            <p><em>&rdquo;If you don&rsquo;t increase my salary to 38k than I&rsquo;m afraid I will look for a new job.&rdquo;</em>
            <p/>Please don&rsquo;t do that. This is not a hostage negotiation. No matter what the outcome of the meeting is, stay polite and professional. Maybe you can ask for another meeting in a couple of months.</p>
            <p>You can still look for another job if they say no, however it&rsquo;s best not to mention it to your employer and definitely shouldn&rsquo;t be used as a bargaining chip.</p>
            <p/>
            <p/>
            <h3>Negotiating your pay - The meeting</h3>
            <p>First of all, be confident! Walk into the room with a smile and create a positive and calming environment.&nbsp;</p>
            <p>Start the conversation with something like:
            <p/><em>&rdquo;I really enjoy working here and find my projects very challenging. In the last year, I&rsquo;ve been feeling that the scope of my work has expanded quite a bit. I believe my roles and responsibilities, and my contributions have risen. I&rsquo;d like to discuss with you the possibilities of reviewing my compensation.&rdquo;</em> 
            <p/>Present your case&hellip; list your accomplishments, market research and what salary you&rsquo;re looking for.&nbsp;</p>
            <p>After that, stop talking and see what your boss says and don&rsquo;t break the silence. This is a useful technique and don&rsquo;t feel like you need to say anything. Don&rsquo;t forget, your boss was most likely not prepared for this type of conversation, so give them 5-10 seconds.&nbsp;&nbsp;</p>
            <p/>
            <p/>
            <h3>This conversation usually goes 3 ways</h3>
            <p/>
            <p><strong><em>&ldquo;Oh wow, you've been with us for 2.5 years already? Okay, shall we schedule a meeting for Tuesday and discuss your next steps?&rdquo;</em></strong></p>
            <p>This is pretty much the outcome we were hoping for. It also sounds like you&rsquo;ve presented a good case and your boss clearly doesn&rsquo;t want to lose you. Well done.&nbsp;</p>
            <p/>
            <p><strong><em>&ldquo;Mhmmm we&rsquo;re planning on having a salary review in 5 months time. We can have a chat then.&rdquo;</em></strong></p>
            <p>This might not sound great but you&rsquo;re not done yet. You could either ask if you&rsquo;re on track with your goals or if you need to work on specific points.&nbsp;</p>
            <p>If you aren&rsquo;t happy to wait another 5 months - you could always ask for other benefits, such as adjusting your working arrangements (if that is something you would like), or attending more networking events etc. Whatever you value.&nbsp;</p>
            <p/>
            <p><strong><em>&ldquo;Sorry, you want how much money?! There is NO WAY we are able to pay that.&rdquo;&nbsp;</em></strong></p>
            <p>That&rsquo;s not the response we were hoping for and things just got a whole lot more stressful (thanks boss). But keep your nerves and find out why they said that. The better you understand the constraints, the more likely it is that you&rsquo;ll find a solution and both sides are happy with it.</p>
            <p>Ask for ways how you could achieve your salary/career goals, and ideally you should ask for a structured career development plan.&nbsp;</p>
            <p>However, if your boss won&rsquo;t even do that, then it might be time to look somewhere else as they clearly don&rsquo;t value your development as an employee.</p>
            <p/>
            <p/>
            <h3>How not to negotiate</h3>
            <p>The most common mistakes people make during salary negotiations are:</p>
            <p>❌ Asking for too much or making unrealistic requests. Salary negotiations are a delicate process, and it's important to be realistic about what you're asking for.</p>
            <p>❌ Not preparing ahead of time. It's crucial to do your research beforehand so that you know what the average compensation is in your field, as well as having a clear argument for why you deserve a pay increase.</p>
            <p>❌ Getting flustered or emotional during the negotiation. It's important to stay calm and professional throughout the process, and not let your emotions get in the way of securing a good deal. Practicing what you want to say can help with this.</p>
            <p>❌ You joined the practice less than a year ago and you are already asking for a chunky pay increase. Don&rsquo;t forget, you need to prove yourself and show them what you bring to the table before asking for an increase.&nbsp;</p>
            <p/>
            <p/>
            <h2>Conclusion</h2>
            <p>As scary as asking for more money is, just remember that negotiation is just a conversation.&nbsp; Do your market research first - if you truly believe you&rsquo;re not being paid enough and come prepared with a list with all your accomplishments, then you have a good chance to get the salary increase you want.&nbsp;</p>
            <p>Do your research, know your worth, practice your pitch, think outside the box, schedule a meeting and be confident when talking to your boss.&nbsp;</p>
            <p>So, what are you waiting for? Start preparing today to ace your salary negotiation and secure that raise you're looking for. <a href="https://www.archjobs.co.uk/architectural-career-coaching" target="_blank" rel="noopener">For additional help and advice on negotiating your pay increase, book a confidential call with me</a>, and I'll provide you with more tips and tricks.</p>
            </ArticleFrame>
    }
}

// Now, go to the App.js file
// add this line at the top, rename {YourArticle} to whatever you called it. You also need to copy and rename this file to "{YourArticle}.js"
// import YourArticle from './articles/YourArticle'
// Add another line in the Routes section, renaming "YourArticle" again:
// <Route path="/articles/YourArticle" element={<YourArticle/>} />


// now in TERMINAL below, run the following command:
// npm run-script dev
// head to the article page and review the html to make sure its properly formatted
//add <p/> between rows where you want a larger space
//add any images that were missed out (will need to upload these to article_images folder too) eg. <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/example_keywords.png" alt="Archjobs Keywords when applying"></img>
//add rel="nofollow noopener" to any links so it doesnt impact our SEO eg.  <a href="https://dictionary.cambridge.org/dictionary/english/cv" target="_blank" rel="nofollow noopener">Cambridge Dictionary</a>





// finally, publish the changes to github
// When its live, go to search console and inspect the new url: https://search.google.com/search-console?resource_id=sc-domain%3Aarchjobs.co.uk
// Might need a couple of attempts for it to get cached and display properly
// Make sure the rich text snippet is working properly
// If it is, request indexing from google to speed up the process