import React, { useEffect } from 'react';

const ChatWidget = () => {
  useEffect(() => {
    // Load the LeadConnectorHQ script
    const script = document.createElement('script');
    script.src = 'https://widgets.leadconnectorhq.com/loader.js';
    script.setAttribute('data-resources-url', 'https://widgets.leadconnectorhq.com/chat-widget/loader.js');
    document.body.appendChild(script);

    // Clean up the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <chat-widget
      style={{
        '--chat-widget-primary-color': '#363636',
        '--chat-widget-active-color': '#363636',
        '--chat-widget-bubble-color': '#363636',
      }}
          location-id="837jxhUTcBKn24nqtFN4"
          sub-heading="Enter your question below and we will get right back to you."
          prompt-msg="Hi there, have a question? Message us here."
          use-email-field="true"
          success-msg="One of our team members will get back to you soon."
          prompt-avatar="https://firebasestorage.googleapis.com/v0/b/highlevel-backend.appspot.com/o/locationPhotos%2F837jxhUTcBKn24nqtFN4%2Fchat-widget-person?alt=media&token=7d6a154e-1675-4c89-a27c-eb81b1b22633"
          agency-name="Lead Optimizer"
          agency-website="https://leadoptimizer.co.uk/"
      // location-id="837jxhUTcBKn24nqtFN4"
      // location-id="837jxhUTcBKn24nqtFN4"
      // prompt-avatar="https://firebasestorage.googleapis.com/v0/b/highlevel-backend.appspot.com/o/locationPhotos%2F837jxhUTcBKn24nqtFN4%2Fchat-widget-person?alt=media&token=91b31ef6-db1d-43e6-b929-e42f92f86539"
      // agency-name="Lead Optimizer"
      // agency-website="https://leadoptimizer.co.uk/"
    ></chat-widget>
  );
};

export default ChatWidget;