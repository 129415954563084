
import React from 'react'
import withRouter from '../withRouter.js';
import classNames from 'classnames';
import '../css/navbar.css'
import {MdMenu, MdArrowDropDown} from 'react-icons/md'
import {IoMdNotificationsOutline} from 'react-icons/io'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import Button from '../components/Buttons.js'

class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: "",
            open: false,
        }
      }


     toggleMenu = () => {
         this.setState({open: !this.state.open})
     }

     componentDidUpdate = (prevProps, prevState) => {
         if((this.props.router.location !== prevProps.router.location)&&(this.state.open)){
             this.setState({open: false})
         }
     }

     componentDidMount = () => {
        window.addEventListener('click', (e) => {  
            if ((document.getElementById('NavbarContainer') !== null) && this.state.open){
                if (document.getElementById('NavbarContainer').contains(e.target)){
                    // Clicked in box
                  } else{
                    // Clicked outside the box
                     this.setState({open: false})
                  }
            } 

          });
     }

    render() {

        let navBarClass = "Navbar"
        console.log("nav props:", this.props)
        if (this.props.router?.location?.pathname?.startsWith("/contact-me")){
            navBarClass = "Navbar ContactPageNavBar"
        }

      return (
        <div className={navBarClass}>
            <div className="NavbarContainer" id="NavbarContainer">
                <Link className="NavbarTitle" to="/">
                    {/* <img alt="ArchJobs Recruitment Logo" src="ARlogo_1.svg" className="NavbarLogoImg"/> */}
                    <span className="ArchjobsTitle1">ArchJobs</span> 
                    <span className="ArchjobsTitle2">Recruitment</span> 
                </Link>
                <div className={classNames("NavbarLinks", {hidden: !this.state.open})} >
                    <a href="https://recruitcrm.io/jobs/archjobs" target="_blank" rel="noreferrer" className="NavLink">
                         View Jobs</a>
                    <Link to="/job-seekers" className={classNames("NavLink", {active: this.props.router.location.pathname.startsWith("/job-seekers") || this.props.router.location.pathname.startsWith("/submit-cv")})} >
                        Job Seekers
                    </Link>
                    <Link to="/recruitment" className={classNames("NavLink", {active: this.props.router.location.pathname.startsWith("/recruitment")})} >
                        Recruitment
                    </Link>
                    <a href="https://recruitcrm.io/talent_pool_submission/ArchJobs" target="_blank" rel="noreferrer" className="NavLink">
                        Submit CV</a>
                    {/* <Link to="/services" className={classNames("NavLink", {active: this.props.router.location.pathname === "/services"})}>
                        Services
                    </Link> */}
                    <Link to="/contact-me" className={classNames("NavLink", {active: this.props.router.location.pathname.startsWith("/contact-me")})}>
                        Contact Me
                    </Link>
                
                </div>
                <div className="CollapseMenuContainer">
                    <MdMenu className="CollapseMenu" onClick={() => {this.toggleMenu()}}/>
                </div>
                
            </div>
            {/* <div className="NavbarBanner">
                We're having some temporary server issues but are working on it - please check back in a bit!
            </div> */}
        </div>
      )
    }
  }

  export default withRouter(Navbar)