import ArticleFrame from "./ArticleFrame";
import React, { Component } from 'react';


export default class ChooseOffers extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
      };
    }
    componentDidMount = () => {
      window.scrollTo(0, 0)
    }

    render(){
        return <ArticleFrame ArticleDataName="ChooseOffers" >
          <p>Getting multiple job offers can be exciting, but also kind of overwhelming, especially when the offers seem really similar - same job title, same pay, and similar projects. It can be easy to decide if one company really stood out during the interview, but what if you genuinely like both options? Making a smart choice that matches your career goals and dreams is really important.&nbsp;</p>
          <p>In this article, I'll help you figure out the best steps to take when comparing and choosing between similar job offers in the architecture field. We'll talk about understanding what motivates you, comparing the offers carefully, and thinking about the good and bad parts of each option. My goal is to help you make the best decision for your future in a way that's easy to understand.</p>
          <p/>
          <p/>
          <h3>Remember Why you are Looking for a New Position</h3>
          <p>Before delving into the evaluation of job offers, it's crucial to understand the underlying reasons for why you even looked for a new position in the first place. Did you feel stagnant in your current role, crave for more challenges and opportunities to grow? Or perhaps you were seeking exposure to diverse sectors and project types to expand your architectural expertise. It could also be that you found yourself in a toxic working environment that hampered your professional development.</p>
          <p>By remembering your motivations, you can effectively evaluate whether the new job offers align with your desired career trajectory.</p>
          <p><strong>In case you need a quick refresher, why not go through these 10 questions:</strong></p>
          <ol>
          <li>What were the reasons driving my wish to leave my current job?</li>
          <li>Was I seeking new challenges and growth opportunities that were lacking in my current role?</li>
          <li>Did the work environment exhibit toxicity or hinder my well-being?</li>
          <li>Were there limited prospects for career advancement in my current position?</li>
          <li>Did my current job align with my long-term career goals and aspirations?</li>
          <li>Was I content with my current salary and benefits package?</li>
          <li>Did I feel valued and appreciated by my team leader/director and colleagues?</li>
          <li>Did my current job offer a healthy work-life balance?</li>
          <li>Did the company's values and culture resonate with my own?</li>
          <li>Had I explored all potential avenues for improvement or change within my current organisation?</li>
          </ol>
          <p/>
          <p/>
          <h3>Understanding Your Career Goals and Aspirations</h3>
          <p>Having a clear understanding of your long-term career goals is important when evaluating job offers. Contemplate where you envision yourself in the next two, five, or ten years. Are you aspiring to attain a specific leadership position within the architecture industry? Are you seeking opportunities to specialise in a particular architectural niche? Defining your career aspirations will enable you to assess whether the new job offers align with your ambitions and provide the necessary growth opportunities to propel your career forward.</p>
          <p>I hope that during your interviews, you managed to get a good feel for each practice and asked questions regarding career development and how they would support you.</p>
          <p/>
          <p/>
          <h3>Initial Impressions During and After the Interview Process</h3>
          <p>Recall your initial impressions of each company and the people you interacted with during the interview process. Reflect on factors such as how well you connected with the interviewers and the overall vibe you experienced during your interactions. These initial impressions can provide valuable insights into the company culture, work environment, and how well you might fit within the organisation.</p>
          <p>This also applies to the post-interview phase. How was the communication handled? Did you feel like you were always kept in the loop, or did they make you wait and wonder what would happen next?&nbsp;</p>
          <p>Effective communication during the post-interview stage is crucial for candidates to have clarity and peace of mind regarding the status of their application. If you experienced consistent and timely communication, it reflects positively on the organisation's professionalism and consideration.&nbsp;</p>
          <p>However, if you were left waiting and uncertain about the next steps, it may indicate a lack of organisation or disregard for candidate experience.</p>
          <p/>
          <p/>
          <h3>Looking at the Bigger Picture</h3>
          <p>When comparing two similar job offers, it's important to ask yourself the right questions to make an informed decision.</p>
          <ol>
          <li>Which job aligns better with my long-term career goals?</li>
          <li>Which company's culture and values resonate with me more?</li>
          <li>Does one offer more opportunities for professional growth and development?</li>
          <li>How does the salary and benefits package compare between the two offers?</li>
          <li>Which position offers better work-life balance and flexibility?</li>
          <li>Does one offer more desirable benefits, such as healthcare, pension plans, or holiday allowance?</li>
          <li>Which company has a better reputation within the industry?</li>
          <li>Are there differences in the commuting time and associated costs between the two positions?</li>
          <li>Which work environment and team dynamics seem more appealing to me?</li>
          <li>Which position offers more challenging and interesting projects or responsibilities?</li>
          </ol>
          <p>By carefully considering these questions, you can gain clarity on the aspects that matter most to you and make an informed decision when choosing between two similar job offers.</p>
          <p/>
          <p/>
          <h3>Employment Contracts</h3>
          <p>Before making a decision, request to see the employment contract from each prospective employer. Review the contract thoroughly to ensure that it covers all the essential details discussed during the hiring process. Pay attention to important factors such as salary, benefits, working hours, notice period, and any other relevant terms and conditions. It's crucial to have a clear understanding of what you are agreeing to before accepting an offer.</p>
          <p/>
          <p/>
          <h3>Job Title and Responsibilities</h3>
          <p>Carefully examine the job titles and accompanying responsibilities outlined in each offer. Consider whether the titles represent a step up in your career path, align with your desired professional path or even what was discussed during your interview. Assess the level of responsibility and the scope of work associated with each role. Ensure that the offers provide the opportunity to expand your skill set and contribute meaningfully to projects that interest you.</p>
          <p/>
          <p/>
          <h3>Salary and Benefits Package</h3>
          <p>Review the salary and benefits packages offered by each prospective employer. Compare the compensation, taking into account factors such as basic salary, bonuses, and incentives. Additionally, assess the non-monetary benefits, including healthcare, hybrid working, pension plans, life assurance, paid membership fees and other perks. Consider the overall value of the package and how it aligns with your financial goals and personal needs.</p>
          <p>Refer to our <a href="https://www.archjobs.co.uk/salary-guide" target="_blank" rel="noopener">Architectural Salary Guide</a> to determine if the salary is in line with current market trends and industry standards.</p>
          <p/>
          <p/>
          <h3>Notice Period and Start Date</h3>
          <p>Take note of the notice period required by each employer and the proposed start date for the new position. Check whether the notice period looks &ldquo;standard&rdquo;. Unless you&rsquo;re at an Associate level, your notice period shouldn&rsquo;t be longer than 4 weeks. Additionally, check if the proposed start date is correct, considering any personal or professional obligations you may have during that period.</p>
          <p/>
          <p/>
          <h3>Working Hours and Location</h3>
          <p>This could make a huge difference, so consider whether the working hours are suitable for your lifestyle and whether they align with your desired work-life balance. Most architectural practices will offer full-time positions between 35 - 40 hours per week. If one offer is 35 hours and the other 40 hours, that would be a 20-hour difference, potentially for the same basic salary.</p>
          <p>Evaluate the commuting distance and transportation options to the workplace, taking into account potential costs and the impact on your daily routine. Assess whether the location of the new position offers conveniences or amenities that contribute to your overall satisfaction.</p>
          <p>In my experience as a recruiter, I found that when someone's commute was longer than 1 hour each way, they were much more likely to leave due to added stress and costs.</p>
          <p/>
          <p/>
          <h3>Company Cultures</h3>
          <p>That's an important factor that many people overlook. It's crucial to remember that you will spend more than 35-40 hours per week with your team. That amount of time represents a significant portion of your week, so it's essential to evaluate whether you have a positive rapport with the team, management, and the overall company culture.&nbsp;</p>
          <p>Having good working relationships, a supportive management style, and positive company culture can greatly contribute to your job satisfaction and overall well-being in the workplace.</p>
          <p/>
          <p/>
          <h3>Seek Clarification on any Doubts or Concerns</h3>
          <p>If you have any doubts or concerns while going through the job contract or talking about the offer, don't hesitate to ask for clarification. Reach out to the recruiters, hiring managers, or HR representatives and ask them any questions or uncertainties you might have. It's crucial to fully understand the terms and conditions so that you can make a well-informed decision. Don't be afraid to seek the answers you need to feel confident about your choice.</p>
          <p>Still not sure which offer to take? No worries, let&rsquo;s explore other decision factors.&nbsp;</p>
          <p/>
          <p/>
          <h3>Room for Career Progression</h3>
          <p>Evaluate the growth opportunities offered by each employer. Consider whether the companies provide clear pathways for career advancement, mentorship programmes, or opportunities for professional development. Assess the track record of promoting employees internally and their commitment to nurturing talent. Look for indications that the new position will provide the necessary challenges and opportunities to advance your architecture career.</p>
          <p/>
          <p/>
          <h3>Learning and Development Programmes</h3>
          <p>Assess the learning and development programmes offered by each employer. Look for indications of ongoing training, workshops, or educational support to enhance your skills and knowledge. Consider whether the companies prioritise employee growth and invest in their professional development. A commitment to learning opportunities can greatly contribute to your long-term career growth and job satisfaction.</p>
          <p>The architectural field is such a fast-moving industry. It is important to stay up to date and keep improving your skills.&nbsp;</p>
          <p/>
          <p/>
          <h3>Exposure to Different Sectors or Project Types</h3>
          <p>If broadening your experience and working on diverse projects is a priority, assess whether the job offers provide exposure to different sectors or project types. Consider the variety and complexity of the projects in the company's portfolio. Check whether the new positions will enable you to expand your architectural expertise and work on projects that align with your interests and aspirations.</p>
          <p>Sometimes people find themselves "stuck" in a particular sector, such as healthcare, for example. Unless you are content with becoming an expert in that specific area, you might want to ensure that you can work in various sectors and broaden your knowledge and experience. This will also be beneficial if you seek new opportunities in the future, as most clients prefer to see a well-rounded professional (think long term).</p>
          <p/>
          <p/>
          <h3>Company Reputation and Stability</h3>
          <p>Assess the reputation and stability of each prospective employer within the architecture industry. Look for indicators of their track record, such as the types of projects they have completed, awards they have received, or their involvement in industry organisations. Consider factors such as financial stability, client base, and overall standing within the industry. A reputable and stable company can offer a more secure and fulfilling career opportunity.You can always use Glassdoor to read reviews, other social media platforms or see if they have been in the news lately.&nbsp;</p>
          <p/>
          <p/>
          <h3>List the Advantages of Each Job Offer</h3>
          <p>Take the time to list the advantages of each job offer you have received. Consider factors such as salary, benefits, career growth opportunities, work-life balance, company culture, and the overall alignment of the role with your professional goals. Highlight the positive aspects of each offer and evaluate how they contribute to your long-term career aspirations.</p>
          <p/>
          <p/>
          <h3>Identify the Disadvantages or Potential Challenges</h3>
          <p>In addition to the advantages, it's important to identify and assess the potential disadvantages or challenges associated with each job offer. Consider factors such as commute time, work demands, potential conflicts with personal commitments, or any limitations that might affect your career progression. That way you can make a more balanced assessment of each offer and understand the potential trade-offs involved.</p>
          <p/>
          <p/>
          <h3>Other Benefits and Perks&nbsp;</h3>
          <p>When comparing job offers, look beyond the traditional benefits and perks and consider any unique offerings or non-traditional benefits that may enhance your overall job satisfaction. Some companies may offer flexible work arrangements, remote work options, additional holiday time, wellness programmes, or opportunities for skill development outside of your core role.&nbsp;</p>
          <p/>
          <p/>
          <h3>Consider the Potential Impact on Your Personal Life</h3>
          <p>Choosing a job offer isn't solely about professional considerations; it's also important to assess the potential impact on your personal life and relationships. Reflect on how each job offer may affect your work-life balance, commute time, and overall well-being.&nbsp;</p>
          <p>Consider the location of the office, the demands of the role, and the potential adjustments you may need to make in your personal life to accommodate the new job.&nbsp;</p>
          <p>For example, if you are currently sharing school drop-offs in the morning but due to the new office location, you won't be able to continue doing them. Would your partner be able to handle all of them?</p>
          <p>Striking a balance between professional fulfilment and personal happiness is crucial for long-term satisfaction.</p>
          <p/>
          <p/>
          <h3>Ask for Help</h3>
          <p>When faced with a challenging decision, seeking advice from mentors, trusted friends, and family can be beneficial as they can provide valuable perspectives and insights. However, it's important to recognise that their opinions may differ from yours, which can make the decision-making process more challenging.</p>
          <p>Ultimately, it's important to remember that the decision is yours to make, and you should consider the advice and opinions of others as just one factor in the equation. It can be helpful to listen to different viewpoints, weigh the pros and cons, and trust your own judgment and intuition in making the final decision that aligns with your values, goals, and circumstances.</p>
          <p/>
          <p/>
          <h3>Accepting an Offer</h3>
          <p>Once you have made up your mind, it's important to inform your new employer about your decision to accept their offer. Contact them as soon as possible and let them know you want to expect their job offer. Be sure to review and sign the contract offer as required. Additionally, inform your new employer about the date you handed in your notice at your current job.</p>
          <p>It's also beneficial to maintain open lines of communication with your new employer throughout the time leading up to your start date. This helps establish a positive and proactive relationship and ensures that any necessary arrangements or preparations are made smoothly. You never know, you mind being invited to their summer BBQ party.</p>
          <p><strong>Tip:</strong> If you are handing in your notice at your current practice, there is a very high chance they will make you a counter-offer. Think about this scenario before you speak to your boss. I mean, if they would match your new offer, would you accept the counter-offer? You might want to read &ldquo;<a href="https://www.archjobs.co.uk/articles/should-i-accept-a-counter-offer" target="_blank" rel="noopener">Should I Accept a Counter-Offer?</a>&rdquo;&nbsp;</p>
          <p/>
          <p/>
          <h3>Declining the Other Offer(s)</h3>
          <p>You know this moment will come. If you have multiple job offers, you will need to keep all parties informed. When declining a job offer, it's essential to handle the situation professionally and respectfully. Craft a polite and concise email or phone call to inform the company that you have decided to accept another offer. Express your appreciation for the opportunity to interview and for their consideration. Avoid burning bridges by maintaining a professional and courteous tone throughout the communication.&nbsp;</p>
          <p/>
          <p/>
          <h3>Maintain Positive Relationships with Potential Employers</h3>
          <p>Even though you declined an offer, it's important to maintain positive relationships with potential employers. You never know when future opportunities may arise, or when you may encounter individuals from those companies again. Maintain open lines of communication, follow up with a thank-you note for the opportunity, and keep networking within your industry.</p>
          <p/>
          <p/>
          <h3>What If None of the Offers Is Right</h3>
          <p>If none of the job offers meets your expectations or requirements, it is good to know when to walk away from the offers and keep looking. Just because you have received offers, it doesn&rsquo;t mean that they are right for you. There is no point in accepting a new position if you deep down know that they don&rsquo;t meet your expectations.&nbsp;</p>
          <p>In that instance, it would be advisable to decline both offers and continue your job search. This highlights another reason why job seekers should not rely on a single opportunity. It's essential to apply to multiple roles and have multiple interviews simultaneously (if possible). By doing so, you avoid wasting too much time in between offers and increase your chances of finding the right fit for your career.</p>
          <p/>
          <p/>
          <h3>Conclusion</h3>
          <p>Choosing between job offers is a significant decision that requires careful consideration. By assessing factors such as career progression, salary, benefits, company culture, and personal fulfillment, you can make an informed choice that aligns with your professional goals.</p>
          <p>Seek additional information, create a pros and cons list, trust your instincts, and prioritise your long-term aspirations. If none of the offers meets your expectations, reassess your priorities and consider alternative avenues.&nbsp;</p>
          <p>Remember to handle the decline professionally and maintain positive relationships. Ultimately, by following a systematic approach, you can make a decision that propels your architecture career forward.&nbsp;</p>
            </ArticleFrame>
    }
}