import ArticleFrame from "./ArticleFrame";
import React, { Component } from 'react';


export default class JobInterview extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
      };
    }
    componentDidMount = () => {
      window.scrollTo(0, 0)
    }

    render(){
        return <ArticleFrame ArticleDataName="JobInterview" >
          <p>So, you received an interview invite - now it's time to really make sure you get that offer. Now you might be thinking: How do I prepare for a job interview? What are the most common interview questions? How much research should I do before interviewing? Should I ask them questions at the end? I&rsquo;ll answer all of these and more below.</p>
          <p>When doing your interview prep, remember that your first impression counts, just like having a <a href="https://www.archjobs.co.uk/articles/how-to-write-and-structure-your-cv" target="_blank" rel="noopener">well-written and structured CV</a>. This means doing your research about the company, their niche, who is interviewing you, and making sure you have reasons why you're a suitable fit for the role/company. The company's website, LinkedIn and Glassdoor pages will become your best friend for researching this information, as well as any news or social media from the company.</p>
          <p/>
          <p/>
          <h2>Type of Interviews</h2>
          <p>First, you need to know what type of interview you will attend and ideally how many stages they do. Here are some of the most common interview types, and some advice on how to prepare.&nbsp;</p>
          <p><strong>Telephone</strong> - a telephone interview is often used as the initial step. This will help the recruiter or hiring manager filter candidates and decide which people they would like to invite to an interview. Make sure you have a quiet place to talk with good reception, and that you&rsquo;re comfortable speaking on the phone for at least 20-30min.</p>
          <p><strong>Face-to-Face </strong>- this is by far the most traditional way of interviews. Not only is it easier to read each other's body language but you can also present your portfolio, see the studio, get a taste of the commute and meet the team. I would suggest turning up for a face-to-face interview around 10 min before. That will give you enough time to settle down, have a glass of water and gather your thoughts.&nbsp;</p>
          <p><strong>💡 Tip:</strong> Make sure you know how to get to your interview (car, public transport, walk). If you want to get there earlier in case of traffic, you can find a coffee shop nearby and relax before meeting them.</p>
          <p><strong>Video</strong> - this could be via Teams/Zoom/Skype. Doing a video interview can be very convenient for candidates with a busy schedule or if they&rsquo;re working from home. If you are interviewing via Teams/Zoom, try to join the meeting a couple of minutes before and ensure your microphone and camera are on. You might also want to try and have a &ldquo;practice run&rdquo; to check all your equipment works the day before.&nbsp;</p>
          <p><strong>💡 Tip:</strong> Look into the camera and not the picture of yourself. I know it&rsquo;s tricky but give it a go.</p>
          <p>&zwj;</p>
          <h2>Preparing for Different Types of Interviews</h2>
          <p/>
          <h3>Familiarise Yourself with Teams/Zoom</h3>
          <p>If you received an invite to attend a Teams/Zoom meeting, first check your emails and make sure you confirm the invite (also, check if the date and time are correct and double check your junk folder in case an invitation ends up there). Once you accept the invite, you could ask a friend/family member or your recruiter to do a quick test run with the software. Test if your camera and microphone are working, familiarise yourself with the menu - make sure you know how to share and unshare your screen, and how to potentially change your background or add background blur (see image below).&nbsp;</p> 
          <p/>
          <img className="ArticleImage teams_blur" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/teams_blur_option.png" alt="A screenshot of the Teams settings menu, highlighting the 'Apply background effect' section. The image includes a arrow which highlights where the Apply background effects setting is. The user interface displays a dropdown menu for selecting background effects, including device settings, meeting options, meeting info and more. "></img>
          <p/>
          <p/>
          <h3>Preparing for a Face-to-Face Interview</h3>
          <p>I enjoy face-to-face interviews as it&rsquo;s the easiest way to read people's body language, hold eye contact, get a feel for the company culture, try the commute and leave a better impression behind. However, before you attend your interview, check how you get there. Would you use public transport - if so, what&rsquo;s the best route? The same goes for driving, how long it will take and where you can park.</p>
          <p>I would suggest leaving an hour before you actually have to, not only will it give you a bit more time in case you are stuck in traffic or you missed a bus/train but it will also give you time to gather your thoughts and not feel rushed.&nbsp;</p>
          <p><strong>💡 Tip </strong>Put your outfit together, pack your back and get a good night's sleep the night before.&nbsp;</p>
          <p/>
          <p/>
          <h3>Do your Research on the Company and Interviewers</h3>
          <p>It might sound obvious, but do your research on the company and the people you're meeting. Read about the practice, check their website, find any news articles and see if they won any awards etc.&nbsp;</p>
          <p>The same goes for the interviewers. If you don&rsquo;t know who you&rsquo;re meeting, confirm with the hiring manager or recruiter. Ideally, you want to know the current position they hold within the business, how long they have been with the company, which projects they worked on and even where they worked before. Not only will that show the interviewers you have done your research, but it could also help break the ice.&nbsp;</p>
          <p>&zwj;</p>
          <h3>Read Through the Job Brief</h3>
          <p>Go through the job description and familiarise yourself with the role, what skills they are looking for and how you can match them. This is very important as you can match your personal skill set to the role and think of reasons why you are a good fit for the job - you need to show them you can do the job. Depending on if you work with a recruiter or hiring manager, you could always ask for a more detailed or updated job brief.</p>
          <p>&zwj;</p>
          <h3>How to Prepare for Potential Interview Questions</h3>
          <p>Having gone through the job description you should have a rough idea of what sort of questions they might ask you. Depending on the position they might ask you specific questions about your design, presentation, team leader, management and/or technical skills.</p>
          <p/>
          <p>Here are some of the most common interview questions:</p>
          <ul>
          <li>Tell us about yourself</li>
          <li>Describe your design style</li>
          <li>What other experience do you have that makes you a good fit for this role?</li>
          <li>What was your biggest achievement to date?</li>
          <li>What aspect of the job do you find the most rewarding?</li>
          <li>Tell me about a time when you missed a deadline. How did you overcome this obstacle?</li>
          <li>Why did you leave your previous job?</li>
          <li>What do you know about our company?</li>
          <li>What are your salary expectations?</li>
          <li>Do you have any questions for us?</li>
          </ul>
          <p><strong>💡 Tip:</strong> Hiring managers put a lot of emphasis on personality fit. They would much rather hire someone genuinely interested in the role/company, who is willing to learn and would integrate well into the team.&nbsp; &zwj;</p>
          <p>You might be interested in reading &ldquo;<a href="https://www.archjobs.co.uk/articles/architects-guide-how-to-answer-common-interview-questions" target="_blank" rel="noopener">Interview Guide: Answering Questions & Understanding Purpose</a>&rdquo;.&nbsp;</p>
          <p/>
          <p/>
          <h3>How do I Answer the &ldquo;Tell Us About Yourself&rdquo; Question</h3>
          <p>I find it helps to rephrase this question - tell us about your current and previous work experience and explain how your skill set matches our job brief. Easy.</p>
          <p>What they don't want to hear is your entire life story or what you had for dinner last night. Go through your current and previous work situations, responsibilities (if applicable), work highlights, awards and education. Make sure you know your working history, job titles, dates and responsibilities. Start from your most recent experience rather than in chronological order, as that is likely to be the most relevant to the role.</p>
          <p/>
          <p/>
          <h3>How to Structure your Interview Answers: The STAR Method</h3>
          <p>When answering competency or behavioural questions, use the STAR method. It will help you to structure and give a detailed answer.</p>
          <p>What does STAR Stand for?</p>
          <ul>
          <li>Situation - the situation you had to deal with</li>
          <li>Task - the task you were given to do</li>
          <li>Action - the action you took</li>
          <li>Result - what happened as a result of your action and what you learned from the experience</li>
          </ul>
          <p/>
          <h3>STAR Example&nbsp;</h3>
          <p><em>Tell me about a time when a design didn't go to plan. How did you overcome this obstacle?</em></p>
          <p><strong>Situation</strong> - &ldquo;I once designed a building but there was an error in the initial measuring of the design, meaning that the final drafts and blueprints didn't work to fit the actual available space.</p>
          <p><strong>Task </strong>- I had to solve the issue as soon as possible.</p>
          <p><strong>Action</strong> - I edited the design by updating the measurements. Then the construction team could begin building.</p>
          <p><strong>Result</strong> - As a result, my company was able to create a building that complied with safety protocol and fit the available space. After that project, I started to regularly double-check my measurements before submitting a building plan, which has improved the overall accuracy of my designs.&rdquo;</p>
          <p>&zwj;</p>
          <h3>Go through your CV and Portfolio</h3>
          <p>Familiarise yourself with your CV and go through your working history, job titles, dates and responsibilities. Write down your key achievements, identify your selling points and note some results/outcomes of your successful projects to date.&nbsp;</p>
          <p>If it's relevant to your role, the same goes for your <a href="https://www.archjobs.co.uk/articles/how-to-prepare-an-architecture-portfolio" target="_blank" rel="noopener">Architectural Portfolio</a> - be ready to present and go through two to three projects. This will not only show your presentation skills but will also test your communication skills.&nbsp;</p>
          <p><strong>💡 Tip:</strong> Showing your Portfolio shouldn&rsquo;t take longer than 10 minutes (unless the hiring manager asked for a more detailed presentation). Don&rsquo;t forget, interviews usually take between 45-60 minutes in total.</p>
          <p>&zwj;</p>
          <h3>Should I Ask Questions at the End of the Interview?</h3>
          <p>Usually, at the end of an interview, you might be asked &ldquo;Do you have any questions for us?&rdquo; and the answer is YES.&nbsp;</p>
          <p>Whether you could see yourself working there is a big decision. Before you receive an offer, you should understand the team fit, company culture, responsibilities, and much more.</p>
          <p/>
          <h3>Interview Questions to Ask</h3>
          <div>✔ Can you please share some potential projects that I&rsquo;d be working on?</div>
          <div>✔ What would be your expectations for my first 3 - 6 months?</div>
          <div>✔ What would you say are the three most important skills needed to excel in this position?</div>
          <div>✔ Which particular problem are you trying to solve by hiring for this position?</div>
          <p/>
          <p/>
          <h3>Interview Questions to Avoid</h3>
          <div>❌ How quickly could I get promoted?</div>
          <div>❌ Who are your main competitors?</div>
          <div>❌ Are you going to contact my references?</div>
          <div>❌ If I&rsquo;m hired, when can I start applying for other positions in the company?</div>
          <p/>
          <p>An interview should never be one-sided. One thing I always mentioned to my candidates was &ldquo;Don&rsquo;t forget, it&rsquo;s a two-way street. It&rsquo;s not all about selling yourself to them, they need to prove to you why you should join their company&rdquo;. You need to interview them as much as they interview you, and make sure you can really see yourself working there.</p>
          <p><strong>💡 Tip: </strong>In case you get nervous and there is a chance you might forget your questions, write them on a piece of paper/notebook.&nbsp;</p>
          <p>&zwj;</p>
          <h3>Should I Mention my Salary Expectations?</h3>
          <p>Before you enter an interview, do your research on the salary ranges for that sort of role. Unless you are working with an agency recruiter, you most likely have to negotiate your salary and benefits package.&nbsp;</p>
          <p>The hiring manager might ask you &ldquo;What are your salary expectations?&rdquo; and most people panic when they hear the question. Why? Because you don&rsquo;t want to give them a number which is too high or too low.&nbsp;</p>
          <p>To handle that question, you can say &ldquo;Having done more market research, the average salary for this role in this area is between 37.6-41.3k (give them some uneven numbers - sounds better than 35-40k) and having learned more about the responsibilities, and workload, I&rsquo;d be looking at the higher end.&rdquo;</p>
          <p><strong>💡 Tip: </strong>Try not to give them a figure you would later regret. Once you give them a range, the hiring manager will most likely go with the lower figure. If they make you an offer and you dispute the salary, they would use what you said to justify their decision.&nbsp; You can also refer to our <a href="https://www.archjobs.co.uk/salary-guide" target="_blank" rel="noopener">Salary Guide</a> for a better idea of architectural salaries in the UK.</p>
          <p>&zwj;</p>
          <h3>Don&rsquo;t Leave your Interview Prep to the Last Minute</h3>
          <p>Don't leave your interview preparation to the last minute, regardless of the interview type you're preparing for. Whether it's a telephone call, a face-to-face meeting, or an online interview, give yourself enough time to do your research about the interviewers and the practice.</p>
          <p>Trust me, it will make a huge difference and will not only help you come across as more confident, but you will also have time to prepare for any potential interview questions, practice your answers, and refine your overall presentation.</p>
          <p>Don’t forget, if the interview is face-to-face, ensure you know how to get to their office and give yourself some buffer in case of any delays or road closures. Remember, the more you invest in preparation, the more you set yourself up for success on the big day.</p>
          <p>&zwj;</p>
          <h2>Conclusion</h2>
          <p>Congratulations on receiving an interview invitation! To master the interview and secure the job offer, thorough preparation is crucial. Research the company, interviewers, and position, using the website, LinkedIn and Glassdoor. Understand the interview type &ndash; telephone, face-to-face, or video &ndash; and <a href="https://www.archjobs.co.uk/articles/architects-guide-how-to-answer-common-interview-questions" target="_blank" rel="noopener">practice answering common questions</a> using the STAR method.</p>
          <p>Review your CV and portfolio, highlighting achievements. Prepare thoughtful questions to ask the interviewer. Research salary ranges to confidently discuss salary expectations and please don't leave your interview prep to the last minute.&nbsp;</p>
          <p>If you haven't had an interview in a long time, or if you're new to interviews and feeling nervous, unsure about how to answer certain questions, or how to present your portfolio, <a href="https://www.archjobs.co.uk/architectural-career-coaching" target="_blank" rel="noopener">consider booking a tailored interview session with me</a>. I'll help boost your confidence, provide guidance on negotiating a better salary and benefits package, and equip you with the knowledge you need.</p>
            </ArticleFrame>
    }
}