import ArticleFrame from "./ArticleFrame";
import React, { Component } from 'react';


export default class ATS extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
      };
    }
    componentDidMount = () => {
      window.scrollTo(0, 0)
    }

    render(){
        return <ArticleFrame ArticleDataName="ATS" >
            <p>Whether you're actively applying to new positions or planning to in the near future, it's highly likely that you'll need to submit your job application through an Applicant Tracking System (ATS). Nowadays, many recruitment agencies and mid to large practices use these systems to manage and track all of their applications. However, if your CV isn't optimised to be read by an ATS, the chances of your application making it to a human reviewer are slim. In this article, we'll explore the most important questions and provide practical tips to help you beat the ATS system and land your dream job.</p>
            <p/>
            <h3>What is an Application Tracker System (ATS)?&nbsp;</h3>
            <p>An Applicant Tracking System is a software application that helps employers manage their recruitment process by automating the initial screening and management of CVs and job applications.&nbsp;</p>
            <p/>
            <p/>
            <h3>Why do companies use ATS systems?</h3>
            <p>With an ATS system, employers can automate many of the initial stages of the recruitment process, such as the initial screening and sorting of CVs and applications. By automating these tasks, employers can save time and resources, and focus on the most qualified candidates.</p>
            <p>In addition, ATS systems can help employers collect and store candidate information in a centralised database, making it easier to search and filter through CVs and applications. This streamlined approach helps recruiters and hiring managers quickly identify the best candidates for a particular job opening, thereby shortening the overall recruitment process.</p>
            <p><strong>💡 Did you know: </strong>Around 70% of larger companies and 20% of Small and Midsize businesses use ATS systems to manage their recruitment process.&nbsp;</p>
            <p/>
            <p/>
            <h3>How does an ATS system work?</h3>
            <p>When you submit your CV or job application, the ATS system will typically scan and analyse the document to extract relevant information such as your name, contact details, work experience, education, and skills. This information is then stored in a database, which the employer can use to search and filter through the CVs and applications.</p>
            <p>The ATS system then applies various algorithms and filters to the CV to determine if you are a good match for that particular job. These algorithms and filters may look for specific keywords, phrases, or qualifications that match the job description.</p>
            <p>If your CV passes the initial screening, it may be passed on to a recruiter or hiring manager for further review. If it doesn't pass, it might be rejected automatically or put into a database for future consideration.</p>
            <p/>
            <p>You must understand that when submitting your application via an ATS, you're sending it to software and not an actual person. Therefore, your CV needs to be ATS-friendly and optimised to ensure that it passes the initial screening and makes it to a human reviewer.</p>
            <p/>
            <p/>
            <h3>How can I optimise my CV and make it ATS-friendly?</h3>
            <p>To increase your chances of getting through the initial screening, you need to ensure that your CV is well-formatted, uses relevant keywords, and is tailored to the job you're applying for.</p>
            <p>According to recent studies, up to <a href="https://www.forbes.com/sites/nextavenue/2014/03/18/how-to-get-your-resume-read-by-an-employer/?sh=49aba3ba6865" target="_blank" rel="nofollow noopener">75% of submitted resumes</a> never make it into the hands of an actual human being. Many of these failed CVs aren't formatted correctly or don't include the relevant keywords.</p>
            <p/>
            <p/>
            <h3>How do you ensure that your CV is optimised for ATS?</h3>
            <p><strong>1. Format:</strong> To ensure your CV can be read by the ATS, you need to do the following:</p>
            <ul>
            <li>Avoid using images, tables, or graphics as they can cause scanning issues</li>
            <li>Use headings and bullet points to break up text and improve readability</li>
            <li>Use simple formatting such as bold, italics, and underline to highlight key information</li>
            </ul>
            <p><strong>2. Keywords:</strong> Use relevant keywords in your CV. When reading through the job advert, you might've noticed that they list certain skills or experiences. Pick the most relevant keywords and incorporate them into your CV/Cover Letter. Let's say the job advert lists "healthcare sector, leadership skills, coordination skills, Revit, AutoCAD," make sure you have those words included.</p>
            <p>💡 Tip: Before you submit your application on <a href="https://www.archjobs.co.uk/" target="_blank" rel="noopener">ArchJobs</a>, we provide you with the most relevant keywords?</p>
            <p> <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/ArchJobs keywords_ATS.JPG" alt="On ArchJobs, we highlight which keywords you should include in your application"></img></p>
            <p><strong>3. Job Title: </strong> List your job title underneath your name. Many candidates don't list their actual job title in their CV.&nbsp;</p>
            <p>💡 Tip: If your current CV says &ldquo;BIM Specialist&rdquo; but the job advert states "BIM Coordinator" amend your job title on your CV so it matches the job adverts.&nbsp;</p>
            <p><strong>4. Work Experience:</strong> When listing your work experience, make sure you start in chronological order, beginning with your most recent experience. Structure each work experience as follows:</p>
            <ul>
            <li>Company name and city</li>
            <li>Job title</li>
            <li>Start and end dates (MM/YYYY)</li>
            <li>List your responsibilities and achievements using bullet points</li>
            </ul>
            <p>For more guidance, check out our articles on <a href="https://www.archjobs.co.uk/articles/how-to-write-and-structure-your-cv" target="_blank" rel="noopener">How to Write and Structure an Architecture CV</a> or if you are more experienced <a href="https://www.archjobs.co.uk/articles/how-to-write-a-senior-architectural-cv" target="_blank" rel="noopener">How to Write and what to Include in a Senior Architectural CV</a>.&nbsp;</p>
            <p/>
            <p/>
            <h3>Are the CV templates on Canva compliant with ATS?</h3>
            <p>After doing more research, it appears that <a href="https://www.canva.com/" target="_blank" rel="nofollow noopener">Canva</a> provides you with a lot of good-looking CV templates; however, they are not optimised for <a href="https://www.archjobs.co.uk/articles/how-to-create-an-ats-friendly-architectural-cv" target="_blank" rel="noopener">Application Tracking Systems (ATS)</a>. Instead, I would suggest creating your CV using one of the free Google Docs templates or using the website <a href="https://www.tealhq.com/" target="_blank" rel="nofollow noopener">Teal</a>.</p>
            <p>Please note that to create your CV through Google Docs, you will need to create a Google account (but I&rsquo;m sure most of us have one already). Simply click on Google Drive, then click on the big plus 'New' button, and select 'Google Docs' -&gt; 'From a template&rsquo;.</p>
            <p>As of now, Google Docs offers 5 different resume/CV templates. I personally prefer the layout of &ldquo;Resume Serif&rdquo;.</p>
            <p/>
            <p/>
            <h3>How can I increase the readability of my CV?</h3>
            <p>To ensure that your CV is easily scannable and readable for an ATS, consider these tips:</p>
            <p><strong>Use clear and simple formatting:</strong> Avoid using fancy fonts, graphics, tables, or columns that can confuse the ATS. Stick to a simple and clean design with easy-to-read headings and bullet points.</p>
            <p><strong>Use standard section headings: </strong> Use standard section headings such as "Work Experience," "Education," "Skills," "Contact Information," "Awards," and "Languages" to make it easier for the ATS to identify and extract relevant information.&nbsp;</p>
            <p>💡 Tip: Make the section headings bigger in size and weight for emphasis.</p>
            <p><strong>Use enough spacing:</strong> Use enough spacing between headings and sections. Rather than squeezing everything onto one page and potentially risking that your CV can&rsquo;t be scanned by the ATS system, spread it onto two pages.</p>
            <p><strong>Use relevant keywords:</strong> Use relevant keywords that match the job advert to increase your chances of passing the initial screening. Look for keywords in the job ad and use them in your CV where appropriate.</p>
            <p><strong>Don&rsquo;t use abbreviations and acronyms:</strong> Avoid using abbreviations and acronyms that the ATS may not recognise. Spell out the full name of the term the first time you use it, followed by the abbreviation in parentheses.&nbsp;</p>
            <p>For example: Team Leader (TL) or Managing Director (MD)</p>
            <p><strong>PDF, DOCX, or TXT:</strong> Usually, the ATS will say which file extensions it can support. PDF is normally a safe option.</p>
            <p><strong>Proofread and edit:</strong> Proofread and edit your CV for spelling and grammar errors. An ATS may not be able to understand misspelt or poorly written words.</p>
            <p/>
            <p/>
            <h3>Are there free tools to check if my CV is ATS-friendly?&nbsp;</h3>
            <p>Yes, there are free tools you can use, such as <a href="https://www.wozber.com/en-gb/ats-cv-scanner" target="_blank" rel="nofollow noopener">Wozber</a>, <a href="https://www.jobscan.co/#uploadWrapper" target="_blank" rel="nofollow noopener">Jobscan</a>, and <a href="https://resumeworded.com/resume-scanner" target="_blank" rel="nofollow noopener">Resume Worded</a>.</p>
            <p/>
            <p/>
            <h3>What are the top 6 CV formatting mistakes for ATS systems?</h3>
            <ol>
            <li>Not including job titles, location, skills, and experiences.</li>
            <li>Not including relevant keywords.</li>
            <li>Not using standard section headings like "Work Experience," "Education," and "Skills."</li>
            <li>Using unconventional formatting, such as non-chronological order or complex graphics</li>
            <li>Not tailoring the CV to the job advert.</li>
            <li>Not leaving enough gaps in between sections.</li>
            </ol>
            <h3>Should I use Logos and Diagrams when listing my Software Skills?</h3>
            <p>No, it is best to avoid relying solely on logos and diagrams to showcase your software skills and experience levels. The reason is that not only are they unreadable by Application Tracking Systems (ATS), but also stating your Revit skills as 8/10 may not convey much to someone else.</p>
            <p>Instead, it is recommended to explicitly mention the software you are proficient in, such as Revit, AutoCAD, and Adobe Creative Suite. Hear what Ian Lowe and Sonda Mvula have to say about this subject:&nbsp;</p>
            <p> <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Ian Lowe - Logos.png" alt="Ian Lowe - Interesting. I prefer to see the logo, backed up with a written note to cover the ATS angle, we are a visual industry after all.
                I'd agree that a bar chart or stars or giving yourself 8 out of 10 dots tells me nothing about your experience of software, though. Simply state how long you've used it and how frequently you use it, then flesh out with what you use it for."></img></p>
            <p> <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Ian Lowe - Logo 2.png" alt="Ian Lowe - I am guilty of doing a horizontal bar chart in my early career. I had a sort of epiphany about it, What I am I comparing myself to? What is the baseline? Is my self appointed 8 out of 10 someone else's 6? If I consider myself 8 out of 10 now, what about the person with 20 years experience, where are they on this scale? Then you realise how limiting and arbitrary it is. When I tutor, and when I do mock interviews, we discuss this; I ask where I fit on the students self appointed scale, where do they fit in 5, 10, 20 years time? I understand the graphical rationale behind it, but it's a poor method to communicate experience to a prospective employer"></img></p>
            <p> <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Sonda - Logos.png" alt="Sonda Mvula - Aylin Round this is great advice and I think this mainly happens with new graduates. I have had this conversation with a lot of people and always tell them to put themselves in the employer's shoes. For example, pie charts that show Revit 8/10 do not mean anything to the employer as they want to know how long you have used the software for and on what kinds of projects, etc."></img></p>
            <p/>
            <p>If you still wish to use logos, you can include the name of the software underneath or next to each respective logo.</p>
            <p>❌ Below is an example of what not to do&nbsp;❌</p>
            <p> <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Adobe Creative Suite Logos_new.png" alt="Image of the Adobe Creative Suite Logos - Credit to: Godline Art"></img></p>
            <p>💡 Tip: The same goes for listing hobbies. I see a lot of CV&rsquo;s which show logos for cookings, climbing, music, painting.&nbsp;</p>
            <p/>
            <p/>
            <h3>If your CV isn't passing the initial ATS screening, what can you do?</h3>
            <p>Let&rsquo;s say you applied to dozens of positions via ATS systems and you genuinely thought you matched the requirements pretty well. In that instance I would suggest you review your CV and make sure you follow the basics again.&nbsp;</p>
            <ol>
            <li>Make sure you include relevant keywords and phrases related to the job advert.&nbsp;</li>
            <li>You might want to modify the format of your CV and make sure it&rsquo;s more easily scanned and analysed by the ATS system.&nbsp;</li>
            <li>If you&rsquo;re working with a recruiter, you can always ask them for feedback and see if they would suggest any improvements.&nbsp;</li>
            </ol>
            <p/>
            <p/>
            <h3>In Conclusion</h3>
            <p>The chance that you will come across an ATS system while applying for your next dream role is increasingly high. More and more practices use them to streamline their recruitment strategy and it&rsquo;s important that your CV is ATS friendly.&nbsp;</p>
            <p>Make sure you use the same keywords as in the job advert, make sure you format it correctly, avoid spelling mistakes and avoid using images when listing your skills.&nbsp;</p>
            <p>I think it is beneficial to understand how an ATS system works and why they are being used. That way it is less daunting and you know that with potentially minor changes to your current CV, you can easily pass them.&nbsp;</p>
            <p>I also think it&rsquo;s clear that ATS systems aren&rsquo;t perfect and companies need to re-evaluate if their current hiring strategies are working and if the job adverts are attracting enough talent. At the end of the day, qualified candidates are applying but don&rsquo;t make it pass the initial ATS screening which is just a waste of time (in my opinion).&nbsp;</p>
            </ArticleFrame>
    }
}