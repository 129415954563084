import React, { Component } from 'react'
import Button from '../components/Buttons'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import ContactForm from './ContactForm'



class RecruitmentPage extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0)
  }


  render() {
    return <div className="HomePage RecruitmentPage" >
      <div className="WhyWorkWithUsBanner">
        <div className="WhyWorkWithUsBannerTextContainer">
          <h1 className="WhyWorkWithUsSectionTitle">ArchJobs Recruitment</h1>
          <p className = "WhyWorkWithUsSectionSubtitle">
            Helping you hire the top architectural talent in Dubai
          </p>

        </div>
        <div className="WhyWorkWithUsBannerBG WhyWorkWithUsBannerRecruitmentBG"> </div>
      </div>
      <div className="HomePageSection RecruitmentFirstSection">
        <div className="HomePageSectionText">
            <h2 className="HomePageSectionTitle">Want to hire the Best Architectural Talent in Dubai?</h2>
            <Link to="/contact-me" className="Link">
              <Button className="Button">Contact Me</Button>
            </Link>
        </div>
      </div>
      <div className="WhyWorkWithUsSection" id="ChoosePackageSection">
          <div className="HomePageSectionTitle"  style={{textAlign:"center"}}>Why Work With Me?</div>
          <div className="HomePageSectionSubtitle" style={{textAlign:"center"}}>Open Communication, Strong Network and Industry Knowledge</div>
          <div className="PricingPackages CoachingPricingPackages WorkWithMeList">
            <div className="HomePageWorkWithMeReason">
              <div className="WorkWithMeNumber">
                1
              </div>
              <div className="WorkWithMeText">
                I take great pride in building strong and long-lasting relationships with my clients and getting to know you. Communication is key.
              </div>
            </div>
            <div className="HomePageWorkWithMeReason">
              <div className="WorkWithMeNumber">
                2
              </div>
              <div className="WorkWithMeText">
                I have a large network of architectural professionals, based in the UK and across the world who trust me. 
              </div>
            </div>
            <div className="HomePageWorkWithMeReason">
              <div className="WorkWithMeNumber">
                3
              </div>
              <div className="WorkWithMeText">
                My years of experience within the sector have given me a keen eye for identifying talent and matching candidates with practices.
              </div>
            </div>
          </div>
        </div>
      {/* <div className="HomePageSectionSeparatorContainer">
        <div className="HomePageSectionSeparator"></div>
      </div> */}
      <div className="HomePageSection RecruitmentHomePageSection">
        <div className="HomePageSplitSection SplitRight">
          <div className="SplitSectionText">
            <h2 className="HomePageSectionTitle">Hi, I’m Aylin the founder and owner of ArchJobs Recruitment.</h2>
            <p className="HomePageSectionSubtitle">
            As someone who has been active within the UK architectural market for nearly 5 years. I’m witnessing a market shift. Talented professionals are increasingly relocating to the Middle East to join forward-thinking practices and boundary-pushing projects. 
            </p>
            <p className="HomePageSectionSubtitle">
              Having made the move to Dubai myself, I want to bridge the gap between you and those talented candidates. If you're looking to hire ARB/RIBA registered Architects, ACIAT/MIACT Architectural Technologists, or BIM professionals, then I’m the recruiter for you.
            </p>
            <Link to="/contact-me" className="Link">
              <Button className="Button">Contact Me</Button>
            </Link>
          </div>
          <img className="CircularImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/aylin_round_profile_picture_medium.jpg" alt="Aylin Round - Archjobs Recruitment"/>
        </div>
      </div>
      <div className="HomePageSection MyApproachSection">
        <h2 className="HomePageSectionTitle"  style={{textAlign:"center"}}>My Approach to Recruitment</h2>
        <p className="HomePageSectionSubtitle"  style={{textAlign:"center"}}>I’ll be there throughout the whole process. </p>
        <div className="MyApproachList">
          <div className="MyApproachLine"></div>
          <div className="MyApproachRow">
            <div className="MyApproachNumber">1</div>
            <div className="MyApproachText">I receive the job brief and write an engaging job advert, which goes out to my network and job board.</div>
          </div>
          <div className="MyApproachRow">
            <div className="MyApproachNumber">2</div>
            <div className="MyApproachText">I will reach out to professionals who could be a good fit. In the meantime, I will also screen candidates who have applied to the job advert.</div>
          </div>
          <div className="MyApproachRow">
            <div className="MyApproachNumber">3</div>
            <div className="MyApproachText">I will only submit CVs and Sample Portfolios from candidates who I believe are a suitable fit. </div>
          </div>
          <div className="MyApproachRow">
            <div className="MyApproachNumber">4</div>
            <div className="MyApproachText">I keep an open communication channel with you and my candidates and help set up interviews when required. </div>
          </div>
          <div className="MyApproachRow">
            <div className="MyApproachNumber">5</div>
            <div className="MyApproachText">I will present the candidate with the job offer and support if any questions arise.</div>
          </div>
          <div className="MyApproachRow">
            <div className="MyApproachNumber">6</div>
            <div className="MyApproachText">Between the candidate signing the contract and them starting, I’ll stay in close contact to ensure a smooth onboarding process.  </div>
          </div>
        </div>
        <Link to="/contact-me" className="Link">
          <Button className="Button">Work With Me</Button>
        </Link>
      </div>

      <div className="HomePageSectionSeparatorContainer">
        <div className="HomePageSectionSeparator"></div>
      </div>
      <div className="HomePageSection NoBottomBorder">
        <div className="HomePageSectionText" style={{marginBottom: "0px"}}>
            <h2 className="HomePageSectionTitle">Zero Upfront Cost, Low Risk</h2>
            <p className="HomePageSectionSubtitle">Only pay when the candidate starts.
            <p className="HomePageSectionSubtitle">9 week rebate period. </p> </p>

        </div>
        <div className="HomePageSectionText" style={{marginBottom: "0px"}}>
            <h2 className="HomePageSectionTitle">Like what you've read so far?</h2>
            <p className="HomePageSectionSubtitle" style={{display: "flex", alignItems:"center", flexDirection: "column"}}>If you'd like to work together, please contact me using the form below, or reach out on any of my social channels
            <div className="ContactLinks">
              <a href="https://www.linkedin.com/in/aylin-round/" target="_blank" rel="noreferrer" className="ContactLink">
                <img src="/linkedinlogo.png" alt="LinkedIn Icon"/>@Aylin-Round
              </a>
              <a href="mailto: aylin@archjobsrecruitment.com" target="_blank" rel="noreferrer" className="ContactLink">
                <img src="/emaillogo.png" alt="Email Icon"/>Aylin@ArchJobsRecruitment.com
              </a>
              <a href="https://wa.me/971585390533" target="_blank" rel="noreferrer" className="ContactLink">
                <img src="/whatsapplogo.png" alt="WhatsApp Icon"/>+971 585390533
              </a>
              <a href="https://www.instagram.com/aylin.round/" target="_blank" rel="noreferrer" className="ContactLink">
                <img src="/instalogo.png" alt="Instagram Icon"/>@aylin.round
              </a>
            </div>
            
            </p>


        </div>
      </div>
      <div className="WhyWorkWithUsSection WhyWorkWithUsDarkSection" style={{paddingBottom: "60px"}}>

        <ContactForm type="Client"></ContactForm>

      </div>
  </div>  
  }
}

/* export the component to be used in other components */
export default RecruitmentPage