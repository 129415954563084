
const HiringGuideData = {
    //template:
    "YourArticle": {
        main_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/writing_cv.jpeg", //resize to 800px wide, ideally at least 500px high. recommend https://www.resizepixel.com for this (scale to 800px wide)
        thumbnail_img: "", //resize to 250px wide, 180px high. Resize to whichever is most limiting (ie. don't resize to 180px high if the image is then less than 250px wide)
        article_title: "Title", //should be less than 110 characters
        article_description: "",
        article_url: "https://www.archjobs.co.uk/articles/your-article", //the url that you create for your article
        author: "Aylin Round", //needs to match an author in AuthorData
        published: new Date(2022, 8, 10), //Year, Month, Day. Months start at 0 and go to 11 
        modified: new Date(2022, 8, 10), //same format as above
        //stuff that is important for google
        main_img_1x1: "", // highest resolution article image, 1x1 height to width ratio. Recommend https://www.resizepixel.com/edit?act=crop 
        main_img_4x3: "",
        main_img_16x9: "",
    },
   

    "HiringGuideArchitect": {
        main_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/architect-hiring-800px.png",
        thumbnail_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/architect-hiring-250pxnew.png",
        article_title: "Architect Hiring Guide & Job Description Template",
        article_description: "A comprehensive hiring guide for any hiring manager or HR professional looking to hire their next Architect. From understanding job requirements, salary ranges, to selecting optimal platforms for posting and more.",
        article_url: "https://www.archjobs.co.uk/hiring/architect-hiring-guide",
        author: "Aylin Round",
        published: new Date(2024, 0, 6), //Year, Month, Day. Months start at 0 and go to 11 
        modified: new Date(2024, 0, 6),
        main_img_1x1: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/architect-hiring-1x1.png", // high resolution article image, 1x1 height to width ratio. Recommend picresize for this (crop don't scale)
        main_img_4x3: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/architect-hiring-4x3.png",
        main_img_16x9: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/architect-hiring-16x9.png",
    },

    "HiringGuidePart2": {
        main_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Part2hiring800px.png",
        thumbnail_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Part2hiring250px.png",
        article_title: "Part 2 Hiring Guide & Job Description Template",
        article_description: "Hiring a Part 2 Architectural Assistant is different to more experienced candidates. Understanding what they need and look for in a practice, will make all the difference.",
        article_url: "https://www.archjobs.co.uk/hiring/part2-architectural-assistant-hiring-guide",
        author: "Aylin Round",
        published: new Date(2024, 0, 10), //Year, Month, Day. Months start at 0 and go to 11 
        modified: new Date(2024, 0, 10),
        main_img_1x1: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Part2hiring1x1.png", // high resolution article image, 1x1 height to width ratio. Recommend picresize for this (crop don't scale)
        main_img_4x3: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Part2hiring4x3.png",
        main_img_16x9: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Part2hiring16x4.png",
    },

    "HiringGuideSeniorArchitect": {
        main_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Senior_Architect_hiring_800px.png",
        thumbnail_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Senior_Architect_hiring_250x180px.png",
        article_title: "Senior Architect Hiring Guide & Job Description Template",
        article_description: "A detailed hiring guide if you are looking to hire your next Senior Architect. From choosing the correct platforms to advertise your role to understanding on how to attract the right candidates and much more.",
        article_url: "https://www.archjobs.co.uk/hiring/senior-architect-hiring-guide",
        author: "Aylin Round",
        published: new Date(2024, 0, 8), //Year, Month, Day. Months start at 0 and go to 11 
        modified: new Date(2024, 0, 8),
        main_img_1x1: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Senior_Architect_hiring_1x1.png", // high resolution article image, 1x1 height to width ratio. Recommend picresize for this (crop don't scale)
        main_img_4x3: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Senior_Architect_hiring_4x3.png",
        main_img_16x9: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Senior_Architect_hiring_16x9.png",
    },

    "HiringGuidePart1": {
        main_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Part1hiring_800px.png",
        thumbnail_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Part1hiring_250px.png",
        article_title: "Part 1 Hiring Guide & Job Description Template",
        article_description: "When hiring a Part 1, it's important to remember not to overwhelm them with requirements. Your role is to mentor and support them.",
        article_url: "https://www.archjobs.co.uk/hiring/part1-architectural-assistant-hiring-guide",
        author: "Aylin Round",
        published: new Date(2024, 1, 14), //Year, Month, Day. Months start at 0 and go to 11 
        modified: new Date(2024, 1, 14),
        main_img_1x1: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Part1hiring_1x1.png", // high resolution article image, 1x1 height to width ratio. Recommend picresize for this (crop don't scale)
        main_img_4x3: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Part1hiring_4x3.png",
        main_img_16x9: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Part1hiring_16x4.png",
    },
    // "HiringGuideAT": {
    //     main_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/ArchitecturalTechnologisthiring_800px.png",
    //     thumbnail_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/ArchitecturalTechnologisthiring_250px.png",
    //     article_title: "Architectural Technologist Hiring Guide & Job Description Template",
    //     article_description: "Here is a comprehensive hiring guide on how to attract Architectural Technologists with your job advert.",
    //     article_url: "https://www.archjobs.co.uk/hiring/architectural-technologist-hiring-guide",
    //     author: "Aylin Round",
    //     published: new Date(2024, 1, 14), //Year, Month, Day. Months start at 0 and go to 11 
    //     modified: new Date(2024, 1, 14),
    //     main_img_1x1: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/ArchitecturalTechnologisthiring_1x1.png", // high resolution article image, 1x1 height to width ratio. Recommend picresize for this (crop don't scale)
    //     main_img_4x3: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/ArchitecturalTechnologisthiring_4x3.png",
    //     main_img_16x9: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/ArchitecturalTechnologisthiring_16x9.png",
    // },

}
const AuthorData = {
    "Aylin Round": {
        name: "Aylin Round",
        profile_img: "https://cdn.archjobs.co.uk/file/ArchJobs/article_images/aylin_profile.jpg",
        description: "Hi, I'm Aylin, the founder of ArchJobs, a platform dedicated to assisting architectural practices in advertising their positions. Before launching ArchJobs, I spent nearly 3 years as a senior recruitment consultant specialising in architecture recruitment across the North of the UK. With my comprehensive experience, I have gained a profound understanding of what hiring managers seek in candidates and what job seekers aspire to find in their next role. Whether you're looking for help crafting engaging job ads or ready to showcase your architectural roles with us, I've got you covered.",
        profile_page: "https://www.archjobs.co.uk/author/aylin-round",
        linkedin: "https://www.linkedin.com/in/aylin-round/",
    }
}

export default {
    HiringGuideData,
    AuthorData,
}