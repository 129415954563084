import React, { Component } from 'react';
import '../css/privacy.css'

class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };  
}    


  render() {
      return <div className="HomePage">
        <div className="PrivacyPage">
          <div className="PrivacyPageContent">
          <div>At ArchJobs, we take the privacy of our clients, candidates and visitors seriously.</div>   <p/>
          <div>When visiting our website, we may gather information about you. We are committed to maintaining customer privacy and to the maintenance of the security of all personal data that is received. Therefore, this policy guides you through which potentially private data we may gather and how you can get in touch should you have any concerns or questions.</div>
          <p><strong>Data received and processed</strong>   <p/>
          ArchJobs receives personal data both in the course of the services it offers and in the recruitment process. This data consists of information such as your name, address, nationality, e-mail address and work and education history. ArchJobs processes this personal data as necessary to provide our service offering and to aid the recruitment process. ArchJobs also collects statistical information to provide it with a better understanding of the website users as a group, but this does not contain personally identifiable information.
          </p>
          <p><strong>Sensitive data received and processed</strong>   <p/>
            ArchJobs receives and processes sensitive personal data only so far as is necessary to ensure it conforms to legal requirements, for example under the equality of opportunity laws and the asylum and immigration legislation. By using this website and by supplying your details to ArchJobs, you consent to the company collecting and processing sensitive personal data supplied by you and disclosing this information to prospective employers and clients in connection with the recruitment process.
          </p>
          <p>
          <strong>Sharing and disclosure</strong>
          <p/>
          ArchJobs does not share or rent any personal information received with third parties unless:

          <div> You have specifically consented to allow ArchJobs to share your personal information with third party organisations</div>
          <div> You are seeking employment with a third-party organisation through the services of ArchJobs and, for the purpose of evaluating your suitability, the third-party organisation requests some or all of the information you have supplied us for this purpose</div>
          <div> It is to enable ArchJobs to provide a product or service you have requested ArchJobs is required to do so by legal process or in response to a court order or other legitimate request by a relevant authority</div>
          </p>

        <p> <strong>Data safeguards</strong></p>
        <p>The security of your data is extremely important to ArchJobs. Access to your personal data is only given to us and third parties who help us to process data, in order to help with the provision of our services and in particular, the recruitment process. ArchJobs has implemented technology and security policies, rules and measures to protect the personal data we have under our control, both on and off-line, from unauthorised access, improper use, alteration, unlawful or accidental destruction and accidental loss. We will continue to enhance our security procedures as new technology becomes available.</p>
        </div>   
    </div>
    </div>
  }
}

/* export the component to be used in other components */
export default PrivacyPolicy