import React, { Component } from 'react'
import Button from '../components/Buttons'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import ContactForm from './ContactForm'


class ContactPage extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0)
  }


  render() {
    return <div className="HomePage ContactPage" >
      <div className="HomePageSection RecruitmentFirstSection">
        <div className="HomePageSplitSection SplitRight">
          <div className="SplitSectionText">
            <h2 className="HomePageSectionTitle">Interested in Working With Me? Ready to Work in Dubai?</h2>
            <div className="HomePageSectionSubtitle">Just fill out the form below and I'll be in contact.</div>
            <div className="HomePageSectionSubtitle">You can also find me on the following channels:
              <div className="ContactLinks">
                <a href="https://www.linkedin.com/in/aylin-round/" target="_blank" rel="noreferrer" className="ContactLink">
                  <img src="/linkedinlogo.png" alt="LinkedIn Icon"/>@Aylin-Round
                </a>
                <a href="mailto: aylin@archjobsrecruitment.com" target="_blank" rel="noreferrer" className="ContactLink">
                  <img src="/emaillogo.png" alt="Email Icon"/>Aylin@ArchJobsRecruitment.com
                </a>
                {/* <a href="https://wa.me/971585390533" target="_blank" rel="noreferrer" className="ContactLink">
                  <img src="/whatsapplogo.png" alt="WhatsApp Icon"/>+971 585390533
                </a> */}
                <a href="https://www.instagram.com/aylin.round/" target="_blank" rel="noreferrer" className="ContactLink">
                  <img src="/instalogo.png" alt="Instagram Icon"/>@aylin.round
                </a>
              </div>
            </div>
          </div>
          <img className="CircularImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruiters/AylinLightBG_1x1.png" alt="Aylin Round - Archjobs Recruitment"/>
          </div>
      </div>
      <div className="WhyWorkWithUsSection WhyWorkWithUsDarkSection" style={{paddingBottom: "60px"}}>
        <ContactForm type="Generic"></ContactForm>
      </div>
      
  </div>  
  }
}

/* export the component to be used in other components */
export default ContactPage