import * as React from 'react';
// import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useTheme } from '@mui/material/styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};



export default function SalaryRoleSelect(props) {
  const handleChange = (event) => {
    props.updateRole(event.target.value)
  };

  return (

        <Select
        className="PostJobInput"
          value={props.role}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          MenuProps={MenuProps}      
        >
           {props.roles.map(role => <MenuItem key = {role.aj_title} value={role.aj_title} className={(role.aj_title==role) ? "RoleDropdown Selected": "RoleDropdown"}>{role.aj_title}</MenuItem>)} 
        </Select>

  );
}
