import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';

const ExperienceChart = ({ data }) => {
  // create a ref to store the chart element
  const chartRef = useRef();

  // use effect to draw the chart on mount and update on data change
  useEffect(() => {
    // set the dimensions and margins of the chart
    console.log("Data changed, rerendering")
    d3.select(chartRef.current).selectAll('svg').remove();
    const margin = { top: 25, right: 20, bottom: 30, left: 60 };
    const div = d3.select('#experience_chart').node();
    const width = div.offsetWidth;
    const height = div.offsetHeight;
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    // create scales for the x and y axes
    const x = d3.scaleBand().range([0, innerWidth]).padding(0.1);
    const y = d3.scaleLinear().range([innerHeight, 0]);

    // define the line generator function
    const valueline = d3.line()
        .x(d => x(data.indexOf(d))+ x.bandwidth() / 2)
        .y(d => y(d.avg))
        .curve(d3.curveCardinal);

    // create the svg element
    const svg = d3.select(chartRef.current)
      .append('svg')
      .attr('width', innerWidth + margin.left + margin.right)
      .attr('height', innerHeight + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    // Scale the range of the data
    x.domain(data.map((d, i) => i));
    y.domain([0, d3.max(data, d => d.avg)]);


    // add the valueline path
    svg.append('path')
      .data([data])
      .attr('class', 'line')
      .attr('fill', 'none')
      .attr('stroke', '#2A4777')
      .attr('opacity', '0.7')
      .attr('d', valueline);

    // add the x axis
    svg.append('g')
      .attr('transform', `translate(0, ${innerHeight})`)
      .call(d3.axisBottom(x)
      .tickValues(x.domain())
      .tickFormat((d, i) => data[i].level));
    // add the y axis
    svg.append('g')
      .call(d3.axisLeft(y)
      .tickFormat((d, i) => d.toLocaleString('en-GB',   {style: 'currency', currency: 'GBP', maximumFractionDigits: 0})));

    svg.append('g')
      .attr('class', 'grid')
      .attr('transform', `translate(0, ${innerHeight})`)
      .call(d3.axisBottom(x)
        .ticks(5)
        .tickSize(-innerHeight)
        .tickSizeOuter(0)
        .tickFormat(''));
    
    svg.append('g')
      .attr('class', 'grid')
      .call(d3.axisLeft(y)
        .ticks(5)
        .tickSize(-innerWidth)
        .tickSizeOuter(0)
        .tickFormat(''));



    // plot points along the line
    svg.selectAll('.point')
      .data(data)
      .enter()
      .append('circle')
      .attr('class', 'point')
      .attr('fill', '#2A4777')
      .attr('cx', d => x(data.indexOf(d)) + x.bandwidth() / 2)
      .attr('cy', d => y(d.avg))
      .attr('r', 3);

        // add labels above each point
        svg.selectAll('.point-label')
        .data(data)
        .enter()
        .append('g')
        .attr('class', 'point-label')
        .each(function(d) {
        // append a text element to the group
        d3.select(this)
            .append('text')
            .attr('x', d => x(data.indexOf(d)) + x.bandwidth() / 2 - 28)
            .attr('y', d => y(d.avg))
            .attr('dy', '-0.7em')
            .text(d => d.avg.toLocaleString('en-GB',   {style: 'currency', currency: 'GBP', maximumFractionDigits: 0}));
    
        // get the bounding box dimensions of the text element
        const bbox = this.getBBox();
    
        // append a rectangle element to the group
        d3.select(this)
            .insert('rect', ':first-child')
            .attr('x', bbox.x - 5) // set the x position to be 5 pixels to the left of the bounding box
            .attr('y', bbox.y - 2) // set the y position to be 5 pixels above the bounding box
            .attr('width', bbox.width + 10) // set the width to be the width of the bounding box plus 10 pixels
            .attr('height', bbox.height + 4) // set the height to be the height of the bounding box plus 10 pixels
            .attr('fill', 'rgb(255, 255, 255, 0.4)'); // set the fill color to white
        });
    // add a resize listener to rerender the chart on resize
    const handleResize = () => {
        const div = d3.select('#experience_chart').node();
        const width = div.offsetWidth;
        const height = div.offsetHeight;
        const innerWidth = width - margin.left - margin.right;
        const innerHeight = height - margin.top - margin.bottom;
  
        // update the scales with the new width and height
        x.range([0, innerWidth]);
        y.range([innerHeight, 0]);
  
        // update the svg element with the new width and height
        svg.attr('width', innerWidth + margin.left + margin.right)
           .attr('height', innerHeight + margin.top + margin.bottom);
  
        // update the valueline path
        svg.select('.line')
           .attr('d', valueline);
  
        // update the x axis
        svg.select('.x.axis')
           .attr('transform', `translate(0, ${innerHeight})`)
           .call(d3.axisBottom(x)
            .tickValues(x.domain())
            .tickFormat((d, i) => data[i].level));
  
        // update the y axis
        svg.select('.y.axis')
           .call(d3.axisLeft(y));
  
        // update the labels
        svg.selectAll('.point-label')
           .attr('x', d => x(data.indexOf(d)) + x.bandwidth() / 2)
           .attr('y', d => y(d.avg));
      };
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
  }, [data]);

  return (
    <div ref={chartRef} />
  );
}

export default ExperienceChart;