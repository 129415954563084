import ArticleFrame from "./ArticleFrame";
import React, { Component } from 'react';


//first go to ArticleData.js and add all the relavant data
//next, rename "YourArticle" below to what you named the article data section
const thisArticleData = "HowToFindARecruiter"

export const articleContent = <div className="LivingWageArticle">
            <p>As an architectural professional, finding the right recruiter to help you on your career journey can make a positive impact. However, this task can often feel overwhelming and time-consuming, leaving many unsure of where to start. In this article, I'll walk you through the most effective strategies for discovering the ideal architectural recruiter who understands your goals and aspirations. From using your network and exploring job boards to utilising LinkedIn and our new "Find a Recruiter" feature, I'll also share tips for identifying the best recruiters, ensuring you can differentiate between a good and bad recruiter.</p>
            <p>Please note: In this article, I will only focus on Agency Recruiters and not In-house Recruiters. To learn more about the difference read <a href="https://www.archjobs.co.uk/articles/how-an-architectural-recruiter-can-help-find-your-next-role" target="_blank" rel="noopener">How a Recruiter Can Help Find Your Next Role</a>.</p>
            <p/>
            <h2>Structure of the article:</h2>
            <ol>
            <li>Basics, What Is a Recruiter and More</li>
            <li>How to Find a Recruiter and Work With Them</li>
            <li>Understanding How Recruiters Can Help&nbsp;</li>
            <li>The Good, The Bad, The Scams</li>
            <li>Frequently Asked Questions About Recruiters</li>
            </ol>
            <p/>
            <p/>
            <h2>1. Section: Basics, What Is a Recruiter and More</h2>
            <p/>
            <p/>
            <h3>What Is An Agency Recruiter?</h3>
            <p>An agency recruiter finds suitable candidates for companies, working across various practices and specialising in specific sectors and regions (e.g., Architecture, Construction, Interior Design in London, North West, South West, Midlands).&nbsp;</p>
            <p>They increase your chances of getting interviews and offers by approaching different practices on your behalf. Agency recruiters earn a commission for successful placements, ensuring you receive the best offer possible and that you're a good match for the companies they present.</p>
            <p/>
            <p/>
            <h3>What Is An In-house Recruiter?</h3>
            <p>An in-house recruiter is a company employee responsible for managing the recruitment process internally. Their tasks include advertising positions, reviewing CVs, scheduling interviews, and handling job offers. Unlike agency recruiters, they don't represent candidates outside their company and receive a fixed salary regardless of placement success.</p>
            <p/>
            <p/>
            <h3>What Is A Recruitment Agency?</h3>
            <p>A recruitment agency, also known as a staffing agency or employment agency, is a company that helps employers find suitable candidates for their job roles, while their recruiters assist job seekers in finding suitable employment opportunities. Recruitment agencies act as intermediaries between employers and job seekers, facilitating the hiring process for both parties.</p>
            <p>Recruitment agencies usually specialize in specific industries or job types, such as architecture, technology, finance, healthcare, or creative fields. Some agencies focus on temporary or contract placements, while others primarily handle permanent positions.</p>
            <p/>
            <p/>
            <h3>How Do Recruiters Earn Money?</h3>
            <p>Recruiters typically earn their income through two primary methods: commission-based or retainers. Among these, commission-based earnings are the most common. This means that recruiters receive a percentage of the salary when they successfully place a candidate in a job.&nbsp;</p>
            <p>The exact percentage can vary, depending on factors such as the specific recruiter, their employing company, and the type of job being filled.</p>
            <p>For instance, a recruiter placing a candidate at a practice might earn a commission of 20% based on the annual salary.</p>
            <p>Usually, the commission is paid only after the candidate begins working. However, in the event that the candidate decides to leave the position within a few weeks or months, the agency may have to refund a portion of the money back to the practice or company. That is the reason why some recruiters will not represent candidates who frequently drop out or exhibit unreliability.</p>
            <p/>
            <p/>
            <h2>2. Section: How to Find a Recruiter and Work With Them</h2>
            <p/>
            <p/>
            <h3>How To Find A Recruiter?</h3>
            <p>Here is the million-pound question, how do you actually find a recruiter? There are various ways you can find a recruiter, and each method offers unique advantages for architectural professionals seeking the right fit. Let's look at some of the most common ways to find recruiters in the industry:</p>
            <p><strong>Your Network:</strong> Engage in networking activities, and attend architectural events, conferences, and online forums to meet potential recruiters and gain valuable insights. Building strong professional connections can lead to better opportunities and recommendations. You can also ask your network if they would recommend any recruiters or who might be able to assist.&nbsp;</p>
            <p><strong>Job Boards:</strong> Another straightforward approach is to look through various job boards. Simply type in the job title and city you are interested in, and you'll find recruitment agencies that specialise in architectural roles. While job boards are an easy way to find recruiters, I would suggest to research each agency's reputation and expertise to check they match your career goals.</p>
            <p><strong>Google: </strong>Doing a simple Google search can lead you to architectural recruiters in your desired location. By searching for terms like "Architectural Recruiters [City]," you can find a list of recruitment agencies that should fit your criteria. However, to find the right recruiter, you will need to visit multiple agency websites and review their expertise and client base. Same as above, make sure they are covering the right roles and locations.</p>
            <p><strong>Let Recruiters Find You:</strong> LinkedIn is an ideal platform for recruiters to discover you. By optimising your LinkedIn profile with the right keywords and filled-out work history, you increase the chances of recruiters finding and reaching out to you with relevant opportunities. However, it is important to keep your profile updated.</p>
            <p>If you want to enhance your chances further, consider reading "<a href="https://www.archjobs.co.uk/articles/how-to-optimise-an-architectural-linkedin-profile" target="_blank" rel="noopener">10 Steps to transform your LinkedIn profile</a>". This guide provides valuable tips and insights on optimising your profile to attract recruiters and stand out in your industry.</p>
            <p>Another way could be to upload your CV onto a Job Board and recruiters will be able to access the job boards data. However, I am not a fan of that at all, as you have no control over who will see it. To protect your privacy and ensure your CV reaches the right hands avoid uploading your documents on job boards.&nbsp;</p>
            <p/>
            <p/>
            <h3>What Is The Typical Process For Working With A Recruiter? How does it work?</h3>
            <p>The easiest way to explain it is that the recruiter acts as the go-between you and the hiring manager. Everything goes through the recruiter, from the first contact to interview feedback and receiving an offer.</p>
            <p>A typical scenario is as follows:</p>
            <p><strong>Initial Contact: </strong>The process usually starts with you reaching out to the recruiter or vice versa. The recruiter may have found your profile on LinkedIn, a job board or received your application through their website.</p>
            <p><strong>Candidate Screening:</strong> After the initial contact, the recruiter will conduct a screening process to assess your qualifications, experience, and suitability for the job. This may involve a phone or video interview to delve deeper into your skills and career aspirations. They will also review your CV and Portfolio and may suggest small changes to make it more appealing to potential employers.&nbsp;</p>
            <p><strong>Matching and Submission: </strong>If the recruiter finds a suitable job opportunity that aligns with your skills and preferences, they will ask if they can submit your application to the hiring manager or company on your behalf.&nbsp;</p>
            <p><strong>Interview Request:</strong> If the company is interested in your experience, the recruiter will schedule an interview with the hiring manager from the company.</p>
            <p><strong>Interview Preparation:</strong> Before the interview, most recruiters will offer guidance and tips on how to prepare effectively. This could include telling you what to prepare for, going through the requirements and practicing interview questions.</p>
            <p><strong>Interview Feedback:</strong> After the interview, the recruiter will follow up with both you and the hiring manager to ask for feedback. This part is crucial to the recruiter because they can get a feel for how the interview went and if both parties are interested.&nbsp;</p>
            <p><strong>Negotiation and Offer:</strong> If the interview is successful, and the practice decides to make you an offer, the recruiter will communicate the details to you. They may also negotiate on your behalf regarding salary, benefits, or other aspects of the job offer.</p>
            <p><strong>Offer Acceptance and Onboarding:</strong> Once you accept the job offer, the recruiter may help with the onboarding process, ensuring all necessary paperwork is completed and facilitating a smooth transition into your new role.&nbsp;</p>
            <p><strong>Follow-up and Support: </strong>Even after you start the new job, the recruiter may check in with you to ensure that everything is going well and address any concerns or questions you may have during the initial stages of your employment.</p>
            <p>That&rsquo;s usually how it world between a recruiter and a job seeker.&nbsp;</p>
            <p/>
            <p/>
            <h3>How To Approach A Recruiter?</h3>
            <p>There are different ways you can approach a recruiter. Perhaps you saw a job advert and want to find out more information or apply for the position. In such cases, you can either submit your application and wait for them to contact you, or I would suggest submitting your application and then giving them a call afterwards.</p>
            <p>In that instance, you could say &ldquo;Hi [recruiters name], I&rsquo;ve submitted my application to the role XYZ in Manchester. Could you please tell me a bit more about the position and the company? Also, looking at my skill set and experience, do you have similar roles available?&rdquo;.</p>
            <p>Alternatively, you could give them a call and set up an initial call. Don&rsquo;t be surprised if a recruiter doesn't pick up right away, as they are often very busy with phone calls and meetings. It's best to arrange a call where both of you have 20-30 minutes to discuss what you're looking for, and the recruiter can answer any questions you may have.</p>
            <p>When emailing a recruiter, be professional and concise. Explain who you are, your level of experience, the type of role and location you're seeking, and ideally attach your CV and Sample Portfolio as well.</p>
            <p>Avoid sending an email that simply says, 'I&rsquo;m looking for a job - call me back.' Trust me, no one will call you back with such an unprofessional approach. Recruiters will be selective in who they work with and introduce to their clients. Don&rsquo;t mess it up.&nbsp;</p>
            <p/>
            <p/>
            <h3>What Should You Look For In A Recruiter?</h3>
            <p>When choosing the right recruiter, it&rsquo;s important to understand that not every recruiter will be able to help you. Why? Because most recruiters will focus on a specific sector (Architecture, IT, Design, etc.) and area/location (London, Midlands, North West, etc.).&nbsp;</p>
            <p>So first things first, make sure you find someone who focuses on the right sector and area/location.</p>
            <p>Secondly, make sure you schedule a call with the recruiter. That way, you can explain to them what exactly you are looking for and go through your skills and experiences. This will help the recruiter understand what sort of practices might be a good fit and will also enable them to provide you with a market update.</p>
            <p>Thirdly, prioritise open communication. A good recruiter will know their stuff. Whether that's having the right connections, knowing the right clients, having access to hidden jobs, or just instantly understanding what you are looking for. After your conversation with the recruiter, you should have a good idea if they'll be able to help you out and if you want to work with them.</p>
            <p>So, make sure the recruiter has good communication skills and is a good listener.</p>
            <p><strong>Tip</strong>: Trust your gut feeling. If during or after the call you felt like the recruiter didn't listen or was just fishing for information, you don't have to continue the partnership with them.</p>
            <p/>
            <p/>
            <h3>What Should I Discuss In My First Call With A Recruiter?</h3>
            <p>During your first call with a recruiter, they mainly want to learn more about you, your skill set, your experience, and the reasons behind your job search. The best recruiters will listen more than they talk. They will ask you questions but primarily focus on listening to what you have to say and understanding your needs. This helps them find the right role for you and avoid pushing you towards unsuitable job opportunities. You can also ask the recruiter questions about the current market, salary ranges for your level of experience and skills, and if they have any clients who might be interested in reviewing your CV.</p>
            <p>Don&rsquo;t forget, during the call, most recruiters will decide if they can help you and if they feel like they have clients who will be interested in your experience. I want you to understand that recruiters can and will choose who they want to represent to their clients.</p>
            <p/>
            <p/>
            <h3>How Should I Prepare Before My First Call With The Recruiter?</h3>
            <p>There are definitely a few things you can do to help the recruiter understand why and what you are looking for:</p>
            <ol>
            <li>Think about what exactly you are looking for, including the desired position, location, and salary expectations.</li>
            <li>Reflect on why you are looking to leave your current role or what you feel is missing in your current job.</li>
            <li>Ensure that you have your CV and Sample Portfolio ready to share with the recruiter.</li>
            </ol>
            <p>When I used to work as a recruiter, I used to ask lots of questions like, 'What are you looking for? What are you missing in your current role? What type of practice are you looking for? What would happen if your current practice made you a counter-offer, would you stay?' and so on.&nbsp;</p>
            <p>Sometimes, candidates realised during the conversation that they hadn't really thought things through and weren't ready to start looking.</p>
            <p/>
            <p/>
            <h3>What Sort Of Questions Should I Ask A Recruiter?</h3>
            <p>If a recruiter calls you about a specific role, make sure you know the company name, which position they are hiring for, and what the role entails. Don&rsquo;t feel pressured to send your details over without knowing where they're going. You should always know where your details are going to, this is extremely important.</p>
            <p>You can also ask more specific questions, such as:</p>
            <ul>
            <li>Have you previously placed candidates with this company?</li>
            <li>How long have you been working with them?</li>
            <li>What is the salary range for the position?</li>
            <li>Are there any additional benefits or perks offered with this role?</li>
            <li>What insights can you provide about their company culture?</li>
            <li>How does the company support employee growth and development?</li>
            <li>Is remote work or flexible working hours an option for this role?</li>
            <li>Could you explain their hiring process, including interview stages and other relevant details?</li>
            </ul>
            <p/>
            <p/>
            <h2>3. Section: Understanding How Recruiters Can Help&nbsp;</h2>
            <p/>
            <p/>
            <h3>How can a Recruiter Help Me to Find a New Job?</h3>
            <p>Working with a good recruiter can make a big difference in your job search. They possess a wealth of market knowledge and can guide you throughout the entire process, providing advice and informing you about unadvertised opportunities, among other things. I will only highlight the top 4 reasons and if you want to read more, you can find it here <a href="https://www.archjobs.co.uk/articles/how-a-recruiter-can-help-find-your-next-role" target="_blank" rel="noopener">How a Recruiter Can Help Find Your Next Role</a>.</p>
            <p>✔ Their Market</p>
            <p>Knowledge Recruiters gather extensive market knowledge through daily interactions with companies and candidates. They stay informed about exciting new projects, the benefits packages offered by various companies, the growth of certain businesses, and potential challenges. This additional knowledge is invaluable for any job seeker.</p>
            <p>✔ Their Network</p>
            <p>Networking plays an important role in a recruiter's day. Over time, they establish strong working relationships with hiring managers and company leaders, meeting them in person to learn more about the company, its culture, and its growth plans. That way, recruiters have a better understanding of the skills and personality traits employers are seeking. They will also get access to unadvertised/hidden roles.</p>
            <p>✔ Their Negotiation Skills</p>
            <p>Recruiters are skilled negotiators and can help advocate for your interests during the salary negotiation process. They have experience in understanding market rates and can work to secure a competitive compensation package for you.</p>
            <p>✔ Access to Hidden Opportunities</p>
            <p>Recruiters often have access to job openings that are not publicly advertised. By working with a recruiter, you may gain access to these hidden opportunities and increase your chances of finding the right job.</p>
            <p/>
            <p/>
            <h2>4. Section: The Good, The Bad, The Scams</h2>
            <p/>
            <p/> 
            <h3>What Makes A Good Recruiter?</h3>
            <p>A good recruiter will keep you updated, be proactive in finding you a new position, provide market updates, listen to what you are after, help you with your CV and Portfolio, guide you through the interview process, assist in negotiating the best offers, give you tips on how to hand in your notice and stay in contact between you accepting the offer and starting the new role.&nbsp;</p>
            <p>They might even stay in touch afterwards, but it all depends on what you want and what the working relationship was like.</p>
            <p/>
            <p/>
            <h3>What Makes A Bad Recruiter?</h3>
            <p>A bad recruiter will not listen, refuse to disclose who their clients are, have poor communication skills, fail to keep you in the loop with any applications, prioritise their own interests over yours and only focus on placing you in any new role, send your applications to practices without your permission, and pressure you to accept an offer.</p>
            <p>Unfortunately, there are bad recruiters out there, so make sure you don't fall for them. Please avoid such recruiters and be cautious in your interactions. They are the ones who give recruiters a bad reputation.</p>
            <p/>
            <p/>
            <h3>How To Spot Fake Recruiters And Avoid Scams</h3>
            <p>I've noticed an increase in recruiter scams, especially over the last few years. It's essential to recognise the red flags that can help identify suspicious activities.</p>
            <ul>
            <li>Instant job offers without an interview</li>
            <li>Lack of information about the recruiter and their company</li>
            <li>Requesting upfront payments (job seekers shouldn't pay recruiters)</li>
            <li>Vague job advertisements</li>
            <li>Pressure to act quickly</li>
            <li>Soliciting personal information, such as bank details</li>
            <li>Unprofessional communication, such as poor grammar or spelling mistakes</li>
            <li>Absence of an online presence</li>
            <li>Requiring submission of all personal information, including bank details, to apply</li>
            <li>It seems too good to be true; trust your gut feeling</li>
            </ul>
            <p>If you notice any of these signs, be cautious and take a moment to assess the situation. Seek opinions from friends, family, or others you trust. Conduct thorough research and avoid acting impulsively. Remember, you're in architecture, and you're smart!</p>
            <p>Below is an example of a scam application where they ask for bank details.</p>
            <p><img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Scam Alert.jpg" alt="The image shows a screenshot of a scam application on a computer screen. The application asks the recipient to provide their available start date and the name of their bank for payroll purposes. The email also contains a warning that the email is a scam."></img></p>
            <p/>
            <p/>
            <p/>
            <h2>5. Section: Most Frequently Asked Questions About Recruiters</h2>
            <p/>
            <p/>
            <h3>Do I Need To Keep Working With A Recruiter I Don't Get Along With?&nbsp;</h3>
            <p>No, you are not obligated to work with a recruiter if you don't feel that they can genuinely help you or if you don't have a good rapport with them. It could be that they are not attentive to your preferences or keep suggesting unsuitable roles. Just because a recruiter has submitted your application to a specific client does not mean you have to continue working with them until you find a new role.</p>
            <p>Do I have to pay a recruiter to help me find a new job in the UK? No, if you are a job seeker, you do not pay recruiters. Their base salary is paid by their agency, and their commission comes from successful placements made with their clients. If you are ever asked to pay a recruiter or pay for any assessments, do not do so. It is likely a scam.</p>
            <p/>
            <p/>
            <h3>How Many Recruiters Should I Work With?</h3>
            <p>I would recommend working with a maximum of two recruiters at a time. Managing more than that can become challenging as it becomes harder to keep track of your applications and the information you've shared with each recruiter.</p>
            <p/>
            <p/>
            <h3>What Are The Worst Things To Say To A Recruiter?</h3>
            <p>Okay, there are a couple of things to keep in mind. When you speak to a recruiter for the first time, they want to find out more about you, what you are looking for, and usually why you are looking.</p>
            <p>First impressions count, and you don&rsquo;t want to do the following things:</p>
            <ul>
            <li>Don&rsquo;t talk negatively about your previous company or boss</li>
            <li>Don't say, "You can send my application to all your clients. I've sent my application to hundreds of practices. I don't know where my application has gone to"</li>
            <li>Don't say, "I&rsquo;m only looking for more money, and I would take a counter-offer from my current place."</li>
            <li>Don't say, "I&rsquo;m working with a lot of recruiters together&rdquo;</li>
            </ul>
            <p/>
            <p/>
            <h3>How Do I Know That I&rsquo;m Working With A Knowledgeable Recruiter?</h3>
            <p>The best way to find out if you&rsquo;re working with a knowledgeable recruiter is simply by talking to them. Ask them questions about your sector/market, which skill sets are in demand and what are the going salaries for people with your level of experience. Test them as much as they&rsquo;re testing you.&nbsp;</p>
            <p/>
            <p/>
            <h3>Is It Ethical For A Recruiter To Send My Application To Practices Without My Permission Or Knowledge?&nbsp;</h3>
            <p>No, it is not ethical. When working with a recruiter, you need to give them permission to send or approach practices on your behalf. If a recruiter calls and asks to send your details to "my clients," always ask for the names of the practices. This is important because you may have already approached them or may not want your application to be sent to certain firms.</p>
            <p/>
            <p/>
            <h3>Should I Work With A Recruiter If I&rsquo;m Not Ready To Move Jobs Yet?&nbsp;</h3>
            <p>It depends. If you are not ready to start a new job, working with a recruiter may not be the best use of your time. Recruiters are typically paid when they place a candidate in a new role, so they may be more interested in finding you a job that is a good fit for them than a job that is a good fit for you. Additionally, if you are not ready to start a new job, you may not be as motivated to interview or accept a position.&nbsp;</p>
            <p>However, there are some potential benefits to working with a recruiter even if you are not ready to start a new job. Recruiters can help you stay up-to-date on the job market, identify potential opportunities, and prepare for interviews. Plus, recruiters can provide support and guidance throughout the job search process. If you are considering working with a recruiter, it is important to be upfront about your timeline and expectations.</p>
            <p>This approach is particularly common among more senior candidates. Often, they seek something more specific or niche, knowing that such job opportunities are not readily available every day. Instead, they choose to work with a trusted and reliable recruiter who can notify them when something interesting comes up.&nbsp;</p>
            <p/>
            <p/>
            <h3>Can Recruiters Help Every Job Seeker?</h3>
            <p>Well, the truth is, it's not that straightforward. Not every job seeker who reaches out to recruiters can be assisted. It all depends on various factors, like the kind of job you're after, where you're looking to work, your skill level, and your experience. They might genuinely not be the right person to talk to. One thing to keep in mind is, recruiters can choose who they want to work with and who they want to represent in front of their clients.&nbsp;</p>
            <p>I'll be honest with you, if your initial email, CV, or Portfolio contains loads of typos or lacks clarity about what you're looking for, don't expect to get a response. Recruiters are super busy and focused on candidates whose skills and experience are in high demand.&nbsp;</p>
            <p>Over time, an experienced recruiter can glance at a CV and quickly determine if the candidate is of interest and whether they have clients seeking their specific experience. If the recruiter already represents numerous people with similar experience, they might be less inclined to add another candidate with identical expertise.</p>
            <p>So, don't take it personally if you don't hear back from a recruiter. And here's a pro tip: when you're reaching out to a recruiter for the first time, don't just say, "I want a new job anywhere." or &ldquo;Do you have any jobs available?&rdquo;. Trust me, that's not going to grab their attention.</p>
            <p>Instead, take a little time to craft a short but informative message that outlines your experience and exactly what type of job you're after. And remember, being polite always goes a long way.</p>
            <p/>
            <p/>
            <h2>Conclusion</h2>
            <p>I hope this article has shown you how to find a recruiter and the different ways available. Moreover, I want to ensure that you understand how to effectively work with a recruiter and can distinguish between a good and bad one. Collaborating with a recruiter can genuinely impact your career for many years to come. So, if you find one you get along with, who truly understands your needs, and keeps you in mind for any "hidden roles," make sure to keep that recruiter close to your chest.</p>
</div>

//rename YourArticle below to your article name
export default class HowToFindARecruiter extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
      };
    }

    componentDidMount = () => {
      window.scrollTo(0, 0)
    }
    render(){
        return <ArticleFrame ArticleDataName={thisArticleData}>
                {articleContent}
            </ArticleFrame>
    }
}

// Now, go to the App.js file
// add this line at the top, rename {YourArticle} to whatever you called it. You also need to copy and rename this file to "{YourArticle}.js"
// import YourArticle from './articles/YourArticle'
// Add another line in the Routes section, renaming "YourArticle" again:
// <Route path="/articles/YourArticle" element={<YourArticle/>} />


// now in TERMINAL below, run the following command:
// npm run-script dev
// head to the article page and review the html to make sure its properly formatted
//add <p/> between rows where you want a larger space
//add any images that were missed out (will need to upload these to article_images folder too) eg. <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/example_keywords.png" alt="Archjobs Keywords when applying"></img>
//add rel="nofollow noopener" to any links so it doesnt impact our SEO eg.  <a href="https://dictionary.cambridge.org/dictionary/english/cv" target="_blank" rel="nofollow noopener">Cambridge Dictionary</a>





// finally, publish the changes to github
// When its live, go to search console and inspect the new url: https://search.google.com/search-console?resource_id=sc-domain%3Aarchjobs.co.uk
// Might need a couple of attempts for it to get cached and display properly
// Make sure the rich text snippet is working properly
// If it is, request indexing from google to speed up the process