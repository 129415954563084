
import React from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import Button from './Buttons.js'
import Autocomplete from '@mui/material/Autocomplete';
import tags from '../data/tags.js'
import Quill from 'quill';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import {MdClose} from 'react-icons/md';
import Checkbox from '@mui/material/Checkbox';
import axios from 'axios';
import { Toggle } from './WhyWorkWithUsRecruiters.js'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export const checkoutLinks = {
  agency: {
    basic: {link: "https://buy.stripe.com/7sIdRl8F7fAUcfKdR4", price: 129},
    jobs: {link: "https://buy.stripe.com/cN200v8F7dsMenScN2", price: 499},
    linkedin: {link: "https://buy.stripe.com/cN200v8F7dsMenScN2", price: 499},
    full: {link: "https://buy.stripe.com/eVa7sXbRjagAgw000i", price: 899},
  },
  recruiter: {
    basic: {link: "https://buy.stripe.com/7sIdRl8F7fAUcfKdR4", price: 89},
    jobs: {link: "https://buy.stripe.com/cN200v8F7dsMenScN2", price: 199},
    linkedin: {link: "https://buy.stripe.com/cN200v8F7dsMenScN2", price: 239},
    full: {link: "https://buy.stripe.com/eVa7sXbRjagAgw000i", price: 359},
  }
}

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <MdClose />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};



class RecruiterPayment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            job_submission_status: {},
            success_open: false,
            package: "standard",
            agency: false,
        }
        this.quill = null
        this.multiSelectValues = {}
      }    
    
      componentDidMount = () => {
        if (!this.quill){
          this.quill = new Quill('#jobdescriptioninput', {
            theme: 'snow',
            formats: [
              'bold',
              'header',
              'italic',
              'link',
              'list',
              'blockquote',
              'image',
              'indent'
            ],
            modules: {
              toolbar: [
                [{'header': 1}, {'header': 2}],
                ['bold', 'italic', 'link'],
                ['blockquote', {'list': 'ordered'}, {'list': 'bullet'}],
              ],
              clipboard: {
                matchVisual: false // https://quilljs.com/docs/modules/clipboard/#matchvisual
              }
            }
          });
        }
        if (window.location.search){
          var urlParams = new URLSearchParams(window.location.search);
          var SelectedPackage = urlParams.get('package')
          var agency = urlParams.get('agency')
          if (agency == "true") {
            agency = true
          } else {
            agency = false
          }
          if (SelectedPackage){
            this.setState({package: SelectedPackage, agency: agency})
          }
        }
        window.scrollTo(0, 0)
      }

      updateMultiSelectValue = (id, value) => {
        this.multiSelectValues[id] = value
      }

      Submit = () => {
        console.log("submitting")
        this.setState({job_submission_status: {status: "loading"}})

        const fields = [
            {id: "company_name", field_name:"company_name", required: true, label: "Company Name"},
            {id: "company_site", field_name:"company_site", required: true, label: "Company Site"},
            {id: "name", field_name:"contact_name", required: true, label: "Contact Name"},
            {id: "phone", field_name:"phone", required: true, label: "Contact Number"},
            {id: "email", field_name:"email", required: true, label: "Email Address"},
          ]

          var jobSubmission = {data: {}}
      
          for (const field of fields){
            var input = document.getElementById(field.id)
      
            if (input != null){
              if (field.type == "checkbox"){
                jobSubmission.data[field.field_name] = input.checked ? 1: 0
              } else if(field.type == "numeric") {
                var int_value = parseInt(input.value)
                if (int_value < 0){
                  int_value = null
                }
                jobSubmission.data[field.field_name] = int_value
              } else {
                if ((input.value == "") && (field.required == true)){
                  this.setState({job_submission_status: {status: "error", message: "Required field "+field.label+" not filled out", field: field.id, field_message: "Required Field"}})
                  return
                } else {
                  jobSubmission.data[field.field_name] = input.value
                }
                jobSubmission.data[field.field_name] = input.value
              }
            } else {
              console.log("Couldnt get input: ", field.id)
            }
        }
    
        jobSubmission.data["package"] = this.state.package + " recruiter"
        if (this.state.agency){
          jobSubmission.data["package"] += " - Agency"
        } else {
          jobSubmission.data["package"] += " - Recruiter"
        }

        console.log(jobSubmission)
        const SubmitUrl = "https://api.archjobs.co.uk/customer_signup" 

        const checkoutLink = checkoutLinks[this.state.agency ? "agency" : "recruiter"][this.state.package].link

        axios.post(SubmitUrl, {
            body: JSON.stringify(jobSubmission),
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            }
          }).then(response => {
            console.log(response.data)
            if ("error" in response.data){
                this.setState({job_submission_status: {status: "error", message: "Could not submit form, please email us at hello@archjobs.co.uk and let us know!"}})
            } else {
                this.setState({success_open: true, job_submission_status: {status: "success"}})
                // window.open(checkoutLink, '_blank');
            }
          }).catch(error =>{
            this.setState({job_submission_status: {status: "error", message: "Could not submit form, please email us at hello@archjobs.co.uk instead"}})
          })
      }

      handleClose = () => {
        this.setState({success_open: false})
      }

      handlePackageChange = (newPackage) => {
        this.setState({package: newPackage})
      }

      handleToggle = () => {
        this.setState({agency: !this.state.agency})
      }

    render() {

    var error_text = ""

    const checkoutSelectedRange = this.state.agency ? checkoutLinks.agency : checkoutLinks.recruiter

      return (
        <div className="PostJobPage">
            <div className="PostJobFormContainer">
                <div className="PostJobForm">
                    <div className="PostJobFormHeader"> 
                        Thanks for your interest in joining our Trusted Architectural Recruiters
                    </div>
                    <div className="PostJobFormText"> 
                    Please fill out the details below, and we'll be in touch to determine if your agency is a suitable fit for ArchJobs.
                        {/* We will review whether your company/practice is a good fit for our job board, and someone from the team will get in touch ASAP.   */}
                    </div>
                    {/* <div className="PostJobFormNotice"> 
                        Already a client? Please email <a href="mailto:hello@archjobs.co.uk">hello@archjobs.co.uk</a>.
                    </div> */}
                    <div className="PostJobFormText"> 
                        {/* Please note ArchJobs is a <b>curated job board</b>, we can only list recruiters that work with Architectural and Multi-Disciplinary Companies. */}
                         {/* that meet the following criteria:
                        <ul>
                            <li>You are looking for architectural staff</li>
                            <li>Your company is based in the UK</li>
                            <li>You are not a recruitment agency</li>
                        </ul> */}
                    </div>
                    <div className="PostJobFormDivider"></div>
                    <div className="PostJobFormHeader">
                        Basic Company and Contact Details
                    </div>
                    <div className="PostJobFormInputRow">
                        <div className="PostJobFormInputTitle">Company Name</div>
                        <TextField
                            variant="outlined"
                            id="company_name"
                            className="PostJobInput"
                            type="text"
                            placeholder="Company Name"
                            error={this.state.job_submission_status.field == "company_name"}
                            helperText={(this.state.job_submission_status.field == "company_name") && this.state.job_submission_status.field_message}
                            />
                    </div>
                    <div className="PostJobFormInputRow">
                        <div className="PostJobFormInputTitle">Company Website</div>
                        <TextField
                            variant="outlined"
                            id="company_site"
                            className="PostJobInput"
                            type="text"
                            placeholder="Company Website"
                            error={this.state.job_submission_status.field == "company_site"}
                            helperText={(this.state.job_submission_status.field == "company_site") && this.state.job_submission_status.field_message}
                            />
                    </div>
                    <div className="PostJobFormInputRow">
                        <div className="PostJobFormInputTitle">Contact Person</div>
                        <TextField
                            variant="outlined"
                            id="name"
                            className="PostJobInput"
                            type="text"
                            placeholder="Full Name"
                            error={this.state.job_submission_status.field == "name"}
                            helperText={(this.state.job_submission_status.field == "name") && this.state.job_submission_status.field_message}
                            />
                    </div>
                    <div className="PostJobFormInputRow">
                        <div className="PostJobFormInputTitle">Contact Number</div>
                        <TextField
                            variant="outlined"
                            id="phone"
                            className="PostJobInput"
                            type="text"
                            placeholder="Contact Number"
                            error={this.state.job_submission_status.field == "phone"}
                            helperText={(this.state.job_submission_status.field == "phone") && this.state.job_submission_status.field_message}
                            />
                    </div>
                    <div className="PostJobFormInputRow">
                        <div className="PostJobFormInputTitle">Email Address</div>
                        <TextField
                            variant="outlined"
                            id="email"
                            className="PostJobInput"
                            type="email"
                            placeholder="Contact Email"
                            error={this.state.job_submission_status.field == "email"}
                            helperText={(this.state.job_submission_status.field == "email") && this.state.job_submission_status.field_message}
                            />
                    </div>
                    <div className="PostJobFormDivider"></div>
                    <div className="PostJobFormHeader">
                        Your Package
                    </div>
                    <div className="PostJobFormText"> 
                        We will be in contact to confirm whether our services will be a fit, and proceed with payment afterwards.
                    </div>
                    <div className="PostJobFormInputRow">
                        <div className="PostJobFormInputTitle">I'm interested in</div>
                        <div className="PostJobPackageContainer">
                            <div className="PostJobPackageToggleContainer">
                              <Toggle isAgency={this.state.agency} handleToggle={this.handleToggle} />
                            </div>
                            <div className={"PostJobPackage"+(this.state.package == "basic"?" PostJobPackageSelected":"")} onClick={() => {this.handlePackageChange("basic")}} id="basic" >
                              <div className="PackageTitle">Find a Recruiter</div>
                              {/* <div className="PackageDescription">A basic Job Advert posted on ArchJobs</div> */}
                              {/* <div className="PackagePrice">£{checkoutSelectedRange.basic.price}</div> */}
                              {/* <div className="PackagePerAdSubtext">One-off payment</div> */}
                            </div>
                            <div className={"PostJobPackage"+(this.state.package == "jobs"?" PostJobPackageSelected":"")}  onClick={() => {this.handlePackageChange("jobs")}} id="jobs" >
                              <div className="PackageTitle">Find a Recruiter+</div>
                              {/* <div className="PackageDescription">A standard Job Advert, posted on ArchJobs & across our social media channels</div> */}
                              {/* <div className="PackagePrice">£{checkoutSelectedRange.jobs.price}</div> */}
                              {/* <div className="PackagePerAdSubtext">{this.state.agency ? "Recruiter": "per month"}</div> */}
                            </div>
                            <div className={"PostJobPackage"+(this.state.package == "linkedin"?" PostJobPackageSelected":"")}  onClick={() => {this.handlePackageChange("linkedin")}} id="linkedin" >
                              <div className="PackageTitle">LinkedIn Growth</div>
                              {/* <div className="PackageDescription">A standard Job Advert, posted on ArchJobs & across our social media channels</div> */}
                              {/* <div className="PackagePrice">£{checkoutSelectedRange.linkedin.price}</div> */}
                              {/* <div className="PackagePerAdSubtext">{this.state.agency ? "per month - billed annually": "per month"}</div> */}
                            </div>
                            <div className={"PostJobPackage"+(this.state.package == "full"?" PostJobPackageSelected":"")}  onClick={() => {this.handlePackageChange("full")}} id="full" >
                              <div className="PackageTitle">Max Exposure</div>
                              {/* <div className="PackageDescription">A premium Job Advert, sponsored post on the website & posted across our social media channels</div> */}
                              {/* <div className="PackagePrice">£{checkoutSelectedRange.full.price}</div> */}
                              {/* <div className="PackagePerAdSubtext">{this.state.agency ? "per month - billed annually": "per month"}</div> */}
                            </div>
                        </div>
                    </div>
                    <div className="PostJobFormSubmit">
                        <a href="/recruiters/join-us" target="_blank" className="WhyWorkWithUsLink" style={{textDecoration: "underline"}}>What is included?</a>
                    </div>
                    <div className="PostJobFormSubmit"> 
                        <Button btnClassName="JobAdHeaderApplyButton" onClickFunc = {() => {this.Submit()}}>Submit</Button>
                    </div>
                    <div className="PostJobFormError"> 
                        {(this.state.job_submission_status.status == "error") && this.state.job_submission_status.message}
                    </div>
                </div>
            </div>
            <BootstrapDialog
                onClose={this.handleClose}
                aria-labelledby="customized-dialog-title"
                open={this.state.success_open}
                className="SuccessModal"
            >
                <div className="ModalClose"><MdClose className="CloseIcon" onClick={this.handleClose}></MdClose></div>
                <div className="SuccessModalTitle">Thank You for your Interest</div>
                <div className="SuccessModalText">We will be in contact soon to confirm whether our packages are a fit for your company. <p/> You can now close this window. </div>
                                          
            </BootstrapDialog>
        </div>
      )
    }
  }

  export default RecruiterPayment