import ArticleFrame from "./ArticleFrame";
import React, { Component } from 'react';


export default class ArchAdemia extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
      };
    }
    componentDidMount = () => {
      window.scrollTo(0, 0)
    }

    render(){
        return <ArticleFrame ArticleDataName="ArchAdemia" >
            <h2>Welcome to ArchAdemia.</h2>
            <p>Welcome to <a target="_blank" href="http://www.archademia.com?utm_source=ArchJobs&utm_medium=Article">ArchAdemia</a>, your gateway to architecture. With expert-led tutorials, interactive courses, enlightening podcasts, and a nurturing community, it's all designed to empower your architectural journey from the ground up.</p>
            <p><strong>For students</strong>, ArchAdemia is your bridge from theory to practice. Software tutorials under the guidance of industry professionals will give you the capability to make an impact from day 1. Master the art of drawing, manage projects like a pro, and immerse yourself in podcasts that give you the inside scoop from those who've paved the way. Say goodbye to the daunting transition from academia to industry; ArchAdemia is here to make you practice-ready.</p>
            <p><strong>For practicing architects</strong>, the platform acknowledges the preciousness of your time, offering digestible lessons and resources that fit seamlessly into your hectic schedules. Elevate your expertise in sustainability, building regulations, or mastering the art of client communication. It's about enriching your professional palette with skills that go beyond the drawing board.</p>
            <p>But ArchAdemia isn't just tutorials and resources. It's a vibrant community where passions meet, discussions flourish, and connections are forged. It's a space where students and professionals alike share, learn, and grow together, transcending traditional boundaries.</p>
            <p>Your success is our priority. Navigate through structured courses, pick lessons that intrigue you, or tailor a learning path that fits your ambitions. Learn at your pace, on your terms, and let your curiosity lead the way.</p>
            <p/>
            <p/>
            <h2>Mastering Architectural Expertise.</h2>
            <p>With 35 meticulously curated <a target="_blank" href="http://www.archademia.com/lessons?utm_source=ArchJobs&utm_medium=Article">courses</a>, offering everything from beginner basics to advanced expertise in parametric design and BIM, we will help you to master architecture. Whether you're refining your software skills or diving deep into design theory, we’ve got you covered.</p>
            <p><strong>Software Mastery:</strong> From Revit to Rhino, Photoshop to Twinmotion, our courses make mastering industry-leading software easy &amp; enjoyable. Led by RIBA qualified architects, each tutorial is packed with practical knowledge and hands-on exercises designed to turn you into a software wizard.</p>
            <p><strong>Practice Ready:</strong> Delve into ‘real-life’ architecture, from project management and client communication to the nuances of architectural detailing. Elevate your designs with courses on perspective drawing and parametric design, adding layers of depth and sophistication to your work.</p>
            <p><strong>What's Next:</strong> Our library is ever-growing to keep pace with the latest innovations in our industry. Our courses currently in production include Blender basics, part 3 navigation, and architectural modelmaking masterclass. We promise to always keep you at the forefront of industry innovation and regulations.</p>
            <p> <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/ArchAdemia_Course Display.jpg" alt="Screenshot from ArchAdemia website - The image is a screenshot of the homepage of a website that offers courses in 3D design, architecture, and animation software. The homepage features a grid of cards, each of which represents a different course. The cards include the course title, a brief description, and an image. Some of the courses featured on the homepage include: SketchUp Complete Guide, The Perfect Floor Plan,Rhino & Grasshopper Essentials and much more."></img> </p> 
            <p/>
            <p/>
            <p>To get <a target=" blank" href="https://archademia.com/pricing/"><strong>10% off your Annual Membership subscription</strong></a>, simply enter <strong>ARCHJOBS10</strong>. </p>  
            <p/>
            <p/>
            <h2>Enhance Your Workflow with ArchAdemia's Download Packs.</h2>
            <p>Our extensive collection of <a target="_blank" href="http://www.archademia.com/resources?utm_source=ArchJobs&utm_medium=Article">downloadable resources</a> is designed to streamline your architectural workflow by giving you a library of essential tools and resources in a variety of software’s. Packs include CAD blocks, Revit families, CGI textures, Rendering pre-sets, Excel templates for area schedules and so much more. See below a quick list:</p>
            <p><strong>Architect's Toolkit:</strong> For any practitioner, this is packed with templates, checklists, and guides, ensuring your day runs smoothly. From efficient project management spreadsheets to InDesign presentation templates, this toolkit is your key to navigating the architectural landscape with confidence.</p>
            <p><strong>Real-world Scenarios:</strong> Gain practical insights with project filing systems, template contracts, and design risk registers that mirror professional practice. These resources offer a glimpse into the industry's workings, preparing you for real-life challenges.</p>
            <p><strong>CAD Excellence:</strong> Access a comprehensive library of CAD drawings and details, enhancing your drafting efficiency and learning from established standards. This pack saves you time while ensuring quality in your design documentation.</p>
            <p><strong>Inclusive Design:</strong> Our Access For All Toolkit makes compliance with accessibility standards straightforward, providing layouts, signage templates, and thoughtful design considerations to cater to a diverse user base.</p>
            <p><strong>Regulation Compliance:</strong> Navigate UK construction regulations effortlessly with our CDM &amp; Principal Designer Pack. It's filled with checklists and guidance to keep your projects compliant and safe.</p>
            <p><strong>Planning Mastery:</strong> Simplify the planning process with our Planning Toolkit. From site analysis to feasibility reports, these resources help you create compelling applications and achieve planning approvals with ease.</p>
            <p><strong>Visual Communication:</strong> The Ultimate Diagram Toolkit and Presentation Boards equip you with pre-made diagrams, symbols, and customisable layouts, enabling you to convey your design intent clearly and professionally.</p>
            <p> <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/ArchAdemia_Example Lesson.jpg" alt="Screenshot from the ArchAdemia website - The image is a screenshot of a video on the website Architectural Detailing Basics. The video is titled 'Chapter 1-Importance of Detailing & Call Outs' and is part of Lesson 1-Introduction. The video is 22 minutes and 55 seconds long and is complete."></img> </p>
            <p/>
            <p/>
            <h2>Elevate Your Journey with Personalised Guidance and Community Support.</h2>
            <p>Learning isn't just about acquiring knowledge; it's also about engaging, receiving feedback, and finding support. At ArchAdemia, we recognise the uniqueness of your architectural path and are dedicated to empowering you at every turn. We achieve this with bespoke mentorship and a vibrant community forum where students and practicing architects support each other.</p>
            <p><strong>Personalised Mentorship &amp; Tutoring:</strong> We link you with experienced architects for one-on-one advice, focused on your specific needs. Discuss project challenges, portfolio enhancement, or career goals, and gain insights from professionals who've been in your shoes.</p>
            <p><strong>Webinars and live sessions:</strong> Join live sessions with industry leaders on topics like sustainable design and business development. Engage directly, ask questions, and get detailed information to help you overcome any challenges you’re facing.</p>
            <p><strong>A Supportive Community:</strong> Study &amp; connect. Through our forums and online groups, people can share experiences, exchange ideas, and build a network of support, all within a community that understands and shares your passion.</p>
            <p><strong>Support for Solo Practitioners &amp; Early-Career Architects:</strong> We know the hurdles of starting out or working alone—balancing multiple roles, feeling isolated, and staying motivated. Our community offers a space to share and learn from others' experiences, providing not just professional support but also a sense of belonging.</p>
            <p><strong>Investing in Well-being:</strong> Recognising the pressures of the architectural profession, we offer resources focused on mental and emotional health, including stress management and work-life balance workshops. </p>
            <p> <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/ArchAdemia_Team learning Lumion.jpg" alt="Screenshot from the ArchAdemia website showing 3 men in front of a computer screen in an office. One of them is pointing at the screen while explaining the design."></img> </p> 
            <p/>
            <p/>
            <h2>ArchAdemia for Studios - A Collaborative Vision for Excellence.</h2>
            <p><a target="_blank" href="https://archademia.com/for-studios/?utm_source=ArchJobs&utm_medium=Article">ArchAdemia for Studios</a> aims to enhance the capabilities of both practices and universities, by upskilling their professionals and students. We will take care of their training requirements, allowing them to focus on the running on institutions.</p>
            <p><strong>Empowering Practices: </strong>We help to grow architectural practices, by making efficiencies in the training process. With access to over 500 industry-aligned tutorials, we provide the tools for skill enhancement, confidence building, and cultivating a learning culture. This resource helps in attracting top talent, and ultimately improving productivity and profitability. Practices can manage subscriptions, track progress, and leverage insights to foster a competitive learning environment.</p>
            <p><strong>Supporting Universities:</strong> For academic institutions, ArchAdemia supplements traditional teaching, allowing faculty to focus on advanced instruction while promoting independent learning among students. This approach enriches the curriculum, attracts top students, and contributes to their success by offering a blend of theoretical and practical knowledge. It also alleviates the teaching workload, enabling a focus on design, theory, and personal guidance.</p>
            <p><strong>Real-World Impact:</strong> Our case study practice, Urbanist Architecture, highlights ArchAdemia's impact, showcasing how the platform's in-depth training videos and comprehensive learning resources streamline the onboarding process, enhance efficiency, and boost team skills. It proves the platform's ability to meet the practical needs of modern architectural practices and educational institutions.</p>
            <p/>
            <p/>
            <h2>Join ArchAdemia and empower yourself.</h2>
            <p>In conclusion we are a platform dedicated to transforming architectural education. We support those who want to advance in their architectural careers, through online courses, downloadable asset packs and personalised mentorship.</p>
            <p>By joining our community, you will not only be skilled in the latest software but also in leadership, sustainable design, and client relations. We have the largest architecture specific library with over 500 tutorials, hands-on resources, and a supportive community to enrich your skill set and confidence.</p>
            <p><a target="_blank" href="http://www.archademia.com/pricing?utm_source=ArchJobs&utm_medium=Article">Join ArchAdemia</a> today to begin a journey of continuous learning, growth, and empowerment. Together, we can elevate the future of architecture, one empowered architect at a time. Visit our website to explore our offerings, browse success stories, and discover how ArchAdemia can transform your architectural journey. Your success is our priority.</p>
            <p>To get <a target=" blank" href="https://archademia.com/pricing/"><strong>10% off your Annual Membership subscription</strong></a>, simply enter <strong>ARCHJOBS10</strong>. </p>  
            </ArticleFrame>
    }
}