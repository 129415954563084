import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';
import { feature } from 'topojson';
import ukTopoJSON from '../data/ukcountries.json'

const SalaryMap = ({ data }) => {
  const svgRef = useRef();

  useEffect(() => {

    data = data.filter(row => row.Lat && row.Long)
    d3.select(svgRef.current).selectAll('svg').remove();
    //   .append('g')
    //   .attr('transform', `translate(${margin.left}, ${margin.top})`);
    const margin = { top: 20, right: 70, bottom: 20, left: 70 };
    const div = d3.select('#salary_map').node();
    const width = div.offsetWidth;
    const height = div.offsetHeight;
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    const uk = feature(ukTopoJSON, ukTopoJSON.objects.Countries_December_2017_Ultra_Generalised_Clipped_Boundaries_in_UK_WGS84);

    const svg = d3.select(svgRef.current)
      .append('svg')
      .attr('width', innerWidth + margin.left + margin.right)
      .attr('height', innerHeight + margin.top + margin.bottom)
    // Set up the projection and path generators
    const projection = d3.geoMercator()
    .fitExtent([[margin.left, margin.bottom-height*0.35], [(width - margin.right), (height - margin.top)*1.1]], uk);

    const path = d3.geoPath().projection(projection);

    const max_count = d3.max(data, d => d.count); 

    svg.append('path')
        .datum(uk)
        .attr('d', path)
        .attr('fill', '#EDEDED')
        .attr('stroke', '#484E57')
        .attr('stroke-width', 0.5);

    // Bind the data to the circles
    const circles = svg.selectAll('circle')
      .data(data);

    // Enter and update
    circles.enter()
        .append('g')
        .each(function(d) {
            d3.select(this)
                .append('circle')
                .attr('cx', d => projection([d.Long, d.Lat])[0])
                .attr('cy', d => projection([d.Long, d.Lat])[1])
                .attr('r', d => 8 + 5*(d.count/max_count)**0.5)
                .attr('fill', '#2A4777')
                .attr('class', 'circle-hover')
                .attr('z-index', 5)
                .attr('opacity', 0)
                .on('mouseover', function(d) {
                    d3.selectAll('.map-point-label')
                    .transition() // Start a transition
                    .duration(200)
                    .style('opacity', d2 => d.target.__data__.city == d2.city ? 1: 0);
                    // d3.select(`#label_${d.city}`).attr('opacity', 1);
                    })
                    .on('mouseout', function(d) {
                    d3.selectAll('.map-point-label')
                    .transition() // Start a transition
                    .duration(200)
                    .style('opacity', (d, i) => i < 8? 1: 0);
                });


            d3.select(this)
                .insert('circle', ':first-child')
                .attr('cx', d => projection([d.Long, d.Lat])[0])
                .attr('cy', d => projection([d.Long, d.Lat])[1])
                .attr('r', d => 2 + 5*(d.count/max_count)**0.5)
                .attr('fill', '#2A4777')
                .attr('opacity', 0.7)
                .attr('id', d => `circle_${d.city}`);
        })
    // Exit
    circles.exit().remove();

    // Bind the data to the labels
    const labels = svg.selectAll('.label')
      .data(data);

    // Enter and update
    labels.enter()
        .append('g')
        .attr('class', 'map-point-label')
        .attr('id', d => `label_${d.city}`)
        .attr('opacity', (d, i) => i < 8? 1: 0)
        .attr('pointer-events', "none")
        .each(function(d) {
        // append a text element to the group
        let x_scale = 1
        let y_scale = 1
        let [x_pos, y_pos] = projection([d.Long, d.Lat])
        if (x_pos > 0.65*width){
            x_scale = -1
        }
        if (y_pos > 2/3*height){
            y_scale = -1
        }
        //fix edinburgh/glasgow line
        if(y_pos < 0.55*height && x_pos > 0.5*width){
            x_scale = -1
        }
        
        const r = (0.5*(2 + 5*(d.count/max_count)**0.5)**2)**0.5

        d3.select(this)
            .append('text')
            .attr('class', 'city_label')
            .attr('x', d => projection([d.Long, d.Lat])[0] - x_scale*(12+r+5))
            .attr('y', d => projection([d.Long, d.Lat])[1] - y_scale*(12+r+5)-7)
            .text(d => `${d.aj_city}`)
            
        d3.select(this)
            .append('text')
            .attr('class', 'city_salary_label')
            .attr('x', d => projection([d.Long, d.Lat])[0] - x_scale*(12+r+5))
            .attr('y', d => projection([d.Long, d.Lat])[1] - y_scale*(12+r+5)+7)
            .text(d => `${d.avg.toLocaleString('en-GB',   {style: 'currency', currency: 'GBP', maximumFractionDigits: 0})}`) 

        // get the bounding box dimensions of the text element
        const bbox_initial = this.getBBox();
        
        if(x_scale == 1){
            d3.select(this)
                .selectAll('text')
                .attr('x', d => projection([d.Long, d.Lat])[0] - x_scale*(12+r+5) - bbox_initial.width)
        }
        const bbox = this.getBBox();
        // append a rectangle element to the group
        d3.select(this)
            .insert('rect', ':first-child')
            .attr('x', bbox.x - 5) // set the x position to be 5 pixels to the left of the bounding box
            .attr('y', bbox.y - 2) // set the y position to be 5 pixels above the bounding box
            .attr('width', bbox.width + 10) // set the width to be the width of the bounding box plus 10 pixels
            .attr('height', bbox.height + 4) // set the height to be the height of the bounding box plus 10 pixels
            .attr('fill', 'rgb(255, 255, 255, 0.8)'); // set the fill color to white

        d3.select(this)
            .insert('line', ':first-child')
            .attr('x1', d => projection([d.Long, d.Lat])[0] - x_scale*r)
            .attr('y1', d => projection([d.Long, d.Lat])[1] - y_scale*r)
            .attr('x2', d => projection([d.Long, d.Lat])[0] - x_scale*(12+r))
            .attr('y2', d => projection([d.Long, d.Lat])[1] - y_scale*(12+r))
            .attr('stroke', '#2A4777')
            .attr('opacity', 0.6)

    });
    


    }, [data]);
      
    return (
    <div ref={svgRef} />
    );
};
      
export default SalaryMap;