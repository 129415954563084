import ArticleFrame from "./ArticleFrame";
import React, { Component } from 'react';


export default class Networking extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
      };
    }
    componentDidMount = () => {
      window.scrollTo(0, 0)
    }

    render(){
        return <ArticleFrame ArticleDataName="Networking" >
          <p>We always talk about how important it is to grow your professional network, especially for architectural students, but where can you find them? There are so many options these days, some are being held online or in person. You could attend an awards ceremony, go to a talk, visit an exhibition, take part in webinars, join a CPD session, or just a normal networking event where you can spend time talking and meeting like-minded people like yourself. Luckily, there are plenty of events across the UK, and I will share with you where you can find them and why it&rsquo;s worth growing your professional network.</p>
          <p>But first, let&rsquo;s start with the basics:&nbsp;</p>
          <p/>
          <p/>
          <h2>What is Networking?</h2>
          <p>Professional networking is the intentional and strategic process of building and nurturing relationships within one's industry or field. It involves connecting with peers, mentors, and professionals to exchange knowledge, advice, and opportunities. Effective professional networking not only enhances career prospects but also fosters a supportive community for continuous learning and collaboration.</p>
          <p/>
          <p/>
          <h2>Why is Networking Important? </h2>
          <p>Whether you&rsquo;re just starting your career or you&rsquo;re an experienced professional. Networking is crucial to develop in your professional career. You will make connections, learn from others, broaden your horizon, make friendships and share your own knowledge.</p>
          <p/>
          <p/>
          <h2>What are the Advantages of Networking? </h2>
          <p>Networking and engaging with other professionals within and outside your field carry a lot of weight, and you should never underestimate the power of building these connections. You never know who you&rsquo;ll meet and who's in their network. Maybe the person you&rsquo;re talking to knows the director at the firm you've always dreamed of working at, and they might be able to put in a good word for you or help introduce you to their connection.&nbsp;</p>
          <p/>
          <h3>But let&rsquo;s have a look at all the advantages:</h3>
          <p><strong>Opportunities:</strong> Networking in the field of architecture opens doors to new career opportunities, unveils job openings, new client relationships, and facilitates potential collaborations within your professional circle.&nbsp;</p>
          <p><strong>Staying Up-to-Date:</strong> Architecture is an ever-evolving sector where staying up-to-date on the latest trends and regulations is crucial. Interacting with other professionals helps exchange ideas, insights, and industry knowledge, fostering continuous learning and adaptation to industry advancements.</p>
          <p><strong>Career Advice and Mentorship:</strong> Architecture networking provides access to experienced professionals who can offer valuable guidance and mentorship, aiding in navigating the unique challenges and opportunities within the architectural sector.</p>
          <p><strong>Visibility:</strong> Building a strong network in architecture enhances your visibility within the industry, increasing the likelihood of being considered for architectural opportunities and projects.</p>
          <p><strong>Skill Development:</strong> Engaging with diverse professionals exposes you to different perspectives and skills, contributing to your personal and professional growth in the architectural field. Attend various networking events across different sectors to broaden your knowledge base, help innovative thinking, and problem-solving skills.</p>
          <p><strong>Confidence Building:</strong> Going to networking events and talking to strangers can feel daunting. Still, getting outside your comfort zone will help you develop your interpersonal, communication, and potentially even presentation skills.&nbsp;</p>
          <p><strong>Access to Resources:</strong> Your architectural network serves as a valuable resource hub, providing information, the latest software tricks, and support during challenging times in your architectural journey.</p>
          <p/>
          <p/>
          <h2>Where to find Networking Events in the UK</h2>
          <p>There are so many networking events, workshops, talks, exhibitions, tours, CPD sessions available but a lot don&rsquo;t know where to look and how to find them. By not attending any events, you are losing out on potential job offers, connections, mentors and more. If you are currently at university, this should be one of your main priorities to grow your professional network while studying. I hear from so many talented Architectural Assistants, who are struggling to find a job because they have not invested the time to attend events, where you might meet your future boss. You need to grow your network before you need it.</p>
          <p/>
          <p><a href="https://www.architecture.com/" target="_blank" rel="nofollow noopener"><h3>Royal Institute of British Architects RIBA</h3></a>They have in-person and/or online events. Some are paid, some are free. From talks, workshops to tours, there will be something for everyone.</p>
          <p>Christine Cavanag MCIM, Director of Memberships Experience &amp; Support UK RIBA says:</p>
          <p><em>&rdquo;Our model of the membership experience is very different to some of the other institutes. It really is a "hive" working collaboratively to deliver an experience in each of our 11 regions.&nbsp;</em>&ldquo;</p>
          <p>They have 11 different RIBA Regions, <a href="https://www.architecture.com/my-local-riba/riba-east" target="_blank" rel="nofollow noopener">RIBA East</a>, <a href="https://www.architecture.com/my-local-riba/riba-east-midlands" target="_blank" rel="nofollow noopener">RIBA East Midlands</a>, <a href="https://www.architecture.com/my-local-riba/riba-london" target="_blank" rel="noopener">RIBA London</a>, <a href="https://www.architecture.com/my-local-riba/riba-north-east" target="_blank" rel="nofollow noopener">RIBA North East</a>, <a href="https://www.architecture.com/my-local-riba/riba-north-west" target="_blank" rel="nofollow noopener">RIBA North West</a>, <a href="https://www.architecture.com/my-local-riba/riba-south-south-east" target="_blank" rel="nofollow noopener">RIBA South / South East</a>, <a href="https://www.architecture.com/my-local-riba/riba-south-west" target="_blank" rel="nofollow noopener">RIBA South West / Wessex</a>, <a href="https://www.architecture.com/my-local-riba/riba-west-midlands" target="_blank" rel="nofollow noopener">RIBA West Midlands</a>, <a href="https://www.architecture.com/my-local-riba/riba-yorkshire" target="_blank" rel="nofollow noopener">RIBA Yorkshire</a>.</p>
          <p>To view all of their latest events and programmes, please <a href="https://www.architecture.com/whats-on" target="_blank" rel="nofollow noopener">visit RIBA - What&rsquo;s On events page.</a></p>
          <p/>
          <p><a href="https://architecturaltechnology.com/" target="_blank" rel="noopener"><h3>Chartered Institute of Architectural Technologists (CIAT)</h3></a>The Chartered Institute of Architectural Technologists (CIAT) is the qualifying body for architectural technology, primarily in the United Kingdom but also internationally. The institute has members in overseas centres such as Hong Kong and the Republic of Ireland.</p>
          <p>They offer a wide range of workshops, webinars, awards, exhibitions, seminars, online, in-person events and more all across the UK.</p>
          <p>To view their busy calendar, please head over to <a href="https://architecturaltechnology.com/events.html" target="_blank" rel="nofollow noopener">their events page</a>.</p>
          <p/>
          <p><a href="https://www.rias.org.uk/about" target="_blank" rel="nofollow noopener"><h3>Royal Incorporation of Architects in Scotland (RIAS)</h3></a>The Royal Incorporation of Architects in Scotland (RIAS) was founded in 1916 as the professional body for all chartered architects in Scotland and is the foremost Institute in the country dealing with architecture and the built environment.</p>
          <p>The RIAS has charitable status and offers a wide range of services and products for architects, students of architecture, construction industry professionals and all those with an interest in the built environment and the design process.</p>
          <p>To view any upcoming events in Scotland, please <a href="https://www.rias.org.uk/about/events" target="_blank" rel="nofollow noopener">visit their events page</a>.&nbsp;</p>
          <p/>
          <p><a href="https://manchestersocietyofarchitects.com/" target="_blank" rel="nofollow noopener"><h3>Manchester Society of Architects</h3></a>Manchester Society of Architects is a community of architects and students who champion and nurture the present and future architects of our city. Ideal for anyone based in the North West area.</p>
          <p>To view any upcoming events, please <a href="https://manchestersocietyofarchitects.com/events/?eventstag=16" target="_blank" rel="noopener">head over to their events page</a>.&nbsp;</p>
          <p/>
          <p><a href="https://brumarchitecture.com/" target="_blank" rel="nofollow noopener"><h3>Birmingham Architectural Association (BAA)</h3></a>Founded in 1874, represents architects, built environment professionals and creatives in the local area. The BAA is the local branch of RIBA, ran by a hardworking volunteer committee, composed of people from practices and universities across the city.</p>
          <p>Our aim is to promote, support and share knowledge through and for our community. Committee members champion their interests and issues through workshops, events, publications and their contribution to local and national design boards.</p>
          <p>We hold events in unique venues, with fantastic local, independent caterers and have collaborated with some of the most influential local and international architects, designers and industry leaders.</p>
          <p>To find all upcoming events, please <a href="https://www.eventbrite.co.uk/o/birmingham-architectural-association-29471153023" target="_blank" rel="nofollow noopener">visit their Eventbrite profile</a>.</p>
          <p/>
          <h3><a href="https://www.landscapeinstitute.org/" target="_blank" rel="nofollow noopener">Landscape Institute</a></h3>
          <p>They have a variety of events to support your CPD training including webinars and masterclasses. In addition, they also offer walking tours, branch meetings, ceremonies, conferences, and other events that support the landscape profession.The events within the LI programme are open to LI members and non-members.</p>
          <p>To find out about their events, <a href="https://www.landscapeinstitute.org/events/events/" target="_blank" rel="nofollow noopener">visit the link here</a>.</p>
          <p/>
          <h3><a href="https://www.udg.org.uk/" target="_blank" rel="nofollow noopener">Urban Design Group</a></h3>
          <p>Founded in 1978, the Urban Design Group (UDG) is a campaigning membership organisation with over 1000 members who care about the quality of life in our cities, towns and villages, and believe that raising standards of urban design is central to its improvement.</p>
          <p>Most of our online events are free and are open to the general public as well as members. Registration is required and you can do this via the event listings below or our <a href="https://www.eventbrite.co.uk/o/urban-design-group-17271354065" target="_blank" rel="nofollow noopener">Eventbrite</a> page.</p>
          <p/>
          <h3><a href="https://www.wia-uk.org/" target="_blank" rel="nofollow noopener">Women in Architecture UK</a></h3>
          <p>Women in Architecture UK (WiA) is a volunteer-led organisation that offers a platform for debate, idea exchange and support for women in architecture, practice, education and the wider built environment.</p>
          <p>WiA strives to promote, advance and celebrate women&rsquo;s ongoing contribution to architecture. We aim to raise the profile, represent the views and promote the progression of women in architecture and the built environment professions.</p>
          <p>To view all of their events, please visit their<a href="https://www.wia-uk.org/events-1" target="_blank" rel="nofollow noopener"> events page</a>.</p>
          <p/>
          <h3><a href="https://www.womeninproperty.org.uk/" target="_blank" rel="nofollow noopener">Women in Property</a></h3>
          <p>Women in Property creates opportunities, expands knowledge and inspires change for women working in the property and construction industry.</p>
          <p>Our network links members to fellow professionals across sectors and across the UK.&nbsp; We are multi-disciplinary, encouraging an exchange of ideas and sharing of expertise, often through our extensive schedule of best practice workshops, site visits, key note talks and social events&nbsp;</p>
          <p>They are offering a wide range of events for both members and non members. To view their busy calendar, please head over to <a target="_blank" href="https://www.womeninproperty.org.uk/events/">their events page</a>.</p>
          <p/>
          <h3><a target="_blank" href="https://www.aabc-register.co.uk/">AABC (Architects Accredited in Building Conservation)</a></h3>
          <p>AABC is an independent accreditation body established in 1999 for and by skilled conservation architects. The primary purpose of the AABC Register is to protect the historic built environment from damaging interventions devised by people not skilled in historic building conservation and adaptation. </p>
          <p>After having looked through their website, it seems they don't host regular events (the register has around 400 members). However, it's worth keeping an eye on <a target="_blank" href="https://www.aabc-register.co.uk/noticeboard/">their website</a>, you never know, maybe something might pop up.</p>
          <p/>
          <h3><a target="_blank" href="https://aecb.net/">AECB (Association for Environment Conscious Building)</a></h3>
          <p>The AECB is a membership network with the aim of promoting green, low-energy, sustainable building. This is great for anyone who would like to network and upskill.</p>
          <p>They bring together contractors, trades people, self builders, architects, designers, engineers, manufacturers, housing associations, local authorities and academics to help develop, share, train and promote sustainable building best practice. AECB promotes excellence in design and construction.</p>
          <p>They are offering online webinars, and in-person meetups, events for both members and non-members. To view their busy calendar, please head over to <a target="_blank" href="https://aecb.net/events/">their events page</a>.</p>
          <p/>
          <h3><a target="_blank" href="https://passivehouse.com/">Passive House Institute</a></h3>
          <p>The Passive House Institute (PHI) is an independent research institute that has played a crucial role in developing the Passive House concept - the only internationally recognised, performance-based energy standard in construction.</p>
          <p>Their events calendar is filled with webinars, courses, conferences and networking opportunities across the globe. It might take you a moment to find suitable events in your area.</p>
          <p>To view their busy calendar, please head over to <a target="_blank" href="https://www.passivehouse-international.org/index.php?page_id=75">their events page</a>.</p>
          <p/>
          <p><a href="https://www.ciob.org/" target="_blank" rel="nofollow noopener"><h3>Chartered Institute of Building (CIOB)</h3></a>The Chartered Institute of Building (CIOB) is a global organization which exists to promote and advance for the public benefit the science and practice of building and construction.</p>
          <p>We have a Royal Charter to promote the science and practice of building and construction for the benefit of society, and we&rsquo;ve been doing that since 1834. Our members work worldwide in the development, conservation and improvement of the built environment.Every year the CIOB organises hundreds of CPD and networking events, award ceremonies and competitions across the globe.</p>
          <p>To view them all, please visit their <a href="https://www.ciob.org/events" target="_blank" rel="nofollow noopener">events page</a>.</p>
          <p/>
          <p><a href="https://www.ciobacademy.org/" target="_blank" rel="nofollow noopener"><h3>CIOB Academy</h3></a>The CIOB Academy&rsquo;s professional development resources have helped over 35,000 professionals in the industry gain qualifications, improve their practical skills, and work towards Chartered Membership. Whether you&rsquo;re new to construction or a seasoned pro, we have the resources to support your career growth.</p>
          <p>To view all of their events, please visit their <a href="https://www.ciob.org/events" target="_blank" rel="nofollow noopener">events page</a>.</p>
          <p/>
          <h3><a href="https://www.ndsa.org.uk/" target="_blank" rel="nofollow noopener">Nottingham and Derby Society of Architects</a></h3>
          <p>The Nottingham and Derby Society of Architects champions architecture, ideas and initiatives which can improve the places in which we live, and the ways in which we live, for the good of the people of Nottinghamshire, Derbyshire and the architectural profession.</p>
          <p>Founded in 1862 we are an inclusive society of architects, related built environment professionals and interested members of the public.</p>
          <p>We provide a critical forum for debate between different, related professions and the public about the state of our cities, towns and villages, and the changing roles and challenges facing architectural practitioners.</p>
          <p>To view all of their events, please visit their <a href="https://www.ndsa.org.uk/events" target="_blank" rel="nofollow noopener">events page</a>.</p>
          <p/>
          <h3><a href="https://www.rics.org/" target="_blank" rel="nofollow noopener">Royal Institution of Chartered Surveyors (RICS)</a></h3>
          <p>As a globally recognised professional body, everything we do is designed to effect positive change in the built and natural environments.</p>
          <p>The Royal Institution of Chartered Surveyors promotes and enforces the highest professional qualifications and standards in the development and management of land, real estate, construction and infrastructure.</p>
          <p>To view various types of events, ranging from seminars, networking, webinars across the UK,<a href="https://www.rics.org/training-events" target="_blank" rel="nofollow noopener"> follow the link here</a>.</p>
          <p/>
          <p><a href="https://www.royalacademy.org.uk/" target="_blank" rel="nofollow noopener"><h3>Royal Academy of Arts</h3></a></p>
          <div>Address: Burlington House, Piccadilly, London W1J 0BD</div>
          <p>An independent institution, The Royal Academy of Arts exists to promote art and artists &ndash; a mission we pursue through exhibitions, education and debate.Enjoy some of the finest temporary and touring art exhibitions at the Royal Academy of Arts, from ancient sculpture to contemporary masterpieces. The Academy&rsquo;s permanent collection spans 250 years of British Art with works from the likes of John Constable, J.M.W. Turner and David Hockney. It is also home to the annual Summer Exhibition.</p>
          <p>To view what exhibitions and events are on, <a href="https://www.royalacademy.org.uk/exhibitions-and-events?page=1" target="_blank" rel="nofollow noopener">please click here</a>.&nbsp;</p>
          <p/>
          <p><a href="https://nla.london/" target="_blank" rel="nofollow noopener"><h3>New London Architecture (NLA) </h3></a>Address: The London Centre, Guildhall, Aldermanbury, London EC2V 7HH</p>
          <p>An independent forum for discussion, debate, and information about architecture, planning, and development in London.</p>
          <p>NLA delivers over 150 events per year, including a mixture of round table discussions, conferences, debates, talks, walking tours, and receptions. The organisation also publishes research, hosts rotating exhibitions, holds awards&nbsp; and organises international ideas competitions, such as "New Ideas for Housing" (2015). NLA's main publication is New London Quarterly. New London Quarterly is circulated to 5,000 leading people with an interest in London's built environment. Its galleries are open, free of charge, six days a week and receive over 10,000 visitors each month.</p>
          <p>To view all of their upcoming exhibitions, networking events, walking tours and award ceremonies, <a href="https://nla.london/events" target="_blank" rel="nofollow noopener">please click here</a>.</p>
          <p/>
          <p><a href="http://www.rocalondongallery.com/" target="_blank" rel="nofollow noopener"><h3>Roca London Gallery</h3></a>Address: Station Court, Townmead Rd, London SW6 2PY</p>
          <p>Designed by the award-winning Zaha Hadid Architects, the Roca London Gallery is an innovative space where product display environments live harmoniously alongside a series of cultural exhibitions, events and meetings on a variety of themes such as architecture, design and sustainability.</p>
          <p>To view what exhibitions and events are on, <a href="http://www.rocalondongallery.com/activities-exhibitions" target="_blank" rel="nofollow noopener">please click here</a>.&nbsp;</p>
          <p/>
          <p><a href="https://www.eventbrite.co.uk/" target="_blank" rel="nofollow noopener"><h3>Eventbrite</h3></a>Eventbrite is an American event management and ticketing website. The service allows users to browse, create, and promote local events. Using the website is very straightforward. You can either use the map or search for specific topics and cities. The beauty of Eventbrite is that you never know what you might find - from photography exhibitions to awards ceremonies, talks, seminars, tours, and more.</p>
          <p>To view various architectural events across the UK, <a href="https://www.eventbrite.co.uk/d/united-kingdom/architectural/?page=1" target="_blank" rel="nofollow noopener">follow the link here</a>.&nbsp;</p>
          <p/>
          <p><a href="https://www.meetup.com/" target="_blank" rel="nofollow noopener"><h3>Meetup</h3></a>Meetup is a social media platform for hosting and organising in-person and virtual activities, gatherings, and events for people and communities of similar interests, hobbies, and professions.</p>
          <p>Personally, I think Meetup is a great website and they have so many different events on. Probably fewer architectural events and more Art and Culture like life drawing events, take a look through their page, you might someone you like the look of.</p>
          <p>Do have a <a href="https://www.meetup.com/find/?source=EVENTS&amp;categoryId=521&amp;keywords=Art%20and%20Culture&amp;location=gb--17--London" target="_blank" rel="nofollow noopener">look through their page</a>.</p>
          <p/>
          <p><strong>Note:</strong> If I have missed a website, please feel free to contact me either via my <a href="https://www.linkedin.com/in/aylin-round/" target="_blank" rel="noopener">LinkedIn profile</a> or email me on <a href="mailto:aylin@archjobs.co.uk" target="_blank" rel="noopener">aylin@archjobs.co.uk</a>. I am more than happy to update the list above and ensure it&rsquo;s as accurate as possible. Thank you.&nbsp;</p>
          <p/>
          <p/>
          <h2>Using LinkedIn to Connect and Maintain your Network</h2>
          <p>Don&rsquo;t forget, attending these types of events is perfect for connecting with professionals either during or afterwards on LinkedIn. Many professionals have a LinkedIn profile and use the platform to exchange, share, and maintain their network. So, don&rsquo;t miss out - invest the time in creating your own LinkedIn profile. It will be incredibly useful for your career.</p>
          <p><strong>💡 Tip:</strong> When sending a connection request on LinkedIn, take a moment and write a short message. You could write something like this:</p>
          <p>Hi [Name],</p>
          <p>Great meeting you at [event] yesterday. Enjoyed our conversation about [topic].</p>
          <p>Let's stay connected here on LinkedIn.</p>
          <p>All the best,</p>
          <p>[Your Name]</p>
          <p/>
          <p/>
          <h3>Conclusion</h3>
          <p>In architecture, making connections isn't just a thing to do but it will help you to have a more successful career and help your personal development. Whether you're just starting or you&rsquo;re a seasoned professional, networking is what moves your career forward.</p>
          <p>Networking is a powerful tool, and many underestimate the impact it can have on their professional journey. It offers insights into what's happening in your field, mentorship from experienced professionals, and a supportive network of friends. Additionally, it helps develop your interpersonal and communication skills.</p>
          <p>So, regardless of where you stand in your career, consider networking a necessity, not an option. Every conversation you have will help you gain more knowledge and valuable insights. Build connections, nurture your network, and leverage it to your advantage. Always remember to build your network before you need it. It takes time, but it will be worth it in the long run.</p>
            </ArticleFrame>
    }
}