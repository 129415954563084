import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import TextField from '@mui/material/TextField';
import axios from 'axios';

const friday = 5; // Friday is the 6th day of the week, with Sunday as the first day (0-indexed)
const hour = 11;
const minute = 0;

const now = new Date();
const daysSinceFriday = (now.getDay() - friday + 7) % 7;
const lastFriday = new Date(now.getTime() - daysSinceFriday * 24 * 60 * 60 * 1000);
lastFriday.setHours(hour, minute, 0, 0);



const CreateNewsletter = (props) => {
  // Default date is last Friday at 11am
  const [selectedDate, setSelectedDate] = useState(lastFriday);

  const [response, setResponse] = useState('');

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleSubmit = () => {
    axios.get('https://api.archjobs.co.uk/create_newsletter2?date='+(selectedDate.getTime() / 1000).toFixed(0), {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        }
      })
      .then((res) => setResponse('Success'))
      .catch((err) => setResponse(`Error: ${err.message}`));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} >
        <div className="CreateNewsletterPage"  hidden={props.hidden} style={props.hidden? {display: "none"}: {}}>
          <DateTimePicker
            renderInput={(props) => <TextField {...props} />}
            label="Choose date and time"
            value={selectedDate}
            onChange={handleDateChange}
          />
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
          <Typography variant="subtitle1">{response}</Typography>
          <div className="Messagelink" style={{height: "40px"}}>{response == "Success" && <a className="Link" href="https://app.mailjet.com/templates/marketing" target="_blank">View Here</a>}</div>

        </div>
    </LocalizationProvider>
  );
};

export default CreateNewsletter;