
import React from 'react'
import '../css/salary.css'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import axios from 'axios'
import ExperienceChart from '../graphs/ExperienceChart'
import SalaryMap from '../graphs/SalaryMap';
import { BsArrowReturnRight, BsFillShareFill } from 'react-icons/bs'
import SalaryRoleSelect from './SalaryRoleSelect.js'
import Button from './Buttons';
import ShareComponent from './ShareComponent'
import {Helmet} from "react-helmet";
import { SimilarJobSquare } from './SearchPage'
import { NewsLetterPopUp } from './NewsLetter';

const url = "https://api.archjobs.co.uk/salary_guide"
const search_url = "https://api.archjobs.co.uk/search"
//https://api.archjobs.co.uk/salary_guide_options
export const roles = [{"aj_title":"Architect","mean_salary":33466.5},{"aj_title":"Architectural Assistant","mean_salary":23681.6},{"aj_title":"Architectural Designer","mean_salary":29893.4},{"aj_title":"Architectural Technician","mean_salary":24233.8},{"aj_title":"Architectural Technologist","mean_salary":28007.3},{"aj_title":"Architectural Visualiser","mean_salary":29747.1},{"aj_title":"Associate Architect","mean_salary":48603.8},{"aj_title":"Associate Director","mean_salary":53603.8},{"aj_title":"Bid and Marketing Coordinator","mean_salary":25179.2},{"aj_title":"Bid and Marketing Manager","mean_salary":39895.7},{"aj_title":"Bid Coordinator","mean_salary":23179.2},{"aj_title":"Bid Manager","mean_salary":39895.7},{"aj_title":"Bid Writer/Assistant","mean_salary":22179.2},{"aj_title":"BIM Coordinator/Consultant","mean_salary":34689.7},{"aj_title":"BIM Manager","mean_salary":43148.4},{"aj_title":"BIM Specialist","mean_salary":35689.7},{"aj_title":"Conservation Architect","mean_salary":35519.0},{"aj_title":"Design Architect","mean_salary":35519.0},{"aj_title":"Director","mean_salary":77766.1},{"aj_title":"Graduate BIM","mean_salary":24689.7},{"aj_title":"Landscape Architect","mean_salary":27492.8},{"aj_title":"Part 1 Architectural Assistant","mean_salary":22181.6},{"aj_title":"Part 2 Architectural Assistant","mean_salary":25181.6},{"aj_title":"Part 3","mean_salary":31893.4},{"aj_title":"Passivhaus Designer","mean_salary":30254.7},{"aj_title":"Project Architect","mean_salary":36985.8},{"aj_title":"Senior Architect","mean_salary":42246.4},{"aj_title":"Senior Architectural Technician","mean_salary":35009.0},{"aj_title":"Senior Architectural Technologist","mean_salary":39509.0},{"aj_title":"Senior Landscape Architect","mean_salary":42246.4},{"aj_title":"Sustainability","mean_salary":30800.9},{"aj_title":"Technical Architect","mean_salary":36985.8},{"aj_title":"Urban Designer","mean_salary":29425.7}]


function parse_url (url_to_parse) {
  const url_params = {}
  const split_url = url_to_parse.split("/")
  if (split_url.length >= 2){
    const url_end = split_url[split_url.length-1]
    let url_role = url_end.replace("-salary", "")
    const role_lookup = {}
    roles.forEach(role => {
      role_lookup[role.aj_title.replace(/ /g, '-').toLowerCase()] = role
    })
    if (role_lookup[url_role]){
      url_params.role = role_lookup[url_role].aj_title
    }
  }
  return url_params
}

class SalaryGuide extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          data: {},
          selected_role: "Architect", 
          location: "",
          shareOpen: false,
          job_suggestions: {}
        }
      }

      componentDidMount = () => {
        window.scrollTo(0, 0)
        console.log(window.location.pathname)
        if (window.location.pathname.endsWith("-salary")){
          let url_params = parse_url(window.location.pathname) 
          if (url_params.role){
            this.getData(url_params.role)
            this.setState({selected_role: url_params.role})
          }
        } else {
          this.getData(this.state.selected_role)
        }
        
      }

      getData = (role) => {
        axios.get(`${url}?title=${role}`).then(response => {
          for (const key in response.data.results[0]){
            if (typeof response.data.results[0][key] == "string" && (response.data.results[0][key].startsWith("[") || response.data.results[0][key].startsWith("{"))){
              console.log(key)
              response.data.results[0][key] = JSON.parse(response.data.results[0][key])
            }
          }
          this.setState({data: response.data.results[0], count:  response.data.count})
        })
        axios.get(`${search_url}?tag=${role}&limit=6`).then(response => {
          this.setState({job_suggestions: response.data})
        })
      } 

      updateRole = (new_role) => {
        if (new_role !== this.state.selected_role){
          this.setState({selected_role: new_role, location: ""})
          window.history.pushState(null, `${new_role} Salary Guide`, "/salary-guide/"+new_role.replace(/ /g, '-').toLowerCase()+"-salary")
          this.getData(new_role)
        }
      }

      setShareOpen = (open) => {
        console.log("SetShare Open")
        this.setState({shareOpen: open})
      }

    render() {
      console.log(this.state)
      let salaryGuideContent = <div className="SalaryGuideLoading">Loading...</div>
      if (this.state.data.salary){
        let sorted_locations = [...this.state.data.locations].filter(loc => loc.aj_city && (loc.avg > parseFloat(this.state.data.salary.min.replace("£", "").replace(",", ""))-1000))
        sorted_locations.sort((a, b) => b.avg - a.avg)

        let salaryProgression = []
        salaryProgression.push(<div className="salaryProgression salaryProgressionRow1">
          <div className="SalaryClickableRole">{this.state.data.aj_title}</div>
          <div className="SalaryTopLocationSalary">{this.state.data.mean_salary.toLocaleString('en-GB',   {style: 'currency', currency: 'GBP', maximumFractionDigits: 0})}</div>
        </div>)
        for (const role in this.state.data.progression){
          if (this.state.data.progression[role].salary){
            salaryProgression.push(<div className="salaryProgression salaryProgressionRow2">
            <div className="SalaryClickableRole" onClick={() => {this.updateRole(role)}}><BsArrowReturnRight className="ProgressionArrow"/> {role}</div>
            <div className="SalaryTopLocationSalary">{this.state.data.progression[role].salary.toLocaleString('en-GB',   {style: 'currency', currency: 'GBP', maximumFractionDigits: 0})}</div>
          </div>)
          }

          for (const role2 in this.state.data.progression[role].progression){
            if (this.state.data.progression[role].progression[role2].salary){
              salaryProgression.push(<div className="salaryProgression salaryProgressionRow3">
              <div className="SalaryClickableRole" onClick={() => {this.updateRole(role2)}}><BsArrowReturnRight className="ProgressionArrow"/> {role2}</div>
              <div className="SalaryTopLocationSalary">{this.state.data.progression[role].progression[role2].salary.toLocaleString('en-GB',   {style: 'currency', currency: 'GBP', maximumFractionDigits: 0})}</div>
            </div>)
            }

          }
        }

        var pattern = /^([aeiou])/i;

        salaryGuideContent = <div className="SalaryGuideContentContainer">
              <div className="SalaryGuideMain">
                <div className="SalaryContainer">
                  <div className="SalaryMain">
                    <div className="SalaryMainNumber">{this.state.data.salary.avg.toLocaleString('en-GB',   {style: 'currency', currency: 'GBP', maximumFractionDigits: 0})}</div>
                    <div className="SalaryMainLabel">Average Salary <span className="SalarySubLabel">per annum</span></div>
                    <div className="SalaryMainSampleSize">Based on {this.state.data.salary.count} salaries</div>
                  </div>
                  <div className="SalaryRanges">
                    <div className="SalaryRange"><span className="SalaryRangeLabel">Low: </span>{this.state.data.salary.low.toLocaleString('en-GB',   {style: 'currency', currency: 'GBP', maximumFractionDigits: 0})}</div>
                    <div className="SalaryRange"><span className="SalaryRangeLabel">High: </span>{this.state.data.salary.high.toLocaleString('en-GB',   {style: 'currency', currency: 'GBP', maximumFractionDigits: 0})}</div>
                  </div>
                </div>
                <div className="SalaryJobDescriptionContainer">
                  <h2 className="SalaryGuideContentTitle">
                    How much do {this.state.selected_role}s make?
                  </h2>
                  <p>
                    {this.state.selected_role}s typically earn between {this.state.data.salary.low.toLocaleString('en-GB',   {style: 'currency', currency: 'GBP', maximumFractionDigits: 0})} and {this.state.data.salary.high.toLocaleString('en-GB',   {style: 'currency', currency: 'GBP', maximumFractionDigits: 0})} per year, with an average salary of {this.state.data.salary.avg.toLocaleString('en-GB',   {style: 'currency', currency: 'GBP', maximumFractionDigits: 0})} per year. This varies based on experience and location, with London salaries normally being slightly higher.
                  </p>
                  <h2 className="SalaryGuideContentTitle">
                    What is {pattern.test(this.state.selected_role) ? "an" : "a"} {this.state.selected_role}'s Job Description?
                  </h2>
                  <p>
                  {this.state.data.job_description}
                  </p>
                </div>
                <div className="ExperienceChartContainer">
                  <div className="SalaryGuideContentTitle SalaryChartTitle">
                    Pay by Experience Level
                  </div>
                  <div id="experience_chart">
                    <ExperienceChart data={this.state.data.experience} />
                  </div>
                </div>
                <div className="SalaryNote">Note: Different skills & sector experience can affect your salary. </div> 
                {salaryProgression.length > 1 && 
                <div className="SalaryProgressionContainer">
                  <div className="ExperienceChartContainer CareerProgression">
                    <div className="SalaryGuideContentTitle SalaryChartTitle">
                      Career Progression
                    </div>
                    <div className="SalaryProgressionList">
                      {salaryProgression}
                    </div>
                  </div>
                </div>}
              </div>
              
              <div className="SalaryGuideLocations">
                <div className="SalaryGuideMap">
                  <div className="SalaryGuideMapTitle">
                    UK Salary Map
                  </div>
                  <div className="SalaryGuideMapContainer">
                    <div id="salary_map">
                      <SalaryMap data={sorted_locations}/>
                    </div>
                  </div>
                </div>
                <div className="ExperienceChartContainer TopCities">
                  <div className="SalaryGuideContentTitle SalaryChartTitle">
                    Top Cities
                  </div>
                  <div className="SalaryTopLocationList">
                    {sorted_locations.slice(0,10).map(loc => {
                      return <div className="SalaryTopLocation">
                          <div className="SalaryTopLocationCity">{loc.aj_city}</div>
                          <div className="SalaryTopLocationSalary">{loc.avg.toLocaleString('en-GB',   {style: 'currency', currency: 'GBP', maximumFractionDigits: 0})}</div>
                        </div>
                    })}
                    {(sorted_locations.length < 5) && <div className="SalaryTopLocationWarning">Not enough sample size for more locations</div>}
                  </div>
                </div>
              </div>
            </div>
      }

      let SimilarJobs
      if (this.state.job_suggestions.results && this.state.job_suggestions.results.length > 0){
        let SimilarJobsList = []
        let JobSearches = []
        let LocationSearches = []

        let text = `See All ${this.state.job_suggestions.count} ${this.state.selected_role} Roles on ArchJobs`
        let url = `/search/${this.state.selected_role}-Jobs-in-United-Kingdom`
        JobSearches.push(<Link to={url} className="SimilarJobLink" style={{textAlign: "left"}}>{text}</Link>)

        this.state.job_suggestions.results.forEach(job => {
          SimilarJobsList.push(<SimilarJobSquare data={job} key={job.job_id}/>)
        })
        
    
        SimilarJobs = <div className="SalarySimilarJobsSection">
          <div className="SalaryGuideContentTitle">Available {this.state.selected_role} Roles:</div>
          <div className="SimilarJobsSectionList">{SimilarJobsList}</div>
          <div className="SimilarSearches">
            <div className="SimilarSearchList">{JobSearches}</div>
            <div className="SimilarSearchList">{LocationSearches}</div>
          </div>
        </div>
      }

      let desc = `Discover the latest architect salary trends in the UK with our comprehensive salary guide at ArchJobs.`
      if (this.state.data.salary){
        desc = `${this.state.selected_role}s typically earn between ${this.state.data.salary.low.toLocaleString('en-GB',   {style: 'currency', currency: 'GBP', maximumFractionDigits: 0})} and ${this.state.data.salary.high.toLocaleString('en-GB',   {style: 'currency', currency: 'GBP', maximumFractionDigits: 0})} per year, with an average salary of ${this.state.data.salary.avg.toLocaleString('en-GB',   {style: 'currency', currency: 'GBP', maximumFractionDigits: 0})} per year. This varies based on experience and location, with London salaries normally being slightly higher.`
      }


      return (
        <div className="SalaryGuidePage">
          <Helmet>
            <meta charSet="utf-8" />
                <title>{this.state.selected_role} Salary Guide | ArchJobs  </title>
                <link rel="canonical" href={"https://www.archjobs.co.uk"+window.location.pathname} />
                <meta name="description" content={desc }/>
                <meta name="og:description" content={desc}/>
                <meta name="og:title" content={`ArchJobs | ${this.state.selected_role} Salary Guide`}/>
                <meta name="og:type" content={"website"}/>
                <meta name="og:url" href={"https://www.archjobs.co.uk"+window.location.pathname}/>
            </Helmet>
          <div className="SalaryGuideTitle">
            UK Architecture Salary Guide
          </div>
          <div className="SalaryGuideRoleSelection">
            <div className="SalaryGuideRoleSelectionLabel">
              Select an Architectural Position: 
            </div>
            <div className="SalaryGuideRoleSelectionDropdown">
              <SalaryRoleSelect updateRole={this.updateRole} role={this.state.selected_role} roles={roles}/>
            </div>
          </div>
          <div className="SalaryGuideContainer">
            <div className="SalaryGuideHeaderContainer">
              <div className="SalaryGuideShare">
                
              </div>
              <div className="SalaryGuideHeader">
                <h1 className="SalaryGuideRoleTitle">
                  {this.state.selected_role} Salary Guide
                </h1>
                <div className="SalaryGuideLocation">
                  United Kingdom
                </div>
              </div>
              <div className="SalaryGuideShare">
                <Button btnClassName="SmallButton" onClickFunc={() => this.setShareOpen(true)}>Share <BsFillShareFill className="SmallButtonIcon"/></Button>
                <ShareComponent url={"https://www.archjobs.co.uk"+window.location.pathname} open={this.state.shareOpen} setOpen = {this.setShareOpen.bind(this)} id={"SalaryGuideShare"} shortJobDesc={`${this.state.selected_role} Salary Guide`}/>
              </div>
            </div>
            {salaryGuideContent}
            {SimilarJobs}
          </div>
          <NewsLetterPopUp/>
        </div>
      )
    }
  }

  export default SalaryGuide