
import React from 'react'
import classNames from 'classnames';
import '../css/workwithus.css'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
  import Button from './Buttons'
  import {FAQRecruiter} from './FAQRecruiter'
  import { FaQuoteLeft } from 'react-icons/fa';
  import { Helmet } from 'react-helmet';
import { BsFillArrowUpLeftSquareFill } from 'react-icons/bs';
import { checkoutLinks } from './RecruiterPayment';
import ChatWidget from './ChatWidget';


const CircleCheck = <svg className="CircleCheck" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"></path><path d="M9.999 13.587 7.7 11.292l-1.412 1.416 3.713 3.705 6.706-6.706-1.414-1.414z"></path></svg>

const Check = <svg className="Check" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z"></path></svg>
const Cross = <svg className="Cross" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path></svg>


export class Toggle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }



  render() {
    const { isAgency } = this.props;
    let highlighterClass = "highlighter"
    if (isAgency) {
      highlighterClass = "highlighter highlighterYearly"
    } else {
      highlighterClass = "highlighter highlighterMonthly"
    }

    return (
      <div class="switch-wrapper" onClick={this.handleToggle}>
        <input id="monthly" type="radio" name="switch" checked = {!isAgency} onClick={this.props.handleToggle}/>
        <input id="yearly" type="radio" name="switch" checked = {isAgency} onClick={this.props.handleToggle}/>
        <label for="monthly" className="toggleLabelMonthly">Recruiter</label>
        <label for="yearly" className="toggleLabelYearly">Agency
          {/* <div className="Discount">5+ Profiles</div> */}
        </label> 
        <span class={highlighterClass}></span>
      </div>
    );
  }
}

class WhyWorkWithUsRecruiters extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          isAgency: false,
        }
      }

      componentDidMount = () => {
        window.scrollTo(0, 0)
      }

      handleToggle = () => {
        this.setState(prevState => ({
          isAgency: !prevState.isAgency,
        }));
      }

    render() {

      console.log(checkoutLinks)
      const checkoutSelectedRange = this.state.isAgency ? checkoutLinks.agency : checkoutLinks.recruiter

      return (
        <div className="WhyWorkWithUsPage">
            <Helmet>
          <meta charSet="utf-8" />
            <title>ArchJobs | Recruitment Experts </title>
              <link rel="canonical" href={"https://www.archjobs.co.uk/recruiters/join-us"} />
              <meta name="description" content={"Advantages of working with ArchJobs to grow your reach" }/>
              <meta name="og:title" content={"ArchJobs | Recruitment Experts"}/>
              <meta name="og:type" content={"website"}/>
              <meta name="og:image" content={"https://cdn.archjobs.co.uk/file/ArchJobs/backgrounds/archjobs_banner.png"}/>
              <meta name="og:url" content={"https://www.archjobs.co.uk/recruiters/join-us"}/>
          </Helmet>
          <div className="WhyWorkWithUsBanner">
            <div className="WhyWorkWithUsBannerTextContainer">
              <div className="WhyWorkWithUsSection">
                <div className="WhyWorkWithUsBannerTextContainer">
                  <h1 className="WhyWorkWithUsSectionTitle WhyWorkWithUsSectionTitleBold">Join our Trusted Architectural Recruitment Experts</h1>
                  <div className="WhyWorkWithUsSectionSubtitle">Ever dreamt of being the unmistakable choice for architectural talent? Ready to move your agency's visibility to new heights? Seeking a partner to amplify your brand and set you apart in the competitive recruitment landscape?</div>
                  <div className="WhyWorkWithUsSectionSubtitle">Look no further. As a Trusted Architectural Recruiter, you'll enjoy a spotlight that goes beyond the ordinary. We're here to simplify your journey, enhance your brand, and make your reputation soar. Let's create a lasting impression together and redefine the way architectural recruitment is done.</div>
                  {/* <div className="WhyWorkWithUsSectionSubtitle">Do you want to be the go to person or recruitment agency when an architectural professional is looking for a new job in the UK? Do you need to cut through the noise in your area? </div>
                  <div className="WhyWorkWithUsSectionSubtitle">If so, joining our Trusted Architectural Recruiters might be the answer. Depending on the size of your agency and your goals, with the assistance of ArchJobs and Aylin's LinkedIn networks, we can help enhance your visibility and save time on your marketing efforts.</div> */}
                </div>

            </div>
            
            <div className="WhyWorkWithUsBannerBG"> </div>
            </div>
          </div>
          <div className="WhyWorkWithUsSection">
          <div className="WhyWorkWithUsSectionTitle">Growth Subscriptions</div>
            <div className="WhyWorkWithUsSectionSubtitle">Grow your Brand and Gain Exposure to a Diverse Set of Candidates and Clients</div>
            <div className="WhyWorkWithUsPricingToggleContainer">
              <Toggle isAgency={this.state.isAgency} handleToggle={this.handleToggle.bind(this)} />
            </div>
            <div className="PricingPackages">
              <div to="/post?package=basic" className="PostJobPackage" id="basic" >
                <div className="PackageTitle">Find a Recruiter</div>
                <div className="PackagePrice">£{checkoutSelectedRange.basic.price}</div>
                <div className="PackagePerAd">per month*</div>
                <div className="PackagePerAdSubtext"></div>
                <div className="PackageDescription">
                  <div className="PackageDescriptionTitle">Includes</div>
                  <div className="PackageDescriptionText">{Check} Join our list of Trusted Recruiters</div>
                  {/* <div className="PackageDescriptionText">{Check} Single recruiter profile, incl. photo, personal profile, company banner</div> */}
                  {this.state.isAgency && <div className="PackageDescriptionText">{Check} Single agency profile, incl. photo, company description & company banner</div>}
                  {!this.state.isAgency && <div className="PackageDescriptionText">{Check} Single recruiter profile, incl. photo, personal profile, company banner</div>}
                  <div className="PackageDescriptionText">{Check} Backlink to your company website</div>
                  <div className="PackageDescriptionText">{Check} Receive candidate and client inquiries straight to your inbox</div>
                  <div className="PackageDescriptionText">{Check} Mention on our Newsletter</div>
                </div>
                <div className="StartNowButtonContainer">
                  <Link to={`/recruiters/check-out?package=basic&agency=${this.state.agency}`}>
                    <Button btnClassName="PostJobButton">I'm Interested</Button>
                  </Link>
                </div>
              </div>
              <div to="/post?package=basic" className="PostJobPackage" id="jobs" >
                <div className="PackageTitle">Find a Recruiter+</div>
                <div className="PackagePrice">£{checkoutSelectedRange.jobs.price}</div>
                <div className="PackagePerAd">per month*
                {/* <div className="PackagePerAdSubtext">{this.state.isAgency && "billed annually"}</div> */}
                </div>
                
                <div className="PackageDescription">
                  <div className="PackageDescriptionTitle">Includes</div>
                  <div className="PackageDescriptionText">{Check} Everything from the Find a Recruiter Package</div>
                  <div className="PackageDescriptionText">{Check} Profile appears between search results & after applications (5x more views)</div>
                  {this.state.isAgency && <div className="PackageDescriptionText">{Check} 20 Job Listings per month on our job board, ArchJobs</div>}
                  {!this.state.isAgency && <div className="PackageDescriptionText">{Check} 5 Job Listings per month on our job board, ArchJobs</div>}
                  {this.state.isAgency && <div className="PackageDescriptionText">{Check} 2x Monthly personalised LinkedIn Post (ArchJobs) advertising roles + agency profile</div>}
                  {!this.state.isAgency && <div className="PackageDescriptionText">{Check} Monthly personalised LinkedIn Post (ArchJobs) advertising roles + recruiter profile</div>}
                  <div className="PackageDescriptionText">{Check} Jobs sent to our Newsletter Subscribers</div>
                </div>
                <div className="StartNowButtonContainer">
                  <Link to={`/recruiters/check-out?package=jobs&agency=${this.state.isAgency}`}>
                    <Button btnClassName="PostJobButton">I'm Interested</Button>
                  </Link>
                </div>
              </div>
              <div to="/post?package=basic" className="PostJobPackage" id="linkedin" >
                <div className="PackageTitle">LinkedIn Growth</div>
                <div className="PackagePrice">£{checkoutSelectedRange.jobs.price}</div>
                <div className="PackagePerAd">per month*
                {/* <div className="PackagePerAdSubtext">{this.state.isAgency && "billed annually"}</div> */}
                </div>
                
                <div className="PackageDescription">
                  <div className="PackageDescriptionTitle">Includes</div>
                  <div className="PackageDescriptionText">{Check} Everything from the Find a Recruiter Package</div>
                  {this.state.isAgency && <div className="PackageDescriptionText">{Check} Monthly personalised LinkedIn post on your agency and market trends, or highlighting a specific recruiter</div>}
                  {!this.state.isAgency && <div className="PackageDescriptionText">{Check} Monthly personalised LinkedIn posts on your area of expertise, approach, and other topics</div>}
                  {this.state.isAgency && <div className="PackageDescriptionText">{Check} Interactions and comments with company posts, and individual posts from your team from @ArchJobs and @AylinRound</div>}
                  {!this.state.isAgency && <div className="PackageDescriptionText">{Check} Interactions & comments with your LinkedIn Posts - boost visibility and engagement within our network of Architectural Professionals</div>}
                  {this.state.isAgency && <div className="PackageDescriptionText">{Check} Increased exposure to passive job seeker market & grow your company brand</div>}
                  {!this.state.isAgency && <div className="PackageDescriptionText">{Check} Increased exposure to passive job seeker market</div>}
                </div>
                <div className="StartNowButtonContainer">
                  <Link to={`/recruiters/check-out?package=jobs&agency=${this.state.isAgency}`}>
                    <Button btnClassName="PostJobButton">I'm Interested</Button>
                  </Link>
                </div>
              </div>
              <div to="/post?package=basic" className="PostJobPackage" id="full" >
                <div className="PackageTitle">Max Exposure</div>
                <div className="PackagePrice">£{checkoutSelectedRange.full.price}</div>
                <div className="PackagePerAd">per month*
                {/* <div className="PackagePerAdSubtext">{this.state.isAgency && "billed annually"}</div> */}
                </div>
                <div className="PackageDescription">
                  <div className="PackageDescriptionTitle">Includes</div>
                  <div className="PackageDescriptionText">{Check} Everything from the Find a Recruiter+</div>
                  <div className="PackageDescriptionText">{Check} Everything from Linkedin Growth</div>
                  <div className="PackageDescriptionText">{Check} Featured on our Homepage with backlink to your website</div>
                  {this.state.isAgency && <div className="PackageDescriptionText">{Check} 20 Job Listings per month on our job board, ArchJobs</div>}
                  {!this.state.isAgency && <div className="PackageDescriptionText">{Check} 5 Job Listings per month on our job board, ArchJobs</div>}
                  <div className="PackageDescriptionText">{Check} Maximise exposure to both active and passive job seekers</div>
                </div>
                <div className="StartNowButtonContainer">
                  <Link to={`/recruiters/check-out?package=full&agency=${this.state.isAgency}`}>
                    <Button btnClassName="PostJobButton">I'm Interested</Button>
                  </Link>
                </div>
              </div>
            </div>
            <p/>
            <div className="WhyWorkWithUsSectionSubtitle">*Please note, every single package requires a minimum commitment of 3 months.</div>
            <p/>
            <div className="WhyWorkWithUsSection GreySection">
            <div className="WhyWorkWithUsSectionTitle">Discover the benefits of each package</div>

            <div className="WhyWorkWithUsCoachingPackage"> 
              <div className = "CoachingPackageTitle">Find a Recruiter<span className="CoachingPackageSubtitle"></span> </div>
              <div className="CoachingPackageDescription">
                <p><b>Access leads</b> from inquiries on the ArchJobs website and increase your <b>brand’s visibility</b></p>
                <ul>
                  <li><p>Elevate your status by becoming one of our trusted architectural recruiters, gaining visibility within ArchJobs’s extensive network of professionals, providing undeniable social proof.</p></li>
                  <li><p>Write your unique profile, showcasing your photo, company banner, and a personal description. Explain your working style, the roles you can support them with, and share testimonials.</p></li>
                  <li><p>Enhance your online presence with a valuable backlink to your company website, boosting your SEO and increasing your website's visibility.</p></li>
                  <li><p>Simplify your recruitment process by receiving candidate and client inquiries directly to your email inbox, streamlining communication and saving you time.</p></li>
                  <li><p>Gain exposure by being featured in our newsletter, reaching a targeted audience of architectural professionals. Your agency name will be highlighted, creating brand recognition among our engaged subscribers.</p></li>
                </ul>
              </div>
              <div className="StartNowButtonContainer">
                  <Link to={`/recruiters/check-out?package=basic&agency=${this.state.isAgency}`}>
                    <Button btnClassName="PostJobButton">Learn More</Button>
                  </Link>
                </div>
              </div>
            

            <div className="WhyWorkWithUsCoachingPackage"> 
              <div className = "CoachingPackageTitle">Find a Recruiter+<span className="CoachingPackageSubtitle"></span> </div>
              <div className="CoachingPackageDescription">
              <p>Gain <b>even more leads</b>, further increase your <b>brand’s visibility</b> and help <b>fill your active roles</b> with active job seekers</p>
                <ul>
                  <li><p>Everything from the Find a Recruiter Package.</p></li>
                  <li><p>Maximise your profile's visibility by having it appear between our job board results and after job applications. This strategic placement guarantees 5 times more views, significantly increasing your profile's exposure.</p></li>
                  <li><p>Advertise your architectural positions on ArchJobs, reaching a targeted audience of relevant candidates. Boost your brand visibility while attracting potential hires for your positions.</p></li>
                  <li><p>Increase your online presence with a monthly LinkedIn post reaching ArchJobs’s 4.1k+ followers. This post will feature your photo, company banner, and current roles, ensuring your agency stands out in the competitive architectural recruitment landscape.</p></li>
                  <li><p>Extend your reach by having each job listing showcased and sent directly to our weekly newsletter subscribers. This strategic promotion increases the likelihood of receiving relevant applications from architectural professionals.</p></li>
                </ul>
              </div>
              <div className="StartNowButtonContainer">
                  <Link to={`/recruiters/check-out?package=jobs&agency=${this.state.isAgency}`}>
                    <Button btnClassName="PostJobButton">Learn More</Button>
                  </Link>
                </div>
            </div>

            <div className="WhyWorkWithUsCoachingPackage"> 
              <div className = "CoachingPackageTitle">LinkedIn Growth<span className="CoachingPackageSubtitle"></span> </div>
              <div className="CoachingPackageDescription">
              <p>Increase your <b>personal & company brand</b> on LinkedIn, building <b>trust and visibility</b> amongst <b>passive job seekers</b></p>
                <ul>
                <li><p>Everything from the Find a Recruiter Package</p></li>
                <li><p>We'll connect monthly for a catch-up where you have the option to share market updates and trends. These insights will be transformed into a LinkedIn post, reaching ArchJobs’s 4.1k+ followers. This post will showcase your expertise within the architectural market, fostering trust with both active and passive job seekers.</p></li>
                <li><p>Both ArchJobs’s and Aylin’s (8k+ followers) profiles will engage and interact with your posts, helping increase your visibility and gain more impressions. Plus, it provides social proof as we actively support and promote your posts to our network.</p></li>
                <li><p>By raising your LinkedIn visibility, you'll also boost your chances of attracting passive job seekers. They seek recruiters who understand the architectural market, possess the right contacts, and understand their needs.</p></li>               
               </ul>
              </div>
              <div className="StartNowButtonContainer">
                  <Link to={`/recruiters/check-out?package=linkedin&agency=${this.state.isAgency}`}>
                    <Button btnClassName="PostJobButton">Learn More</Button>
                  </Link>
                </div>
            </div>
            <div className="WhyWorkWithUsCoachingPackage"> 
              <div className = "CoachingPackageTitle">Max Exposure<span className="CoachingPackageSubtitle"></span> </div>
              <div className="CoachingPackageDescription">
              <p><b>Maximise your brand’s visibility</b>, and leverage the ArchJobs and Aylin Networks to get <b>more leads & applications</b> from both <b>active and passive job seekers</b></p>
                <ul>
                  <li><p>Everything from the Find a Recruiter Package+.</p></li>
                  <li><p>Everything from the LinkedIn Growth packages.</p></li>
                  <li><p>Be featured on ArchJobs’s homepage, where we'll showcase your agency name, logo, and a backlink to your website. This not only establishes more social proof but also enhances exposure, building trust with our audience.</p></li>
                  <li><p>You will receive maximum exposure through our website, ArchJobs, our weekly newsletter, ArchJobs’s LinkedIn, and our job board. We will actively engage with your LinkedIn posts, helping to build trust within our networks.</p></li>
                  <li><p>We're providing you with multiple avenues for job seekers and clients to see your company brand and name, fostering increased awareness.</p></li>                 
                </ul>
              </div>
              <div className="StartNowButtonContainer">
                  <Link to={`/recruiters/check-out?package=full&agency=${this.state.isAgency}`}>
                    <Button btnClassName="PostJobButton">Learn More</Button>
                  </Link>
                </div>
            </div>
            </div>
          </div>

          <div className="WhyWorkWithUsSection ">

            <FAQRecruiter/>
          </div>
      
          <div className="WhyWorkWithUsSection Bottom">
            <div className="WhyWorkWithUsSectionTitle">Ready to Attract more Job Seekers? </div>
            <div className="WhyWorkWithUsSectionSubtitle">Make an Inquiry and we will be in contact shortly.</div>
            <div className="StartNowButtonContainer">
              <Link to="/recruiters/check-out">
              <Button btnClassName="PostJobButton">Get Started</Button>
              </Link>
            </div>
          </div>
          {/* <div className="WhyWorkWithUsSection Bottom">
            <div className="WhyWorkWithUsSectionTitle">Interested in Understanding our Reach? </div>
            <div className="WhyWorkWithUsSectionSubtitle">Make an Inquiry and we will send you our stats and figures on views, applications and LinkedIn numbers.</div>
            <div className="WhyWorkWithUsSectionSubtitle">Drop us an email at <a href="mailto:hello@archjobs.co.uk">hello@archjobs.co.uk</a></div>

          </div> */}
          <div className="BottomPadding"></div> 
          <ChatWidget />
        </div>
       
      )
    }
  }

  export default WhyWorkWithUsRecruiters