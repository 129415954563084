import ArticleFrame from "./ArticleFrame";
import React, { Component } from 'react';


export const articleContent = <div className="LivingWageArticle">
        <p>Deciding on the best approach to navigate the job market can be a daunting task. With numerous avenues available, one option worth considering is partnering with a recruiter. Working with a recruiter will give you access to diverse opportunities and can be an invaluable support in your job search.</p> 
        <p>In this article, we'll delve into the advantages of working with an agency recruiter, exploring their market knowledge, extensive networks, time-saving abilities, and comprehensive support throughout the hiring process.</p> 
        <p>Learn why working with a recruiter might just be the difference you need to find the ideal job and move your career forward.</p>
        <p/>
        <p/>
        <h3>Agency Recruiter vs In-house Recruiter</h3>
        <p>While I'll focus on agency recruiters, let's clarify the difference between agency and in-house recruiters to understand their level of support.</p>
        <p>An <strong>agency recruiter</strong> (recruitment coordinator/consultant) is someone who finds suitable candidates for companies. They are not tied to a specific practice and work with various practices to fill their recruitment needs. However, recruiters usually specialise in a specific sector/industry i.e. Architecture, Construction, Interior Design etc.</p>
        <p>They will approach different companies, which increases your chances of getting interviews and, hopefully, an offer. They receive a commission if they successfully make a placement, so they will strive to ensure that you are well compensated and a good match for the companies they present you to.</p>
        <p/>
        <p>An <strong>in-house recruiter</strong> is a company employee responsible for overseeing the recruitment process within the organization. Their duties include advertising positions, reviewing CVs, scheduling interviews, and managing job offers.</p>
        <p>Unlike agency recruiters, in-house recruiters do not represent candidates for job opportunities outside their company. They receive a fixed salary regardless of their success in making placements.</p>
        <p/>
        <p/>
        <h3>Who uses In-house Recruiters?</h3>
        <p>Larger companies like bigger AJ100 or multi-disciplinary firms have dedicated in-house recruiters, whereas smaller practices are more likely to rely on agencies or have a few people within the team who handle recruitment needs, such as a Director, HR team, or more senior members of staff.</p>
        <p/>
        <p/>
        <h2> What are the Benefits of Working with a Recruiter</h2>
        <p>Working with a good recruiter can make a big difference in your job search. They possess a wealth of market knowledge and can guide you throughout the entire process, providing advice and informing you about unadvertised opportunities, among other things. But let&rsquo;s dig a bit deeper and see how else they can help you.&nbsp;</p>
        <p/>
        <h3>✔ Their Market Knowledge</h3>
        <p>Recruiters gather extensive market knowledge through daily interactions with companies and candidates. They stay informed about exciting new projects, the benefits packages offered by various companies, the growth of certain businesses, and potential challenges. This additional knowledge is invaluable for any job seeker.</p>
        <p/>
        <h3>✔ Their Network</h3>
        <p>Networking plays an important role in a recruiter's day. Over time, they establish strong working relationships with hiring managers and company leaders, meeting them in person to learn more about the company, its culture, and its growth plans. That way, recruiters have a better understanding of the skills and personality traits employers are seeking. They will also get access to unadvertised/hidden roles.</p>
        <p/>
        <h3>✔ Relocation Assistance</h3>
        <p>If you are considering relocating for a job, recruiters can provide valuable assistance by connecting you with employers in your desired location and offering insights into the local job market. Make sure you find a recruiter who specialises in the area you&rsquo;re moving to.&nbsp;</p>
        <p/>
        <h3>✔ Their Negotiation Skills</h3>
        <p>Recruiters are skilled negotiators and can help advocate for your interests during the salary negotiation process. They have experience in understanding market rates and can work to secure a competitive compensation package for you.</p>
        <p/>
        <h3>✔ Access to Hidden Opportunities</h3>
        <p>Recruiters often have access to job openings that are not publicly advertised. By working with a recruiter, you may gain access to these hidden opportunities and increase your chances of finding the right job.</p>
        <p/>
        <h3>✔ Time Saving</h3>
        <p>During your initial call with a recruiter, they aim to understand the gaps in your current job and why you are looking for a new opportunity. An experienced recruiter can identify potential companies that align with your preferences. They will handle things like chasing feedback, setting up interviews and talking to employers, and negotiating offers which take the stress off you.</p>
        <p/>
        <h3>✔ Helping you with your CV and Sample Portfolio</h3>
        <p>Recruiters go through hundreds of CVs and Sample Portfolios every week. You can ask a recruiter for feedback on your documents to make sure you haven't missed anything. They might suggest restructuring or shortening your CV, and it's a good idea to listen to their advice because they know which CV formats work best for their clients.&nbsp;</p>
        <p>Additionally, you can read our resources on <a href="https://www.archjobs.co.uk/articles/how-to-write-a-senior-architectural-cv" target="_blank" rel="noopener">How to Write and what to Include in a Senior Architectural CV</a>, <a href="https://www.archjobs.co.uk/articles/how-to-write-and-structure-your-cv" target="_blank" rel="noopener">How to Write and Structure an Architecture CV</a>, and <a href="https://www.archjobs.co.uk/articles/how-to-prepare-an-architecture-portfolio" target="_blank" rel="noopener">How to prepare an architectural Portfolio</a>.</p>
        <p/>
        <h3>✔ Interview Preparation</h3>
        <p>Recruiters will assist you in preparing for interviews, ensuring a smooth and successful process. They will guide you on what to expect and give you tips on what questions to ask the interviewers. If it's your first time having a remote interview through Teams or Zoom and you feel a bit uneasy, they can do a practice run with you. They will check if your camera and microphone are working properly and help you with screen sharing. Just ask them for help.&nbsp;</p>
        <p/>
        <h3>✔ Interview Feedback and Coaching</h3>
        <p>They can provide valuable feedback and insights after each interview. They can help you identify areas for improvement and provide coaching to enhance your interview skills.</p>
        <p/>
        <h3>✔ Long-Term Relationship</h3>
        <p>Building a relationship with a recruiter can be beneficial for your long-term career growth. Even if they don't find you a job immediately, they can keep you in mind for future opportunities and provide ongoing career advice.</p>
        <p/>
        <p/>
        <h2>Here are some frequently asked questions about Recruiters</h2>
        <p/>
        <h3>How many Recruiters should I work with?</h3>
        <p>I would recommend working with a maximum of two recruiters at a time. Managing more than that can become challenging as it becomes harder to keep track of your applications and the information you've shared with each recruiter.</p>
        <p>From a recruiter's perspective, working with too many recruiters can slow down the process. Recruiters don't want to approach their clients only to discover that your CV has already been submitted. This can reflect negatively on both the recruiter and you, as it may indicate a lack of communication skills.&nbsp;</p>
        <p>Instead of working with every recruiter, focus on quality over quantity. If you have a good conversation with a recruiter who seems knowledgeable and experienced, consider offering them exclusivity for a week or two. This can motivate them to prioritise your applications.&nbsp;</p>
        <p/>
        <h3>How do I know that I&rsquo;m working with a knowledgeable Recruiter?</h3>
        <p>The best way to find out if you&rsquo;re working with a knowledgeable recruiter is simply by talking to them. Ask them questions about your sector/market, which skill sets are in demand and what are the going salaries for people with your level of experience. Test them as much as they&rsquo;re testing you.</p>
        <p>Don&rsquo;t forget it is up to you if you want to work with the recruiter. If you feel like they might not have the right jobs on the go, connections or market knowledge, then tell them you&rsquo;re putting your search on hold or need a bit more time to think things through.&nbsp;</p>
        <p/>
        <h3>How can I find out if a Recruiter actually represents a certain practice?</h3>
        <p>If a Recruiter wants to put your details forward, how do you know they are actually representing the practice? Here are a few questions you can ask a recruiter if they are working with a particular company:</p>
        <ul>
        <li>Have you previously placed candidates with them?</li>
        <li>How long have you been collaborating with them?</li>
        <li>What insights can you provide about their company culture?</li>
        <li>Have you had any in-person meetings with them?</li>
        <li>Could you explain their hiring process, including interview stages and other relevant details?</li>
        </ul>
        <p/>
        <h3>Do Recruiters charge job seekers?&nbsp;</h3>
        <p>No, they don't. Their base salary is paid by their agency, and their commission comes from successful placements made with their clients. If you are ever asked to pay a recruiter or pay for any assessments, do not do so. It is likely a scam.</p>
        <p/>
        <h3>Do Recruiters work on the weekends?</h3>
        <p>Usually, recruiters work Monday to Friday during normal business hours. However, it is possible for a recruiter to reach out to a candidate or handle administrative tasks over the weekend.</p>
        <p/>
        <h3>Do Recruiters read cover letters?&nbsp;</h3>
        <p>In all honesty, most recruiters probably don't read cover letters. When applying for a position, check the required documents. Typically, when an agency recruiter advertises a position, they ask for a CV and Sample Portfolio. However, if you are applying through a company's recruitment portal with an in-house recruitment team, they may request cover letters along with your CV and Sample Portfolio.</p>
        <p/>
        <h3>Do Recruiters look at LinkedIn?&nbsp;</h3>
        <p>Yes, they do. After reviewing your CV and Sample Portfolio, one of the things recruiters may do is check your LinkedIn profile. LinkedIn is a valuable resource for recruiters and hiring managers to learn more about you and potentially connect. It is a good practice to keep your LinkedIn profile up to date. If you need a refresher, you can read <a href="https://www.archjobs.co.uk/articles/how-to-optimise-an-architectural-linkedin-profile" target="_blank" rel="noopener">10 Steps to transform your LinkedIn profile</a>.&nbsp;</p>
        <p/>
        <h3>Do Recruiters call to inform you that you didn't get the job?&nbsp;</h3>
        <p>Yes, they might. When I worked as a recruiter myself, I believed that calling candidates to deliver the news, even if it was not good news, was the right thing to do. Communication is crucial between candidates and recruiters. Even if you didn't get the job, an agency recruiter may have other opportunities and would like to discuss them with you.</p>
        <p/>
        <h3>Is it ethical for a recruiter to send my application to practices without my permission or knowledge?&nbsp;</h3>
        <p>No, it is not ethical. When working with an agency recruiter, you need to give them permission to send or approach practices on your behalf. If a recruiter calls and asks to send your details to "my clients," always ask for the names of the practices. This is important because you may have already approached them or may not want your application to be sent to certain firms.</p>
        <p/>
        <h3>Why do Recruiters ask about my current salary?&nbsp;</h3>
        <p>Recruiters ask about your current salary package to ensure they do not present job opportunities that offer lower compensation than what you are currently earning. It is highly unlikely that you would accept a new offer with lower pay.</p>
        <p/>
        <h3>What should I discuss in my first call with a Recruiter?&nbsp;</h3>
        <p>During your first call with a good recruiter, they mainly want to learn more about you, your skill set, your experience, and the reasons behind your job search. The best recruiters will listen more than they talk. They will ask you questions but primarily focus on listening to what you have to say and understanding your needs.&nbsp;</p>
        <p>This helps them find the right role for you and avoid pushing you towards unsuitable job opportunities. You can also ask the recruiter questions about the current market, salary ranges for your level of experience and skills, and if they have any clients who might be interested in reviewing your CV.</p>
        <p/>
        <h3>Do I need to keep working with a Recruiter I don't get along with?&nbsp;</h3>
        <p>No, you are not obligated to work with a recruiter if you don't feel that they can genuinely help you or if you don't have a good rapport with them. It could be that they are not attentive to your preferences or keep suggesting unsuitable roles. Just because a recruiter has submitted your application to a specific client does not mean you have to continue working with them until you find a new role.</p>
        <p/>
        <h3>Why do Recruiters ask which companies I've applied to?&nbsp;</h3>
        <p>Recruiters ask about the companies you've applied to in order to avoid duplication. They want to ensure they don't approach clients or practices that have already received your application. This helps maintain a professional image for both the recruiter and the candidate. If a recruiter asks you about this, it is advisable to provide the information.&nbsp;</p>
        <p>If you're uncomfortable sharing, you can ask the recruiter to email you a list of potential practices and check if you have already approached them within the last six months.</p>
        <p/>
        <h3>Do Recruiters specialise in specific sectors/industries?&nbsp;</h3>
        <p>Yes, recruiters typically specialise in specific sectors or industries. They cannot cover all areas such as architecture, IT, manufacturing, media, education, etc. It would be overwhelming.&nbsp;</p>
        <p>Each recruiter usually focuses on one industry. If you are seeking an architectural job, make sure to engage with a recruiter who specialises in architecture. </p>
        <p/>
        <h3>&zwj;Do Agency Recruiters work across all seniority levels (junior-senior)?&nbsp;</h3>
        <p>It depends. Each agency recruiter operates differently. Some may work across all levels, including graduates, Part 1 Architectural Assistants and Director-level roles.&nbsp;</p>
        <p>However, many recruiters tend to focus on positions beyond internships, apprenticeships, and graduate roles, as those are typically handled by in-house recruitment or HR teams. Recruiters are used for their ability to find candidates with rare skill sets, specific industry experience, niche sector knowledge, or individuals from reputable companies.</p>
        <p/>
        <h3>Can Recruiters assist everyone?</h3>
        <p>As mentioned earlier, recruiters cannot assist everyone they interact with. It depends on the type of position you are seeking, the location, your expectations, your skill level, and your experience.</p>
        <p>Some clients may not engage recruiters to fill junior roles, such as graduate, Part 1 Architectural Assistant, and intern positions. In such cases, as a job seeker, you would need to approach practices on your own or find a recruiter who specializes in more junior roles.</p>
        <p/>
        <p/>
        <h2>Conclusion</h2>
        <p>I hope this article has shown the significant benefits of working with an agency recruiter in your job search. They possess extensive market knowledge, can provide valuable connections, introduce you to potential employers, assist with relocation, offer guidance on your CV and Portfolio, prepare you for interviews, help with negotiating job offers and more.&nbsp;</p>
        <p>While finding the right architectural recruiter may require some time, once you find someone who truly understands your needs, it is advisable to maintain a working relationship with them as they will keep you in mind for excellent opportunities.</p>
</div>

export default class WorkingWithRecruiters extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
      };
    }
    componentDidMount = () => {
      window.scrollTo(0, 0)
    }

    render(){
        return <ArticleFrame ArticleDataName="WorkingWithRecruiters" >
          {articleContent}
                
            </ArticleFrame>
    }
}