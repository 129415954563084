import withRouter from "../withRouter";
import React, { Component } from 'react';
import Helmet from 'react-helmet'
import '../articles/articles.css'
import HiringGuideData from './HiringGuideData';
import Button from "../components/Buttons";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";

class HiringHub extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
      };
    }


    render(){
        let authorData = {}
        let url = "https://www.archjobs.co.uk/hiring"
        let title = "ArchJobs Hiring Hub | Architectural Hiring Advice"
        let description = "Stay updated with the latest architectural hiring insights, salaries, and in depth hiring guides."
        let authorComponent

        let ArticleListTitle = "Architectural Hiring Guides: Strategies to Find the Best Talent for your Business"
        let ArticleList = []
        
        for (const article in HiringGuideData.HiringGuideData){
            if (article !== "YourArticle"){ //this is the demo article
                HiringGuideData.HiringGuideData[article].dataName = article
                ArticleList.push(HiringGuideData.HiringGuideData[article])
            }
        }
        
        // ArticleList.sort((a,b) => b.published - a.published)

        let ArticleElements = []

        ArticleList.forEach(article => {
            ArticleElements.push(<Link to={article.article_url.replace("https://www.archjobs.co.uk", "")} className="ArticlePreview">
                <div className="ArticlePreviewContent">
                    <div className="ArticlePreviewTitle">{article.article_title}</div>
                    {/* <div className="ArticlePreviewPublished">{article.published.toLocaleDateString("en-GB", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</div> */}
                    <div className="ArticlePreviewDescription">{article.article_description}</div>
                </div>
                <img className="ArticlePreviewImage" src={article.thumbnail_img} alt={article.article_title}/>
            </Link>)
        })

        return <div className="WhyWorkWithUsPage HiringHub">
        <Helmet>
            <meta charSet="utf-8" />
            <title>{title}</title>
            <link rel="canonical" href={url} />
            <meta name="description" content={description}/>
            <meta name="og:description" content={description}/>
            <meta name="og:title" content={title}/>
            <meta name="og:type" content={"website"}/>
            <meta name="og:url" content={url}/>
        </Helmet>
            <div className="WhyWorkWithUsBanner WhyWorkWithUsBannerCoaching">
                <div className="WhyWorkWithUsBannerTextContainer">
                <h1 className="WhyWorkWithUsSectionTitle WhyWorkWithUsSectionTitleBold">The Architecture Hiring Hub</h1>
                <div className="WhyWorkWithUsSectionSubtitle">Whether you run a small, medium, or large architectural practice, the need to expand your team is inevitable. Finding the right talent can be a game-changer, and that's where our comprehensive guides and articles come in. Explore valuable insights to navigate the intricacies of architectural recruitment.</div>
                {/* <div className="WhyWorkWithUsSectionSubtitle">Whether you're struggling with your architectural job search journey in the UK, or you just need some specific advice, working with Aylin will offer you useful insights and actionable advice to help you succeed in navigating your Architectural Career.</div> */}
            </div>
            <div className="WhyWorkWithUsBannerBG HiringBG"> </div>
            </div>
            <div className="WhyWorkWithUsSection">
                <div className="CoachingPackageTitle">Hiring Guides</div>
                <div className="WhyWorkWithUsSectionSubtitle">We understand that hiring the right candidate is crucial to growing your team and practice in the right direction. Take a look through our detailed hiring guides, which will answer most of your questions and include job advert examples, what job seekers are looking for and much more.</div>
                <div className="ArticleList">{ArticleElements}</div>
            </div>
            <div className="WhyWorkWithUsSection GreySection">
                <div className="CoachingPackageTitle">Architectural Salary Ranges UK</div>
                <div className="WhyWorkWithUsSectionSubtitle">Before considering whether your salary package is competitive, visit our Interactive Architectural Salary Guide. It provides insights into the average salary for your desired position and location. On average, salaries in London are at least £3,000 - £5,000 higher than those outside London, reflecting the higher cost of living.</div>
                <div className="StartNowButtonContainer">
                  <Link to="/salary-guide">
                    <Button btnClassName="PostJobButton">View Salaries</Button>
                  </Link>
                </div>
                <Link to="/salary-guide" className="SalaryGuideImgContainer">
                    <img className="SalaryGuideImg" src="https://cdn.archjobs.co.uk/file/ArchJobs/backgrounds/ArchjobsSalaryGuideTiltedGrey700.png" alt="Architectural Salary Guide UK"/>
                </Link>
            </div>
            <div className="WhyWorkWithUsSection">
            <div className="CoachingPackageTitle">Advertise your Architectural roles with us</div>
                <div className="WhyWorkWithUsSectionSubtitle">Hiring can cost you thousands of pounds, and even more if the placements don't work out. At ArchJobs, we do more than just list your architectural job advert, you gain access to our experts and receive hands-on support from <a target="_blank" href="https://www.linkedin.com/in/aylin-round/">Aylin Round</a>.</div>
                {/* <div className="WhyWorkWithUsSectionSubtitle">Interested in hearing about a more affordable alternative? </div> */}
                {/* <div className="WhyWorkWithUsSectionSubtitle">At ArchJobs, we do more than just list your architectural job advert, you gain access to our experts and receive hands-on support from Aylin Round.</div> */}
                <div className="WhyWorkWithUsSectionSubtitle"><strong>Which each job ad, we also:</strong></div>
                <div className="WhyWorkWithUsSection MeetTheCoachHub">
                  <div>✔ Help you create an engaging job advert</div> 
                  <div>✔ Assess if your salary/benefits package is competitive</div> 
                  <div>✔ Share current industry knowledge</div> 
                  <div>✔ Provide other ways to market your role</div> 
                  <div>✔ Engage with your job advert on LinkedIn (4.3k+ followers)</div> 
                  <div>✔ Show you how you can increase the chances of passive job seekers applying</div>
               </div>
                {/* <div className="CoachingPackageTitle">Advertise your Architectural roles with us</div>
                <div className="WhyWorkWithUsSectionSubtitle">ArchJobs is more than just an architectural job board. Our team takes pride in highlighting the best features and benefits, providing hiring managers with useful tips on how to optimise their job adverts and attract suitable talent.</div>
                <div className="WhyWorkWithUsSectionSubtitle">If you'd like to work with a job board that does more than just upload a job, if you'd like to work with someone who understands the architectural sector and can provide you with current market insights, then ArchJobs is for you.</div> */}
                <div className="StartNowButtonContainer">
                  <Link to="/post-job">
                    <Button btnClassName="PostJobButton">Post a Job</Button>
                  </Link>
                </div>
                <p/>
                <p/>
                <Link to="/post-job" className="SalaryGuideImgContainer">
                    <img className="SalaryGuideImg JobAdImg" src="https://cdn.archjobs.co.uk/file/ArchJobs/backgrounds/LSIJobAdsmaller.png" alt="Example Job Advert"/>
                </Link>
                <p/>
                <p/>
                <p> Our prices range between £129 and £189 per ad (no VAT) and no hidden fees.</p>
            </div>
            {/* <div className="WhyWorkWithUsSection GreySection">
                <div className="CoachingPackageTitle">Are you looking for a Recruiter?</div>
                <div className="WhyWorkWithUsSectionSubtitle">ArchJobs is partnering up with the best architectural recruiters the UK has to offer. We want to make it easier for our clients to find the right recruiter. That is the reason why we have created Find an Architectural Recruiter. All recruiters and agencies are pre-vetted by <a target="_blank" href="https://www.archjobs.co.uk/">ArchJobs</a> to ensure the highest standard.</div>
                <div className="StartNowButtonContainer">
                  <Link to="/recruiters">
                    <Button btnClassName="PostJobButton">Find a Recruiter</Button>
                  </Link>
                </div>
            </div> */}
            <div className="WhyWorkWithUsSection Bottom">
            <div className="CoachingPackageTitle">Do you still have a question? Reach out to us via email.</div>
            <div className="WhyWorkWithUsSectionSubtitle">Drop us an email at <a href="mailto:hello@archjobs.co.uk">hello@archjobs.co.uk</a></div>
            {/* <div className="WhyWorthWithUsHeaderButton">
              <Link to="/post">
                <Button btnClassName="PostJobButton">Contact Us</Button>
              </Link>
            </div> */}
          </div>
            {/* <div className="WhyWorkWithUsSection">
                <div className="WhyWorkWithUsSectionTitle">Need help writing your job advert?</div>
                <div className="WhyWorkWithUsSectionSubtitle">At ArchJobs, we're here to assist. We understand what job seekers are looking for in an ad and can help you craft a more engaging one. Reach out to Aylin, and she'll be here to guide you and share valuable market insights.</div>
                <div className="StartNowButtonContainer">
                  <Link to="/recruiters">
                    <Button btnClassName="PostJobButton">Find a Recruiter</Button>
                  </Link>
                </div>
            </div> */}
    </div>
    }
}

export default withRouter(HiringHub)