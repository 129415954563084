
import React from 'react'
import classNames from 'classnames';
import '../css/navbar.css'
import {BsPlus} from 'react-icons/bs'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
  import { Collapse } from '@mui/material';
  import tags from '../data/tags';
  import Masonry from 'react-masonry-css'


const Questions = [
    {"Number": 1, 
    "Question": "What is the process for adding job posts to ArchJobs?",
    "Answer": <div className="FAQAnswer">
        <p>Starting is easy. Choose a <Link to="/post">packages</Link> and give us the necessary details, and we'll take care of everything else. Our team will optimise your job advert to not only look appealing but also attract the best talent.</p>
        <p>Please note: Our job board is curated to feature job listings only from architectural practices and multi-disciplinary firms with positions available in the UK.</p>
        </div>},

    {"Number": 2, 
    "Question": "Is there a limit to how many candidates can apply to a job?",
    "Answer": <div className="FAQAnswer">
        <p>We do not limit the number of applicants for a given position, regardless of the package. Our goal is to provide you with as many qualified candidates as possible.</p>
        </div>},

    {"Number": 3, 
    "Question": "What happens after you purchase a package?",
    "Answer": <div className="FAQAnswer">
    <p>After purchasing a package, our team will receive your request and either upload your job advert or arrange a 10-minute phone call to gather additional information. Once the ad is live, we'll send you a confirmation email with a link to view it.</p>
    </div>},
   
    // {"Number": 4, 
    // "Question": "How long are job postings live for?",
    // "Answer": <div className="FAQAnswer">
    //     <p>Job postings remain active on our page for 28 days. If you would like the ad to expire before then, please inform us.</p>
    //     </div>},
    
    {"Number": 5, 
    "Question": "Can we amend our job ads? ",
    "Answer": <div className="FAQAnswer">
        <p>Yes, please contact aylin@archjobs.co.uk with any minor amendments.</p>
        <p>However, this is not part of the Basic Package.</p>
        </div>},
   
    {"Number": 6, 
    "Question": "How will we receive job applications? ",
    "Answer": <div className="FAQAnswer">
        <p>We can cater to various ways of sending applications. Let us know if you would rather receive applications to a specific email address, or if you prefer people to apply direct on your company website.</p>
    </div>},
 
    {"Number": 7, 
    "Question": "What type of positions do you post ads for?",
    "Answer": <div className="FAQAnswer">
        <p>No matter what type of contract you're looking for, we have you covered with options including full-time, part-time, contract, fixed-term, and permanent.</p>
        <p>The full list of positions we post jobs for is as follows:
            <ul>
                {tags["JobTitles"].map(Job => {return<li>{Job}</li>})}
            </ul>
        </p>
    </div>},

    {"Number": 8, 
    "Question": "How many visitors do you typically receive per month?",
    "Answer": <div className="FAQAnswer">
    <p>Currently we get over 5.960 unique users per month, and our user base is continuing to expand.</p>
    </div>},

    // {"Number": 9, 
    // "Question": "How can we get in contact with you?",
    // "Answer": <div className="FAQAnswer">
    // <p>Drop us an email at hello@archjobs.co.uk or use the chat function on the bottom right of this page.</p>
    // </div>},

    {"Number": 10, 
    "Question": "Do you offer a retainer model for ongoing hiring needs?",
    "Answer": <div className="FAQAnswer">
    <p>Yes, we offer a retainer model for clients who have ongoing hiring needs. Our retainer model allows you to pay a fixed monthly fee. </p>
    <p> If you are interested to learn more about how it can benefit your company, please contact Aylin at aylin@archjobs.co.uk or use the chat function on the bottom right of this page.</p>
    </div>},

    // {"Number": 11, 
    // "Question": "What is Find a Recruiter?",
    // "Answer": <div className="FAQAnswer">
    // <p>ArchJobs is partnering up with the best architectural recruiters the UK has to offer. We want to make it easier for our clients to find the right recruiter. That is the reason why we have created Find an Architectural Recruiter. All recruiters and agencies are pre-vetted by <a target="_blank" href="https://www.archjobs.co.uk/">ArchJobs</a> to ensure the highest standard.</p>
    // </div>},

    // {"Number": 12, 
    // "Question": "Want to join our team of Architectural Recruitment Experts?",
    // "Answer": <div className="FAQAnswer">
    // <p>If you are a recruiter who specialises in architecture roles across the UK, we would love to have a chat with you. Visit the <Link className="Link" to="/recruiters/join-us">Find a Recruiter Join Us</Link> page.</p>
    // <p>Please contact Aylin at aylin@archjobs.co.uk or use the chat function on the bottom right of this page.</p>
    // </div>},
]


class FAQ extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: [],
            layout: "Default"
        };  
    }    
    
      componentDidMount = () => {
        window.addEventListener("resize", this.getSize.bind(this));

      }
    
      getSize = () => {
        var pagewidth = window.innerWidth
        var layout = "Default"
        if (pagewidth < 600){
          layout = "Mobile"
        }
        if (this.state.layout !== layout){
          this.setState({layout: layout})
        }
      }

      toggleQuestion = (num) => {
        const open = this.state.open
        const index = open.indexOf(num);
        if (index > -1) { // only splice array when item is found
            open.splice(index, 1); // 2nd parameter means remove one item only
        } else {
            open.push(num)
        }
        this.setState({open: open})
      }

    render() {

        var question_list = []

        Questions.forEach(Question => {
            question_list.push(
                <div className="FAQSection">
                    <div className="FAQQuestion" onClick={() => {this.toggleQuestion(Question["Number"])}}>
                        <BsPlus className="PlusIcon"/> {Question["Question"]}
                    </div>
                    <Collapse in={this.state.open.includes(Question["Number"])}>
                        {Question["Answer"]}
                    </Collapse>
                </div>)
        })


      return (
        <div className="TeamPage">
            <div className="FAQContainer">
                
                <div className="FAQContent">
                    <div className="FAQTitle">FAQ - For Employers </div>
                    <p>We've collected the answers to some of our frequently asked questions below. Hopefully this helps clarify more about our service but as always, feel <a target="_blank" href="mailto:hello@archjobs.co.uk">free to reach out to us</a> directly if you have any further questions.</p>
                    {/* <div className="FAQSubTitle">We've collected the answers to some of our frequently asked questions below. Hopefully this helps clarifiy more about our service but as always, feel free to reach out to us directly if your question isn't covered below.</div> */}
                    <Masonry
                        breakpointCols={this.state.layout == "Mobile"? 1: 2}
                        className="my-masonry-grid"
                        columnClassName="my-masonry-grid_column">
                        {question_list}
                    </Masonry>
                    {/* <div className="FAQContact">
                        Can't find what you're looking for? <a href="mailto:hello@archjobs.co.uk">Contact</a> the ArchJobs team.
                    </div> */}
                    
                </div>
                <div className="FAQBackground"> </div>
            </div>
        </div>
      )
    }
  }

  class FAQPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };  
    }    
    
    componentDidMount = () => {
        window.scrollTo(0, 0)
      }
      render() {


      return (
        <div className="TeamPage">
            <FAQ/>
            <div className="WhyWorkWithUsSection">
            <div className="CoachingPackageTitle">Still have a question? Reach out to us via email</div>
            <div className="WhyWorkWithUsSectionSubtitle">Drop us an email at <a href="mailto:hello@archjobs.co.uk">hello@archjobs.co.uk</a></div>
            {/* <div className="WhyWorthWithUsHeaderButton">
              <Link to="/post">
                <Button btnClassName="PostJobButton">Contact Us</Button>
              </Link>
            </div> */}
          </div>
          <div className="BottomPadding"></div> 
        </div>
      )
    }
  }

  export default FAQPage
  export {FAQ}