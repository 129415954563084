import React, { Component } from 'react'
import Button from '../components/Buttons'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'


class ContactPage extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0)
  }


  render() {
    return <div className="HomePage RecruitmentPage" >
    <div className="WhyWorkWithUsBanner" style={{marginBottom: "0px"}}>
      <div className="WhyWorkWithUsBannerTextContainer">
        <h1 className="WhyWorkWithUsSectionTitle">Hire Top Architectural Talent</h1>
        <p className = "WhyWorkWithUsSectionSubtitle">
          Find your perfect next hire with Aylin
        </p>
        <a href="https://calendly.com/aylin-round/30min"   target="_blank" rel="noreferrer" className="Link YellowButton">
          <Button className="Button">Interested? Book a Chat!</Button>
        </a>
      </div>
      <div className="WhyWorkWithUsBannerBG WhyWorkWithUsBannerRecruitmentBG"> </div>
    </div>
    {/* <div className="HomePageSection RecruitmentFirstSection">
      <div className="HomePageSectionText">
          <h2 className="HomePageSectionTitle">Hi, I’m Aylin the founder and owner of ArchJobs Recruitment </h2>
      </div>
    </div> */}
    <div className="WhyWorkWithUsSection RecruitmentHomePageSection" style={{paddingBottom: "60px"}}>
      <div className="VideoContainer">
        <h2 className="HomePageSectionTitle" style={{textAlign: "center"}}>Hi, I’m Aylin the founder and owner of ArchJobs Recruitment </h2>
        <LiteYouTubeEmbed id="6e0IAPHQyaQ" title="About ArchJobs Recruitment" />
        <a href="https://calendly.com/aylin-round/30min"   target="_blank" rel="noreferrer" className="Link">
          <Button className="Button">Interested? Book a Chat!</Button>
        </a>
      </div>
    </div>
    <div className="HomePageSection MyApproachSection">
      <h2 class="HomePageSectionSubtitle" >When it comes to working with me, there are a few things you need to know:</h2>
      <div class="MyApproachList">
          <ol>
            <li><b>Strong Talent Network</b> - I’ve invested years growing my network, giving me access to a large number of passive job seekers at top practices that are hard to attract otherwise.</li>
            <li><b>Industry Knowledge & Experience</b> - I’m experienced and knowledgeable in the entire Architectural industry, and committed to seeing it flourish. Having seen thousands of Architectural CVs, I know exactly what to watch out for.</li>
            <li><b>Quality over quantity</b> - I will never bombard you with unsuitable applications... because, really, what’s the point? I’ll filter out unqualified applications and only forward the top ones, saving you time.</li>
            <li><b>Building strong relationships</b> - I like to understand as much about your practice, team, and projects. This way, you’re giving me the best chance to sell the opportunity to candidates.</li>
          </ol>
      </div>
      <a href="https://calendly.com/aylin-round/30min"   target="_blank" rel="noreferrer" className="Link">
        <Button className="Button">Interested? Book a Chat!</Button>
      </a>
    </div>

    <div className="HomePageSection NoBottomBorder">
        <div className="HomePageSectionText">
            <h2 className="HomePageSectionTitle">A Recruiter That Cares</h2>
            <p className="HomePageSectionSubtitle">I’m passionate about sharing my knowledge to help aspiring architects. My advice to job seekers has been featured in...</p>
            <div className="HomePageLogos">
              <img className="HomePageLogo" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/building_design_logo.png" alt="BD Logo"/>
              <img className="HomePageLogo" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/architects_journal_logo_small.jpg" alt="Architects Journal Logo"/>
              <img className="HomePageLogo" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/arch_social_logo.png" alt="Architecture Social Logo"/>
              <img className="HomePageLogo" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/aspiration_logo.jpg" alt="Aspiration Logo"/>
              <img className="HomePageLogo" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/archademia_logo.png" alt="Archidemia Logo"/>
              <img className="HomePageLogo" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/ciatwessex_logo.png" alt="CIAT Wessex Logo"/>
              <img className="HomePageLogo" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/CareerSidekick.png" alt="Career Sidekick Logo"/>
              <img className="HomePageLogo" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/architecturebusinessclub.jpg" alt="Architecture Business Club Logo"/>
              <img className="HomePageLogo" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/thebrokearchitectpodcast_logo.png" alt="The Broke Architect Logo"/>
              <img className="HomePageLogo" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/riba.png" alt="RIBA Logo"/>
              <img className="HomePageLogo" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/grandbestexpo_logo.jpg" alt="Grand Best Expo Logo"/>
              <img className="HomePageLogo" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/nottinghamandderbysocietyofarchitects_logo.png" alt="Nottingham and Derby Society of Architects Logo"/>
            </div>
        </div>
      </div>
  </div>  
  }
}

/* export the component to be used in other components */
export default ContactPage