import React, { Component } from 'react'
import SearchBar from '../components/SearchBar';
import Button from '../components/Buttons'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";



class HomePage extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0)
  }


  render() {
    return <div className="HomePage" >
      <div className="WhyWorkWithUsBanner">
        <div className="WhyWorkWithUsBannerTextContainer">
          <h1 className="WhyWorkWithUsSectionTitle">ArchJobs Recruitment</h1>
          <p className = "WhyWorkWithUsSectionSubtitle">
            Matching Dubai's leading architectural firms with top global talent
          </p>

        </div>
        <div className="WhyWorkWithUsBannerBG"> </div>
      </div>
      <div className="HomePageSection">
        <div className="HomePageSplitSection SplitLeft">
          <div className="SplitSectionText">
            <h2 className="HomePageSectionTitle">Are you looking for a new architectural role in Dubai?</h2>
            <p className="HomePageSectionSubtitle">
              If you're ready to take the next step in your career but are unsure where to start, or you're looking for a strategic move, let's have a confidential chat.
            </p>
            <Link to="/job-seekers" className="Link">
              <Button className="Button">Learn More</Button>
            </Link>
            </div>
            <img className="CircularImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/d3square266x266.jpg" alt="ArchJobs Recruitment for Job Seekers"/>
        </div>
        <div className="SectionSeparator">~</div>
        <div className="HomePageSplitSection SplitRight">
          <div className="SplitSectionText">
            <h2 className="HomePageSectionTitle">Are you an architectural practice in Dubai looking for the best architectural talent?</h2>
            <p className="HomePageSectionSubtitle">
              Are you finding it harder to attract the best architectural talent? Want to expand your team with top Architects from across the world? I'm here to help.
            </p>
            <Link to="/recruitment" className="Link">
              <Button className="Button">Learn More</Button>
            </Link>
          </div>
          <img className="CircularImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/zahahadidsquare266x266.jpg" alt="ArchJobs Recruitment for Practices"/>
        </div>
      </div>
      {/* <div className="HomePageSectionSeparatorContainer">
        <div className="HomePageSectionSeparator"></div>
      </div> */}
      <div className="HomePageSection RecruitmentHomePageSection">
        <div className="HomePageSplitSection SplitRight">
          <div className="SplitSectionText">
            <h2 className="HomePageSectionTitle">Hi, I’m Aylin the founder and owner of ArchJobs Recruitment.</h2>
            <p className="HomePageSectionSubtitle">
              With 5 years in the architectural industry, I've gained invaluable insights on how to match talent with practices and vice versa. Every job seeker's journey is different, and I’m here to understand exactly what you are looking for so I can help you find the practices where you'll thrive for many years to come.
            </p>
            <p className="HomePageSectionSubtitle">
              At the same time, I’m keen to get to know practices inside and out. Why? Because I want to do my absolute best in representing your firm to my network of architectural professionals.
            </p>
            <p className="HomePageSectionSubtitle">Whether you are seeking a new architectural role or would like to work with me in finding the right talent for your practice, please reach out.</p>
            <Link to="/contact-me" className="Link">
              <Button className="Button">Contact Me</Button>
            </Link>
          </div>
          <img className="CircularImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruiters/AylinLightBG_1x1.png" alt="Aylin Round - Archjobs Recruitment"/>
        </div>
      </div>
      {/* <div className="HomePageSectionSeparatorContainer">
        <div className="HomePageSectionSeparator"></div>
      </div> */}
      <div className="HomePageSection NoBottomBorder">
        <div className="HomePageSectionText">
            <h2 className="HomePageSectionTitle">A Recruiter That Cares</h2>
            <p className="HomePageSectionSubtitle">I’m passionate about sharing my knowledge to help aspiring architects. My advice to job seekers has been featured in...</p>
            <div className="HomePageLogos">
              <img className="HomePageLogo" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/building_design_logo.png" alt="BD Logo"/>
              <img className="HomePageLogo" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/architects_journal_logo_small.jpg" alt="Architects Journal Logo"/>
              <img className="HomePageLogo" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/arch_social_logo.png" alt="Architecture Social Logo"/>
              <img className="HomePageLogo" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/aspiration_logo.jpg" alt="Aspiration Logo"/>
              <img className="HomePageLogo" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/archademia_logo.png" alt="Archidemia Logo"/>
              <img className="HomePageLogo" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/ciatwessex_logo.png" alt="CIAT Wessex Logo"/>
              <img className="HomePageLogo" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/CareerSidekick.png" alt="Career Sidekick Logo"/>
              <img className="HomePageLogo" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/architecturebusinessclub.jpg" alt="Architecture Business Club Logo"/>
              <img className="HomePageLogo" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/thebrokearchitectpodcast_logo.png" alt="The Broke Architect Logo"/>
              <img className="HomePageLogo" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/riba.png" alt="RIBA Logo"/>
              <img className="HomePageLogo" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/grandbestexpo_logo.jpg" alt="Grand Best Expo Logo"/>
              <img className="HomePageLogo" src="https://cdn.archjobs.co.uk/file/ArchJobs/recruitment/nottinghamandderbysocietyofarchitects_logo.png" alt="Nottingham and Derby Society of Architects Logo"/>
            </div>
        </div>
      </div>
  </div>  
  }
}

/* export the component to be used in other components */
export default HomePage