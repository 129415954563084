
import React from 'react'
import withRouter from '../withRouter.js';
import classNames from 'classnames';
import '../css/navbar.css'
import {MdMenu, MdArrowDropDown} from 'react-icons/md'
import {IoMdNotificationsOutline} from 'react-icons/io'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import Button from './Buttons.js'

class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: "",
            open: false,
        }
      }


     toggleMenu = () => {
         this.setState({open: !this.state.open})
     }

     componentDidUpdate = (prevProps, prevState) => {
         if((this.props.router.location !== prevProps.router.location)&&(this.state.open)){
             this.setState({open: false})
         }
     }

     componentDidMount = () => {
        window.addEventListener('click', (e) => {  
            if ((document.getElementById('NavbarContainer') !== null) && this.state.open){
                if (document.getElementById('NavbarContainer').contains(e.target)){
                    // Clicked in box
                  } else{
                    // Clicked outside the box
                     this.setState({open: false})
                  }
            } 

          });
     }

    render() {

        let navBarClass = "Navbar"

      return (
        <div className={navBarClass}>
            <div className="NavbarContainer" id="NavbarContainer">
                <Link className="NavbarTitle" to="/">
                    <img alt="ArchJobs Logo" src="/logo.png" className="NavbarLogoImg"/>
                    ArchJobs
                </Link>
                <div className={classNames("NavbarLinks", {hidden: !this.state.open})} >
                    <Link to="/search" className={classNames("NavLink", {active: this.props.router.location.pathname.startsWith("/search")})} >
                        Search Jobs
                    </Link>
                    <Link to="/articles" className={classNames("NavLink", {active: this.props.router.location.pathname.startsWith("/articles")})} >
                        Articles
                    </Link>
                    <div className={"NavLinkDropdown"} >
                        <Link to="/salary-guide" className={classNames("NavLink ", {active: (this.props.router.location.pathname.startsWith("/salary-guide") || this.props.router.location.pathname.startsWith("/unpaid-overtime"))})} >
                            Salaries <MdArrowDropDown className="NavDropdownArrow"/>
                            {/* <span class="NewHighlight" >New!</span> */}
                        </Link>
                        <div className="DropdownContainer">
                            <Link to="/salary-guide" className={classNames("NavLink DropdownLink ", {active: this.props.router.location.pathname.startsWith("/salary-guide")})} >
                                Salary Guide
                            </Link>
                            <Link to="/unpaid-overtime-calculator" className={classNames("NavLink DropdownLink ", {active: this.props.router.location.pathname.startsWith("/unpaid-overtime")})} >
                                Unpaid Overtime Calculator
 
                            </Link>
                        </div>
                    </div>
                    <div className={"NavLinkDropdown"} >
                        <Link to="/recruiters" className={classNames("NavLink ", {active: (this.props.router.location.pathname.startsWith("/recruiters"))})} >
                            Find a Recruiter 
                            {/* <span class="NewHighlight" >New!</span> */}
                        </Link>
                        {/* <div className="DropdownContainer">
                            <Link to="/recruiters" className={classNames("NavLink DropdownLink ", {active: this.props.router.location.pathname ==("/recruiters")})} >
                                Find a Recruiter
                            </Link>
                          
                        </div> */}
                    </div>

                    {/* <Link to="/guides" className={classNames("NavLink", {active: this.props.router.location.pathname === "/guides"})} >
                        Guides
                    </Link>
                    <Link to="/tools" className={classNames("NavLink", {active: this.props.router.location.pathname === "/tools"})}>
                        Tools
                    </Link> */}
                    <Link to="/architectural-career-coaching" className={classNames("NavLink", {active: this.props.router.location.pathname.startsWith("/architectural-career-coaching")})}>
                        Coaching
                    </Link>
                    <Link to="/hiring" className={classNames("NavLink", {active: this.props.router.location.pathname.startsWith("/hiring")})} >
                        Hiring Hub 
                        {/* <span class="NewHighlight" >New!</span> */}
                    </Link>                   
                    <Link to="/post-job" className={classNames("NavLink", {active: this.props.router.location.pathname.startsWith("/post") || this.props.router.location.pathname.startsWith("/post-job")})}>
                        <Button btnClassName={"JobAlertNavbar"}>Submit a Job</Button>
                    </Link>
                    {/* <div className="NavbarButtonContainer">
                        <Button btnClassName={"JobAlertNavbar"}>Submit a Job z<IoMdNotificationsOutline className="AlertIcon"/></Button>
                    </div> */}
                </div>
                <div className="CollapseMenuContainer">
                    <MdMenu className="CollapseMenu" onClick={() => {this.toggleMenu()}}/>
                </div>
                
            </div>
            {/* <div className="NavbarBanner">
            <a href="https://lu.ma/tvvdfo04" target="_blank" rel="noopener">Join Us on Sunday, 4th August | Online Masterclass - LinkedIn for Beginners: How to Start, Post & Grow Your Network | Get Your Ticket</a>
            </div> */}
            <div className="NavbarBanner">
            <a href="https://www.archjobs.co.uk/architectural-career-coaching" target="_blank" rel="noopener">Please note: 15 Min Calls and Coaching Packages can only be scheduled from Monday, 9th September onwards</a>
            {/* <a href="https://www.archjobs.co.uk/architectural-career-coaching" target="_blank" rel="noopener">NEW: Updated Coaching Packages | Covering CV, Portfolio, and Strategy Calls for Both UK and Dubai</a> */}

            </div>
        </div>
      )
    }
  }

  export default withRouter(Navbar)