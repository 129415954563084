import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelectChip(props) {
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const newValue = typeof value === 'string' ? value.split(',') : value
    setPersonName(
      // On autofill we get a stringified value.
      newValue
    );
    props.updateMultiSelectValue(props.id, newValue)
  };

  const handleDelete = (value) => {
    console.log("handle delete: ", value)
    const newValues = [...props.value];
    newValues.splice(newValues.indexOf(value), 1);
    setPersonName(
      // On autofill we get a stringified value.
      newValues
    );
    props.updateMultiSelectValue(props.id, newValues)
  };

  return (
        <Select
          labelId="job-title-tags"
          id={props.id}
          className="PostJobInput"
          multiple
          value={("value" in props)? props.value: personName}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }} >
              {selected.map((value) => (
                <Chip key={value} label={value} onDelete={() => handleDelete(value)}  classes={{root: props.values.includes(value) ? '' : 'invalid-chip'}} onMouseDown={(event) => {
                  event.stopPropagation();
                }}/>
              ))}
            </Box>
          )}
          MenuProps={MenuProps}        >
          {props.values.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, personName, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
  );
}