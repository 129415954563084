
import React from 'react'
import classNames from 'classnames';
import '../css/navbar.css'
import {BsLinkedin, BsTwitter, BsFillBellFill} from 'react-icons/bs'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import ArticleData from "../articles/ArticleData"
// import { roles } from "./SalaryGuide"
  const Jobs =  ['Architect', "Architectural Technologist",'Associate Architect', 'BIM Manager', 'BIM Coordinator/Consultant', 'Director', 'Landscape Architect', 'Part 1 Architectural Assistant', 'Part 2 Architectural Assistant', "Part 3", 'Project Architect', 'Senior Architect', "Senior Architectural Technologist", "Senior Landscape Architect", "Urban Designer"]

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: "",
            open: false,
        }
      }

    render() {

        var link_list = []

        // const sortedJobs = Jobs.sort()
        // sortedJobs.forEach(Job => {
        //     link_list.push(<Link to={"/search/"+Job.replace(/[\W_]+/g, "-")+"-Jobs-in-United-Kingdom"} className="Link">{Job} Jobs</Link>)
        // })
        // article_title: "A Guide to Answering Common Interview Questions and Understanding Their Purpose",
        // article_description: "Whether you are just starting your job search or have been through several interviews, it's helpful to know what to expect. In this article, we will cover some of the most common interview questions that you may be asked, along with tips for answering them effectively. From discussing your strengths and weaknesses to describing your approach to handling stress and conflict, these questions are designed to give the interviewer a sense of your skills and fit for the role.",
        // article_url: "https://www.archjobs.co.uk/articles/how-to-answer-common-interview-questions",
        // console.log(ArticleData)
        let article_list = []
        for (const article in ArticleData.ArticleData){
            if (ArticleData.ArticleData[article].article_url && ArticleData.ArticleData[article].article_title !== "Title"){
                article_list.push(<Link to={ArticleData.ArticleData[article].article_url.replace("https://www.archjobs.co.uk", "")} className="Link ArticleLink">{ArticleData.ArticleData[article].article_title}</Link>)
            }
            
        }

        let salary_list = []


      return (
        <div className="Footer">
            <div className="FooterContainer" id="FooterContainer">
                <div className="FooterContent">
                    <div className="NavbarTitle">
                    <span className="ArchjobsTitle1">ArchJobs</span> 
                    <span className="ArchjobsTitle2">Recruitment</span> 
                    </div>
                </div>
                <div className="FooterSubtext">
                    {/* <span> All our data is gathered from publicly available sources or contributed by users </span> */}
                    <Link to="/privacy">Privacy Policy</Link>
                </div>
            </div>

        </div>
      )
    }
  }

  export default Footer