
import React from 'react'
import classNames from 'classnames';
import '../css/navbar.css'
import {BsPlus} from 'react-icons/bs'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
  import { Collapse } from '@mui/material';
  import tags from '../data/tags';
  import Masonry from 'react-masonry-css'


const Questions = [
    {"Number": 1, 
    "Question": "What are your criteria to be one of your Recruitment Experts?",
    "Answer": <div className="FAQAnswer">
    <p>You need to be a recruiter or agency who covers UK architecture roles (BE). I.e. Architect, Part 2 Architectural Assistant, BIM Manager, Project Architect, Landscape Architect, Urban Designer etc. </p>
    </div>},
    
    {"Number": 2, 
   "Question": "What is the minimum length of each package?",
    "Answer": <div className="FAQAnswer">
        <p>Each package requires a minimum subscription of 3 months. Building trust and growing your visibility take time, providing us with various opportunities for exposure.</p>
     </div>},

    {"Number": 3, 
    "Question": "How long does it take to see my profile on ArchJobs?",
    "Answer": <div className="FAQAnswer">
    <p>Once we, ArchJobs decided that you and/or your agency are a suitable fit for the Architectural Recruitment Experts It depends on how quickly you provide us with your profile details. On average, it takes 2-4 business days.</p>
    </div>},

    {"Number": 4, 
    "Question": "Monthly or upfront 3-month payment option available?",
    "Answer": <div className="FAQAnswer">
    <p>The choice is yours! You can opt to split the costs and pay month by month, or if you prefer, you have the flexibility to pay for the entire 3 months upfront.</p>
    </div>},
   
    {"Number": 5,
    "Question": "How active should I be on LinkedIn?",
    "Answer": <div className="FAQAnswer">
    <p>It varies based on the package. The "Find a Recruiter" suits those less active on LinkedIn.</p>
    <p>"Find a Recruiter+" benefits from an active profile, as we create monthly LinkedIn posts. </p>
    <p>For the packages "LinkedIn Growth" and "Max Exposure", a moderate to very active presence is required. To optimise these packages, aim for at least 2 to 3+ posts per week for us to engage and boost your visibility effectively.</p>
    </div>}, 
   
   
    {"Number": 6, 
    "Question": "How many Clients are you able to look after at one time?",
    "Answer": <div className="FAQAnswer">
        <p>We limit it to a maximum of 5 clients, with a waiting list available.</p>
        </div>},
    
    
    {"Number": 7,
    "Question": "How many visitors do you typically receive per month?",
    "Answer": <div className="FAQAnswer">
    <p>Currently we get over 5.960 unique users per month, and our user base is continuing to expand.</p>
    </div>},

    {"Number": 8,
    "Question": "How extensive are your LinkedIn profiles?",
    "Answer": <div className="FAQAnswer">
    <p><a href="https://www.linkedin.com/company/archjobsuk/" target="_blank" rel="noopener">ArchJobs’s LinkedIn</a> boasts over 4.1k+ followers, while <a href="https://www.linkedin.com/in/aylin-round/" target="_blank" rel="noopener">Aylin Round’s</a> profile has 8k+, with an average of 78k post impressions per week.</p>
    </div>},
   
    {"Number": 9, 
    "Question": "Payment Confirmation and Invoicing",
    "Answer": <div className="FAQAnswer">
        <p>We can send you an invoice as well as a confirmation of payment.</p>
    </div>},
 
    {"Number": 10, 
    "Question": "Renewal or change of Package",
    "Answer": <div className="FAQAnswer">
        <p>For renewal or package changes, just reach out to Aylin, and we'll transition you to a new plan.</p>
    </div>},

    {"Number": 11, 
    "Question": "Do you offer a Free Trial?",
    "Answer": <div className="FAQAnswer">
        <p>No, we do not offer a free trial.</p>
        </div>},

    {"Number": 12, 
    "Question": "What Payment Method can I use?",
      "Answer": <div className="FAQAnswer">
          <p>We use Stripe as our payment processor, allowing you to pay by card or any other payment method accepted by Stripe.</p>
          </div>},
]


class FAQRecruiter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: [],
            layout: "Default"
        };  
    }    
    
      componentDidMount = () => {
        window.addEventListener("resize", this.getSize.bind(this));

      }
    
      getSize = () => {
        var pagewidth = window.innerWidth
        var layout = "Default"
        if (pagewidth < 600){
          layout = "Mobile"
        }
        if (this.state.layout !== layout){
          this.setState({layout: layout})
        }
      }

      toggleQuestion = (num) => {
        const open = this.state.open
        const index = open.indexOf(num);
        if (index > -1) { // only splice array when item is found
            open.splice(index, 1); // 2nd parameter means remove one item only
        } else {
            open.push(num)
        }
        this.setState({open: open})
      }

    render() {

        var question_list = []

        Questions.forEach(Question => {
            question_list.push(
                <div className="FAQSection">
                    <div className="FAQQuestion" onClick={() => {this.toggleQuestion(Question["Number"])}}>
                        <BsPlus className="PlusIcon"/> {Question["Question"]}
                    </div>
                    <Collapse in={this.state.open.includes(Question["Number"])}>
                        {Question["Answer"]}
                    </Collapse>
                </div>)
        })


      return (
        <div className="TeamPage">
            <div className="FAQContainer">
                
                <div className="FAQContent">
                    <div className="FAQTitle">FAQ - For Architectural Recruiter</div>
                    <p>We've collected the answers to some of our frequently asked questions below. Hopefully this helps clarify more about our feature but as always, feel <a target="_blank" href="mailto:hello@archjobs.co.uk">free to reach out to us</a> directly if you have any further questions.</p>
                    {/* <div className="FAQSubTitle">We've collected the answers to some of our frequently asked questions below. Hopefully this helps clarifiy more about our service but as always, feel free to reach out to us directly if your question isn't covered below.</div> */}
                    <Masonry
                        breakpointCols={this.state.layout == "Mobile"? 1: 2}
                        className="my-masonry-grid"
                        columnClassName="my-masonry-grid_column">
                        {question_list}
                    </Masonry>
                    {/* <div className="FAQContact">
                        Can't find what you're looking for? <a href="mailto:hello@archjobs.co.uk">Contact</a> the ArchJobs team.
                    </div> */}
                    
                </div>
                <div className="FAQBackground"> </div>
            </div>
        </div>
      )
    }
  }

  class FAQRecruiterPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };  
    }    
    
    componentDidMount = () => {
        window.scrollTo(0, 0)
      }
      render() {


      return (
        <div className="TeamPage">
            <FAQRecruiter/>
            <div className="WhyWorkWithUsSection">
            <div className="CoachingPackageTitle">Still have a question? Reach out to us via email</div>
            <div className="WhyWorkWithUsSectionSubtitle">Drop us an email at <a href="mailto:hello@archjobs.co.uk">hello@archjobs.co.uk</a></div>
              {/* <div className="WhyWorthWithUsHeaderButton">
                <Link to="/post">
                  <Button btnClassName="PostJobButton">Contact Us</Button>
                </Link>
              </div> */}
            </div>
            <div className="BottomPadding"></div> 
        </div>
      )
    }
  }

  export default FAQRecruiterPage
  export {FAQRecruiter}