import React from 'react'
import withRouter from './withRouter.js';
import GA4React from 'ga-4-react'

class GoogleAnalytics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.ga4 = null;
      }

      componentDidMount() {
        try {
            setTimeout(_ => {
              const ga4react = new GA4React("G-V545RHHH1M");
              ga4react.initialize().then(ga4 => {
                  this.ga4 = ga4
              })
              .catch(err => console.error(err));
            }, 1000);
          } catch (err) {
                console.error(err);
          }
      }

      componentDidUpdate = (prevProps, prevState) => {
          if (this.props.router.location.pathname !== prevProps.router.location.pathname){
            if (this.ga4 !== null){
                this.ga4.pageview(this.props.router.location.pathname);
              }
          }
      }

    render() {
        return null
    }
  }

  export default withRouter(GoogleAnalytics)