import React, {Component} from 'react'
import '../css/recruiter.css'
import { Collapse } from '@mui/material'
import withRouter from '../withRouter';

import {
    Navigate,
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import Button from './Buttons.js'
import Autocomplete from '@mui/material/Autocomplete';
import tags from '../data/tags.js'
import Quill from 'quill';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import {MdClose} from 'react-icons/md';
import Checkbox from '@mui/material/Checkbox';
import MultipleUpload from './MultipleUpload'
import axios from 'axios';
import { BsChevronLeft } from 'react-icons/bs';
import { Helmet } from 'react-helmet';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const checkoutLinks = {
  "basic": "https://buy.stripe.com/fZeeVpf3v4Wg2Fa3cc",
  "standard": "https://buy.stripe.com/fZefZtbRj3Sc1B6cMN",
  "premium": "https://buy.stripe.com/8wMcNh2gJfAU5RmdQS"
}

const RecruiterAnalyticsUrl = "https://api.archjobs.co.uk/recruiter_analytics"

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <MdClose />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};



class RecruiterApply extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            job_submission_status: {},
            success_open: false,
            redirect: false,
            email_sent: false
        }
        this.quill = null
        this.multiSelectValues = {}
        this.files = []
      }    
    
      componentDidMount = () => {
        if (!this.quill){
          this.quill = new Quill('#jobdescriptioninput', {
            theme: 'snow',
            formats: [
              'bold',
              'header',
              'italic',
              'link',
              'list',
              'blockquote',
              'image',
              'indent'
            ],
            modules: {
              toolbar: [
                [{'header': 1}, {'header': 2}],
                ['bold', 'italic', 'link'],
                ['blockquote', {'list': 'ordered'}, {'list': 'bullet'}],
              ],
              clipboard: {
                matchVisual: false // https://quilljs.com/docs/modules/clipboard/#matchvisual
              }
            }
          });
        }
        if (window.location.search){
          var urlParams = new URLSearchParams(window.location.search);
          var SelectedPackage = urlParams.get('package')
          if (SelectedPackage){
            this.setState({package: SelectedPackage})
          }
        }
        window.scrollTo(0, 0)
      }

      updateMultiSelectValue = (id, value) => {
        this.multiSelectValues[id] = value
      }

      updateFiles = (files) => {
        this.files = files
      }

      Submit = () => {
        console.log("submitting")
        if (this.state.job_submission_status.status == "loading"){
          console.log("Already submitting")
          return
        }
        if (this.state.email_sent){
          console.log("Already Sent an email")
          return
        }
        this.setState({job_submission_status: {status: "loading"}, })
        const recruiter = this.props.router.location.state.recruiter

        const fields = [
            {id: "name", field_name:"contact_name", required: true, label: "Contact Name"},
            {id: "phone", field_name:"phone", required: true, label: "Contact Number"},
            {id: "email", field_name:"email", required: true, label: "Email Address"},
            {id: "job_title", field_name:"job_title", required: true, label: "Job Title"},
            {id: "job_location", field_name:"job_location", required: true, label: "Job Location"},
            {id: "job_type", field_name:"job_type", required: true, label: "Job Type"},
          ]

          var jobSubmission = new FormData()
      
          for (const field of fields){
            var input = document.getElementById(field.id)
      
            if (input != null){
              if (field.type == "checkbox"){
                jobSubmission.append(field.field_name, input.checked ? 1: 0)
              } else if(field.type == "numeric") {
                var int_value = parseInt(input.value)
                if (int_value < 0){
                  int_value = null
                }
                jobSubmission.append(field.field_name, int_value)

              } else {
                if ((input.value == "") && (field.required == true)){
                  this.setState({job_submission_status: {status: "error", message: "Required field "+field.label+" not filled out", field: field.id, field_message: "Required Field"}})
                  return
                } else {
                  if (field.id == "job_title"){
                    if (recruiter.role_blacklist){
                      let role_blacklist = recruiter.role_blacklist.split(",")
                      if (role_blacklist.includes(input.value)){
                        this.setState({job_submission_status: {status: "error", message: "This recruiter does not cover this role"}})
                        return
                      }
                    }
                  }
                  jobSubmission.append(field.field_name, input.value)
                }
              }
            } else {
              console.log("Couldnt get input: ", field.id)
            }
        }
    
        //job description
        if (this.quill){
            var job_description_content = this.quill.root.innerHTML
            // console.log(job_description_content)
            if (job_description_content !== "<p><br></p>"){
                jobSubmission.append("job_description", job_description_content)
            }
        }
        this.files.forEach(file => {
            jobSubmission.append("file",file)
        })


        if (recruiter.recruiter_name){
          jobSubmission.append("hiring_name", recruiter.recruiter_name)
        }
        if (recruiter.recruiter_email){
            jobSubmission.append("apply_url", recruiter.recruiter_email)
        }
        if (recruiter.recruiter_id){
            jobSubmission.append("recruiter_id", recruiter.recruiter_id)
        }

        console.log(jobSubmission)
        const SubmitUrl = "https://api.archjobs.co.uk/recruiter_send" 



        axios({
                url: SubmitUrl,
                method: "POST",
                data: jobSubmission,
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
            console.log(response.data)
            if ("error" in response.data){
                this.setState({job_submission_status: {status: "error", message: "Could not submit form, please email us at hello@archjobs.co.uk and let us know!"}})
            } else {
                this.setState({success_open: true, job_submission_status: {status: "success"}, email_sent: true})
                setTimeout(() => {
                    this.setState({redirect: true})
                  }, 3000);
                const recruiter_id = recruiter.recruiter_id
                axios.post(RecruiterAnalyticsUrl+`?recruiter_ids=${recruiter_id}&event=application&page=recruiter_apply`).then(response => {
                  console.log("Analytics response: ", response.data)
                }).catch(error => {
                    console.log(error)
                })
            }
          }).catch(error =>{
            this.setState({job_submission_status: {status: "error", message: "Could not submit form, please email us at hello@archjobs.co.uk instead"}})
          })
      }

      handleClose = () => {
        this.setState({success_open: false})
      }

      handlePackageChange = (newPackage) => {
        this.setState({package: newPackage})
      }

    render() {

    var error_text = ""
    
    console.log(this.props)

    if (!(this.props.router && this.props.router.location && this.props.router.location.state && this.props.router.location.state.recruiter)){
        return <Navigate to="/recruiters" />
    }
    const recruiter = this.props.router.location.state.recruiter

    let location_options = []
    if (recruiter.locations){
      location_options = recruiter.locations.map((location) => {
        return location.city
      })
    }
    if (recruiter.parsed_tags.region){
      recruiter.parsed_tags.region.forEach(location =>{
        if (!location_options.includes(location)){
          location_options.push(location + " (Flexible)")
        }
      })
    }
    location_options.push("Anywhere (Flexible)")
    location_options.push("Remote Only")
    location_options.push("Other (Please Specify)")
    let role_blacklist = []
    if (recruiter.role_blacklist){
      role_blacklist = recruiter.role_blacklist.split(",")
    }
    console.log("role_blacklist", role_blacklist)
      return (
        <div className="PostJobPage">
              <Helmet>
                <meta charSet="utf-8" />
                <title>ArchJobs - Work with {recruiter.recruiter_name}</title>
                <meta name="robots" content="noindex"/>
              </Helmet>
            <Link className="ResultsGoBack" to={-1} ><BsChevronLeft className="BackArrow"/>Go Back</Link>
            {/* <div className="PostJobFormContainer"> */}
                <div className="PostJobForm">
                    <div className="PostJobFormHeader"> 
                        Enquiry Form: {recruiter.recruiter_name}
                    </div>
                    <div className="PostJobFormText"> 
                        <strong>Important:</strong> Candidates <u>must</u> have the right to work in the UK. Thank you for your cooperation.
                        {/* We will review whether your company/practice is a good fit for our job board, and someone from the team will get in touch ASAP.   */}
                    </div>
                    {/* <div className="PostJobFormNotice"> 
                        Already a client? Please email <a href="mailto:hello@archjobs.co.uk">hello@archjobs.co.uk</a>.
                    </div> */}
                    {/* <div className="PostJobFormText"> 
                        Please note ArchJobs is a <b>curated job board</b>, we can only post job adverts from Architectural and Multi-Disciplinary Companies.
                    </div> */}
                    <div className="PostJobFormDivider"></div>
                    <div className="PostJobFormHeader">
                        Your Contact Details
                    </div>
                    <div className="PostJobFormInputRow">
                        <div className="PostJobFormInputTitle">Your Name</div>
                        <TextField
                            variant="outlined"
                            id="name"
                            className="PostJobInput"
                            type="text"
                            placeholder="Full Name"
                            error={this.state.job_submission_status.field == "name"}
                            helperText={(this.state.job_submission_status.field == "name") && this.state.job_submission_status.field_message}
                            />
                    </div>
                    <div className="PostJobFormInputRow">
                        <div className="PostJobFormInputTitle">Contact Number</div>
                        <TextField
                            variant="outlined"
                            id="phone"
                            className="PostJobInput"
                            type="text"
                            placeholder="Contact Number"
                            error={this.state.job_submission_status.field == "phone"}
                            helperText={(this.state.job_submission_status.field == "phone") && this.state.job_submission_status.field_message}
                            />
                    </div>
                    <div className="PostJobFormInputRow">
                        <div className="PostJobFormInputTitle">Email Address</div>
                        <TextField
                            variant="outlined"
                            id="email"
                            className="PostJobInput"
                            type="email"
                            placeholder="Contact Email"
                            error={this.state.job_submission_status.field == "email"}
                            helperText={(this.state.job_submission_status.field == "email") && this.state.job_submission_status.field_message}
                            />
                    </div>
                    <div className="PostJobFormDivider"></div>
                    <div className="PostJobFormHeader">
                        Reason for Contacting
                    </div>
                    <div className="PostJobFormText"> 
                    Whether you are looking for a new position or looking to hire, please provide some details below. Please note, each recruiter focuses on a specific area/location. Make sure they are able to help you.
                    </div>
                    <div className="PostJobFormInputRow">
                        <div className="PostJobFormInputTitle">Job Title</div>
                        <Autocomplete
                            required
                            id="job_title"
                            freeSolo
                            className="PostJobInput"
                            options={tags.JobTitles}
                            renderOption={(props, option) => { 
                              if (role_blacklist.includes(option)){
                                return <div className="AutocompleteOptionSeparated AutocompleteOptionDisabled" {...props} value={option}><div className="AutocompleteOptionDisabled" value={option}>{option}</div><div className="OptionTagType">Not Covered</div></div>
                              }
                              return <div className="AutocompleteOptionSeparated" {...props} value={option}>{option}</div>
                            }}
                            renderInput={(params) => <TextField {...params} placeholder="Your desired Job Title" />}
                        />
                    </div>
                    <div className="PostJobFormInputRow">
                        <div className="PostJobFormInputTitle">Job Location(s)</div>
                        <Autocomplete
                            required
                            id="job_location"
                            freeSolo
                            className="PostJobInput"
                            options = {location_options}
                            renderInput={(params) => <TextField {...params} placeholder="Please provide cities or regions you would like to work" />}
                            />
                    </div>
                    <div className="PostJobFormInputRow">
                        <div className="PostJobFormInputTitle">Job Type</div>
                        <Autocomplete
                            required
                            id="job_type"
                            freeSolo
                            className="PostJobInput"
                            options={tags.JobType}
                            renderInput={(params) => <TextField {...params} placeholder="Full time, part time or contract?" />}
                        />
                    </div>
                    <div className="PostJobFormInputRow">
                        <div className="PostJobFormInputTitle">Further Details <div className="JobSubmissionsubtitle">Provide the recruiter with some further info.</div> </div>
                        <div className="JobDescriptionInputContainer">
                            <div id="jobdescriptioninput" className="JobDescriptionInput" ></div>
                        </div>
                    </div>
                    <div className="PostJobFormInputRow">
                        <div className="PostJobFormInputTitle">Upload CV & Sample Portfolio (optional) <div className="JobSubmissionsubtitle"></div></div>
                        <div className="JobDescriptionInputContainer">
                            <MultipleUpload updateFiles = {this.updateFiles}/>
                        </div>
                    </div>
                    
                    <div className="PostJobFormSubmit"> 
                        <Button btnClassName="JobAdHeaderApplyButton" onClickFunc = {() => {this.Submit()}}>Submit Enquiry</Button>
                    </div>
                    <div className="PostJobFormError"> 
                        {(this.state.job_submission_status.status == "error") && this.state.job_submission_status.message}
                    </div>
                {/* </div> */}
            </div>
            <p><strong>Disclaimer:</strong> If you require UK sponsorship or a UK visa, please note that our recruiters are unfortunately not in a position to support you. Please refer to our Career Guide: <a target="_blank" href="https://www.archjobs.co.uk/articles/moving-to-the-uk-as-an-overseas-architect?utm_source=ArchJobs&amp;utm_medium=FAR">Moving to the UK as an Overseas Architect</a> for some useful tips.</p>
            <BootstrapDialog
                onClose={this.handleClose}
                aria-labelledby="customized-dialog-title"
                open={this.state.success_open}
                className="SuccessModal"
            >
                <div className="ModalClose"><MdClose className="CloseIcon" onClick={this.handleClose}></MdClose></div>
                <div className="SuccessModalTitle">Success! </div>
                <div className="SuccessModalText">We've sent your details to {recruiter.recruiter_name} <p/>Taking you back to the recruiter page...</div>
                                          
            </BootstrapDialog>
            {this.state.redirect && <Navigate to="/recruiters" />}
        </div>
      )
    }
  }

export default withRouter(RecruiterApply)

