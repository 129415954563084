import HiringGuideFrame from "./HiringGuideFrame";
import React, { Component } from 'react';


export default class HiringGuideArchitect extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
      };
    }
    componentDidMount = () => {
      window.scrollTo(0, 0)
    }

    render(){
        return <HiringGuideFrame ArticleDataName="HiringGuideArchitect" >
          <p>If you are a practice owner or team leader who has identified the need to hire an architect due to an increase in workload or perhaps because someone from your current team is leaving, it is important to take a step back and consider some areas before writing your job description. In this guide, we will go through various sections, such as what exactly you are looking for in an architect, what is the salary range for an architect in the UK, how to write an advert that attracts architects, where to post your job advert, and so on.</p> 
          <p/>
          <p/>
          <h2>The Structure of this Guide</h2>
            <ol>
              <li>Job Description vs. Job Advert</li>
              <li>Defining Expectations</li>
              <li>Best Ways to Advertise Your Architect Job</li>
              <li>Salary Ranges for Architects in the UK</li>
              {/* <li>Which Details Matter in a Job Ad</li> */}
              <li>Important Benefits for Architect Professionals</li>
              <li>How to Structure Your Architect Job Advert</li>
              <li>Example of an Architect Job Advert</li>
              <li>Frequently Asked Questions</li>
            </ol>
          <p/>
          <p/>
          <h2>Job Description vs. Job Advert</h2>
          <p>A job advert and a job description are two different things, although they are often used together in the hiring process.</p>
          <p>A <strong>job advertisement</strong> is a public notice or announcement that a company creates to attract potential candidates for an available job position. It typically provides a brief overview of the job, including key responsibilities, qualifications, and any other relevant information, such as salary and benefits. The goal of a job ad is to generate interest in the position and encourage qualified candidates to apply.</p>
          <p>On the other hand, a <strong>job description</strong> is a more detailed document that outlines the duties, responsibilities, and requirements of a specific job position. It is often used internally by HR departments and managers to define job roles and responsibilities, set performance expectations, and evaluate employee performance.</p>
          <p>While a job advert is designed to attract potential candidates, a job description is used to provide a clear understanding of what is expected of the person who will be filling the position. A job ad may include some information from the job description, but it is typically shorter and more focused on attracting candidates.</p>
          <p/>
          <p/>
          <h2>Defining Expectations</h2>
          <p>While it may seem obvious that you're looking for an architect, take a moment to delve into the specifics. Do you need someone with strong design skills, capable of representing your firm to clients? Or perhaps, an architect with robust technical skills who can also mentor junior staff? On the other hand, are you open to hiring a recently qualified architect, and do you have the capacity to gradually introduce them to the workload and potentially provide training on specific software?</p>
          <p/>
          <p/>
          <h2>Best Ways to Advertise Your Architect Job</h2>
          <p>There are several ways to advertise your Architect job. You can use job boards, social media channels, leverage your professional network, utilise your practice's website, employee referral programmes, or work with an architectural recruiter.</p>
          <p>Let's explore the pros and cons of each option.</p>
          <p/>
          <h3>Job Boards</h3>
          <p>Just to clarify, a job board is an online platform where employers post job opportunities and prospective employees can search and apply for those positions.</p>
          <p>Pros</p>
          <ul>
            <li>Wide Reach: Reaches a diverse audience quickly</li>
            <li>Time Efficiency: Allows for quick role posting</li>
            <li>Diverse Candidates: Attracts talent from various backgrounds</li>
            <li>Cost-Effective: Typically charges per advert/listing</li>
          </ul>
          <p>Cons</p>
          <ul>
            <li>High Volume of Applications: May receive irrelevant applications</li>
            <li>Competition: Your advert may get lost among numerous others</li>
            <li>Quality of Candidates: Non-specialized boards might attract less suitable candidates</li>
            <li>Time Restriction: Usually limited to 28-30 days</li>
          </ul>
          <p><u>My recommendation</u>: Personally, I believe using a job board is an excellent way to kickstart the hiring process and enhance your company's visibility. However, it's crucial to carefully choose the right job boards. Since you are seeking an Architect, I recommend using job boards specifically tailored to the architectural sector. They are likely to attract a more relevant target audience compared to broader, mainstream job boards like Indeed and Glassdoor.</p>
          <p>Here are some architectural-focused job boards to consider:</p>
          <ul>
             <li><a target="_blank" href="https://www.archjobs.co.uk/">ArchJobs</a>: Covers the whole of the UK</li>
            <li>RIBA: Covers the whole of the UK</li>
            <li>RIAS: Covers Scotland</li>
            <li>Dezeen: Worldwide</li>
            <li>BD4Jobs: Covers the whole of the UK but is mostly used by recruitment agencies</li>
          </ul>
          <p/>
          <h3>Social Media Channels</h3>
           <p>Most practices maintain a presence on platforms such as LinkedIn, Instagram, and/or Twitter/X to bolster their company brand. They use these channels to showcase ongoing projects and, for some, as a robust hiring tool, offering insights into their company culture.</p>
          <p>Pros</p>
          <ul>
            <li>Targeted Audience: Reaches followers interested in your brand</li>
            <li>Cost-Effective: Posting is free</li>
            <li>Engagement: Allows for interaction and sharing, increasing visibility</li>
            <li>Brand Visibility: Boosts your company's online presence</li>
          </ul>
          <p>Cons</p>
          <ul>
            <li>Poor Online Presence: Requires an active and engaged network</li>
            <li>Easily Overlooked: There's a risk that your posts may be scrolled past by users, reducing visibility and potentially reducing the chances of attracting attention.</li>
            <li>Quality of Candidates: May attract applicants who don't thoroughly read the job details</li>
          </ul>
          <p><u>My recommendation</u>: If you have a strong brand and are active on any social channel, I highly suggest creating multiple posts to announce your hiring needs to your network. However, if you lack social engagements and aren't active yourself, consider if this avenue is worthwhile. Additionally, posting multiple times is essential, as it's easy for people to scroll past your initial announcement. Experiment with different post ideas, and over time, you'll discover what resonates best.</p>
          <p>In the image below you can see that <a target="_blank" href="https://www.linkedin.com/company/archjobsuk/">ArchJobs</a> is promoting LSI Architects Project Architect role in London. <a target="_blank" href="https://www.linkedin.com/feed/update/urn:li:activity:7148961377347444737">The LinkedIn post</a> has received over 30 likes, 5 reshares, and nearly 4,000 impressions.</p>
          <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/li_post_smaller.png" alt="Screenshot of a LinkedIn post from ArchJobs promoting LSI Architects Project Architect roles in London. It has received over 30 likes, 5 reshares, and nearly 4,000 impressions."></img>
          <p/>
          <h3>Using Company Website</h3>
           <p>Typically, the larger the architectural practice, the more impressive their website (websites can be expensive, costing £30k+). However, regardless of size, every company should cover the basics because job seekers often visit your website to learn about the team, ongoing projects, career opportunities, and the overall company culture.</p>
          <p>Pros</p>
          <ul>
            <li>Targeted Audience: Visitors likely interested in architecture and your brand</li>
            <li>Cost-Effective: Updating the website is usually inexpensive</li>
            <li>Comprehensive Information: Offers insights into your company culture, projects, team and more</li>
          </ul>
          <p>Cons</p>
          <ul>
            <li>Limited External Reach: Depends on visitors finding the role</li>
            <li>Success Depends on Traffic: Requires sufficient website visitors for effective results</li>
            <li>Limited Social Sharing: Relies on existing visitors for sharing</li>
          </ul>
          <p><u>My recommendation</u>: If you have an up-to-date website with information on your sectors/projects, team members, benefits, company culture, etc., and you consistently attract at least 50-100+ visitors monthly, then go ahead and upload your job advert there. It can effectively guide job seekers to learn more about your practice and encourage them to apply. However, if your website hasn't been updated in years and doesn't receive many visitors, it could potentially harm your image as it won't leave a great first impression.</p>
          <p/>
          <h3>Employee Referral Programme</h3>
           <p>An Employee Referral Programme is a structured initiative implemented by a company to encourage its current employees to recommend qualified candidates for job openings within your practice.</p>
           <p>Pros</p>
          <ul>
            <li>Quality Hires: Employee-referred candidates are pre-vetted</li>
            <li>Cost-Effective: Economical way to attract skilled professionals</li>
            <li>Faster Recruitment: Speeds up talent acquisition</li>
          </ul>
          <p>Cons</p>
          <ul>
            <li>Limited Diversity: May lead to a lack of diversity</li>
            <li>Potential for Nepotism: Requires careful management to avoid bias</li>
            <li>Exclusion of External Talent: Heavy reliance on referrals may exclude external candidates</li>
          </ul>
          <p><u>My recommendation</u>: Using an employee referral programme can be highly effective in finding suitable new team members. However, its success depends on enticing incentives and whether your current team members would recommend your company to their network. As always, a healthy work culture is crucial.</p>
          <p/>
            <h3>Architectural Recruiter</h3>
            <p>Working with an architectural recruiter who knows the industry, has the right connections and a vast network of people, could be a quick and efficient way to hire an architect.</p>
          <p>Pros</p>
          <ul>
            <li>Expertise and Network: Taps into a vast network of industry professionals</li>
            <li>Pre-Scanning: Recruiters screen candidates before presenting them</li>
            <li>Market Insights: Offers valuable insights into market trends and candidate expectations</li>
            <li>Time Saving: Saves time throughout the recruitment process</li>
          </ul>
          <p>Cons</p>
          <ul>
            <li>Cost: Recruiter fees can be high, typically ranging from 12-20% per hire. Be prepared for an invoice that could fall between £3,840 to £6,400 + VAT.</li>
            <li>Limited Company Knowledge: Recruiters may lack an in-depth understanding of your company culture, impacting their ability to effectively sell the opportunity to job seekers.</li>
            <li>Lack of Time: Recruiters handle multiple clients simultaneously, potentially limiting the time they can spend researching candidates.</li>
          </ul>
          <p><u>My recommendation</u>: While working with a recruiter may not be suitable for every business, carefully weigh the potential costs before engaging one. If you decide to work with a recruiter, consider limiting it to no more than two, ensuring they specialise in the architectural sector. Engaging with too many recruiters can become messy and time-consuming.</p>
          <p>If you are struggling to find any good recruiters, check out our list of<a target="_blank" href="https://www.archjobs.co.uk/recruiters"> trusted architectural recruiters</a> (all recruiters and agencies are pre-vetted by <a target="_blank" href="https://www.archjobs.co.uk/">ArchJobs</a> to ensure the highest standard).</p>
          <p/>
          <p/>
          <h2>Salary Ranges for Architects in the UK</h2>
          <p>On average, an Architect in the UK earns around £37k per year. However, starting salaries for recently qualified Architects are typically £31k outside London and £35k in London. The size of the practice, location, and the nature of projects will also play a significant role, so please consider the numbers below as a general guideline.</p>
          <div>
          <table>
          <tbody>
          <tr>
          <td><strong>Job Title</strong></td>
          <td><strong>London</strong></td>
          <td><strong>Outside London</strong></td>
          </tr>
          <tr>
          <td><a href="https://www.archjobs.co.uk/salary-guide/part-1-architectural-assistant-salary" target="_blank" rel="noopener">Part 1 Architectural Assistant</a></td>
          <td>&pound;23.000 - &pound;25.000</td>
          <td>&pound;19.000 - &pound;22.000</td>
          </tr>
          <tr>
          <td><a href="https://www.archjobs.co.uk/salary-guide/part-2-architectural-assistant-salary" target="_blank" rel="noopener">Part 2 Architectural Assistant</a></td>
          <td>&pound;28.000 - &pound;33.000</td>
          <td>&pound;23.000 - &pound;26.000</td>
          </tr>
          <tr>
          <td><a href="https://www.archjobs.co.uk/salary-guide/part-3-salary" target="_blank" rel="noopener">Part 3&nbsp;</a></td>
          <td>&pound;30.000 - &pound;35.000</td>
          <td>&pound;28.000 - &pound;30.000</td>
          </tr>
          <tr>
          <td><a href="https://www.archjobs.co.uk/salary-guide/architect-salary" target="_blank" rel="noopener">Architect</a></td>
          <td>&pound;35.000 - &pound;40.000</td>
          <td>&pound;31.000 - &pound;37.000</td>
          </tr>
          <tr>
          <td><a href="https://www.archjobs.co.uk/salary-guide/project-architect-salary" target="_blank" rel="noopener">Project Architect</a></td>
          <td>&pound;39.000 - &pound;45.000</td>
          <td>&pound;36.000 - &pound;39.000</td>
          </tr>
          <tr>
          <td><a href="https://www.archjobs.co.uk/salary-guide/senior-architect-salary" target="_blank" rel="noopener">Senior Architect</a></td>
          <td>&pound;45.000 - &pound;55.000</td>
          <td>&pound;39.000 - &pound;43.000</td>
          </tr>
          </tbody>
          </table>
          </div>
          <p/>
          <p>Please note that salaries will vary between practices and locations, so consider this as a guideline. For more information, head over to our<a target="_blank" href="https://www.archjobs.co.uk/salary-guide/architect-salary"> Architectural Salary Guide</a>.</p>
          <img className="SalaryGuideImg" src="https://cdn.archjobs.co.uk/file/ArchJobs/backgrounds/ArchjobsSalaryGuideTiltedGrey700.png" alt="Architectural Salary Guide UK"/>
          <p/>
          <p/>
          {/* <h2>Which Details Matter in a Job Ad</h2>
          <p>When it comes to writing a job advert, it's important to keep in mind what job seekers are looking for. By understanding their needs and preferences, we can increase the likelihood that they will submit their application.</p>
          <p>So, what are job seekers searching for in an ad?</p>
          <p>Firstly, they want to know about the <strong>requirements of the role</strong>, including the necessary <strong>skills and qualifications</strong>. They also look for information on the <strong>specific responsibilities</strong> that come with the job. Furthermore, candidates are interested in the <strong>location of the position</strong>, as this can impact their decision to apply.</p>
          <p><strong>Salary information</strong> is a crucial factor for many job seekers. A study by <a href="https://www.reed.co.uk/recruiter-advice/how-to-attract-the-right-candidate-for-your-organisation/" target="_blank" rel="nofollow noopener">Reed</a> found that 78% of candidates are less likely to apply to a position if they don't know what the company is offering in terms of pay. It's therefore important to be transparent about the salary range for the role.</p>
          <p><strong>Benefits</strong> are also a key consideration. According to the same Reed survey, 23% of respondents stated that benefits are also an important factor when applying for a job. Be sure to highlight any perks that come with the position, such as health insurance, pension contributions, or flexible working arrangements.</p>
          <p>Finally, give candidates an insight into the <strong>company culture and values</strong>. This can help them to determine whether your practice is a good fit for them. Consider including information about the company's mission, vision, and any relevant awards or recognition they have received.</p>
          <p/>
          <p><strong>Just to summarise, job seekers want to see the following details in your ads:</strong></p>
          <div>
          <div>✔ Requirements of the role</div>
          <div>✔ Necessary skills and qualifications</div>
          <div>✔ Location of the position</div>
          <div>✔ Salary information</div>
          <div>✔ Benefits</div>
          <div>✔ Company culture and values</div>
         </div>
          <p/>
          <p/> */}
          <h2>Important Benefits for Architect Professionals</h2>
          <p>Before you start drafting your job advert, it's crucial to understand your target audience. Which position are you trying to fill? Keep in mind that filling a junior position is very different from filling a senior one.</p>
          <p>As you are trying to attract architects, think about what is important to them and add that in the job advert. They may live independently, value flexibility, prefer hybrid working arrangements, appreciate holiday allowances, and possibly have a young family or will start a family in the coming years. They will also care about pension plans and the potential for growth within your practice.</p>
          <p>According to a survey by <a href="https://www.linkedin.com/company/perkbox/" target="_blank" rel="nofollow noopener">Perkbox</a> of 1,532 UK employees, each generation prioritises different benefits. There are four main types: Baby Boomers, Generation X, Millennials, and Generation Z. Since you are hiring an architect, your focus is likely on Millennials or Generation X. Let's explore what matters to them:</p>
          {/* <p><strong>Baby boomers (born between 1946-1964)</strong></p>
          <ul>
          <li>health insurance</li>
          <li>retirement and pension planning</li>
          <li>flexible working to help with caring duties</li>
          </ul> */}
          <p><strong>Generation X (born between 1965-1980)</strong></p>
          <ul>
          <li>additional holiday</li>
          <li>flexible working</li>
          <li>childcare assistance</li>
          <li>wellbeing coaching</li>
          <li>health insurance</li>
          </ul>
          <p><strong>Millennials (born between 1980-1995)</strong></p>
          <ul>
          <li>working from home or remotely</li>
          <li>personal recognition for their efforts</li>
          <li>opportunities to travel or work abroad</li>
          <li>free meals at work</li>
          <li>gym discounts</li>
          <li>casual dress code</li>
          </ul>
          {/* <p><strong>Generation Z (born after 1996)</strong></p>
          <ul>
          <li>casual dress code</li>
          <li>health insurance</li>
          <li>mental health services</li>
          <li>a company smartphone or laptop</li>
          <li>flexible hours</li>
          <li>free coffee or meals</li>
          </ul> */}
          <p/>
          <p/>
          {/* <h2>How to make your job advert stand out</h2>
          <p>If you are struggling to make your job advert stand out in the crowded online space, then this section is for you. With so many positions being advertised, it's important to make your job advert eye-catching and easily understandable</p>
           <p>Here are some tips to help you achieve that:</p>
          <ul>
          <li>Choose the right job title that accurately reflects the position and aligns with industry standards - Your ad should ideally say “Architect” or “ARB Architect”</li>
          <li>Keep the length of your ad between 300 and 600 words, as this is a comfortable reading length.</li>
          <li>Clearly state what you're looking for in an Architect, including necessary qualifications, skills, and experience</li>
          <li>Avoid using jargon or complex sentences that may be difficult to understand.</li>
          <li>Use bullet points to break up the text (about 25% of your ad should be in bullet points).</li>
          <li>Ensure that your ad is easy to scan quickly by using subheadings, short paragraphs, and bold text to emphasise key points.</li>
          <li>Check for gender bias in your ad, using available resources to ensure that it is inclusive and non-discriminatory. I personally like to use <a target="_blank" href="https://www.totaljobs.com/insidejob/gender-bias-decoder/">the gender bias decoder</a>by TotalJobs.</li>
          </ul>
          <p>By keeping these generational differences in mind, you can tailor your job advert to better appeal to your target audience and attract the right candidates for the job.</p>
          <p/>
          <p/> */}
          <h2>How to Structure Your Architect Job Advert</h2>
          <p>Writing and structuring an effective job advert is luckily not that difficult. Most people will only scan ads and won't read every single word (just like people don't read every single word in a CV). Therefore, it's important to break up the text. Avoid long sentences and aim for 13-18 words per sentence. Use bullet points when listing requirements and benefits. Provide a clear call to action and ideally, inform the candidate about your interview process.</p>
          <p/>
          <p><strong>Position and Location</strong></p>
          <p>Be clear about the job title and office location. It's important to let job seekers know where your office is located so they can consider if the commute is feasible. Use the correct job title and avoid abbreviations.&nbsp;</p>
          <p>For example, instead of "Snr Architect", write "Senior Architect".</p>
          <p/>
          <p><strong>About the Company</strong></p>
          <p>Tell the candidate a little about your company, what you're working on, your mission, recent awards and what's important to you. I suggest keeping this down to one short paragraph. If the job seeker is interested to find out more, they will check out your website and social media accounts.</p>
          <p/>
          <p><strong>About the Role</strong></p>
          <p>Explain the role in detail, including what the candidate would work on, any particular sectors, and a couple of responsibilities. Use "you will" or "you would" instead of "the candidate would" to make the job sound more appealing. Be careful to not overload job seekers with too much info.</p>
          <p>A <a href="https://www.forbes.com/sites/jackzenger/2018/04/08/the-confidence-gap-in-men-and-women-why-it-matters-and-how-to-overcome-it/" target="_blank" rel="nofollow noopener">Hewlett Packard report</a> states that men will apply for a job if they meet 60% of qualifications, yet women will only apply if they feel like they meet 100% of them</p>
          <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/HBR.png" alt="Stats from: Why women don't apply for jobs unless they're 100% qualified by Tara Sophia Mohr"></img>
          <p>Source: <a href="https://hbr.org/2014/08/why-women-dont-apply-for-jobs-unless-theyre-100-qualified" target="_blank" rel="nofollow noopener">HBR</a></p>
          <p/>
          <p><strong>Skills, Experience, and Qualifications (use bullet points)</strong></p>
          <p>List the most important requirements, but keep them to a minimum. Don't list every single one. Adding more than seven role responsibilities would discourage a reported <a href="https://insightsresources.seek.com.au/candidates-look-job-ad" target="_blank" rel="nofollow noopener">69% of candidates</a> from applying. If you have to, you can have two separate sections, one for "Requirements" and the other for "Desirable Skills".</p>
          <p/>
          <p><strong>Pay and Benefits</strong></p>
          <p>As mentioned earlier, 78% of job seekers are less likely to apply to a position if they don't know the company's salary, and 23% said benefits are one of the most important factors when applying for a job.&nbsp;</p>
          <p>Always aim to provide a salary range and list at least 3-4 benefits, such as clear career progression, paid membership fees, hybrid working, enhanced parental leave, etc.&nbsp;</p>
          <p>Additionally, mention the working hours, as offering a <a href="https://jobadder.com/blog/top-employee-retention-strategies" target="_blank" rel="nofollow noopener">good work-life balance</a>&nbsp;is significantly important to candidates, with 51% of job seekers attracted to a job because it offers flexible hours.&nbsp;</p>
          <p>Avoid listing benefits that are the legal requirement, such as 20 days plus bank holidays in the UK, as they are not worth highlighting in the ad.</p>
          <p/>
          <p><strong>How to Apply or Call to Action (CTA)</strong></p>
          <p>Explain how the candidate can apply and which documents they need to submit. Always include a clear call to action, whether they need to submit their details via email, recruitment portal, or the Apply Now button below.&nbsp;</p>
          <p>If job seekers have to apply via your recruitment portal, explain what they can expect, as they dislike applying via internal portals since they never know how long it will take (10, 20, 30min?).&nbsp;</p>
          <p>For example, "Please submit your CV and Sample Portfolio (max 10MB) via the Apply Now button below."</p>
          <p/>
          <p/>
          <h2>Example of an Architect Job Advert</h2>
          <p><em>ArchJobs, a dynamic and sociable team of 8 architects and technicians based in central Edinburgh, is seeking a talented Architect to join our creative studio.</em></p>
          <p><em>Established since 2017, we have been working on high-end residential schemes across the city, and due to our success in winning multiple new projects, we're looking for an ARB registered Architect to help us continue our growth.</em></p>
          <p><strong><em>About the Role</em></strong></p>
          <p><em>We're offering an opportunity for an experienced Architect to join us in Edinburgh and work on high-end residential projects. You will be responsible for overseeing 2-3 projects at the same time, visiting sites as needed, and producing technical detailing.</em></p>
          <p><strong><em>To be successful in this role you must have:&nbsp;</em></strong></p>
          <ul>
          <li><em>ARB registration</em></li>
          <li><em>2 years post qualification experience</em></li>
          <li><em>Experience working on residential schemes</em></li>
          <li><em>Good Revit &amp; Adobe Creative Suite skills</em></li>
          <li><em>Able to produce Tender and Construction packages&nbsp;</em></li>
          <li><em>Interpersonal skills and ability to build long lasting relationships</em></li>
          <li><em>Communication and presentation abilities&nbsp;&nbsp;</em></li>
          <li><em>A good eye for design detail &amp; creativity</em></li>
          </ul>
          <p><em>Please note you must be eligible to work and live in the UK without any additional approval.</em></p>
          <p><strong><em>Benefits</em></strong></p>
          <p><em>In return, we offer a salary of &pound;34,500 - &pound;36,500 per annum plus 24 days holiday + bank holidays plus paid membership fees, bonus scheme, enhanced parental leave, and private healthcare.</em><em>Hybrid working is also available. There is clear career progression within the company as we look to grow our business further over the coming years.</em></p>
          <p><em>If you'd like to find out more about us and our projects, head over to ArchJobs.co.uk.</em></p>
          <p><strong><em>How to Apply</em></strong></p>
          <p><em>If you think you would make a great addition to our team then please send your CV along with a Sample Portfolio (max 10MB) to Aylin at hello@archjobs.co.uk by (date). </em></p>
          <p><em>We are an equal opportunities employer and aim to develop our team to reach their full career potential.</em></p>
          <p/>
          <p>Remeber a job advertisement is primarily focused on promoting the job opening and attracting potential candidates. It should communicate the job's key responsibilities, qualifications, and skills required, but it should also highlight what makes the company an attractive place to work, such as its culture, values, benefits, and opportunities for career growth.</p>
          <p>An effective job ad should grab the attention of job seekers and encourage them to apply for the position. By presenting your company as an employer of choice and highlighting what sets it apart from competitors, you can help to attract top talent and build a strong candidate pool.</p>
          <p/>
          <p/>
          <h2>Frequently Asked Questions</h2>
          <p>How Long Should a Job Ad Be Live?</p>
          <ul>
            <li>Answer: The ideal duration can vary, but it's generally recommended to keep a job ad live for two to four weeks. This allows enough time to attract a diverse pool of candidates without unnecessarily extending the hiring timeline.</li>
          </ul>
          <p>What Are Common Mistakes to Avoid in a Job Ad?</p>
          <ul>
            <li>Answer: Common mistakes include unclear job requirements, using overly technical language, neglecting to highlight company culture, and not specifying the application process. A well-written job ad addresses these issues to attract the right candidates.</li>
          </ul>
          <p>How Often Should I Update Job Descriptions and Adverts?</p>
          <ul>
            <li>Answer: Job descriptions and adverts should be updated regularly to reflect changes in the role, company needs, and industry trends. Review them at least annually or when significant changes occur.</li>
          </ul>
          <p>Should I ask for a CV, Portfolio and Cover Letter?&nbsp;</p>
          <ul>
            <li>Answer: Only ask for the documents you really want to see. You definitely want to see their CV and Sample Portfolio (under 10MB) and I would recommend not asking for a Cover Letter. It puts a lot of passive and active job seekers off. As they believe that it is a waste of time and that you will not read it anyway.</li>
          </ul>
          <p>Should I ask for their Salary Expectation and Availability?</p>
          <ul>
            <li>Answer: I would advise against asking the job seeker for their salary expectations because they have not had a conversation with you and depending on the level of experience it can vary. Asking for their notice period/availability is perfectly fine because some might have a 4+ weeks notice period or they are immediately available.
            </li>
          </ul>
          <p>I am hiring for multiple different roles, can I write them all in one job advert?</p>
          <ul>
            <li>Answer: Do not mix and match. Rather focus on one position at the time, if you are hiring for a Part 2 Architectural Assistant and an Associate Architect, their responsibilities and skills are completely different. However, if you are looking for 2 x architects, then go ahead and only write one advert.</li>
          </ul>
          <p>How long does it normally take to hire an architect from start to finish?</p>
          <ul>
            <li>Answer: The time it takes to hire an architect can vary due to several factors, such as your online company presence, the use of a recruiter, the number of interview stages, the availability of decision-makers for interviews and offer discussions, and more. On average, from writing up the job advert to the architect starting, you should expect a minimum of 2 to 3 months. Keep in mind that if the architect is currently employed, they'll need to give notice, typically at least 4 weeks or more. Therefore, strategic planning is crucial. If you need someone quickly, consider hiring a contractor while actively seeking a permanent placement.</li>
          </ul>
          <p>What's the Best Approach to Attract Passive Candidates?</p>
          <ul>
            <li>Answer: Engage with industry events, forums, and networks to reach passive candidates. Additionally, consider showcasing your company culture on social media and professional platforms to capture their attention. LinkedIn is a great platform to raise your company brand.</li>
          </ul>
          <p>How Many Interview Stages Are Ideal for Hiring an Architect?</p>
          <ul>
            <li>Answer: While the number can vary, it's generally advisable to keep the interview process concise. Usually two stage interview is the ideal number. First stage is to get to know the candidate and see if they could be a suitable fit and the second stage could be to get to know the candidate even better and give them the opportunity to understand your expectations and the role in more depth. During the second stage, salary and benefits would be discussed in more details as well.</li>
          </ul>
          <p>Should we mention our Salary Range?</p>
          <ul>
            <li>Answer: Including a salary range is important to attract candidates who align with your budget and expectations. It sets clear expectations and increases transparency, contributing to a more efficient hiring process.</li>
          </ul>
          <p>How Can I Assess Cultural Fit in the Hiring Process?</p>
          <ul>
            <li>Answer: Cultural fit can be assessed through behavioural questions, discussions about work preferences, and understanding how candidates approach collaboration. Ensure your job ad and interviews reflect your company's values and work environment.</li>
          </ul>
          <p>Should I Work with Multiple Recruiters?</p>
          <ul>
            <li>Answer: While it's possible, it's advisable to limit working to one or two recruiters to maintain clarity and prevent confusion. Choose recruiters who specialise in the architectural field and your location for better results.</li>
          </ul>
          <p>How Do I Encourage Diversity in Applicants?</p>
          <ul>
            <li>Answer: Craft an inclusive job ad, actively seek candidates from diverse sources, and highlight your commitment to diversity in the workplace. Ensure a fair and unbiased hiring process.</li>
          </ul>
          <p>What Metrics Should I Track in the Hiring Process?</p>
          <ul>
            <li>Answer: Important metrics include time-to-fill, quality of hire, diversity metrics, and candidate satisfaction. Regularly tracking these metrics provides insights into the effectiveness of your hiring strategy.</li>
          </ul>
         
          <p>What are some simple ways to stand out from other architectural and multidisciplinary practices?</p>
          <ul>
            <li>Offer hybrid, flexible, and remote working options</li>
            <li>Instead of stating 'we offer a competitive salary,' provide the salary range or at least the minimum figure</li>
            <li>List your benefits</li>
            <li>Specify the number of interview stages</li>
            <li>Be open-minded and consider hiring part-time staff</li>
          </ul>
          <p/>
          <p/>
          {/* <h2>Checklist</h2>
          <p>As you start creating a standout job advert, let's lay the groundwork with this useful checklist. Each item on the list isn't just a piece of the puzzle, it's your guide to not only attracting the right architects but also making the hiring journey smoother.
          </p>
          <ul>
            <li>Be clear on the job title&nbsp;</li>
            <li>What level of experience do you need</li>
            <li>Establish the salary range (refer to our <a target="_blank" href="https://www.archjobs.co.uk/salary-guide/architect-salary">UK Salary Guide</a> for current ranges)</li>
            <li>Outline responsibilities clearly&nbsp;</li>
            <li>Specify sector experience if necessary</li>
            <li>Indicate software skills required or whether training is possible</li>
            <li>Define working arrangements (hybrid/flex or office-based)</li>
            <li>Clarify employment type (full-time/part-time/contract/fixed-term contract)</li>
            <li>Determine the number of interview stages and format (one or two stages, face-to-face/video/mix)</li>
            <li>Specify the application process (email or internal recruiting platform/ATS).</li>
            <li>State required documents (CV, Sample Portfolio, Cover Letter).</li>
            <li>Communicate the ideal start date for the Architect.</li>
            <li>Decide where to advertise the role (i.e., job boards, social media, use a recruiter, etc.).</li>
            <li>Confirm that all decision-makers are in the office and available for the next 4 to 6 weeks to check incoming applications, arrange interviews, and make an offer</li>
          </ul>
          <p/>
          <p/> */}
          <h2>Conclusion</h2>
          <p>Writing an effective job advert requires careful consideration and attention to detail. It's crucial to understand what job seekers are looking for in an advert and ensure that it's inclusive, accurate, and informative.</p>
          <p>By avoiding common pitfalls such as using jargon, being too vague, or copying and pasting from old listings, you can create a job ad that stands out from the crowd and attracts the right candidates.</p>
          <p>Consider where and how you're advertising the role. Which job boards should you use? Perhaps your social media presence is robust enough for a few posts, or maybe you'd like to collaborate with an experienced architectural recruiter. You might even choose to use all avenues and see which one brings the needed success.</p>
          <p>If you're seeking a platform to promote your Architect position, be sure to <a target="_blank" href="https://www.archjobs.co.uk/post-job">visit ArchJobs</a>, where Aylin will be available to assist you. From optimising your job ad to attract more talent to sharing your ad with our expanding network and providing you with the latest market updates.</p>
            </HiringGuideFrame>
    }
}