import ArticleFrame from "./ArticleFrame";
import React, { Component } from 'react';


export default class CounterOffer extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
      };
    }
    componentDidMount = () => {
      window.scrollTo(0, 0)
    }

    render(){
        return <ArticleFrame ArticleDataName="CounterOffer">
          <p>As professionals, we all aspire to grow and advance in our careers. At some point, this journey may lead us to explore fresh opportunities beyond our current organisation. However, the decision to part ways with a job is not always straightforward, particularly when your current employer presents you with a counter-offer, enticing you to remain. In this article, we delve into the realm of counter-offers, seeking to comprehend their implications and navigate the challenging decision-making process they bring.</p>
          <p/>
          <p/>
          <h3>What exactly is a Counter-Offer?</h3>
          <p>Before we start, let&rsquo;s clarify what a counter-offer is. A counter-offer is an offer your current employer makes in response to another offer. You&rsquo;re most likely to receive a counter-offer after you've handed in your notice, and it basically means your current employer is trying to keep you from leaving their company (isn&rsquo;t that nice). A typical counter-offer could consist of higher salary, better benefits or more career progression opportunities.</p>
          <p><em>&ldquo;89% of employers believe people leave just to get more money. In reality, only 12% of employees leave due to salary alone.&nbsp;</em>.&rdquo;</p>
          <p>Source: <a href="https://officevibe.com/blog/disengaged-employees-infographic" target="_blank" rel="nofollow noopener">OfficeVibe</a></p>
          <p>&zwj;</p>
          <h3>Let&rsquo;s set the scene</h3>
          <p>You&rsquo;ve been with your current practice for over 5 years, the work isn&rsquo;t very challenging or exciting, and you haven&rsquo;t had a salary review in over 2 years. You start to wonder if the grass is greener on the other side, so you start looking for a new job, speak to a few recruiters, and discover you could earn more money. After several months of interviews and job hunting you finally get the offer you wanted. I'm sure you feel great and excited about the new chapter.&nbsp;</p>
          <p>Next you need to tell your Team Leader/Boss you're handing in your notice (this is usually very stressful for a lot of people). The moment has come, you sit face to face with your boss and you feel nervous. You tell them that you found a new job which pays more and offers better career opportunities. You think it&rsquo;s all done but wait! They make you a counter-offer and say they match your new offer... &rdquo;Why didn&rsquo;t you come to us before and say something?&rdquo;.</p> 
          <p>Well that was unexpected and I&rsquo;m sure most of you will feel flattered and slightly taken aback by their response. You might even feel a little silly for having looked for a new job in the first place. Clearly you misjudged them if they think so highly of you. You&rsquo;re unsure what to do and your mind is racing.&nbsp;</p>
          <p/>
          <p/>
          <h3>Should I stay or should I go?&nbsp;</h3>
          <p>This conversation could end in 2 ways. You either kindly decline the counter-offer straight away or you feel so conflicted you need time to think about it. I&rsquo;m sure a lot of you will take a few days, look at the pros and cons and ask family and friends for advice.</p> 
          <p>Again, this part can get extremely stressful because you will hear a lot of opinions from either side. You&rsquo;ll have your current employer on one shoulder and a recruiter or new company on the other - each side playing with your emotions in an attempt to convince you to take their offer. Add your family and friends to the mix - who are unlikely to know the full picture - and things can get complicated.</p>
          <p/>
          <p/>
          <h3>But what is the right decision and what should I do?</h3>
          <p>If you feel conflicted and are unsure what to do that's only natural - there&rsquo;s a lot to consider. My advice - sit down, take a deep breath, make yourself a hot drink and put your emotions to one side.</p>
          <p>Really think about what made you decide to look for a new job in the first place. Are there any underlying issues apart from money - lack of career progression, long commute, company culture, empty promises, bad work/life balance, job security, or no recognition?</p>
          <p>If you&rsquo;re wondering what other people value, the team from Reed have put together some great analysis - they tracked 3 years worth of data and these are the most desired benefits:</p>
          <ol>
          <li>Annual Leave/Paid time off - 65%</li>
          <li>Health Insurance - 51%</li>
          <li>Performance bonus - 48%</li>
          <li>Annual Salary increase - 45%</li>
          <li>Higher Company Pension Contributions - 44%</li>
          </ol>
          <p>Source: <a href="https://www.reed.com/articles/salaries-benefits" target="_blank" rel="nofollow noopener">Reed</a></p>
          <p/>
          <p/>
          <h3>What are the most common reasons for people to leave?&nbsp;</h3>
          <p>Looking at the most common reasons why people leave their current job, ask yourself how likely are you to get promoted soon, will the management style improve overnight, will they offer you the development opportunities you have been asking for, or would you get more recognition?&nbsp;</p>
          <p>A study by Gartner (former CEB Corporate Leadership Council) shows that salary isn&rsquo;t everything, and the top 5 reasons for leaving are as follows (employers take note!):</p>
          <ol>
          <li>Future Career Progression - 42%</li>
          <li>Compensation - 36%</li>
          <li>People Management - 35%</li>
          <li>Development Opportunities - 31%</li>
          <li>Recognition - 29%</li>
          </ol>
          <p>Source: <a href="https://www.gartner.com/" target="_blank" rel="nofollow noopener">Gartner</a></p>
          <p>Take a minute and consider these factors in your current situation - how many would actually improve? If you come to the conclusion that nothing will change in the long term, try to decline their counter-offer in person and leave on a positive note. Tell them your reasons, and offer to do a thorough handover and potentially help with the interview process to find a replacement.&nbsp;</p>
          <p/>
          <p/>
          <h3>What if the new Counter-Offer is so much better?</h3>
          <p>Again, look at the bigger picture and go through the list of factors you made. If you have uncovered underlying issues then money won&rsquo;t fix it - especially not in the long term. Research has shown that only <a href="https://officevibe.com/blog/disengaged-employees-infographic" target="_blank" rel="nofollow noopener">12% of employees are leaving due to money</a>. <a href="https://officevibe.com/blog/disengaged-employees-infographic" target="_blank" rel="noopener">However, 89% of employers</a> assume that leaving is money related and believe matching your new offer will fix everything.</p>
          <p>&zwj;</p>
          <h3>Why did they make me a Counter-Offer?</h3>
          <p>You most likely received a counter-offer as they need to protect their business. 72% of businesses know how challenging the current market is. Depending on how long you&rsquo;ve been with them, you would&rsquo;ve built strong relationships with not only your peers but also with clients, as well as the knowledge you&rsquo;ve gathered over the years - priceless.&nbsp;</p>
          <p><em>&ldquo;&ldquo;72% of businesses said that it&rsquo;s been harder to find people with the right skills than in 2021.&rdquo;</em>&ldquo;</p>
          <p>Source: <a href="https://www.reed.com/articles/salaries-benefits" target="_blank" rel="nofollow noopener">Reed</a></p>
          <p/>
          <p>In the current market, it is extremely difficult and costly to find a replacement. You&rsquo;re wondering why?</p>
          <p>Here&rsquo;s a breakdown of the cost to replace someone:</p>
          <ul>
          <li>Advertising the role on several job boards,&nbsp;</li>
          <li>Using a recruitment agency (fees are usually 15-35% of the candidate's annual salary),&nbsp;</li>
          <li>Time lost in going through applications, setting up several interview stages, going through offer stages, waiting for the new person to start.&nbsp;</li>
          <li>3-6 months for a new starter to get settled in, get to know the clients and projects.</li>
          <li>If things don&rsquo;t go to plan, the new hire might even leave after a few weeks and the whole search starts again.&nbsp;</li>
          </ul>
          <p>Let&rsquo;s recap, from you telling them you&rsquo;re handing in your notice to the new person sitting at their desk, it can easily take 6-12 months. Once you add everything up, it can cost the company quite a lot to replace you - all of a sudden a 10-20% raise doesn&rsquo;t sound that bad anymore.&nbsp;</p>
          <p>&zwj;</p>
          <h3>Should I accept the Counter-Offer and see if things improve?</h3>
          <p>Hypothetically, if you accept the counter-offer, understand that <a href="https://blog.jantegze.com/48-of-employees-who-accept-a-counter-offer-will-leave-within-a-year-cb8acb5afa81" target="_blank" rel="nofollow noopener">52% of people</a> will leave after just a year and <a href="https://blog.jantegze.com/48-of-employees-who-accept-a-counter-offer-will-leave-within-a-year-cb8acb5afa81" target="_blank" rel="nofollow noopener">92% will leave within 36 months</a>. Why? Because of the underlying issues we talked about earlier. Money won&rsquo;t fix poor management, the non-existent career progression or cut your daily commute in half.</p>
          <p>You might be surprised that only 52% of people that leave after a year. A lot of recruitment agencies claim that 80-90% of people that accept a counter-offer leave after a year. Recruiters might be somewhat biased on this one - as they won't benefit from you accepting a counter-offer.</p>
          <p/>
          <p/>
          <h3>Are there possible consequences if I do accept the Counter-Offer?</h3>
          <p>Taking a counter-offer can impact your working relationships - a study shows that <a href="https://blog.jantegze.com/48-of-employees-who-accept-a-counter-offer-will-leave-within-a-year-cb8acb5afa81" target="_blank" rel="nofollow noopener">71% of Senior Executives and 67% of HR leaders</a> would question your loyalty going forward.</p>
          <p>Ask yourself if things would really improve. You might see some sort of improvement for around 4 weeks but things might go back to normal and you could feel disappointed and wish you would&rsquo;ve taken that job offer.&nbsp;</p>
          <p>&zwj;</p>
          <h3>Should I tell the new practice that I received a Counter-Offer?&nbsp;</h3>
          <p>Yes, but be cautious - it can depend a lot on where you are in the process. You could use it as a negotiation tactic but only if you haven&rsquo;t signed the contract yet. Going back and asking for more money or a better benefits package after you signed the contract could leave a bitter aftertaste in your new manager's mouth.</p>
          <p>The new practice might offer you slightly more money or flexible working arrangements but at the end of the day, they want someone who is excited and committed to joining their team. So be careful with that approach and see if it&rsquo;s worth the risk. If you do work with a recruiter, ask for their advice.&nbsp;</p>
          <p/>
          <p/>
          <h3>I&rsquo;ve decided to accept a Counter-Offer - now what?</h3>
          <p>If you decide to accept the counter-offer, you went through all the pros and cons and you&rsquo;re happy with your decision - contact the new practice or recruiter straight away. You could either email or call the hiring manager and thank them for their time and the kind offer, but you&rsquo;ve concluded that the timing isn&rsquo;t right. Try and stay in touch with the hiring manager (connect with them on LinkedIn) - you never know what might happen in 6-12 months time.</p>
          <p>If you worked with a recruiter and declined their offer, be aware that they might not be impressed by your decision. Some will understand your reasoning, others will tell you you&rsquo;re making a big mistake and you might ruin your working relationship with that particular recruiter.&nbsp;</p>
          <p/>
          <p/>
          <h3>To Summarise</h3>
          <p>Handing in your notice is never easy and even harder if you receive a counter-offer. They might sound nice at first but make sure you really think it through and put your emotions to one side.</p>
          <p>If your reason to look for a new job was solely money related and you love your current company and colleagues, then you are most likely to accept the counter-offer because it's the easiest option.</p> 
          <p>However, if there are more underlying issues and the new company matches what you&rsquo;re after in terms of career development, job title, type of projects, cultural fit, working arrangements and work/life balance, take the leap and take it. You have to look at the bigger picture and put yourself first.</p>
          <p>If you still feel conflicted and confused, or perhaps you have too many other opinions floating around in your head, <a href="https://www.archjobs.co.uk/architectural-career-coaching" target="_blank" rel="noopener">consider booking a confidential call with me</a>. I can provide an external perspective and help you weigh up the pros and cons.</p>       
                     </ArticleFrame>
    }
}