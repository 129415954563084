import ArticleFrame from "./ArticleFrame";
import React, { Component } from 'react';

const thisArticleData = "CoverLetter"

export default class CoverLetter extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
      };
    }
    componentDidMount = () => {
      window.scrollTo(0, 0)
    }

    render(){
        return <ArticleFrame ArticleDataName={thisArticleData}>
          <p>In this article, we'll delve into why a well-written cover letter is essential to your job application and provide tips on how to structure one effectively. We'll also cover how to research a company and whether or not you need to attach a cover letter to every application. So, whether you're a seasoned job seeker or just starting out, read on for valuable insights on how to elevate your job application with a well-crafted cover letter.</p>
          <p/>
          <p/>
          <h3>What is a Cover Letter?</h3>
          <p>&ldquo;<em>The cover letter is a formal business letter which is often the first contact with a prospective employer. It serves as an introduction to you and your background experience. Since it is usually the first impression you make on the employer, you want it to be your best.</em>&rdquo;&nbsp;</p>
          <p>Source: <a href="https://www.oxy.edu/sites/default/files/assets/cover-letters_0.pdf" target="_blank" rel="nofollow noopener">Oxy.edy</a></p>
          <p/>
          <p/>
          <h3>General Formatting Tips</h3>
          <div>File type: Ideally email, if not PDF</div>
          <div>Font type: Easy to read fonts are Arial, Times New Roman or Calibri</div>
          <div>Font size: 10-11</div>
          <div>Pages: Max 1 - ideally only half a page</div>
          <p/>
          <p/>
          <h3>Why a Well-Written Cover Letter is Essential to Your Job Application</h3>
          <p>When applying for a job, it's easy to focus all your attention on perfecting your resume and forget about the importance of a cover letter. However, a well-written cover letter can be the difference between getting an interview and being passed over for the position.</p>
          <p>A cover letter serves as an introduction to who you are, highlighting your qualifications and explaining why you are the best candidate for the job. It's a chance to showcase your personality, experiences, and skills in a way that goes beyond your resume or job application.</p>
          <p>In fact, some employers may even ask specific questions in their cover letter requirements such as your notice period, salary expectations, or why you want to work for them. A well-crafted cover letter can demonstrate your attention to detail, enthusiasm for the position, and professionalism.</p>
          <p>Ultimately, a cover letter can be a critical part of the job application process. It shows potential employers that you have taken the time to research the company and the position, and that you are genuinely interested in the opportunity. Don't overlook the importance of this document &ndash; take the time to craft a well-written cover letter to increase your chances of getting hired.</p>
          <p/>
          <p/>
          <h3>Do I Need to Attach a Cover Letter to Every Application?</h3>
          <p>One common question that job seekers ask is whether they need to attach a cover letter to every job application they submit. The answer to this question is simple: you should only include a cover letter if the job advert specifically asks for one.</p>
          <p>While it's always a good idea to have a general cover letter ready in case you need it, it's important to tailor your application to the specific job you're applying for. This means reading the job advert carefully and following any instructions provided by the employer. If they ask for a cover letter, make sure you include one that is tailored to the job and showcases why you're the best fit for the role.</p>
          <p>On the other hand, if the job advert doesn't mention a cover letter, it's safe to assume that the employer doesn't require one. In this case, you should focus on crafting a strong CV and making sure that your application meets all the requirements listed in the job advert.</p>
          <p>Attaching a cover letter when it's not required can actually work against you, as it may indicate that you haven't read the job advert carefully. So, always check the job advert for specific instructions and follow them closely to maximse your chances of success.</p>
          <p/>
          <p/>
          <h3>How Long Should a Cover Letter Be?</h3>
          <p>While you want to make sure that your cover letter contains all the necessary information, you also don't want it to be too long. A good rule of thumb is to keep your cover letter to no longer than half an A4 page.</p>
          <p>Your cover letter should be easy to scan and read quickly. A lengthy cover letter can be overwhelming for a hiring manager or recruiter, who likely has to review dozens, if not hundreds, of applications.</p>
          <p>Remember that the purpose of a cover letter is to provide a brief introduction to who you are, what qualifications you have, and why you're the best candidate for the job. You don't need to go into exhaustive detail about every aspect of your career history. Instead, focus on the most important details, such as your relevant skills and experiences, and why you're interested in the position.</p>
          <p>By keeping your cover letter concise and to the point, you'll increase the chances that the hiring manager or recruiter will take the time to read it and consider you for the position.</p>
          <p/>
          <p/>
          <h3>Why Generic Cover Letters don’t work</h3>
          <p>A common mistake that job seekers make is sending a generic cover letter alongside their resume. While it may seem like a time-saver to use a template and simply swap out the company name, this approach can actually harm your chances of landing the job.&nbsp;</p>
          <p>Hiring managers and recruiters can instantly tell when a cover letter has not been tailored to the specific job and company.&nbsp;</p>
          <p>A generic cover letter can give the impression that you are not genuinely interested in the position or the company, and it fails to showcase how your skills and experience align with the requirements of the job.</p>
          <p/>
          <p/>
          <h3>How to Research a Company</h3>
          <p>Before you start crafting your cover letter, it's useful to research the company you're applying to. Not only will this help you tailor your cover letter to their needs, but it will also help you determine if the company is the right fit for you and make your Cover Letter sound less generic.&nbsp;</p>
          <p><strong>Here are some ways to research a company:</strong></p>
          <ol>
          <li><strong>Check out the company's website:</strong> Start by visiting the company's website and reading about their mission, values, and history. Look for any recent news or press releases to get an idea of what the company is currently working on.</li>
          <li><strong>Use social media:</strong> Many companies have a social media presence, so follow them on platforms like LinkedIn, Twitter, and Facebook. This can give you a sense of their company culture and the types of content they share.</li>
          <li><strong>Look up employee reviews:</strong> Websites like Glassdoor and Indeed allow current and former employees to leave anonymous reviews about their experience working at a company. This can provide valuable insights into the company's culture, management style, and work-life balance.</li>
          <li><strong>Network with current or former employees:</strong> If you know someone who works or has worked at the company, reach out to them and ask about their experience. This can give you a more personal perspective on what it's like to work there.</li>
          </ol>
          <p>By researching the company, you can show in your cover letter that you understand their needs and are genuinely interested in working for them. Additionally, it can help you determine if the company is a good fit for your career goals and values. I appreciate that you can't always invest that much time into every single application.&nbsp;</p>
          <p>However, I am a firm believer in quality over quantity, and the aim should never be to send out hundreds of applications every single day.</p>
          <p/>
          <p/>
          <h3>Basic Structure of your Cover Letter (plus examples)</h3>
          <div>You should structure your Cover Letter in 4 parts.</div>
          <ol>
          <li>Why are you contacting them</li>
          <li>Why would you like to work for them</li>
          <li>Highlight your skills</li>
          <li>Summarise&nbsp;</li>
          </ol>
          <p>The<strong> first paragraph </strong>in your Cover Letter should include why you&rsquo;re contacting them, the position you are applying for and where you saw the role advertised.&nbsp;</p>
          <p><strong>Second paragraph</strong>, explain to them why you would like to work for them (do you like the projects they work on, have they recently won any awards, have you been following the practice for a while etc.) and what skills you offer.&nbsp;</p>
          <p><strong>Third paragraph</strong>, highlight any of your key Skills and show them what you could bring to the table i.e. Revit, UK Building Regulations, Job running, Strong communicator, Site visits, Attention to detail, and team player.&nbsp;</p>
          <p><strong>Fourth paragraph</strong>, summarise why you&rsquo;re interested and why you&rsquo;re the right fit for the role.</p>
          <p/>
          <p/>
          <h3>Cover Letter Example 1</h3>
          <p><em>Dear [Hiring Manager],</em></p>
          <p><em>I am writing to express my interest in the Architect position at [Company]. As a highly skilled architect with 3 years of experience in large-scale residential and commercial projects, I am confident that I am the ideal candidate for this role.</em></p>
          <p><em>I am impressed with [Company's] reputation for delivering innovative and sustainable architectural designs, and I believe my skills and experience align perfectly with the requirements of this position. I am particularly interested in the challenging and exciting projects that [Company] has undertaken in the past, and I am excited about the opportunity to contribute my expertise to your team.</em></p>
          <p><em>I possess strong technical skills in Revit, AutoCAD and Adobe Creative Suite, which I have honed throughout my career. These skills, coupled with my ability to work collaboratively with clients and colleagues, have enabled me to successfully deliver projects on time and within budget.</em></p>
          <p><em>What sets me apart from other candidates is my passion for design and my eagerness to learn and grow as an architect. I am looking to join a larger practice to gain more experience and take on more complex projects. I believe that [Company] offers the perfect environment for me to achieve these goals.</em></p>
          <p><em>In summary, I am confident that my skills and experience make me the ideal candidate for this position. I look forward to the opportunity to discuss how I can contribute to [Company's] continued success.</em></p>
          <p><em>Thank you for considering my application.</em></p>
          <p><em>Sincerely,</em></p>
          <div><em>[Your Full Name]&nbsp;</em></div>
          <div><em>[Phone Number]&nbsp;</em></div>
          <div><em>[Email Address]</em></div>
          <p/>
          <p/>
          <h3>Cover Letter Example 2</h3>
          <p><em>Dear [Hiring Manager],</em></p>
          <p><em>As a highly experienced architect with a passion for creating innovative and sustainable designs, I am excited to apply for the role of Architect at [Company]. With 3 years of experience working on large-scale residential schemes and a proven track record of delivering high-quality projects, I am confident that I would make a valuable addition to your team.</em></p>
          <p><em>I am particularly drawn to [Company] for its commitment to sustainability and innovative design. I am impressed by the company's focus on creating spaces that are not only beautiful but also environmentally responsible, and I believe my experience in this area would enable me to make a significant contribution to your practice.</em></p>
          <p><em>In my current role at Company X, I successfully managed several high-rise residential schemes, overseeing all aspects of the projects from RIBA Stages 2-6. I have strengthened my client-facing, problem-solving and technical abilities, and I am confident that these skills, combined with my passion for design, would be a valuable asset to your team.</em></p>
          <p><em>I am excited about the opportunity to work with [Company], and I am confident that my experience, skills and perspective make me an ideal candidate for this role. I look forward to meeting with you on [specific date/time] to discuss my application further.</em></p>
          <p><em>Thank you for your time and consideration.</em></p>
          <p><em>Kind regards,</em></p>
          <div><em>[Your Full Name]&nbsp;</em></div>
          <div><em>[Phone Number]&nbsp;</em></div>
          <div><em>[Email Address]</em></div>
          <p/>
          <p> Before submitting your application, find out the name of the hiring manager/director. This will not only leave a good first impression behind but will also show that you invested time in carefully preparing your application. If you&rsquo;re not certain, address it to &lsquo;Dear Hiring Manager<span>&rsquo;</span>.</p>
          <p/>
          <p/>
          <h3>The Risks of Not Attaching a Cover Letter</h3>
          <p>When a job posting requests a cover letter, it's important to follow through and provide one. Not doing so can instantly put you out of the running for the position. Hiring managers and recruiters may interpret a missing cover letter as a sign that you didn't read the job posting thoroughly or that you're not genuinely interested in the role.&nbsp;</p>
          <p>In a competitive job market, any small detail can make a big difference, and a missing cover letter could be the factor that disqualifies you from consideration.</p>
          <p>Larger companies, in particular, can receive hundreds of applications for a single position.&nbsp;</p>
          <p>Without a cover letter, your application may get immediately overlooked.&nbsp;</p>
          <p>In such a scenario, recruiters and hiring managers often use cover letters to quickly weed out unqualified candidates. By failing to submit a cover letter, you could be missing out on an opportunity to showcase your skills, experience, and enthusiasm for the job.</p>
          <p/>
          <p/>
          <h3>Should I Tailor my Cover Letter?</h3>
          <div>Yes, I would highly recommend spending that extra time amending your Cover Letter. Hiring managers can tell if a Cover Letter has been tailored to a specific job or not.&nbsp;</div>
          <p>Don&rsquo;t forget, first impressions count and you only have their attention for a short time. Make every application count - more time spent on tailoring your applications will reduce your job search time massively.</p>
          <p>&zwj;
          <p/>
          <h3>How Not to Start Your Cover Letter: Tips for Addressing the Hiring Manager</h3>
          <p>One of the most important aspects of writing a cover letter is addressing the hiring manager appropriately. However, it can be challenging to know exactly how to do this, especially if you are not sure who will be reading your application.&nbsp;</p>
          <p>Here are some tips for addressing the hiring manager in your cover letter:</p>
          <ol>
          <li><strong>Avoid using generic greetings:</strong> Starting your cover letter with a generic greeting like "Dear Sir/Madam", "To Whom It May Concern" or "Hi" can make it seem impersonal and unprofessional. It's best to do some research and try to find out the name of the hiring manager or HR representative.</li>
          <li><strong>Use LinkedIn or the company website to find the hiring manager's name: </strong>If the job listing doesn't include the name of the hiring manager, you can try using LinkedIn or the company's website to find the person's name. If all else fails, you can always call the company and ask for the name of the person responsible for hiring.</li>
          <li><strong>Address the person formally, unless instructed otherwise:</strong> Once you have the name of the hiring manager, it's important to address them formally in your cover letter. In the UK, it's common to use the hiring manager's first name instead of their last name. However, if the job listing specifically instructs you to use a different form of address, be sure to follow those instructions.</li>
          <li><strong>Use "Dear Hiring Manager" as a last resort:</strong> If you are unable to find the name of the hiring manager, you can use "Dear Hiring Manager" as a last resort. While it's not as personal as using the person's name, it's still better than using a generic greeting.</li>
          </ol>
          <p>By taking the time to address the hiring manager appropriately, you can make a great first impression and show that you are a professional and serious candidate for the position.</p>
          <p/>
          <p/>
          <p><strong>Did you know:</strong> <a href="https://www.archjobs.co.uk/" target="_blank">ArchJobs</a> provides you with the hiring manager's name? Always read the &ldquo;How To Apply&rdquo; Section (as shown in the image below).&nbsp;</p>
          <p> <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Address your Cover Letter to - ArchJobs.png" alt="ArchJobs website screenshot displaying the 'How To Apply' section, which includes the contact name for the job listing"></img></p>
          </p>  
          <h3>How does an Applicant Tracking System (ATS) impact my application?</h3>
          <div>Most recruitment agencies and mid to large size companies use ATS systems which lets them store, filter, organise, and track applications but also search their database for suitable candidates by entering relevant keywords (skills, location, job titles etc.).&nbsp;</div>
          <p>It's important to understand that when submitting your application via an ATS, you're sending it to software and not an actual person. Therefore, your CV needs to be ATS-friendly and optimised to ensure that it passes the initial screening and makes it to a human reviewer.</p>
          <p>To learn more on this particular subject, read <a href="https://www.archjobs.co.uk/articles/how-to-create-an-ats-friendly-cv" target="_blank" rel="noopener">How to create an ATS-Friendly CV</a>.</p>
          <p/>
          <p/>
          <h3>Which Keywords should I use?&nbsp;</h3>
          <div>Whether you&rsquo;re sending your application directly or uploading your details via a company's recruitment portal using relevant keywords will help your application stand out.&nbsp;</div>
          <p>Below are the most commonly used keywords for any architectural positions:</p>
          <p><em>Presentation skills, technical skills, design skills, concept design, planning applications, site visits, coordination, liaising with clients, UK Building Regulations, communication skills, Revit, AutoCAD, Photoshop, SketchUp, InDesign etc.</em></p>
          <p/>
          <p><strong>Did you know:</strong> Before you submit your application on <a href="https://www.archjobs.co.uk/" target="_blank" rel="noopener">ArchJobs</a>, you should check if your Cover Letter and/or CV include the right keywords.</p>
          <p>Applications that contain relevant keywords are much more likely to be shortlisted for interviews.&nbsp;</p>
          <p>All the keywords are hand-picked by us based on the job description and company website (see image below).</p>
          <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/example_keywords.png" alt="ArchJobs website screenshot displaying the most relevant keywords"></img>
          <p/> 
          <h3>In Conclusion</h3>
          <p>In conclusion, a well-written cover letter can make all the difference in your job search. It is important to remember that a cover letter should never be a generic, one-size-fits-all document. Instead, it should be tailored specifically to the job and company you are applying to.&nbsp;</p>
          <p>Researching the company, understanding their needs, and demonstrating your qualifications are all key components of a successful cover letter.&nbsp;</p>
          <p>Additionally, it is important to keep your cover letter concise and easily scannable.</p>
          <p>Remember, hiring managers and recruiters receive many applications, and they don't have the time to read through lengthy cover letters that don't get to the point.&nbsp;</p>
          <p>By following these tips and guidelines, you can craft a cover letter that stands out from the crowd and helps you land the job you want.</p>
            </ArticleFrame>
    }
}


// Now, go to the App.js file
// add this line at the top, rename {YourArticle} to whatever you called it. You also need to copy and rename this file to "{YourArticle}.js"
// import YourArticle from './articles/YourArticle'
// Add another line in the Routes section, renaming "YourArticle" again:
// <Route path="/articles/YourArticle" element={<YourArticle/>} />


// now in TERMINAL below, run the following command:
// npm run-script dev
// head to the article page and review the html to make sure its properly formatted
//add <p/> between rows where you want a larger space
//add any images that were missed out (will need to upload these to article_images folder too) eg. <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/example_keywords.png" alt="Archjobs Keywords when applying"></img>
//add rel="nofollow noopener" to any links so it doesnt impact our SEO eg.  <a href="https://dictionary.cambridge.org/dictionary/english/cv" target="_blank" rel="nofollow noopener">Cambridge Dictionary</a>


// finally, publish the changes to github
// When its live, go to search console and inspect the new url: https://search.google.com/search-console?resource_id=sc-domain%3Aarchjobs.co.uk
// Might need a couple of attempts for it to get cached and display properly
// Make sure the rich text snippet is working properly
// If it is, request indexing from google to speed up the process