
import React from 'react'
import classNames from 'classnames';
import '../css/workwithus.css'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
  import Button from './Buttons'
  import {FAQCoaching } from './FAQCoaching'
  import { FaQuoteLeft } from 'react-icons/fa';
  import { Helmet } from 'react-helmet';
import { BsFillArrowUpLeftSquareFill } from 'react-icons/bs';
import ChatWidget from './ChatWidget';


const CircleCheck = <svg className="CircleCheck" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"></path><path d="M9.999 13.587 7.7 11.292l-1.412 1.416 3.713 3.705 6.706-6.706-1.414-1.414z"></path></svg>

const Check = <svg className="Check" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z"></path></svg>
const Cross = <svg className="Cross" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path></svg>

class WhyWorkWithUsCoaching extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
      }

      componentDidMount = () => {
        window.scrollTo(0, 0)
      }

    render() {


      return (
        <div className="WhyWorkWithUsPage">
            <Helmet>
            <meta charSet="utf-8" />
              <title>ArchJobs | Architectural Career Coaching  | UK and Dubai</title>
                <link rel="canonical" href={"https://www.archjobs.co.uk/architectural-career-coaching"} />
                <meta name="description" content={"Whether you're struggling with your architectural job search journey in the UK, or you just need some specific advice, working with Aylin will offer you useful insights and actionable advice to help you succeed in navigating your Architectural Career" }/>
                <meta name="og:title" content={"ArchJobs | Architectural Career Coaching | UK and Dubai"}/>
                <meta name="og:type" content={"website"}/>
                <meta name="og:image" content={"https://cdn.archjobs.co.uk/file/ArchJobs/backgrounds/archjobs_banner.png"}/>
                <meta name="og:url" content={"https://www.archjobs.co.uk/architectural-career-coaching"}/>
            </Helmet>
          <div className="WhyWorkWithUsBanner WhyWorkWithUsBannerCoaching">
            <div className="WhyWorkWithUsBannerTextContainer">
              <div className="WhyWorkWithUsSectionTitle WhyWorkWithUsSectionTitleBold">Architectural Career Coaching</div>
              <div className="WhyWorkWithUsSectionSubtitle">Ever wondered if your CV is holding you back? Unsure how to navigate the UK or Dubai architectural job markets? If you're tired of guessing what employers look for, or contemplating a move to the UK or Dubai, working with Aylin is your time-saving solution. She provides tailored and actionable advice to your unique situation, enabling you to focus on the right steps and take your career to the next level.</div>
              {/* <div className="WhyWorkWithUsSectionSubtitle">Whether you're struggling with your architectural job search journey in the UK, or you just need some specific advice, working with Aylin will offer you useful insights and actionable advice to help you succeed in navigating your Architectural Career.</div> */}
          </div>
          <div className="WhyWorkWithUsBannerBG CoachingBG"> </div>
          </div>
          <div className="WhyWorkWithUsSection" id="ChoosePackageSection">
           <div className="WhyWorkWithUsSectionTitle WhyWorkWithUsSectionTitleBold">Choose a Coaching Package</div>
            <div className="WhyWorkWithUsSectionSubtitle">For more information on each package scroll down</div>
             <div className="PricingPackages CoachingPricingPackages">
              <div to="/post?package=basic" className="PostJobPackage" id="cvpack" >
                <div className="PackageTitle">CV Review</div>
                <div className="PackagePrice">£69</div>
                <div className="PackagePerAd">Whole package</div>
                <div className="PackageDescription">
                  <div className="PackageDescriptionTitle">Includes</div>
                  <div className="PackageDescriptionText">{Check} Ensuring your CV aligns with the role you are targeting</div>
                  <div className="PackageDescriptionText">{Check} Assessing clarity and suggesting layout improvements to make your CV stand out</div>
                  <div className="PackageDescriptionText">{Check} Insights on what Hiring Managers look for in your CV  (UK or Dubai)</div>
                  <div className="PackageDescriptionText">{Check} Checking layout, format, typos and for ATS-friendliness</div>
                  <div className="PackageDescriptionText">{Check} Offering tailored guidance on improving your CV</div>
                  {/* <div className="PackageDescriptionText">{Check} Feedback via Video Call</div> */}
                </div>
                <div className="StartNowButtonContainer">
                  <Link to="/architectural-career-coaching/check-out?package=cvpack">
                    <Button btnClassName="PostJobButton">Get Started</Button>
                  </Link>
                </div>
              </div>
              <div to="/post?package=basic" className="PostJobPackage" id="cvportfoliopack" >
                <div className="PackageTitle">CV + Portfolio Review</div>
                <div className="PackagePrice">£89</div>
                <div className="PackagePerAd">Whole package</div>
                <div className="PackageDescription">
                  <div className="PackageDescriptionTitle">Includes</div>
                  <div className="PackageDescriptionText">{Check} Everything from the Full CV Review package</div>
                  <div className="PackageDescriptionText">{Check} Ensuring your Portfolio aligns with your CV</div>
                  <div className="PackageDescriptionText">{Check} Offering feedback on the layout & overall presentation</div>
                  <div className="PackageDescriptionText">{Check} Ensuring your Portfolio showcases your skills & experience</div>
                  <div className="PackageDescriptionText">{Check} Assessing if project descriptions are clear</div>
                </div>
                <div className="StartNowButtonContainer">
                  <Link to="/architectural-career-coaching/check-out?package=cvportfoliopack">
                    <Button btnClassName="PostJobButton">Get Started</Button>
                  </Link>
                </div>
              </div>
              {/* <div to="/post?package=basic" className="PostJobPackage" id="linkedinpack" >
                <div className="PackageTitle">LinkedIn Review</div>
                <div className="PackagePrice">£99</div>
                <div className="PackagePerAd">Whole package</div>
                <div className="PackageDescription">
                  <div className="PackageDescriptionTitle">Includes</div>
                  <div className="PackageDescriptionText">{Check} LinkedIn Profile Review</div>
                  <div className="PackageDescriptionText">{Check} 15min call to understand the role you're aiming for</div>
                  <div className="PackageDescriptionText">{Check} Crafting the perfect LinkedIn Headline and About section</div>
                  <div className="PackageDescriptionText">{Check} Optimising your LinkedIn Profile for maximum visibility</div>
                  <div className="PackageDescriptionText">{Check} Learn how Hiring Managers/Recruiters find candidates on LinkedIn</div>
                </div>
                <div className="StartNowButtonContainer">
                  <Link to="/architectural-career-coaching/check-out?package=linkedinpack">
                    <Button btnClassName="PostJobButton">Get Started</Button>
                  </Link>
                </div>
              </div> */}
              <div to="/post?package=basic" className="PostJobPackage" id="strategy" >
                <div className="PackageTitle">Strategy Call | UK</div>
                <div className="PackagePrice">£129</div>
                <div className="PackagePerAd">Per session</div>
                <div className="PackageDescription">
                  <div className="PackageDescriptionTitle">Includes</div>
                  <div className="PackageDescriptionText">{Check} Market insights and salary guidance for the UK</div>
                  <div className="PackageDescriptionText">{Check} Review of your CV & Portfolio</div>
                  <div className="PackageDescriptionText">{Check} Learn what UK hiring managers look for in an application</div>
                  <div className="PackageDescriptionText">{Check} Tailored support for overcoming your biggest job search hurdles</div>
                  <div className="PackageDescriptionText">{Check} For Relocators: hings you need to know before moving i.e. job titles, cost of living, skilled worker visas...</div>
                </div>
                <div className="StartNowButtonContainer">
                  <Link to="/architectural-career-coaching/check-out?package=strategy">
                    <Button btnClassName="PostJobButton">Get Started</Button>
                  </Link>
                </div>
              </div>
              <div to="/post?package=basic" className="PostJobPackage" id="strategy" >
                <div className="PackageTitle">Strategy Call | Dubai</div>
                <div className="PackagePrice">£149</div>
                <div className="PackagePerAd">Per session</div>
                <div className="PackageDescription">
                  <div className="PackageDescriptionTitle">Includes</div>
                  <div className="PackageDescriptionText">{Check} Market insights and salary guidance for Dubai</div>
                  <div className="PackageDescriptionText">{Check} Review of your CV & Portfolio</div>
                  <div className="PackageDescriptionText">{Check} Learn what hiring managers look for in an application</div>
                  <div className="PackageDescriptionText">{Check} Tailored support for overcoming your biggest job search hurdles</div>
                  <div className="PackageDescriptionText">{Check} For Relocators: Things you need to know before moving i.e. renting, additional costs, attesting your degrees...</div>
                </div>
                <div className="StartNowButtonContainer">
                  <Link to="/architectural-career-coaching/check-out?package=strategydubai">
                    <Button btnClassName="PostJobButton">Get Started</Button>
                  </Link>
                </div>
              </div>
              {/* <div to="/post?package=basic" className="PostJobPackage" id="starter" >
                <div className="PackageTitle">Starter Pack</div>
                <div className="PackagePrice">£179</div>
                <div className="PackagePerAd">Whole package</div>
                <div className="PackageDescription">
                  <div className="PackageDescriptionTitle">Includes</div>
                  <div className="PackageDescriptionText">{Check} CV Review</div>
                  <div className="PackageDescriptionText">{Check} Sample Portfolio Review</div>
                  <div className="PackageDescriptionText">{Check} LinkedIn Profile Review </div>
                  <div className="PackageDescriptionText">{Check} + 1 hour Feedback Session</div>
                </div>
                <div className="StartNowButtonContainer">
                  <Link to="/architectural-career-coaching/check-out?package=starter">
                    <Button btnClassName="PostJobButton">Get Started</Button>
                  </Link>
                </div>
              </div> */}
               
              {/* <div to="/post?package=basic" className="PostJobPackage" id="interview" >
                <div className="PackageTitle">Interview Pack</div>
                <div className="PackagePrice">£179</div>
                <div className="PackagePerAd">Whole package</div>
                <div className="PackageDescription">
                  <div className="PackageDescriptionTitle">Includes</div>
                  <div className="PackageDescriptionText">{Check} Interview Preparation</div>
                  <div className="PackageDescriptionText">{Check} Portfolio Presentation Feedback</div>
                  <div className="PackageDescriptionText">{Check} Salary Negotiation Tips</div>
                  <div className="PackageDescriptionText">{Check} + 1.5 hour Coaching Session</div>
                </div>
                <div className="StartNowButtonContainer">
                  <Link to="/architectural-career-coaching/check-out?package=interview">
                    <Button btnClassName="PostJobButton">Get Started</Button>
                  </Link>
                </div>
              </div> */}
        
            </div>
            <p/>
            <p/>
            <div className="WhyWorkWithUsSectionTitle">Meet the Coach</div>
            <div className="WhyWorkWithUsSection MeetTheCoach">
              <p>Hi, I'm Aylin. After several years working as a senior recruitment consultant specialising in architectural recruitment across the North of the UK, I launched ArchJobs in September 2022 and now dedicate my time to <b>helping people succeed in their Architectural Careers</b>. Now based in Dubai, I continue to support UK professionals while also offering guidance to those who are interested in relocating to Dubai. I have a deep understanding of the unique challenges facing job seekers and businesses in this industry, as well as the current market trends.</p> 
              <p><b>Who could benefit from working with me?</b><br></br>Whether you're a <b>graduate, an experienced architectural professional, or an overseas qualified expert</b>, looking to transition to the UK or Dubai, I'm here to provide tailored coaching.</p>
              <p>My approach is all about listening to your needs and offering actionable feedback to help you overcome any missteps or common mistakes on your architectural career path. I'm here to provide you with the insights and tools you need.</p>
              <p>I look forward to working with you.</p>
              <p><b>Aylin</b></p>
              <img src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/aylin_profile.jpg" className="AuthorImg CoachAuthorImg" alt="Aylin's Profile Image"></img>
            </div>
          
          </div>
          <div className="WhyWorkWithUsSection GreySection">
            <div className="WhyWorkWithUsSectionTitle">Learn more about each package</div>

            <div className="WhyWorkWithUsCoachingPackage"> 
              <div className = "CoachingPackageTitle">CV Review<span className="CoachingPackageSubtitle"></span> </div>
              <div className="CoachingPackageDescription">
                <p>Do you feel like your CV is missing something? Are you looking at it and wondering why you're not getting any interviews?</p>
                <p>The CV Review package is ideal for any job seeker looking for an architectural role in the UK or Dubai - whether you're a recent graduate seeking your first role, an experienced professional who hasn't searched for a new job in years, an overseas professional unfamiliar with what hiring managers look for, or someone who simply needs a fresh perspective.</p>
                <p><b>Here's what you can expect during our 1-hour call:</b></p>
                <p>After you book the session, please email your CV across so I can take an initial look. Before providing feedback on your CV, the session will start with a 10-15 minute chat where you can tell me your skills, experience, the architectural roles you're targeting, and any strategies you've already tried. This way, I can offer tailored advice to enhance your CV and improve your chances of securing an interview. I'll be ensuring your CV aligns with the role you are targeting, assessing its clarity, and suggesting layout improvements to make your CV stand out.</p>
                <p>I won't just save you time, I'll show you how to avoid common mistakes that could make the difference between landing the job or not.</p>
              </div>
              <div className="StartNowButtonContainer">
                  <Link to="/architectural-career-coaching/check-out?package=cvpack">
                    <Button btnClassName="PostJobButton">Book Now</Button>
                  </Link>
                </div>
            </div>

            <div className="WhyWorkWithUsCoachingPackage"> 
              <div className = "CoachingPackageTitle">CV + Portfolio Review<span className="CoachingPackageSubtitle"></span> </div>
              <div className="CoachingPackageDescription">
                <p>Do you ever wonder what hiring managers and recruiters look for in your CV and Portfolio? Are you unsure if your documents are too long or what to include in your Portfolio?</p>
                <p>If you just said yes, then the CV + Portfolio Review Pack is designed to answer all your questions and more. With experience in reviewing thousands of CVs and Portfolios and engaging in discussions with hiring managers, I understand what they are looking for. Whether you're a recent graduate, an overseas qualified professional, or an experienced architectural professional unsure of how to present your vast experience without overwhelming, this is for you.</p>
                <p><b>Here's what you can expect during our 1-hour call:</b></p>
                <p>After booking your session, please email your CV and Portfolio/Sample Portfolio to me so I can review them initially. You will receive everything included in the CV Review Package. Therefore, the session will begin with a 10-minute conversation before I provide feedback. During this chat, please share the specific role you are targeting and what strategies you have already employed. This will help me better understand your situation and provide tailored advice accordingly.</p>
                <p>Your CV and Portfolio are crucial documents in your job search. Hiring managers and recruiters only spend a short amount of time going through them. I will provide guidance on improving both, saving you time and removing any uncertainty about what might be missing.</p>
              </div>
              <div className="StartNowButtonContainer">
                  <Link to="/architectural-career-coaching/check-out?package=cvportfoliopack">
                    <Button btnClassName="PostJobButton">Book Now</Button>
                  </Link>
                </div>
            </div>

            {/* <div className="WhyWorkWithUsCoachingPackage"> 
              <div className = "CoachingPackageTitle">LinkedIn Review<span className="CoachingPackageSubtitle"></span> </div>
              <div className="CoachingPackageDescription">
                <p>Would you like to optimise your LinkedIn profile to attract more hiring managers and recruiters, but you're unsure if your profile is strong enough or if they can even find you in their searches? </p>
                <p>The LinkedIn Review is ideal for anyone who has recently joined the platform or wants to optimise their profile to attract the right people with the right opportunities. </p>
                <p><b>Here's what you can expect:</b></p>
                <p>The LinkedIn Review Pack is ideal for anyone who has recently joined the platform or wants to optimise their profile to attract the right people with the right opportunities. </p>
                <p>Once I know your goal, I can provide specific advice and guidance on increasing your visibility. This involves using the correct keywords, having a well-written headline and 'about' section, and expanding your professional network.</p>
                <p>As someone who has used LinkedIn to find suitable candidates for previous architectural practices, I know which keywords are crucial and what needs to be included in your profile. Let me help you save time and create a profile that not only looks good but might also increase your chances of finding a new role.</p>
              </div>
              <div className="StartNowButtonContainer">
                  <Link to="/architectural-career-coaching/check-out?package=linkedinpack">
                    <Button btnClassName="PostJobButton">Book Now</Button>
                  </Link>
                </div>
            </div> */}
            <div className="WhyWorkWithUsCoachingPackage"> 
              <div className = "CoachingPackageTitle">Strategy Call | UK <span className="CoachingPackageSubtitle"></span> </div>
              <div className="CoachingPackageDescription">
                <p>If you want to discover the best strategy for finding a new role, combined with the latest UK market insights, information on current salary ranges, and which skills are in demand, then this 1.5-hour Strategy Call is the answer to all your questions and more.</p>
                <p>This 1.5-hour session is ideal for overseas architectural professionals planning to move to the UK or for anyone seeking to enhance their job search strategies. During the call, I will share insightful job search strategies and provide up-to-date market insights, highlighting potential challenges and how to overcome them. From understanding job titles and salary ranges to what hiring managers look for and how to structure your search effectively.</p>
                <p><b>Here's what you can expect during our 1.5-hour call:</b></p>
                <p>Before our call, please email me your CV and portfolio in advance. Let's start our session with a 10-15 minute chat where you can tell me more about yourself, your goals, areas where you feel you're struggling, or any areas where you need clarity. During the strategy session, we'll pinpoint the areas you need to focus on, discuss how to structure your job search, and determine the most effective approaches tailored to your situation. Combined with up-to-date market and salary insights, you'll feel more confident in your job search, and I'll also save you time.</p>
              </div>
              <div className="StartNowButtonContainer">
                  <Link to="/architectural-career-coaching/check-out?package=strategy">
                    <Button btnClassName="PostJobButton">Book Now</Button>
                  </Link>
                </div>
            </div>
            <div className="WhyWorkWithUsCoachingPackage"> 
              <div className = "CoachingPackageTitle">Strategy Call | Dubai <span className="CoachingPackageSubtitle"></span> </div>
              <div className="CoachingPackageDescription">
                <p>Have you been thinking of relocating to Dubai but you’re not sure how to start your search, what to include in your CV and portfolio, and what the actual costs of moving and living here are? Whether you have doubts and want to speak to someone who has made the move themselves, I’ll be able to share market updates, which skills are in demand, and information on living costs.</p>
                <p>This 1.5-hour session is ideal for overseas architectural professionals planning to move to Dubai or for anyone seeking to enhance their job search strategies. During the call, I will share insightful job search strategies and provide up-to-date market insights, highlighting potential challenges and how to overcome them.</p>
                <p><b>Here's what you can expect during our 1.5-hour call:</b></p>
                <p>Before our call, please email me your CV and portfolio in advance. Let's start our session with a 10-15 minute chat where you can tell me more about yourself, your goals, areas where you feel you're struggling, or any areas where you need clarity. During the strategy session, we'll pinpoint the areas you need to focus on, discuss how to structure your job search, and determine the most effective approaches tailored to your situation.</p>
              </div>
              <div className="StartNowButtonContainer">
                  <Link to="/architectural-career-coaching/check-out?package=strategydubai">
                    <Button btnClassName="PostJobButton">Book Now</Button>
                  </Link>
                </div>
            </div>
            {/* <div className="WhyWorkWithUsCoachingPackage"> 
              <div className = "CoachingPackageTitle">Full Pack <span className="CoachingPackageSubtitle"> - Everything from the 3 packages plus more</span> </div>
              <div className="CoachingPackageDescription">
                <p>Get everything from all three packages and more. This package is perfect for anyone who wants a more hands on approach or who is not working with a recruiter. I'll be guiding you through all the steps from our initial strategy call to handing in your notice. </p>
                <p><b>Here's what you can expect:</b></p>
                <p><b>Strategy Call:</b> We start with a 1 hour strategy call to understand your current situation, goals, and recommend your next steps.</p>
                <p><b>Application Feedback:</b> Tailoring your CV, Sample Portfolio, and LinkedIn profile to your target role. Ensuring they're keyword-rich, readable, and highlight your strengths.</p>
                <p><b>Interview Prep:</b> Get ready for interviews through video calls. We cover questions, project presentations, and salary negotiations.</p>
                <p><b>Offer Review:</b> Get ready for interviews through video calls. We cover questions, project presentations, and salary negotiations.</p>
                <p><b>Resignation Guidance:</b> If you decide to make a move, I'll guide you on submitting your notice and provide a resignation letter template, ensuring a smooth transition.</p>
                <p>Throughout our coaching, I'm here to assist with anything that comes up and brainstorm ideas. Feel free to reach out at any time with additional questions.</p>
              </div>
              <div className="StartNowButtonContainer">
                  <Link to="/architectural-career-coaching/check-out?package=full">
                    <Button btnClassName="PostJobButton">Book Now</Button>
                  </Link>
                </div>
            </div> */}
          </div>
          {/* <div className="WhyWorkWithUsSection WhyWorkWithUsSectionCall">
          <div className="WhyWorkWithUsSectionTitle">Do you need advice on a different matter?</div>
              <div className="WhyWorkWithUsSectionSubtitle">Let's chat, book your FREE 15 min phone call with Aylin today. </div>
            <div className="WhyWorthWithUsHeaderButton">
              <Link to="/architectural-career-coaching/check-out?package=call">
                <Button btnClassName="PostJobButton">Request a Call</Button>
              </Link>
            </div>
          </div> */}
          <div className="WhyWorkWithUsSectionTestimonials">
            <div className="WhyWorkWithUsSectionTitle">Testimonials</div>
              <div className="WhyWorkWithUsSectionSubtitle">Hear from my happy clients</div>
                <div className="WhyWorkWithUsTestimonialColumns">
                  <div className="WhyWorkWithUsTestimonialColumn">
                  <div className="WhyWorkWithUsTestimonial">
                      <FaQuoteLeft className="TaylrHomePageTestimonialQuote"/>
                      
                      <div className="WhyWorkWithUsTestimonialText">
                        <p><p>Aylin's knowledge of the architectural industry, combined with her passion for helping individuals advance in their careers, is truly remarkable. I can't express enough how valuable Aylin's guidance and expertise have been.</p>
                        <p>In our coaching session, Aylin took the time to listen to my concerns and dilemmas, providing thoughtful insights and practical advice to help me make important decisions. I highly recommend Aylin and ArchJobs to anyone in the architectural industry who is looking for career support, job opportunities, or valuable insights. Aylin's professionalism, expertise, and unwavering commitment make her an exceptional resource in the field. Thank you, Aylin, for your invaluable support and dedication to helping professionals thrive in their careers.</p></p>
                      </div>
                      <div className="ArchJobsTestimonialProfile">
                        <div className="TestimonialName">E.A.</div>
                      </div>
                    </div>
                    
                    <div className="WhyWorkWithUsTestimonial">
                      <FaQuoteLeft className="TaylrHomePageTestimonialQuote"/>
                      <div className="WhyWorkWithUsTestimonialText">
                        <p>Being new to the country and being a recent pass out I wasn't sure if I was ticking the right boxes in terms job hunting as the market, I was used to has an entirely different approach for job seekers.</p>
                        <p>Aylin pointed me in the right direction, gave the best advice and took time to help me see things I would've missed if I were to search for jobs by myself.</p>
                      </div>
                      <div className="ArchJobsTestimonialProfile">
                        <div className="TestimonialName">S.S.</div>
                      </div>
                    </div>
                    <div className="WhyWorkWithUsTestimonial">
                      <FaQuoteLeft className="TaylrHomePageTestimonialQuote"/>
                      <div className="WhyWorkWithUsTestimonialText">
                        <p>I had the pleasure of working with Aylin as my career coach, and I cannot recommend her highly enough. Aylin's expertise in reviewing CVs and portfolios provided invaluable insights into the market landscape. Her keen eye for detail and deep understanding of industry trends helped me refine my professional materials to stand out in today's competitive job market. Aylin's guidance not only enhanced my application but also boosted my confidence in pursuing new opportunities. If you're seeking a dedicated and knowledgeable career coach, look no further than Aylin.</p>
                      </div>
                      <div className="ArchJobsTestimonialProfile">
                        <div className="TestimonialName">L.S.</div>
                      </div>
                    </div>
                    <div className="WhyWorkWithUsTestimonial">
                      <FaQuoteLeft className="TaylrHomePageTestimonialQuote"/>
                      <div className="WhyWorkWithUsTestimonialText">
                        <p>Aylin has outstanding knowledge of recruiting processes and management that enables her to be a valuable asset for any organisation. She has good connections in the industry and can fulfil any complex job requirements through her commitment in achieving the desired outcomes. </p>
                      </div>
                      <div className="ArchJobsTestimonialProfile">
                        <div className="TestimonialName">M.S.</div>
                      </div>
                    </div>
                    {/* <div className="WhyWorkWithUsTestimonial">
                      <FaQuoteLeft className="TaylrHomePageTestimonialQuote"/>
                      <div className="WhyWorkWithUsTestimonialText">
                        <p>A year ago Aylin found me my perfect job. I wasn't actively searching but I didn't feel my current position at the time was a perfect fit. She listened to what I really wanted out of a new position and my goals and aspirations for my future and I felt she really cared about what I needed not just placing me in a job. I found the communication skills and industry knowledge second to none. </p>
                      </div>
                      <div className="ArchJobsTestimonialProfile">
                        <div className="TestimonialName">I.W.</div>
                      </div>
                    </div> */}
                  </div>
                  <div className="WhyWorkWithUsTestimonialColumn">
                  <div className="WhyWorkWithUsTestimonial">
                      <FaQuoteLeft className="TaylrHomePageTestimonialQuote"/>
                      <div className="WhyWorkWithUsTestimonialText">
                        <p>I connected with Aylin two years ago due to moving locations, therefore I needed support looking for a new job opportunity. Aylin is so friendly, professional and a great listener. She took the time to understand my goals & what I was looking for - highly recommend for advice, career support and networking!</p>
                      </div>
                      <div className="ArchJobsTestimonialProfile">
                        <div className="TestimonialName">J.P.</div>
                      </div>
                    </div>
                    
                    <div className="WhyWorkWithUsTestimonial">
                      <FaQuoteLeft className="TaylrHomePageTestimonialQuote"/>
                      <div className="WhyWorkWithUsTestimonialText">
                        <p>Aylin has helped me continuously in improving my CV and gave me amazing advice. She is very helpful and I would recommend her to any graduate architects who are in need of assistance with their CV, portfolios and have any general questions about what is involved in the recruitment process and how you can gain skills to strengthen your CV. Lovely person and great advisor! </p>
                      </div>
                      <div className="ArchJobsTestimonialProfile">
                        <div className="TestimonialName">M.R.</div>
                      </div>
                    </div>
                    <div className="WhyWorkWithUsTestimonial">
                      <FaQuoteLeft className="TaylrHomePageTestimonialQuote"/>
                      <div className="WhyWorkWithUsTestimonialText">
                        <p>I just wanted to take a moment to share my experience with Aylin at ArchJobs. From the very beginning, Aylin made me feel comfortable and heard. She took the time to understand my career goals and aspirations and has been a constant support in my job search journey.</p>
                        <p>Aylin's genuine and personal approach sets her apart from others in the industry, and she definitely cares about helping people find the right fit for their skills and interests.</p>
                        <p>I highly recommend ArchJobs to anyone looking for an architectural-related role in the UK. The way the job board is structured and highlights important keywords makes it easy to use. Thank you!</p>
                      </div>
                      <div className="ArchJobsTestimonialProfile">
                        <div className="TestimonialName">J.W.</div>
                      </div>
                    </div>
                    <div className="WhyWorkWithUsTestimonial">
                      <FaQuoteLeft className="TaylrHomePageTestimonialQuote"/>
                      <div className="WhyWorkWithUsTestimonialText">
                        <p>Aylin is well-versed in her field and understands any inquiries that may have you emotionally encumbered. She has followed up with me and has given further insights beyond expectations.</p>
                      </div>
                      <div className="ArchJobsTestimonialProfile">
                        <div className="TestimonialName">D.S.</div>
                      </div>
                    </div>
                  </div>
            </div>
        </div>
            <p/>
          <div className="WhyWorkWithUsSection ">
            <FAQCoaching/>
          </div>
          <div className="WhyWorkWithUsSection Bottom">
          <div className="WhyWorkWithUsSectionTitle WhyWorkWithUsSectionTitleBold">Ready to invest in yourself and move your architectural career in the right direction? </div>
              <div className="WhyWorkWithUsSectionSubtitle">Choose the package which suits you and your situation best.</div>
            <div className="WhyWorthWithUsHeaderButton">
                <Button btnClassName="PostJobButton" onClickFunc = {() => {
                  // scroll to id "ChoosePackageSection" 50px above it
                  var element = document.getElementById("ChoosePackageSection");
                  var headerOffset = 50;
                  var elementPosition = element.getBoundingClientRect().top;
                  var offsetPosition = elementPosition - headerOffset;

                  window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth"
                  });

                }}>Let's Go</Button>
            </div>
          </div>
          <div className="BottomPadding"></div> 
          <ChatWidget />
        </div>
      )
    }
  }

  export default WhyWorkWithUsCoaching