import React, { Component, useState } from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MultipleSelectChip from './MultiSelect.js'
import OutlinedInput from '@mui/material/OutlinedInput';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { CircularProgress, Box, Typography  } from '@mui/material';
import Quill from 'quill';
import Button from '@mui/material/Button';
import tags from '../data/tags.js'

const SubmitUrl = "https://api.archjobs.co.uk/add_recruiter"

const OverwriteUrl = "https://api.archjobs.co.uk/ammend_recruiter"

const LocationURL = "https://api.archjobs.co.uk/get_location"

const JobTitles = tags.JobTitles

const Sectors = tags.Sectors

const Software = tags.Software

const WorkArrangements = tags.WorkArrangements

const JobType = tags.JobType

const OtherTags = tags.OtherTags

const AutoCompletePrompt = ``

export const Regions = ['North East', 'North West', 'Yorkshire', 'East Midlands', 'West Midlands', 'East of England', 'London', 'South East', 'South West', 'Wales', 'Scotland', 'Northern Ireland', 'UK (All Regions)']

// table format
// recruiters (
//     #     recruiter_id INT NOT NULL AUTO_INCREMENT,
//     #     recruiter_name Text,
//     #     company_name varchar(255) NOT NULL,
//     #     company_website Text,
//     #     profile_description Text,
//     #     profile_url text,
//     #     linkedin_url text,
//     #     phone_number text,
//     #     banner tinyint,
//     #     role_blacklist text,
//     #     area_blacklist text,
//     #     demo tinyint,
//     #     recruiter_title Text)


const fields = [
    {id: "recruiter_name", field_name:"recruiter_name", required: true},
    {id: "recruiter_title", field_name:"recruiter_title", required: true},
    {id: "company_name_recruiter", field_name:"company_name", required: true},
    {id: "company_website_recruiter", field_name:"company_website", required: false},   
    {id: "profile_description", field_name:"profile_description", required: true},
    {id: "recruiter_email", field_name:"recruiter_email", required: true},
    {id: "profile_url", field_name:"profile_url", required: false},
    {id: "linkedin_url", field_name:"linkedin_url", required: false},
    {id: "phone_number", field_name:"phone_number", required: false},
    {id: "experience_text", field_name:"experience_text", required: false},
    {id: "expires", field_name:"expires", required: false, type: "date"},
    {id: "experience_start", field_name:"experience_start", required: false, type: "date"},
    // {id: "role_blacklist", field_name:"role_blacklist", required: false},
    // {id: "area_blacklist", field_name:"area_blacklist", required: false},
    {id: "Demo_recruiter", field_name:"demo", required: false, type: "checkbox"},
    {id: "Banner_recruiter", field_name:"banner", required: false, type: "checkbox"},
]

let state_fields = {}
fields.forEach(field => {
  let value = ""
  if (field.type == "numeric") {
    value = 0
  }
  if (field.type == "checkbox") {
    value = false
  }
  if (field.default_value) {
    value = field.default_value
  }
  state_fields[field.id] = {...field, value: value}
})

const tag_fields = [
//   {id: "Sectors", tag_type: "sector"},
//   {id: "Software", tag_type: "software"},
//   {id: "WorkArrangements", tag_type: "work_arrangement"},
  {id: "JobType", tag_type:"job_type"},
  {id: "JobTitles", tag_type:"job_title"},
  {id: "JobTitleBlacklist", tag_type:"job_title_blacklist"},
  {id: "Regions", tag_type:"region"},
  {id: "Locations", tag_type:"location"},
  {id: "HighlightLocation", tag_type:"highlight_location"},
  {id: "HighlightJobType", tag_type:"highlight_job_type"},
//   {id: "OtherTags", tag_type:"other"},
//   {id: "experience", tag_type:"experience"},
]

let state_tag_fields = {}
tag_fields.forEach(field => {
  state_tag_fields[field.id] = {...field, value: []}
})


const application_fields = [
//   {id: "ApplyWebsite", field_name:"apply_method", required: false, type: "checkbox", multiple: 16, default_value: false},
//   {id: "ApplyCoverLetter", field_name:"apply_method", required: false, type: "checkbox", multiple: 4, default_value: false},
//   {id: "ApplyPortfolio", field_name:"apply_method", required: false, type: "checkbox", multiple: 2, default_value: true},
//   {id: "ApplyCV", field_name:"apply_method", required: false, type: "checkbox", multiple: 1, default_value: true},
]

let state_application_fields = {}
application_fields.forEach(field => {
  state_application_fields[field.id] = {...field, value: false}
})

function CompleteJobButton(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleClick = async () => {
    setIsLoading(true);
    setMessage('');
    let jobSubmission = {}
    jobSubmission.auth = props.password

    jobSubmission.profile_description = props.getJobDescription()
    
    jobSubmission.prompt = AutoCompletePrompt
    
    try {
      const response = await axios.post('https://api.archjobs.co.uk/job_completion', {
        body: JSON.stringify(jobSubmission),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        }
      });
      if (response.data.choices) {
        props.parseData(response.data.choices[0].message.content)
      }
      console.log("response: ", response)
      setMessage(response.data.message);
    } catch (error) {
      setMessage('Error updating manual job pages');
    }
    setIsLoading(false);
  };

  return (
    <div>
      <Button onClick={handleClick} disabled={isLoading}  variant="contained" className="SubmitFormButton">
        AutoComplete
      </Button>
      {isLoading && <CircularProgress sx={{ ml: 2 }} size={20} />}
      {message && (
        <Typography variant="body2" sx={{ ml: 2, color: message.startsWith('Error') ? 'error.main' : 'success.main' }}>
          {message}
        </Typography>
      )}
    </div>
  );
}

class RecruiterAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validated: {},
      location_input: [""],
      expires: null,
      experience_start: null,
      job_submission_status: {},
      checkbox: {
        Demo_recruiter: false,
        Banner_recruiter: false,
      },
      fields: state_fields,
      tag_fields: state_tag_fields,
      application_fields: state_application_fields,
      overwrite: null,
      locations: [],
    };  
    this.quill = null
    this.multiSelectValues = {}
  }    

  onFieldChange = (event, value) => {
    console.log(event, value)
    const new_fields = {...this.state.fields} 
    let target_id = event.target.id
    if (target_id.includes("-option-")){
        target_id = target_id.split("-option-")[0]
    }
    if (typeof value == "undefined"){
      new_fields[target_id].value = event.target.value
    } else {
      if (value !== null){
        new_fields[target_id].value = value
      }
    }
    this.setState({fields: new_fields})
  }

  onTagFieldChange = (event, value) => {
    console.log(event, value)
    const new_fields = {...this.state.tag_fields} 
    let target_id = event.target.id
    if (target_id.includes("-option-")){
      target_id = target_id.split("-option-")[0]
    }
    new_fields[target_id].value = value
    this.setState({tag_fields: new_fields})
  }

  componentDidMount = () => {
    if (!this.quill){
      this.quill = new Quill('#recruiterinput', {
        theme: 'snow',
        formats: [
          'bold',
          'header',
          'italic',
          'link',
          'list',
          'blockquote',
          'image',
          'indent'
        ],
        modules: {
          toolbar: [
            [{'header': 1}, {'header': 2}],
            ['bold', 'italic', 'link'],
            ['blockquote', {'list': 'ordered'}, {'list': 'bullet'}],
          ],
          clipboard: {
            matchVisual: false // https://quilljs.com/docs/modules/clipboard/#matchvisual
          }
        }
      });
    }

    if (this.props.recruiter_data && Object.keys(this.props.recruiter_data).length > 0){
      this.setJobData(this.props.recruiter_data)
    }
    axios.get("https://api.archjobs.co.uk/locations").then(response => {
        var location_list = response.data.results//+" ("+String(location.jobs)+")")
        this.setState({locations: location_list})
        // console.log(location_list)
    })
  }


  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.recruiter_data !== this.props.recruiter_data){
      console.log("recruiter_data changed", this.props.recruiter_data)
      this.setJobData(this.props.recruiter_data)
      
    }
  }

  updateMultiSelectValue = (id, value) => {
    this.multiSelectValues[id] = value
    const new_fields = {...this.state.tag_fields} 
    new_fields[id].value = value
    this.setState({tag_fields: new_fields})
  }

  setJobData = (recruiter_data) => {
    console.log("recruiter_data: ", recruiter_data)
    const new_state = {}
    const new_state_fields = {}
    let checkbox = {}
    for (const field of fields){
      let value = ""
      if (field.type == "numeric") {
        value = 0
      }
      if (field.default_value) {
        value = field.default_value
      }
      new_state_fields[field.id] = {...field, value: value}
      var input = document.getElementById(field.id)
      if (input != null){
        if (recruiter_data[field.field_name]) {
          if (field.type == "checkbox"){
            checkbox[field.id] = true
          } else {
            new_state_fields[field.id].value = recruiter_data[field.field_name]
          }
        } else {
          console.log(field.field_name, " not in data ")
        }
      } else {
        console.log("Couldnt get input: ", field.id)
      }
    }

    new_state.fields = new_state_fields

    
    //job description
    if (this.quill){
      this.quill.root.innerHTML = recruiter_data["profile_description"]
    }
    //deadline
    if(recruiter_data["expires"]){
      new_state.expires = new Date(recruiter_data["expires"])
    }
    if(recruiter_data["experience_start"]){
      new_state.experience_start = new Date(recruiter_data["experience_start"])
    }

    const new_tag_fields = {}
    //tags
    tag_fields.forEach(field => {
      new_tag_fields[field.id] = {...field, value: []}
      if (field.tag_type in recruiter_data.parsed_tags){
        new_tag_fields[field.id].value = recruiter_data.parsed_tags[field.tag_type]
        this.multiSelectValues[field.id] = recruiter_data.parsed_tags[field.tag_type]
      }
    })

    if (recruiter_data["role_blacklist"]){
      new_tag_fields["JobTitleBlacklist"].value = recruiter_data["role_blacklist"].split(",")
      this.multiSelectValues["JobTitleBlacklist"] = recruiter_data["role_blacklist"].split(",")
    }

    if (recruiter_data["locations"]){
      new_tag_fields["Locations"].value = recruiter_data["locations"].map(location => location.city)
      this.multiSelectValues["Locations"] = recruiter_data["locations"].map(location => location.city)
    }

    new_state.tag_fields = new_tag_fields


    new_state.overwrite = recruiter_data.recruiter_id
    new_state.checkbox = checkbox
    this.setState(new_state)
    console.log(new_state)
    
  }

  submitJob = (params) => {
    //assemble all the data from various fields
    console.log(params)
    this.setState({job_submission_status: {status: "loading"}})

    var jobSubmission = {data: {}, locations: [], tags: []}

    for (const field of fields){
      var input = document.getElementById(field.id)

      if (input != null){
        
        if (field.type == "checkbox"){
          jobSubmission.data[field.field_name] = this.state.checkbox[field.id] ? 1: 0
        } else if(field.type == "numeric") {
          var int_value = parseInt(input.value)
          if (int_value <= 0){
            int_value = null
          }
          jobSubmission.data[field.field_name] = int_value
        } else {
          if ((input.value == "") && (field.required == true)){
            this.setState({job_submission_status: {status: "error", message: "Required field "+field.field_name+" not filled out"}})
            return
          } else {
            jobSubmission.data[field.field_name] = input.value.trim()
          }
          jobSubmission.data[field.field_name] = input.value.trim()
        }
      } else {
        console.log("Couldnt get input: ", field.id)
      }
    }
    // expires
    if(this.state.expires !== null){
      jobSubmission.data["expires"] = new Date(this.state.expires).toISOString().split("T")[0]
    } else {
      var expires_date = new Date()
      expires_date.setMonth(expires_date.getMonth() + 3)
      jobSubmission.data["expires"] = expires_date.toISOString().split("T")[0]
    }
    // experience_start
    if(this.state.experience_start !== null){
      jobSubmission.data["experience_start"] = new Date(this.state.experience_start).toISOString().split("T")[0]
    }
    //job description
    if (this.quill){
      var job_description_content = this.quill.root.innerHTML
      console.log(job_description_content)
      if (String(job_description_content) == "<p><br></p>"){
        this.setState({job_submission_status: {status: "error", message: "No Profile Description"}})
        return
      }
      jobSubmission.data["profile_description"] = job_description_content
    }
    if (!(params && params.overwrite)){ 
      var submit_date = new Date()
      jobSubmission.data["date_added"] = submit_date.toISOString().replace("T", " ").split(".")[0]
      submit_date.setMonth(submit_date.getMonth() + 3)
      
      jobSubmission.data["times_shown"] = 0
    } else {
      jobSubmission.data.recruiter_id = this.state.overwrite
    }
    
    //locations
    jobSubmission.locations = []
    //tags
    // jobSubmission.regions = []
    //jobSumission.

    let role_blacklist = []
    tag_fields.forEach(field => {
      if (field.id in this.multiSelectValues){
        for (const value of this.multiSelectValues[field.id]){
            if (field.tag_type == "job_title_blacklist"){
                role_blacklist.push(value)
            } else if (field.tag_type == "location"){
                //find the location
                const location = this.state.locations.filter(location => location.city == value)[0]
                if (location){
                    jobSubmission.locations.push(location)
                }
            } else {
                jobSubmission.tags.push({tag_name: value, tag_type: field.tag_type})
            }
        }
      }
    })

    jobSubmission.data["role_blacklist"] = role_blacklist.join(",")

    jobSubmission.auth = this.props.password
    console.log(jobSubmission)
    if(params && params.overwrite){
      axios.post(OverwriteUrl, {
        body: JSON.stringify(jobSubmission),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        }
      }).then(response => {
        window.alert("Updated Recruiter")
        console.log(response.data)
      }).catch(error =>{
        window.alert("Error updating Recruiter: "+ String(error))
        console.log("error: ", error)
      })
    } else {
      axios.post(SubmitUrl, {
        body: JSON.stringify(jobSubmission),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        }
      }).then(response => {
        window.alert("Added Recruiter")
        console.log(response.data)
      }).catch(error =>{
        window.alert("Error adding Recruiter: "+ String(error))
        console.log("error: ", error)
      })
    }

  }

  getJobDescription = () => {
    if (this.quill){
      var job_description_content = this.quill.root.innerHTML
      console.log(job_description_content)
      return job_description_content
    }
  }

  deleteJob = () => {
    // window.alert("Not implemented "+String(this.state.overwrite))
    axios.get("https://api.archjobs.co.uk/disable_recruiter?recruiter_id="+this.state.overwrite).then(response => {
      window.alert("deleted recruiter id "+String(this.state.overwrite))
      console.log(response.data)
    }).catch(error =>{
      console.log("error: ", error)
    })
  }


  handleChange = (event) => {
    this.setState({checkbox: {...this.state.checkbox, [event.target.id]: event.target.checked}});
  };

  parseData = (data) => {
    console.log(data)
    const new_fields = {...this.state.fields} 
    fields.forEach(field => {
      const match = data.match(new RegExp(`${field.id}:\n> (.*)(?=\n)`, "m"));
      console.log("match: ", match, field.id)
      if (match && match[1].trim() !== "N/A") {
        if (field.type=="numeric"){
          new_fields[field.id].value = parseInt(match[1].trim())
        } else {
          new_fields[field.id].value = match[1].trim();
        }
      }
    })

    const new_tag_fields = {...this.state.tag_fields}
    tag_fields.forEach(field => {
      const match = data.match(new RegExp(`${field.id}:\n> (.*)(?=\n)`, "m"));
      if (match && match[1].trim() !== "N/A") {
        new_tag_fields[field.id].value = match[1].trim().split(", ");
      }
    })


    this.setState({fields: new_fields, tag_fields: new_tag_fields})
  }

  render() {

      return <div className="JobSubmissionContainer"  >
      <div className="JobSubmissionRow" hidden={this.props.hidden}>
        <div className="JobSubmissionHeader">Recruiter Profile</div>
        <div id="recruiterinput" className="JobDescriptionInput" updateMultiSelectValue={this.updateMultiSelectValue.bind(this)}></div>
        {/* <div className="AutocompleteButtonContainer">
          <CompleteJobButton getJobDescription={this.getJobDescription} parseData={this.parseData} password={this.props.password}></CompleteJobButton>
        </div> */}
      </div>
        
      <div className="JobSubmission" hidden={this.props.hidden}>
        <div className="JobSubmissionTitle">
          Add a Recruiter
        </div>
        <div className="JobSubmissionRow">
            <div className="JobSubmissionHeader">Recruiter Name</div>
            <Autocomplete
                required
                id="recruiter_name"
                className="PostJobInput"
                value = {this.state.fields.recruiter_name.value}
                onChange={(event, newValue) => this.onFieldChange(event, newValue)}
                inputValue = {this.state.fields.recruiter_name.value}
                onInputChange= {(event, newValue) => this.onFieldChange(event, newValue)}
                freeSolo
                options={[]}
                renderInput={(params) => <TextField {...params} label="Recruiter Name" />}
              />
          </div>
          <div className="JobSubmissionRow">
            <div className="JobSubmissionHeader">Job Title</div>
            <Autocomplete
                required
                id="recruiter_title"
                className="PostJobInput"
                value = {this.state.fields.recruiter_title.value}
                onChange={(event, newValue) => this.onFieldChange(event, newValue)}
                inputValue = {this.state.fields.recruiter_title.value}
                onInputChange= {(event, newValue) => this.onFieldChange(event, newValue)}
                freeSolo
                options={[]}
                renderInput={(params) => <TextField {...params} label="Job Title" />}
              />
          </div>
        <div className="JobSubmissionRow">
            <div className="JobSubmissionHeader">Company Name</div>
            <Autocomplete
                required
                id="company_name_recruiter"
                className="PostJobInput"
                value = {this.state.fields.company_name_recruiter.value}
                onChange={(event, newValue) => this.onFieldChange(event, newValue)}
                inputValue = {this.state.fields.company_name_recruiter.value}
                onInputChange= {(event, newValue) => this.onFieldChange(event, newValue)}
                freeSolo
                options={[]}
                renderInput={(params) => <TextField {...params} label="Company Name" />}
              />
          </div>
          <div className="JobSubmissionRow">
            <div className="JobSubmissionHeader">Company Website</div>
            <Autocomplete
                id="company_website_recruiter"
                className="PostJobInput"
                value = {this.state.fields.company_website_recruiter.value}
                onChange={(event, newValue) => this.onFieldChange(event, newValue)}
                inputValue = {this.state.fields.company_website_recruiter.value}
                onInputChange= {(event, newValue) => this.onFieldChange(event, newValue)}
                freeSolo
                options={[]}
                renderInput={(params) => <TextField {...params} label="Company Website" />}
              />
          </div>
          <div className="JobSubmissionRow">
            <div className="JobSubmissionHeader">Experience (Text)</div>
            <Autocomplete
                id="experience_text"
                freeSolo
                className="PostJobInput"
                value = {this.state.fields.experience_text.value}
                onChange={(event, newValue) => this.onFieldChange(event, newValue)}
                inputValue = {this.state.fields.experience_text.value}
                onInputChange= {(event, newValue) => this.onFieldChange(event, newValue)}
                options={[]}
                renderInput={(params) => <TextField {...params} multiline minRows={3} label="Experience" />}
              />
          </div>
          <div className="JobSubmissionRow">
          <div className="JobSubmissionHeader">Experience Start (To work out how many years)</div>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              className="PostJobInput"
              value={this.state.experience_start}
              onChange={(newValue) => {
                {this.setState({experience_start: newValue})}
              }}
              inputFormat="dd/MM/yyyy"
              id="deadline"
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          </div>
          <div className="JobSubmissionRow">
            <div className="JobSubmissionHeader">Areas Covered</div>
            <MultipleSelectChip className="PostJobInput" values={Regions.sort()} id="Regions" updateMultiSelectValue={this.updateMultiSelectValue.bind(this)} value = {this.state.tag_fields.Regions.value}/>
          </div>
          <div className="JobSubmissionRow">
            <div className="JobSubmissionHeader">Specialist Cities</div> 
            <MultipleSelectChip className="PostJobInput" values={this.state.locations.map(location => location.city)} id="Locations" updateMultiSelectValue={this.updateMultiSelectValue.bind(this)} value = {this.state.tag_fields.Locations.value}/>
          </div>
          <div className="JobSubmissionRow">
            <div className="JobSubmissionHeader">Highlight City (Choose 1)</div> 
            <MultipleSelectChip className="PostJobInput" values={this.state.locations.map(location => location.city)} id="HighlightLocation" updateMultiSelectValue={this.updateMultiSelectValue.bind(this)} value = {this.state.tag_fields.HighlightLocation.value}/>
          </div>
          <div className="JobSubmissionRow">
            <div className="JobSubmissionHeader">Specialist Job Roles (Max 5)</div>
            <MultipleSelectChip className="PostJobInput" values={JobTitles.sort()} id="JobTitles" updateMultiSelectValue={this.updateMultiSelectValue.bind(this)} value = {this.state.tag_fields.JobTitles.value}/>
          </div>
          <div className="JobSubmissionRow">
            <div className="JobSubmissionHeader">Job Role Blacklist</div>
            <MultipleSelectChip className="PostJobInput" values={JobTitles.sort()} id="JobTitleBlacklist" updateMultiSelectValue={this.updateMultiSelectValue.bind(this)} value = {this.state.tag_fields.JobTitleBlacklist.value}/>
          </div>
          
          <div className="JobSubmissionRow">
            <div className="JobSubmissionHeader">Specialist Job Types</div>
            <MultipleSelectChip className="PostJobInput" values={JobType.sort()} id="JobType" updateMultiSelectValue={this.updateMultiSelectValue.bind(this)} value = {this.state.tag_fields.JobType.value}/>
          </div>
          <div className="JobSubmissionRow">
            <div className="JobSubmissionHeader">Highlight Job Type (Choose 1)</div>
            <MultipleSelectChip className="PostJobInput" values={JobType.sort()} id="HighlightJobType" updateMultiSelectValue={this.updateMultiSelectValue.bind(this)} value = {this.state.tag_fields.HighlightJobType.value}/>
          </div>
          
          {/* <div className="JobSubmissionRow">
            <div className="JobSubmissionHeader">Other Tags</div>
            <MultipleSelectChip values={OtherTags.sort()} id="OtherTags" updateMultiSelectValue={this.updateMultiSelectValue.bind(this)} value = {this.state.tag_fields.OtherTags.value}/>
          </div> */}
        <div className="JobSubmissionRow">
            <div className="JobSubmissionHeader">Contact Info</div>
            <Autocomplete
                className="PostJobInput"
                id="recruiter_email"
                value = {this.state.fields.recruiter_email.value}
                onChange={(event, newValue) => this.onFieldChange(event, newValue)}
                inputValue = {this.state.fields.recruiter_email.value}
                onInputChange= {(event, newValue) => this.onFieldChange(event, newValue)}
                freeSolo
                options={[]}
                renderInput={(params) => <TextField {...params} label="Email Address" />}
              />
        </div>
        <div className="JobSubmissionRow">
          <Autocomplete
                className="PostJobInput"
                id="profile_url"
                value = {this.state.fields.profile_url.value}
                onChange={(event, newValue) => this.onFieldChange(event, newValue)}
                inputValue = {this.state.fields.profile_url.value}
                onInputChange= {(event, newValue) => this.onFieldChange(event, newValue)}
                freeSolo
                options={[]}
                renderInput={(params) => <TextField {...params} label="Profile URL (Company Website)" />}
              />
          </div>
          <div className="JobSubmissionRow">
            <Autocomplete
                    className="PostJobInput"
                    id="linkedin_url"
                    value = {this.state.fields.linkedin_url.value}
                    onChange={(event, newValue) => this.onFieldChange(event, newValue)}
                    inputValue = {this.state.fields.linkedin_url.value}
                    onInputChange= {(event, newValue) => this.onFieldChange(event, newValue)}
                    freeSolo
                    options={[]}
                    renderInput={(params) => <TextField {...params} label="LinkedIn URL" />}
                />
          </div>
          <div className="JobSubmissionRow">
            <Autocomplete
                    className="PostJobInput"
                    id="phone_number"
                    value = {this.state.fields.phone_number.value}
                    onChange={(event, newValue) => this.onFieldChange(event, newValue)}
                    inputValue = {this.state.fields.phone_number.value}
                    onInputChange= {(event, newValue) => this.onFieldChange(event, newValue)}
                    freeSolo
                    options={[]}
                    renderInput={(params) => <TextField {...params} label="Phone Number" />}
                />
          </div>
          <div className="JobSubmissionRow">
            <div className="JobSubmissionHeader">Other Details</div>
            <FormGroup>
              <FormControlLabel control={<Checkbox id="Banner_recruiter" checked={this.state.checkbox.Banner_recruiter} onChange={this.handleChange}/>} label="Banner Image" />
              <FormControlLabel control={<Checkbox id="Demo_recruiter" checked={this.state.checkbox.Demo_recruiter} onChange={this.handleChange}/>} label="Demo" />      
            </FormGroup>
          </div>
          <div className="JobSubmissionRow">
          <div className="JobSubmissionHeader">Expires (Leave blank for 3 months)</div>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              className="PostJobInput"
              value={this.state.expires}
              onChange={(newValue) => {
                {this.setState({expires: newValue})}
              }}
              inputFormat="dd/MM/yyyy"
              id="expires"
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          </div>
          <div className="JobSubmissionRow">
            <div className="JobSubmissionHeader">Image Names</div>
            <div className="JobSubmissionHeader">Profile Image: {this.state.fields.recruiter_name.value.replace(/[\W_]+/g, "").replace(/[ +]/g, "").toLowerCase() + ".png"}</div>
            <div className="JobSubmissionHeader">Banner Image: {this.state.fields.recruiter_name.value.replace(/[\W_]+/g, "").replace(/[ +]/g, "").toLowerCase() + "_banner.png"}</div>
          </div>
          <div className="JobSubmissionSubmitRow">
            <Button variant="contained" className="SubmitFormButton" color="success" onClick = {() => {this.submitJob()}}>Submit New</Button>
            <div className="ErrorMessage">
              {this.state.job_submission_status.status == "error" && this.state.job_submission_status.message}
            </div>
            <div className="SuccessMessage">
              {this.state.job_submission_status.status == "success" && this.state.job_submission_status.message}
            </div>
            {this.state.overwrite && <div><Button variant="contained" className="SubmitFormButton" color="warning" onClick = {() => {this.submitJob({overwrite: true})}}>Overwrite</Button>
            <div className="ErrorMessage">
              {this.state.job_submission_status.status == "error" && this.state.job_submission_status.message}
            </div>
            <div className="SuccessMessage">
              {this.state.job_submission_status.status == "success" && this.state.job_submission_status.message}
            </div></div>}
            {this.state.overwrite && <div><Button variant="contained" className="SubmitFormButton" color="error" onClick = {() => {this.deleteJob()}}>Delete</Button></div>}
          </div>
      </div>
    </div>
    }
}

/* export the component to be used in other components */
export default RecruiterAdd