import ArticleFrame from "./ArticleFrame";
import React, { Component } from 'react';


export default class WriteCV extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
      };
    }
    componentDidMount = () => {
      window.scrollTo(0, 0)
    }

    render(){
        return <ArticleFrame ArticleDataName="WriteCV" >
          <p>A good CV is a keystone for any job search. It is the first thing employers and recruiters will see when they look at your application. But how does my CV stand out from the crowd? Do I need to use keywords? What is the best CV format? How does an ATS (Applicant Tracking System) impact my application? What does not belong on my CV?... Don&rsquo;t worry, I will answer some of the most frequently asked questions and will show you how to structure your CV including an example as well.&nbsp;</p>
          <p/>
          <p/>
          <h3>What does CV stand for?</h3>
          <p><em>&ldquo;A CV is a short written description of your education, qualifications, previous jobs, and sometimes also your personal interests, that you send to an employer when you are trying to get a job</em>.&rdquo;</p>
          <p>Source: <a href="https://dictionary.cambridge.org/dictionary/english/cv" target="_blank" rel="nofollow noopener">Cambridge Dictionary</a>&nbsp;&nbsp;</p>
          <p/>
          <p/>
          <h3>General CV Formatting Tips</h3>
          <div>File type: PDF&nbsp;</div>
          <div>File size: Max 5MB</div>
          <div>Font type: Easy to read fonts are Arial, Times New Roman or Calibri</div>
          <div>Font size: 10-11</div>
          <div>Pages: Ideally 1 - 2</div>
          <div>Structure: Always start with your most recent work experience</div>
          <div>Naming your file: First and last name - CV. (i.e. <em>Jane Doe - CV</em>)&nbsp;</div>
          <p/>
          <p/>
          <h3>Basic Structure of your CV</h3>
          <div>After looking through thousands of CVs in my time, I&rsquo;ve noticed one particular CV structure which seems to be liked by most hiring managers.&nbsp;</div>
          <p>A rough outline is as follows:</p>
          <ol>
          <li>Full name, current Job Title, member of an affiliation (ARB, ACIAT, MCIAT etc.)</li>
          <li>Personal Details (phone, email and location)&nbsp;</li>
          <li>About Me/Profile section (quick snapshot of who you are, your level of experience, education and what you are after)</li>
          <li>Education/Awards&nbsp;</li>
          <li>Soft-Skills section - for example, job running, presentation, leadership, design, and team player (only list the most relevant skills)</li>
          <li>Software &amp; Tools section - for example, Revit, AutoCAD, Photoshop, InDesign, SketchUp, ArchiCAD, Vectorworks, Microsoft Teams.</li>
          <li>Working History - begin with your most recent experience (this is very important)</li>
          <div>7.1. Job Title, Company name, Location, Dates (start - finish)</div> 
          <div>7.2. Summarise your responsibilities and transferable skills (if applicable), and mention any work highlights - I worked on a new build mixed-use scheme - supported the team with producing tender packages, attended site meetings and dealt with the planning applications.&nbsp;</div>
          <li>Hobbies (optional but could be a nice ice breaker)</li>
          <li>References - Available upon Request</li>
          </ol>
          <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/example_architecture_cv.png" alt="An example CV"></img>
          <p/>
          <h3>Are the CV templates on Canva compliant with ATS?</h3>
          <p>After doing more research, it appears that <a href="https://www.canva.com/" target="_blank" rel="nofollow noopener">Canva</a> provides you with a lot of good-looking CV templates; however, they are not optimised for <a href="https://www.archjobs.co.uk/articles/how-to-create-an-ats-friendly-architectural-cv" target="_blank" rel="noopener">Application Tracking Systems (ATS)</a>. Instead, I would suggest creating your CV using one of the free Google Docs templates or using the website <a href="https://www.tealhq.com/" target="_blank" rel="nofollow noopener">Teal</a>.</p>
          <p>Please note that to create your CV through Google Docs, you will need to create a Google account (but I&rsquo;m sure most of us have one already). Simply click on Google Drive, then click on the big plus 'New' button, and select 'Google Docs' -&gt; 'From a template&rsquo;.</p>
          <p>As of now, Google Docs offers 5 different resume/CV templates. I personally prefer the layout of &ldquo;Resume Serif&rdquo;.</p>
          <p/>
          <p/>
          <h3>Let&rsquo;s get started</h3>
          <div>Most people would just start listing out their previous jobs and experience, however I&rsquo;d recommend before you write anything, start by looking for some suitable job openings - you can either go on <a href="https://www.archjobs.co.uk/" target="_blank" rel="noopener">ArchJobs</a>, Indeed or LinkedIn.&nbsp;</div>
          <p>Once you find a few interesting job postings, read through them and look for commonly used keywords - do they mention any specific software (Revit, Vectorworks, AutoCAD, ArchiCAD), leadership skills, design skills, years of experience, level of degree, problem-solving skills, business development and communication skills? Highlight all relevant keywords as you will need to incorporate them in your CV.&nbsp;</p>
          <p>The more you tailor your CV to the job brief, the higher the chance someone will contact you.</p><p>&nbsp;
          </p>
          <h3>How do I make my CV stand out?</h3>
          <div>For your CV to stand out, it needs to be well written, free of errors, well structured, and relevant for the position you are applying for - see the section above - Basic Structure of your CV.&nbsp;</div>
          <p>There are some useful websites where you can use free CV templates. One page I used to recommend to candidates is Canva.com. It is easy to use and you can choose from different CV templates.</p>
          <p/>
          <p/>
          <h3>How long should a CV be?</h3>
          <div>Ideally, your CV should fit on 1 page - A4. However, I appreciate that some of you have worked for multiple companies and gained relevant experience. In that case, try and keep it down to a maximum of 2 pages.&nbsp;</div>
          <p>Don&rsquo;t be afraid to cut out experience that isn&rsquo;t relevant or was too long ago, but don&rsquo;t leave gaps.</p><p>&zwj; 
          </p>
          <h3>&zwj;Do I need a Cover Letter?</h3>
          <div>It depends on the position and company. Usually, the company will specify in the job description if they want a Cover Letter or not.</div>
          <p>Read our guide on <a href="https://www.archjobs.co.uk/articles/how-to-structure-an-architecture-cover-letter" target="_blank" rel="noopener">How to Structure an Architecture Cover Letter</a>.&nbsp;</p>
          <p>&zwj;&zwj;
          </p>
          <h3>Should I tailor my CV/Cover Letter whenever I apply for a new job?</h3>
          <div>Yes, I would highly recommend spending that extra time amending your CV/Cover Letter. Hiring managers can tell if a CV/Cover Letter has been tailored to a specific job or not.&nbsp;</div>
          <p>Don&rsquo;t forget, first impressions count and you only have their attention for a short time. Make every application count - more time spent on tailoring your applications will reduce your job search time massively.</p>
          <p><strong>Did you know:</strong> <a href="https://www.archjobs.co.uk/" target="_blank">ArchJobs</a> provides you with the hiring manager's name? Always read the &ldquo;Application Notes&rdquo; section (as shown in the image below).&nbsp;</p>
          <p> <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Address your Cover Letter to - ArchJobs.png" alt="ArchJobs website screenshot displaying the 'How To Apply' section, which includes the contact name for the job listing"></img></p>
          <p>&zwj;
          </p>
          <h3>How does an Applicant Tracking System (ATS) impact my application?</h3>
          <div>Most recruitment agencies and mid to large size companies use ATS systems which lets them store, filter, organise, and track applications but also search their database for suitable candidates by entering relevant keywords (skills, location, job titles etc.).&nbsp;</div>
          <p>It's important to understand that when submitting your application via an ATS, you're sending it to software and not an actual person. Therefore, your CV needs to be ATS-friendly and optimised to ensure that it passes the initial screening and makes it to a human reviewer.</p>
          <p>To learn more on this particular subject, read <a href="https://www.archjobs.co.uk/articles/how-to-create-an-ats-friendly-cv" target="_blank" rel="noopener">How to create an ATS-Friendly CV</a>.</p>
          <p/>
          <p/>
          <h3>Which Keywords should I use?&nbsp;</h3>
          <div>Whether you&rsquo;re sending your application directly or uploading your details via a company's recruitment portal using relevant keywords will help your application stand out.&nbsp;</div>
          <p>Below are the most commonly used keywords for any architectural positions:</p>
          <p><em>Presentation skills, technical skills, design skills, concept design, planning applications, site visits, coordination, liaising with clients, UK Building Regulations, communication skills, Revit, Autocad, Photoshop, Sketchup, InDesign etc.</em></p>
          <p/>
          <p>💡<strong>Did you know:</strong> Before you submit your application on <a href="https://www.archjobs.co.uk/" target="_blank" rel="noopener">ArchJobs</a>, you should check if your Cover Letter and/or CV include the right keywords.</p>
          <p>Applications that contain relevant keywords are much more likely to be shortlisted for interviews.&nbsp;All the keywords are hand-picked by us based on the job description and company website.</p>
          <p>See the image below.</p>
          <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/example_keywords.png" alt="ArchJobs website screenshot displaying the most relevant keywords"></img>
          <p/>
          <p/>
          <h3>Do recruiters and hiring managers read every single word?</h3>
          <div>No chance. They will look for job titles, keywords, companies you worked for, length of employment and skill sets.&nbsp;</div>
          <p>That is why a well-structured CV that catches their eye by reflecting keywords from the job description is so important - this is what they are scanning for.&nbsp;</p>
          <p>Once you have their attention, they will look at your CV in more detail.</p>
          <p>&zwj;</p>
          <h3>Do I need to list all previous jobs on my CV?</h3>
          <div>You do not need to list all of your previous jobs on your CV. That usually applies to candidates with more than 15-20 years of experience.&nbsp;</div>
          <p>For example, if you are applying for a Senior Architect role, would it be necessary to mention that you worked part-time as a Barrister back in the 90s? The short answer is no.&nbsp;</p>
          <p>The hiring manager is more interested in your recent experience.</p>
          <p>You might be interested in reading: <a href="https://www.archjobs.co.uk/articles/how-to-write-a-senior-architectural-cv" target="_blank" rel="noopener">What to include in a Senior Architectural CV</a>.&nbsp;</p>
          <p>&nbsp;</p>
          <h3>Do I need to add a picture of myself?</h3>
          <div>No, you do not need to add a picture of yourself, and I would advise against it. There is a law in the UK, Ireland and the United States which protects job seekers from being discriminated against.&nbsp;</div>
          <p>That being said, people might have unconscious biases and you don&rsquo;t want to give anyone a reason to dismiss you before even getting to interview.</p>
          <p>&ldquo;<em>It is against the law to treat someone less favourably than someone else because of a personal characteristic such as religion, sex, gender reassignment or age.</em>&rdquo;&nbsp;</p>
          <p>Source: <a href="https://www.gov.uk/employer-preventing-discrimination" target="_blank" rel="noopener">Gov.uk</a></p>
          <p>&zwj;</p>
          <h3>Should I add any hobbies?</h3>
          <div>Yes, you can add hobbies but this is optional. If you decide to add some hobbies, keep it short and sweet. Maybe you and the hiring manager have something in common and it could break the ice and make you more memorable.</div>
          <p>💡<strong>Tip:</strong> When listing software skills, hobbies, and experience levels, avoid using images, diagrams, or pie charts. Instead, write them out clearly, like "Revit, Photoshop, AutoCAD&rdquo;. The ATS won&rsquo;t be able to read/translate the images.&nbsp;</p>
          <p> <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/Adobe Creative Suite Logos_new.png" alt="Image of the Adobe Creative Suite Logos - Credit to: Godline Art"></img></p>
           <p/>
           <p/>
          <h2>&zwj;Here are a few things you should avoid:&nbsp;</h2>
          <p/>
          <h3>Too much personal information</h3>
          <div>Please don&rsquo;t share too much personal information like your marital status, religion, nationality, address, number of children, full date of birth and passport details.</div>
          <p>&zwj;</p>
          <h3>Do not lie about non-existing skills or accomplishments&nbsp;</h3>
          <div>Yes, you might get invited to an interview, but they will question you about your skills and experience. You don&rsquo;t want to be called out by hiring managers as they won&rsquo;t give you another chance.&nbsp;</div>
          <p>Be honest and upfront, say that you&rsquo;re looking to gain more experience in X and you&rsquo;re already doing some additional courses. Lying will only waste both your time and the hiring manager's time.</p>
          <p>&zwj;</p>
          <h3>Don&rsquo;t provide a list of your references&nbsp;</h3>
          <div>There is no reason why you should enclose your referee's full contact details i.e. name, phone and email address.&nbsp;</div>
          <p>Just write - &ldquo;Available on request&rdquo; and wait until asked to provide some contact details.</p>
          <p>&zwj;</p>
          <h3>Don&rsquo;t mention your current salary or salary expectations</h3>
          <div>If you disclose your salary or expectations, it could negatively impact you in three ways.&nbsp;</div>
          <ul>
          <li>Your salary expectations are too high,&nbsp;</li>
          <li>Your salary expectations are too low</li>
          <li>You might have eliminated your chance to negotiate a higher salary.</li>
          </ul>
          <p>If your salary expectations are too high and the hiring manager/recruiter is aware that their budget won&rsquo;t stretch that far, they would not contact you.&nbsp;</p>
          <p>If your salary expectations are too low, they will wonder why. They might think you lack knowledge or don&rsquo;t value your experience/skill set.&nbsp;</p>
          <p>And number three - they already know your minimum number. Let&rsquo;s say you wrote that you would accept &pound;40k - the employer would not offer you more than &pound;40k as you took your negotiation abilities away.</p>
          <p>&zwj;</p>
          <h2>Conclusion</h2>
          <p>Writing a standout architecture CV is important for a successful job search. It serves as your first impression to employers and recruiters, making it essential to get it right. By following the recommended structure, tailoring your CV to match job descriptions, including relevant keywords, and <a href="https://www.archjobs.co.uk/articles/how-to-create-an-ats-friendly-architectural-cv" target="_blank" rel="noopener">optimising it for Applicant Tracking Systems (ATS)</a>, you significantly increase your chances of being noticed in the competitive job market. Remember, quality over quantity applies, so focus on relevant details and skills. With a well-structured and targeted CV, you're well on your way to landing your desired architecture position. If you have any further questions or need guidance, don't hesitate to <a href="https://www.archjobs.co.uk/architectural-career-coaching" target="_blank" rel="noopener">reach out for tailored career coaching</a>.</p>
            </ArticleFrame>
    }
}