
import React from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import Button from '../components/Buttons.js'
import Autocomplete from '@mui/material/Autocomplete';
import tags from '../data/tags.js'
import Quill from 'quill';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import {MdClose} from 'react-icons/md';
import Checkbox from '@mui/material/Checkbox';
import axios from 'axios';
import { BsLaptop, BsChevronLeft } from 'react-icons/bs'
import MultipleUpload from '../components/MultipleUpload.js'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const checkoutLinks = {
  "basic": "https://buy.stripe.com/7sIbJd3kNagAfrW5kD",
  "standard": "https://buy.stripe.com/fZefZtbRj3Sc1B6cMN",
  "premium": "https://buy.stripe.com/8wMcNh2gJfAU5RmdQS"
}

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <MdClose />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};



class ContactForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            job_submission_status: {},
            success_open: false,
            package: "basic",
        }
        this.quill = null
        this.multiSelectValues = {}
        this.files = []
      }    
    
      componentDidMount = () => {
        if (!this.quill){
          this.quill = new Quill('#jobdescriptioninput', {
            theme: 'snow',
            formats: [
              'bold',
              'header',
              'italic',
              'link',
              'list',
              'blockquote',
              'image',
              'indent'
            ],
            modules: {
              toolbar: [
                [{'header': 1}, {'header': 2}],
                ['bold', 'italic', 'link'],
                ['blockquote', {'list': 'ordered'}, {'list': 'bullet'}],
              ],
              clipboard: {
                matchVisual: false // https://quilljs.com/docs/modules/clipboard/#matchvisual
              }
            }
          });
        }
        if (window.location.search){
          var urlParams = new URLSearchParams(window.location.search);
          var SelectedPackage = urlParams.get('package')
          if (SelectedPackage){
            this.setState({package: SelectedPackage})
          }
        }
        window.scrollTo(0, 0)
      }

      updateFiles = (files) => {
        this.files = files
      }

      updateMultiSelectValue = (id, value) => {
        this.multiSelectValues[id] = value
      }

      Submit = () => {
        console.log("submitting")
        this.setState({job_submission_status: {status: "loading"}})



        const fields = [
            {id: "name", field_name:"contact_name", required: true, label: "Full Name"},
            {id: "phone", field_name:"phone", required: true, label: "Contact Number"},
            {id: "email", field_name:"email", required: true, label: "Email Address"},
          ]

          var jobSubmission = new FormData()

          jobSubmission.append("type", this.props.type)

          for (const field of fields){
            var input = document.getElementById(field.id)
      
            if (input != null){
              if (field.type == "checkbox"){
                jobSubmission.append(field.field_name, input.checked ? 1: 0)
              } else if(field.type == "numeric") {
                var int_value = parseInt(input.value)
                if (int_value < 0){
                  int_value = null
                }
                jobSubmission.append(field.field_name, int_value)
              } else {
                if ((input.value == "") && (field.required == true)){
                  this.setState({job_submission_status: {status: "error", message: "Required field "+field.label+" not filled out", field: field.id, field_message: "Required Field"}})
                  return
                } else {
                  jobSubmission.append(field.field_name, input.value)
                }
                jobSubmission.append(field.field_name, input.value)
              }
            } else {
              console.log("Couldnt get input: ", field.id)
            }
        }
    
        //job description
        if (this.quill){
            var job_description_content = this.quill.root.innerHTML
            // console.log(job_description_content)
            jobSubmission.append("message", job_description_content)
        }

        this.files.forEach(file => {
          jobSubmission.append("file",file)
        })
        

        console.log(jobSubmission)
        const SubmitUrl = "https://api.archjobs.co.uk/customer_signup_rec" 

        axios({
              url: SubmitUrl,
              method: "POST",
              data: jobSubmission,
              headers: {
                'Content-Type': 'multipart/form-data'
              }
          }).then(response => {
            console.log(response.data)
            if ("error" in response.data){
                this.setState({job_submission_status: {status: "error", message: "Could not submit form, please email me at aylin@archjobsrecruitment.com and let us know!"}})
            } else {
                this.setState({success_open: true, job_submission_status: {status: "success"}})
            }
          }).catch(error =>{
            this.setState({job_submission_status: {status: "error", message: "Could not submit form, please email us at aylin@archjobsrecruitment.com instead"}})
          })
      }

      handleClose = () => {
        this.setState({success_open: false})
      }

      handlePackageChange = (newPackage) => {
        this.setState({package: newPackage})
      }

    render() {

    var error_text = ""

      return (
        <div className="PostJobPage">
          {/* <Link className="ResultsGoBack" to={-1} ><BsChevronLeft className="BackArrow"/>Go Back</Link> */}
                <div className="PostJobForm">
                    <div className="PostJobFormHeader">
                      Contact Form
                    </div>

                    <div className="PostJobFormInputRow">
                        <div className="PostJobFormInputTitle">Full Name</div>
                        <TextField
                            variant="outlined"
                            id="name"
                            className="PostJobInput"
                            type="text"
                            placeholder="Full Name"
                            error={this.state.job_submission_status.field == "name"}
                            helperText={(this.state.job_submission_status.field == "name") && this.state.job_submission_status.field_message}
                            />
                    </div>
                    <div className="PostJobFormInputRow">
                        <div className="PostJobFormInputTitle">Contact Number</div>
                        <TextField
                            variant="outlined"
                            id="phone"
                            className="PostJobInput"
                            type="text"
                            placeholder="Contact Number"
                            error={this.state.job_submission_status.field == "phone"}
                            helperText={(this.state.job_submission_status.field == "phone") && this.state.job_submission_status.field_message}
                            />
                    </div>
                    <div className="PostJobFormInputRow">
                        <div className="PostJobFormInputTitle">Email Address</div>
                        <TextField
                            variant="outlined"
                            id="email"
                            className="PostJobInput"
                            type="email"
                            placeholder="Contact Email"
                            error={this.state.job_submission_status.field == "email"}
                            helperText={(this.state.job_submission_status.field == "email") && this.state.job_submission_status.field_message}
                            />
                    </div>
                    
                    <div className="PostJobFormInputRow">
                        <div className="PostJobFormInputTitle">More Details
                          {this.props.type == "Candidate"  ? <div className="JobSubmissionsubtitle">What are you looking for in your next role in Dubai? Position, Sector, Salary etc.</div>
                          :<div className="JobSubmissionsubtitle">What are you looking for in your next role in Dubai? Position, Sector, Salary etc.</div>} 
                        </div>
                        <div className="JobDescriptionInputContainer">
                            <div id="jobdescriptioninput" className="JobDescriptionInput" ></div>
                        </div>
                    </div>
                    <div className="PostJobFormInputRow TopAligned">
                        {this.props.type == "Candidate"  ? <div className="PostJobFormInputTitle">Upload CV & Sample Portfolio</div>:<div className="PostJobFormInputTitle">Attach Files</div>}
                        <div className="FileInputContainer">
                          {this.props.type == "Candidate"  ?<div className="PostJobFormText"> 
                            Upload your CV/Portfolio and any other relevant files via the Upload Files button below. Max total file size is 15MB (pdf, doc, docx, jpeg).
                          </div>:<div className="PostJobFormText">
                            Upload any files you think might be relevant to our conversation (ie. Job Description). Max total file size is 15MB (pdf, doc, docx, jpeg).
                          </div>}
                          
                          <MultipleUpload updateFiles = {this.updateFiles}/>
                        </div>
                    </div>
                    <div className="PostJobFormSubmit"> 
                        <Button btnClassName="JobAdHeaderApplyButton" onClickFunc = {() => {this.Submit()}}>Send</Button>
                    </div>
                    <div className="PostJobFormError"> 
                        {(this.state.job_submission_status.status == "error") && this.state.job_submission_status.message}
                    </div>

            </div>
            <BootstrapDialog
                onClose={this.handleClose}
                aria-labelledby="customized-dialog-title"
                open={this.state.success_open}
                className="SuccessModal"
            >
                <div className="ModalClose"><MdClose className="CloseIcon" onClick={this.handleClose}></MdClose></div>
                <div className="SuccessModalTitle">Your message has been sent!</div>
                <div className="SuccessModalText">I'll try to get back to you as soon as possible</div>
                                          
            </BootstrapDialog>
        </div>
      )
    }
  }

  export default ContactForm