import React, {Component} from 'react'
import '../css/button.css'


function createRipple(event) {
    const button = event.currentTarget;
  
    const circle = document.createElement("span");
    const diameter = Math.max(button.clientWidth, button.clientHeight);
    const radius = diameter / 2;
  
    circle.style.width = circle.style.height = `${diameter}px`;
    circle.style.left = `${event.clientX - button.offsetLeft - radius}px`;
    circle.style.top = `${event.clientY - button.offsetTop - radius}px`;
    circle.classList.add("ripple");
  
    const ripple = button.getElementsByClassName("ripple")[0];
  
    if (ripple) {
      ripple.remove();
    }
  
    button.appendChild(circle);
  }
  

export default class Button extends Component {
    constructor(props) {
        super(props);
        this.state = { 
         };
      }
      


    componentDidMount = () => {
        const buttons = document.getElementsByTagName("button");
        for (const button of buttons) {
          button.addEventListener("click", createRipple);
        }
    }

    render() {

        var className="Button"
        if (this.props.btnClassName){
            className += " "+this.props.btnClassName
        }

        return (
            <button className={className} type="button" onClick={this.props.onClickFunc} disabled={this.props.disabled}> {this.props.children} </button>
        )
    }
}