import ArticleFrame from "./ArticleFrame";
import React, { Component } from 'react';


export default class DubaiGuide extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
      };
    }
    componentDidMount = () => {
      window.scrollTo(0, 0)
    }

    render(){
        return <ArticleFrame ArticleDataName="DubaiGuide" >
          <p>Earlier this year, my partner and I decided to leave the UK and move to Dubai. It was a big step, and before we made our decision, we went through all the pros and cons and concluded that Dubai would suit our goals. In this article, I will share some observations on the Dubai job market for architectural professionals and answer questions such as: Is the title of architect protected here? What are employers looking for in my application documents? Will you really earn double your current income when relocating to Dubai? I will also cover what you need to know before packing your bags and things I wish I had known beforehand and much more.</p>
          <p/>
          <h2>The Structure of this Article</h2>
          <ol>
          <li>Why did we move from the UK to Dubai</li>
          <li>A few facts and stats about Dubai</li>
          <li>Moving to Dubai</li>
          <li>Looking for an architectural job in Dubai</li>
          <li>Working in Dubai</li>
          <li>Living in Dubai</li>
          <li>Things you need to know before moving to Dubai</li>
          </ol>
          <p/>
          <p/>
          <h2>Section 1: Why did we move from the UK to Dubai, UAE?</h2>
          <p>For over three years now, my partner Sam and I have had the goal of building our own online businesses (<a target="_blank" href="http://metatft.com">MetaTFT</a> and mine is <a target="_blank" href="http://archjobs.co.uk">ArchJobs</a> a platform to help architectural professionals to move their careers forward), which would give us the freedom to live and work anywhere in the world. In all honesty, Dubai was never on our radar, and we’d never visited the city either.</p> 
          <p>Earlier this year, we found ourselves in a position where we wanted to pack our bags and leave the UK behind. As you might imagine, it was quite a big decision and overwhelming as well. Where shall we move? So, we had to put our strategy heads on and think about what was important to us, what our non-negotiables were, the possible pros and cons, and so on. After a lot of research, it came down to Budapest, Hungary, and Dubai, UAE. Both were quite different from one another, but both had their pros and cons.</p>
          <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/edinburgh to dubai.png" alt="Google map showing the distance between Edinburgh, UK to Dubai, UAE. It's a 9 hour direct flight."></img>
          <p>We ended up choosing Dubai for several reasons:</p>
           <ul>
            <li>Much better business opportunities for both of us</li>
            <li>Cost of living vs. quality of life</li>
            <li>One of the safest cities in the world</li>
            <li>We love travelling, and Dubai Airport has excellent connections</li>
            <li>Better weather&nbsp;</li>
            <li>English is widely spoken here</li>
            <li>We could take our dog Otto with us</li>
            <li>The no-tax policy is a nice benefit, but as business owners, we would have to pay income tax if we passed a certain threshold</li></ul>
          <p>Don’t get me wrong, Dubai is not for everyone, but for us, it was 110% the right choice and even after living here for a short time, I can say we have zero regrets.</p>
          <p/>
          <p/>
          <h2>Section 2: A few facts and stats about Dubai</h2>
          <p>Dubai is a vibrant and dynamic city known for its futuristic architecture, luxury shopping, and lively nightlife. As the most populous city in the United Arab Emirates (UAE) and the capital of the Emirate of Dubai, it offers a unique blend of modernity and tradition.</p>
            <ul>
              <li><strong>Currency:</strong> UAE Dirham (AED)</li>
              <li><strong>Main languages:</strong> Arabic and English</li>
              <li><strong>Population:</strong> Around 3 million, with only 15% of the population being UAE citizens</li>
              <li><strong>Number of British expats:</strong> Approximately 240,000</li>
              <li><strong>Saftey:</strong> Dubai ranked the 4th saftest city in 2024 (source: <a href="https://www.numbeo.com/crime/rankings.jsp?title=2024&displayColumn=1" target="_blank" rel="nofollow noopener">Numbeo</a>)</li>
              <li><strong>Government:</strong> Dubai has been ruled by the Al Maktoum family since 1833, the emirate is an absolute monarchy.</li>
              <li><strong>Religion:</strong> The local population is predominately Muslim, and most of the expatriate population is also Muslim, although there are significant Christian, Hindu, and Sikh communities.</li>
          </ul>
          <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/dch2small.jpg" alt="This photo captures a beautiful sunset scene featuring the Burj Khalifa in Dubai. The image is framed by lush greenery and vibrant flowers in the foreground, creating a picturesque contrast with the modern skyline in the background. The tall, iconic structure of the Burj Khalifa stands prominently against a softly lit sky, transitioning from warm golden hues near the horizon to a cool blue as it ascends. The overall composition is serene and highlights both the natural beauty and architectural marvels of Dubai."></img>
          <p/>
          <p/>
          <h2>Section 3: Looking for an architectural job in Dubai</h2>
          <p>I will share with you the differences between the UK and Dubai. Overall, I would say the Dubai architectural job market is much more active. On the downside, you have professionals from all over the world who want to live and work here. It can be difficult to stand out, and it can be even harder if you do not have a network or have not worked on projects in the UAE or Saudi Arabia. But let’s dig into it.</p>
          <p/>
          <h3>How competitive is the architectural job market in Dubai?</h3>
          <p>It is competitive. A lot of professionals from all over the world want to work in the Middle East or Saudi Arabia. They want to be part of some of the most forward-thinking designs. Just look at the Burj Khalifa, the Museum of the Future, the Dubai Frame, etc. If you are genuinely thinking of moving here, what is your edge? How will you stand out from the competition? Do you have something others might not have?
          </p>
          <p/>
          <p/>
          <h3>What are employers looking for in my CV?</h3>
          <p>I have not noticed a huge difference in the layout or structure of your CV between the UK and Dubai. Pretty much the same rules apply to an easy-to-read layout, name, contact details, job title, education, list your software skills, work history plus your responsibilities and so on. If you are a more junior - recently qualified professional, a one-page CV should be enough. However, if you have a few more years under your belt, aim for at least two pages, as you don’t want to undersell yourself.</p>
          <p><strong>Bonus Points:</strong> If you already have a UAE phone number, please add it. If not, please don’t forget to add the country code in front of your number.</p>
          <p/>
          <p/>
          <h3>What do employers want to see in my Portfolio?</h3>
          <p>Think about it: here in Dubai, you are surrounded by high-rise residential, mixed-use, hospitality, commercial buildings, and high-end villas. They want to see similar things in your portfolio. If your experience lies solely in small-scale residential projects or small extensions, then you’ll struggle. Those sorts of schemes are so different from what is going on in Dubai that you will find it very difficult to secure a role. I have not noticed any difference in how you should present your portfolio in Dubai. When applying for a position, the same rules apply - always submit a sample portfolio which shouldn’t be longer than 10-12 pages. If you are getting invited for am interview, take your full portfolio with you.</p>
          <p>If you want support on how to create strong application documents, <a target="_blank" href="https://www.archjobs.co.uk/architectural-career-coaching">feel free to schedule a coaching call with me</a>. Let me help you save time by guiding you on what will work.</p>
          <p/>
          <p/>
          <h3>Which software are the most common in Dubai?</h3>
          <p>Revit and Rhino are the most requested software skills. You should have at least good Revit skills and a basic understanding of Rhino. The projects are incredibly fast-paced, and you want to hit the ground running. Grasshopper and Adobe Creative Suite are also popular.          </p>
          <p><strong>Tip:</strong> If you feel like you are currently missing those skills or could enhance them, work on a project to improve those skills. If not, you could also use the online platform <a target="_blank" href="https://archademia.com/" rel="nofollow noopener">ArchAdemia</a>.</p>
          <p/>
          <p/>
          <h3>Is the Architect's title protected in Dubai?</h3>
          <p>No, the architect's title is not protected in the UAE. However, having studied at a RIBA-accredited university and still being RIBA Chartered and ARB registered goes a long way here. You might be wondering why. Well, the education system is held in high regard here, and therefore employers have high expectations from ARB/RIBA registered professionals.</p>
          <p>This means you can use your current Architect title in Dubai, <a target="_blank" href="https://www.archjobs.co.uk/articles/moving-to-the-uk-as-an-overseas-architect">unlike in the UK where you have to be ARB-registered in order to call yourself an Architect</a>.</p>
          <p>There are only two RIBA-accredited universities in the UAE: Abu Dhabi University and Heriot-Watt University in Dubai. De Montfort University in Dubai is hopefully on the path to accreditation.</p>
          <p/>
          <p/>
          <h3>Architectural job titles in Dubai</h3>
          <p>As the title of architect is not protected here, you will find quite a different mix of job titles. The more international practices such as BDP, Foster + Partners, Woods Bagot, and Gensler will most likely use similar titles to those in the UK.</p>
          <p>Whereas the local practices might have various titles. What I’m trying to say here is, don’t just fixate on the title but focus more on the responsibilities and salary package.</p>
          <p/>
          <p/>
          <h3>Are Salaries advertised per month or per annum?</h3>
          <p>Just to clarify, when you see a job advert in Dubai, the salaries are always listed per month and not per annum. At first, it confused me when I saw salaries such as 20k AED, but those are per month.</p>
          <p/>
          <p/>
          <h3>What are the architectural salary ranges in Dubai?</h3>
          <p>The salaries vary a lot, and you might even struggle to find a “salary guide” — which can be good. You won’t be boxed in by your job title and years of experience; instead, practices will focus more on your skill set and what you can bring to the table. That said, a recently qualified ARB-registered architect can expect a minimum of 17k AED per month, while senior professionals can earn up to 40-50k AED per month. It's difficult to provide more conclusive figures as salaries depend on your skills, experience, and the specific practice.</p>
          <p><strong>Hint:</strong> The higher the salary on offer, the higher the expectations, which might include long hours and a heavy workload. You’ll have to think about what is important to you.</p>
          <p/>
          <p/>
          <h3>Are there any specific skill sets practices are looking for?</h3>
          <p>Yes, I would definitely say there are certain skills practices are looking for in Dubai, such as strong technical skills, communication skills, adaptability, problem-solving, creativity, presentation skills, familiarity with large-scale and complex projects, resilience, and the ability to work under pressure. Unless you have worked on projects in the UAE or Saudi Arabia, you most likely don’t know what to expect. It’s incredibly fast-paced, and if you think you’re busy right now, you’d better buckle up. Most construction sites are active 24/7. So when I say fast-paced, I mean it.</p>
          <p/>
          <p/>
          <h3>Will companies pay for my Visa and Healthcare Insurance?</h3>
          <p>Yes, when you receive an offer, the company will also pay for your visa and your healthcare insurance. What usually happens is the following: You accept an offer, and the new company will already start the visa process even if you have not moved to Dubai yet.</p>
          <p>At one point in the visa process, you will need to come to Dubai and take a health check. They will take a blood sample and a chest x-ray. There are only two outcomes - you’re either fit or unfit. If you are deemed “unfit”, you won’t be able to get a visa, and your journey has ended. However, if you pass your health check, the next step is getting your fingerprints taken. After that, you are pretty much done, and within a week or so, you should receive your visa and Resident Identity Card. On there, it will list your occupation and your employer.</p>
          <p>Regarding your healthcare insurance, each company will offer a slightly different package/healthcare provider. But remember, your employer must provide you with one. </p>
          <p/>
          <p/>
          <h3>What are the best ways to find a job in Dubai?</h3>
          <p>When you’re looking for a new job in the UK, there are various ways to find one. You can use online job boards, apply through company websites, work with a recruiter, use your network, or send out speculative applications.</p><p>The biggest difference I have noticed in Dubai is that your network plays a much bigger role than in the UK. Submitting your application via job boards might not get you very far. If you know someone in Dubai or maybe one of your colleagues has moved here, reach out to them. They might be able to put in a good word for you or get you in contact with the right person. Another alternative would be to work with an <a target="_blank" href="https://archjobsrecruitment.com/">architectural recruiter</a> who might be able to support you in your job search.</p><p>Also, if you can come over and spend time in Dubai while looking for a job (you’ll most likely be on a tourist visa), that will be your best strategy. Don’t forget that there are a lot of professionals who want to work in Dubai, and the competition is fierce.</p>          <p/>
          <p/>
          <h3>Can a recently qualified Architect find a job in Dubai </h3>
          <p>From what I’ve seen and heard, recently ARB-qualified Architects have a chance if they have strong technical skills and experience working on large-scale and/or international schemes. However, as mentioned earlier, if you have only worked on small projects, don’t have good Revit skills, and aren’t driven enough, you will struggle.</p>
          <p/>
          <p/>
          <h2>Section 4: Working in Dubai</h2>
          <p>Working in Dubai as an architectural professional will be different from what you’re most likely used to. Depending on the practice you join, you’ll have the chance to work on incredible projects, collaborate with talented professionals from all over the world, push the boundaries of what is possible, and see your projects being built much faster. However, there are also a few things you might not have known, such as the typical working hours and how most practices approach hybrid working. Not every professional will thrive in Dubai and you will need to be driven, adaptable and thick-skinned.</p>
          <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/zh.jpg" alt="A picture I took from inside the Opus building designed by Zaha Hadid. "></img>
          <p/>
          <p/>
          <h3>International and local architectural practices</h3>
          <p>You have a great mix of international and local architectural practices here. Usually, the bigger practices offer a better pay package than the local firms. Some practices will also offer to pay for your flight to Dubai and provide accommodation for your first month, giving you a chance to find something more long-term.</p>
          <p/>
          <p/>
          <h3>What’s the working culture like?</h3>
          <p>"Work hard, play hard" is what immediately comes to mind. I don’t want to put anyone off, but most professionals don’t move here to work from 9 till 5 and enjoy the sunshine. The reason why most come here is that you will have opportunities to not only work on some incredible projects that push the boundaries of what is possible but also work with very talented professionals from all over the world. Working in an office with lots of different nationalities can boost your creativity and allow you to learn from others.
          </p>
          <p>As you probably know, every practice and company is different. When you have an interview, ask specific questions to get a good feel for the office culture.</p>
          <p/>
          <p/>
          <h3>Which sectors are thriving in Dubai?</h3>
          <p>The sectors thriving the most in Dubai are high-rise residential, hospitality, cultural, retail, and high-end private residential.</p>
          <p/>
          <p/>
          <h3>How much tax do I pay in Dubai?</h3>
          <p>As an employee, you won't pay any income tax in Dubai, which is a significant financial advantage compared to many other countries. Below is a table showing how much money you can save if you are on the exact same salary. However, there is a high chance that you would earn a higher base salary in Dubai, meaning you save even more money.</p>
          <div>
              <table>
                <tbody>
                  <tr><td><strong>Base Salary</strong></td><td><strong>UK Take Home</strong></td><td><strong>UK Tax (%)</strong></td><td><strong>Dubai Take Home</strong></td><td><strong>Dubai Tax (%)</strong></td><td><strong>Saving</strong></td></tr><tr><td>£30,000.00</td><td>£24,771.00</td><td>17.43%</td><td>£30,000.00</td><td>0%</td><td>£5,229.00</td></tr><tr><td>£35,000.00</td><td>£28,271.00</td><td>19.23%</td><td>£35,000.00</td><td>0%</td><td>£6,729.00</td></tr><tr><td>£40,000.00</td><td>£31,771.00</td><td>20.57%</td><td>£40,000.00</td><td>0%</td><td>£8,229.00</td></tr><tr><td>£45,000.00</td><td>£35,271.00</td><td>21.62%</td><td>£45,000.00</td><td>0%</td><td>£9,729.00</td></tr><tr><td>£50,000.00</td><td>£38,771.00</td><td>22.46%</td><td>£50,000.00</td><td>0%</td><td>£11,229.00</td></tr><tr><td>£55,000.00</td><td>£41,703.40</td><td>24.18%</td><td>£55,000.00</td><td>0%</td><td>£13,296.60</td></tr><tr><td>£60,000.00</td><td>£44,603.40</td><td>25.66%</td><td>£60,000.00</td><td>0%</td><td>£15,396.60</td></tr></tbody></table></div>   
          <p/>
          <p/>
          <h3>What are the standard working hours in Dubai?</h3>
          <p>The standard working hours in Dubai are 48 hours per week, which is very normal for the private sector. I know it sounds like a lot, especially if you’re used to working in the UK where standard hours are between 35-40 hours per week. But from speaking to professionals who have made the move, they knew what they were getting themselves into and are working on such incredible schemes that seeing them built is just a great feeling.</p>
          <p/>
          <p/>
          <h3>Is hybrid working a thing in Dubai?</h3>
          <p>To be honest, hybrid working is not really a thing here. You will most likely work in the office five days a week. Of course, there might be some flexibility if you need to look after the kids or see the doctor. I know since Covid a lot of architectural practices in the UK have switched to a minimum of working from home twice a week but again, Dubai is not the UK. There will be certain differences you’ll have to accept.</p>
          <p>Also, it's good to know that some of the UK's most rural places can also be quite expensive. So, be sure to do your research.</p>
          <p/>
          <p/>
          <h3>Is there a minimum wage in Dubai?</h3>
          <p>No, Dubai has no minimum wage, and therefore salaries can vary a lot.</p>
          <p/>
          <p/>
          <h3>Annual Holidays plus Bank Holidays in Dubai?</h3>
          <p>In your first year, it starts with 24 days per year, and once you pass your first year within your company, it goes up to 30 days per year. Don’t forget, you’ll also get 18 bank holidays on top of your holiday allowance, whereas in the UK, you only get 8 bank holidays.</p>
          <p/>
          <div>
            <table>
                <tbody>
                  <tr>
                    <td>
                      <strong>Date</strong>
                    </td>
                    <td>
                      <strong>No. Of Days</strong>
                    </td>
                    <td>
                      <strong>Holiday</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>8 April</td>
                    <td>9</td>
                    <td>Eid-ul-Fitr</td>
                  </tr>
                  <tr>
                    <td>15 June</td>
                    <td>1</td>
                    <td>Arafaat Day</td>
                  </tr>
                  <tr>
                    <td>16 June</td>
                    <td>3</td>
                    <td>Eid-ul-Adha</td>
                  </tr>
                  <tr>
                    <td>7 July</td>
                    <td>1</td>
                    <td>Islamic New Year</td>
                  </tr>
                  <tr>
                    <td>15 September</td>
                    <td>1</td>
                    <td>Prophet Birthday</td>
                  </tr>
                  <tr>
                    <td>1 December</td>
                    <td>1</td>
                    <td>Commemoration Day</td>
                  </tr>
                  <tr>
                    <td>2 December</td>
                    <td>1</td>
                    <td>UAE National Day</td>
                  </tr>
                  <tr>
                    <td>1 January</td>
                    <td>1</td>
                    <td>New Year Evening</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p>2024 UAE Public Holidays</p>
          <p/>
          <p/>
          <h2>Section 5: Moving to Dubai</h2>
          <p>I'll speak from my own experience here, knowing everyone's situation is different. You might have kids, a mortgage, pets, etc., so let me set the scene for you. I moved from Edinburgh, Scotland to Dubai with my partner, Sam, and our dog, Otto. We lived in a rented apartment and we’re both self-employed. In total, we shipped 8 boxes to Dubai and each flew with a large suitcase. Sam flew out 2 weeks before Otto and me. We felt it would be too stressful if we all flew out at the same time. This way, Sam could sort out his Visa and scout the area before we arrived, and honestly, it worked quite well.</p>
          <p>When we first moved to Dubai, we decided to rent a short-term apartment for just under 2 months and stayed in Dubai Marina. That way we have enough time to visit different districts and get a better feel for the city.</p>
          <p>However, we did have non-negotiables when looking for an apartment and after viewing different areas in Dubai we fell in love with Dubai Creek Harbour.&nbsp;</p>
          <ul>
            <li>At least 2 bedrooms, one to serve as our office.</li>
            <li>Pet-friendly accommodations and a dog-friendly neighbourhood (Dubai Marina wasn’t).</li>
            <li>No new construction next to our tower, as the noise and dust were too much (we lived in Cayan Tower near Ciel Tower, the tallest hotel in the world).</li>
            <li>Good gym and pool facilities.</li>
            <li>Quiet area with a nice view.</li>
            <li>Proximity to the airport (Dubai Creek Harbour was only a 15-minute taxi ride).</li>
            <li>EMAAR property (the best developer) with good tower reviews.</li>
          </ul>
          <p/>
          <p/>
          <h3>How to get your pet to Dubai</h3>
          <p>As you may know, we brought our dog, Otto, and opted to work with a company specialising in pet transfers (costs vary based on size and service). It was a door-to-door service, meaning they handled all the necessary paperwork, import documents, flight arrangements, picked him up from our flat, liaised with authorities, and delivered him to our new flat in Dubai Marina. I'm definitely glad we chose to work with the pet transport company. However, please check if your dog/cat breed is allowed to enter the UAE.</p>
          <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/ottohappy.jpg" alt="A picture of Otto in his dog bed after arriving in Dubai."></img>
          <p><strong>Good to know:</strong> We didn't need a dog passport, and Otto didn't have to quarantine in Dubai. Up-to-date vaccination papers were all that was required to bring him from the UK to Dubai - a massive bonus. However, please check the latest guidelines or those from your home country.</p>
          <p/>
          <p/>
          <h3>Can I move and work in Dubai before I receive my visa?</h3>
          <p>Yes, you can move and work here. When you first come here, you will be on a tourist visa. Once you receive your Resident Identity Card, your visa will automatically switch. So, that’s very straightforward.
          </p>
          <p/>
          <p/>
          <h2>Section 6: Living in Dubai </h2>
          <p>Living in Dubai is very different from the UK, but personally, I really enjoy it. It might surprise some to hear that living costs in Dubai are cheaper than in London, and it is also very safe. I also want to discuss the rental market so you know what to expect, along with a few other points. However, you can easily spend a lot of money here, with abundant choices in accommodations, activities, shopping, restaurants, events, and more.</p>
          <p/>
          <p/>
          <h3>How does the cost of living in Dubai compare to London, UK?</h3>
          <p>As already mentioned above the cost of living in Dubai is cheaper than in London. However, it’s very important to understand that you have much higher upfront costs when relocating to Dubai. The renting situation is different here than in most places I’ve lived at. More on the renting below.</p>
          <p>There's a website called <a href="https://www.numbeo.com/cost-of-living/compare_cities.jsp?country1=United+Kingdom&country2=United+Arab+Emirates&city1=London&city2=Dubai" target="_blank" rel="nofollow noopener">Cost of Living</a> where you can check the average costs of living in different cities across the UK. Below is an example that shows the difference between living in Dubai and London.</p>
          <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/dubaivslondon1.png" alt="Cost of Living Comparison Between Barcelona and London. You would need around 6,714.7€ (5,807.7£) in London to maintain the same standard of life that you can have with 3,900.0€ in Barcelona (assuming you rent in both cities). This calculation uses our Cost of Living Plus Rent Index to compare the cost of living and assume net earnings (after income tax). You can change the amount in this calculation. Consumer Prices in London are 45.3% higher than in Barcelona (without rent) Consumer Prices Including Rent in London are 72.2% higher than in Barcelona Rent Prices in London are 128.2% higher than in Barcelona Restaurant Prices in London are 54.1% higher than in Barcelona Groceries Prices in London are 29.4% higher than in Barcelona Local Purchasing Power in London is 16.9% higher than in Barcelona"></img>
          <p>However, everyone's lifestyle is different and it’s very easy to spend your money here. From brunches to shopping, experiences, social activities and so on. </p>
          <p/>
          <p/>
          <h3>Renting in Dubai</h3>
          <p>Having never been to Dubai before, we followed the advice of <a target="_blank" href="https://www.linkedin.com/in/andy-shaw-riba/">Andy Shaw </a> (Architect | Chair of RIBA Gulf Chapter | Managing Partner at AMA) and initially settled on a short-term let in Dubai Marina for just under two months. While expensive, it allowed us to explore different districts quickly.</p>          <p>There's a good mix of furnished, semi-furnished, and unfurnished places, catering to various needs. Popular districts include Business Bay, Dubai Marina, Jumeirah Village Circle, Jumeirah, Dubai International Financial Centre and Dubai Hills—popular with families.</p> 
          <p>To get a better idea of prices and what you could get, take a look at <a target="_blank" href="https://www.propertyfinder.ae/en/map-search?c=2&amp;fu=0&amp;ob=mr&amp;page=1&amp;rp=y#;mapsearch;activeCard=%7B%22id%22%3A%221046%22%2C%22type%22%3A%22tower%22%7D&amp;activeTowerId=1046&amp;center=%7B%22lng%22%3A55.13522023962139%2C%22lat%22%3A25.04064090153979%7D&amp;zoom=11.580117626599284;mapsearch;">
          Property Finder</a> (map). Of course, everyone's situation is different&nbsp; - whether you are living on your own or coming here with your partner/family and depending on the area you’ll live/work in, prices will vary.</p>
          <p>You can find a 1-bedroom apartment for as little as 37,000 AED per year, or you can go all out and get a luxury flat for over 310,000 AED per year. It’s incredibly hard to put a range on these prices, as rental prices have risen by over 20% in the last year. Additionally, each area and each tower will vary in costs. Factors such as the floor level, the developer, and the facilities can all influence the price.</p>
          <p>For example, villas in Dubai Hills can range anywhere from 250,000 AED to 1,900,000 AED per year.</p>
          <p>After viewing several apartments, we fell in love with a flat in Dubai Creek Harbour. We informed the real estate agent of our interest, and things progressed pretty quickly - within 48 hours, we completed due diligence and finalised the lease agreement.</p>
          <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/dvh1.jpg" alt="A picture of Otto fast asleep in his dog bed after arriving in Dubai."></img>

          <p><strong>Important:</strong> It's worth noting that in recent years, landlords typically prefer receiving the entire year's rent upfront or accept between 2 to 6 post-dated cheques. Negotiating a better price is often possible if you can pay the entire year's rent upfront. Additionally, don't forget about the deposit and the real estate agency fee, which typically ranges from 4% to 5.5%.</p>
           <p/>          <p/>
          <h3>Relocating with your Family to Dubai</h3>
          <p>I have spoken to quite a few architectural professionals who either live or recently moved to Dubai and here is what I’ve learned from them.</p>
           <ul>
            <li>All schools are private, with fees detailed on their respective websites.&nbsp;</li>
            <li>For families with young children, hiring a full-time nanny, either live-in or daily, is common and more affordable compared to the UK.&nbsp;</li>
            <li>Most apartments offer spacious living, providing better value for money.&nbsp;</li>
            <li>Proximity to schools is crucial, as most children are transported by American school buses.</li>
            <li>If only one spouse works, ensure your income covers all expenses, including rent, utilities, childcare, and more.</li>
            <li>Children grow up in a multicultural environment.</li>
          </ul>  
          <p>Note: Private schools are often more expensive for expats compared to public schools, however, they offer international curricula. This facilitates easier transfers for students moving between schools in different countries in case of relocation. Private schools have tuition fees ranging from 10,000 to 50,000 AED per year/per kid. International school tuition fees range from 30,000 to 100,000 AED per year/per kid.</p>        
          <a target="_blank" href="https://schoolsclassify.com/ae/british-schools-dubai">British Schools in Dubai - Source: Schools Classify</a> will give you a good idea of prices.<p/>
          <h3>Opening a Bank Account in Dubai</h3>
          <p>To open a bank account in Dubai, you'll need the following:</p><ul><li>Your passport</li><li>A copy of your visa</li><li>A letter or salary certificate from your employer or visa sponsor</li><li>Your Emirates ID card (if applicable)</li><li>Proof of address</li></ul>
          <p/>
          <p/>
          <h3>What is the healthcare system like in Dubai?</h3>
          <p>I can already speak from my own experience as I broke my wrist in two different places. But the healthcare system was incredible and very fast. I wrote a <a target="_blank" href="https://www.linkedin.com/posts/aylin-round_dubai-healthcare-medical-activity-7209102637051351040-8dZg?utm_source=share&amp;utm_medium=member_desktop">
           LinkedIn post about my experience </a> - feel free to read it and there are some other insightful comments.</p>

          <p>Healthcare practices and hospitals are all dotted around the city. I would say that each district has their own medical centers.</p>
          <p/>
          <p/>
          <h3>Public transport in Dubai</h3>
          <p>You’ll have plenty of options when it comes to public transport. You’ll have taxis, water taxis, buses, bikes, trams, or the metro. Taxis are affordable and readily available everywhere. The metro and tram are particularly useful for central commuting but not that useful if you’re living or travelling to more suburban areas. In those instances, you’d be much better off using a taxi.</p>
          <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/new-metro-map-2024-1024x528.jpg" alt="A picture of the Dubai Metro map 2024."></img>
          <p/>
          <p/>
          <h3>Is Dubai safe?</h3>
          <p>Yes, Dubai ranks among the safest cities globally, and from personal experience, I've never felt this safe.</p>
          <p/>
          <p/>
          <h2>Section 7: Things you need to know before moving here</h2>
          <p>Moving to Dubai is not an easy decision, nor is it cheap. I’ve been saying that it’s not for everyone and you’ll need to weigh up the pros and cons and what you’re actually looking for. Below is a quick summary of the things you need to know before moving to Dubai.</p>
            <ul>
              <li>Manage your salary expectations, you might not earn double what you’re earning now.</li>
              <li>You'll likely find a job first and move nearby (most professionals don't commute longer than 20-30 minutes each way).</li>
              <li>Have your finances in place before moving, finding a job won't cover high setup costs, including expensive short-term lets or hotels.</li>
              <li>You can only open a bank account after receiving your visa and resident identity card.</li>
              <li>Renting is very different here, you may need to pay your year’s rent upfront or in 2-6 cheques.</li>
              <li>All schools are private, meaning you have to pay.</li>
              <li>You do not receive a pension.</li>
              <li>Standard working hours are 48hrs per week&nbsp; but the holiday plus bank holidays are much better here.</li>
              <li>Your employer will pay for your Visa and health insurance</li>
              <li>Not all pet breeds are allowed, check the government website.</li>
              <li>Pets aren't allowed on public transport, inside malls, some walkways, and beaches.</li>
              <li>Public transport is limited, especially the Metro and Tram.</li>
              <li>You need to get your degree attested - both in the UK and UAE.</li>
            </ul>
            <p/>
              <p/>
              <h3>Summary</h3>
              <p>Personally, I think it’s important to understand why you are looking to relocate and identify if Dubai, UAE is the right decision for you and your family. If you want to move to Dubai, take a moment to assess if you’re actually ready - professionally and financially. Ensure you have strong Revit and Rhino skills. Ideally, aim to work on projects in the UAE or Saudi Arabia. If your current practice only deals with small residential projects using AutoCAD, I would suggest finding a larger or international practice where you can gain exposure to larger schemes and learn Revit.&nbsp;</p>
              <p>In the meantime, grow your professional network and connect with people already based in Dubai - many UK professionals have made the move. Simply submitting your application will likely not be enough as the competition is fierce. Your network will play a huge role in finding a role, so start building it now.</p>
              <p>Regarding finances, moving to Dubai has high upfront costs. Even if your new employer covers your flight ticket and provides a hotel for the first month, unless you have savings, that will only be a drop in the ocean. Don’t forget, short-term lets are expensive, as are hotels. Therefore, you'll want to find a place to rent, but landlords typically want the year’s rent upfront or between 2 to 6 cheques, plus a deposit, estate agent fee, council tax, internet, etc. If you are moving with your family and your children are going to school, remember that all schools are private here, meaning you’ll have to pay.</p>
              <p>When we first moved to Dubai, I had no idea what to expect and wasn’t even sure if I would like it. But I can say that we’re happy that we made the move. I feel much happier, and I’m sure the good weather plays a big part. People genuinely seem happier in Dubai, and the cost of living versus quality of life is a massive upgrade. When moving to Dubai, it’s important to be respectful of their culture and rules.&nbsp;</p>
              <p>For more tips and insights on the Dubai market, what hiring managers look for in applications, or to determine if this move is right for you in your career right now, <a target="_blank" href="https://www.archjobs.co.uk/architectural-career-coaching">feel free to schedule a coaching call with me</a>.</p>
             </ArticleFrame>
            }
        }