import './App.css';
import { useLocation, Navigate, BrowserRouter as Router, Route, Routes  } from 'react-router-dom';
import HomePage from './recruitment-components/HomePage'
import AdminPage from './components/AdminPage'
import NavBar from './recruitment-components/NavBar';
import SearchPage from './components/SearchPage'
import PostJob from './components/PostJob'
import Apply from './components/Apply'
import Email from './components/Email'
import GoogleAnalytics from './GoogleAnalytics'
import PrivacyPolicy from './recruitment-components/PrivacyPolicy'
import Footer from './recruitment-components/Footer'
import Team from './components/TheTeam'
import RecruitmentPage from './recruitment-components/RecruitmentPage'
import JobSeekerPage from './recruitment-components/JobSeekerPage'
import ContactPage from './recruitment-components/ContactPage'
import SubmitCVPage from './recruitment-components/SubmitCVPage'
import ForClients from './recruitment-components/ForClients'

function App() {
  return (
    <div className="Page Recruitment">
      <Router>
        <NavBar/>
        <Routes >
          <Route exact path="/" element={<HomePage />} />
          <Route path="/privacy" element={<PrivacyPolicy/>} />
          <Route path="/the-team" element={<Team/>} />
          <Route path='/recruitment' element={<RecruitmentPage/>} />
          <Route path='/job-seekers' element={<JobSeekerPage/>} />
          <Route path='/contact-me' element={<ContactPage/>} />
          <Route path='/submit-cv' element={<SubmitCVPage/>} />
          <Route path='/for-clients' element={<ForClients/>} />
        </Routes >
        <GoogleAnalytics/>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
