import React, {Component} from 'react'
import axios from 'axios'
import '../css/recruiter.css'
import { Collapse } from '@mui/material'
import Button from './Buttons';
import tags from '../data/tags';
import { Link } from 'react-router-dom';
import { BsChevronUp, BsChevronDown, BsChevronLeft, BsFillShareFill } from 'react-icons/bs';
import { Regions } from "./RecruiterAdd";
import MultipleSelectChip from './MultiSelect.js'
import withRouter from '../withRouter';
import { Helmet } from 'react-helmet';
import { SimilarJobSquare } from './SearchPage';
import ShareComponent from './ShareComponent'
import {articleContent} from "../articles/WorkingWithRecruiters.js"

const ExperienceLevels = tags.ExperienceLevels
const search_url = "https://api.archjobs.co.uk/search_recruiters"
const RecruiterAnalyticsUrl = "https://api.archjobs.co.uk/recruiter_analytics"
const recruiter_job_search_url = "https://api.archjobs.co.uk/search"

function htmlDecode(input) {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  }

export class RecruiterCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            shareOpen: false,
            job_list: {checked: false, results: []}
        }
    }

    componentDidMount = () => {
        if (this.props.recruiterDetails) {
            this.setState({isOpen: true})
        } else if (this.state.isOpen) {
            this.setState({isOpen: false})
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.recruiterDetails !== prevProps.recruiterDetails) {
            if (!this.props.recruiterDetails && this.state.isOpen) {
                this.setState({isOpen: false})
            } else if (this.props.recruiterDetails && !this.state.isOpen) {
                this.setState({isOpen: true})
            }
        }
        if (this.state.isOpen && this.state.job_list.checked == false) {
            this.getRecruiterJobs()
        }

    }

    getRecruiterJobs = () => {
        this.setState({job_list: {checked: true, results: []}}, () => {
            const recruiter_id = this.props.recruiter.recruiter_id
            axios.get(recruiter_job_search_url+`?recruiter_id=${recruiter_id}`).then(response => {
                var data = response.data.results
                data.forEach(job => {
                    job.parsed_tags = {}
                    if (job.tags) {
                        job.tag_list = job.tags.split(";")
                        job.tag_list.forEach(tag => {
                            const tag_data = tag.split(",")
                            if (!job.parsed_tags[tag_data[1]]) {
                                job.parsed_tags[tag_data[1]] = []
                            }
                            job.parsed_tags[tag_data[1]].push(tag_data[0])
                        })
                    }
                })
                this.setState({job_list: {checked: true, results: data}})
            }).catch(error => {
                console.log(error)
            })
        })
    }

    setShareOpen = (open) => {
        console.log("SetShare Open")
        this.setState({shareOpen: open})
      }
    
    recruiterEvent = (recruiter, event_type) => {
        const recruiter_id = recruiter.recruiter_id
        let page = "recruiters"
        if (this.props.isSearch) {
            page = "search"
        }
        axios.post(RecruiterAnalyticsUrl+`?recruiter_ids=${recruiter_id}&event=${event_type}&page=${page}`).then(response => {
          console.log("Analytics response: ", response.data)
        }).catch(error => {
            console.log(error)
        })
    }

    toggleOpen = () => {
        this.setState({isOpen: !this.state.isOpen})
        if (!this.state.isOpen) {
            this.recruiterEvent(this.props.recruiter, "expand")
        }
    }

    render() {
        

        let short_description = htmlDecode(this.props.recruiter.profile_description.replaceAll("\n", " ").replace(/&nbsp;/g, " ").replace(/<\/?[^>]+(>|$)/g, " ").slice(0, 180)) //.replace(/<\/?[^>]+(>|$)/g, " ")
        var img_url="https://cdn.archjobs.co.uk/file/Archjobs/recruiters/" +this.props.recruiter.recruiter_name.replace(/[\W_]+/g, "").replace(/[ +]/g, "").toLowerCase()+".png"
        var banner_url="https://cdn.archjobs.co.uk/file/Archjobs/recruiters/" +this.props.recruiter.recruiter_name.replace(/[\W_]+/g, "").replace(/[ +]/g, "").toLowerCase()+"_banner.png"

        let region_list = []
        if (this.props.recruiter.parsed_tags && this.props.recruiter.parsed_tags["region"]) {
            this.props.recruiter.parsed_tags["region"].forEach(region => {
                region_list.push(<div className="RecruiterCardHighlight RecruiterCardHighlight_region">{region}</div>)
            })
        }
        let role_list = []
        if (this.props.recruiter.parsed_tags && this.props.recruiter.parsed_tags["job_title"]) {
            this.props.recruiter.parsed_tags["job_title"].forEach(job_title => {
                role_list.push(<div className="RecruiterCardHighlight RecruiterCardHighlight_job_title">{job_title}</div>)
            })
        }
        let type_list = []
        let permanent = false
        if (this.props.recruiter.parsed_tags && this.props.recruiter.parsed_tags["job_type"]) {
            if (this.props.recruiter.parsed_tags["job_type"].includes("Full-Time") && this.props.recruiter.parsed_tags["job_type"].includes("Part-Time")) {
                permanent = true
                type_list.push(<div className="RecruiterCardHighlight RecruiterCardHighlight_job_type">Permanent</div>)
            }
            this.props.recruiter.parsed_tags["job_type"].forEach(job_type => {
                if (job_type == "Fixed Term Contract" && this.props.recruiter.parsed_tags["job_type"].includes("Contract")) return
                if (permanent && job_type == "Full-Time") return
                if (permanent && job_type == "Part-Time") return
                type_list.push(<div className="RecruiterCardHighlight RecruiterCardHighlight_job_type">{job_type}</div>)
            })
        }
        let city_list = []
        if (this.props.recruiter.locations) {
            this.props.recruiter.locations.forEach(location => {
                city_list.push(<div className="RecruiterCardHighlight RecruiterCardHighlight_city">{location.city}</div>)
            })
        }
        let highlights_list = []
        for (const key of Object.keys(this.props.recruiter.parsed_tags)) {
            let permanent2 = false
            if (key.startsWith("highlight")) {
                if (key == "highlight_job_type") {
                    if (this.props.recruiter.parsed_tags[key].includes("Full-Time") && this.props.recruiter.parsed_tags[key].includes("Part-Time")) {
                        permanent2 = true
                        highlights_list.push(<div className="RecruiterCardHighlight RecruiterCardHighlighthighlight_job_type">Permanent</div>)
                    }
                    this.props.recruiter.parsed_tags[key].forEach(highlight => {
                        if (highlight == "Fixed Term Contract" && this.props.recruiter.parsed_tags[key].includes("Contract")) return
                        if (permanent2 && highlight == "Full-Time") return
                        if (permanent2 && highlight == "Part-Time") return
                        highlights_list.push(<div className={"RecruiterCardHighlight RecruiterCardHighlight"+key}>{highlight}</div>)
                    })

                } else if (key == "highlight_location" && this.props.selectedRegions.length !== 0){
                    // just highlight matching regions
                    this.props.recruiter.parsed_tags["region"].forEach(highlight => {
                        if (this.props.selectedRegions.includes(highlight) || highlight == "UK (All Regions)"){
                            highlights_list.push(<div className={"RecruiterCardHighlight RecruiterCardHighlight"+key}>{highlight}</div>)
                        }
                    })

                } else {
                    this.props.recruiter.parsed_tags[key].forEach(highlight => {
                        highlights_list.push(<div className={"RecruiterCardHighlight RecruiterCardHighlight"+key}>{highlight}</div>)
                    })
                }

            }
        }
        let recruiter_url = "/recruiters/"+this.props.recruiter.recruiter_name.replace(/[\W_]+/g, "-").toLowerCase()+"-"+this.props.recruiter.recruiter_id 
        if (this.props.recruiter.experience_start){
            // Work out how many years of experience they have
            var experience = new Date() - new Date(this.props.recruiter.experience_start)
            var years = experience / (1000 * 60 * 60 * 24 * 365.25)
            // Loop over the experience levels and find the one that matches
            if (years > 2) {
                var experience_level
                for (var i = 0; i < ExperienceLevels.length; i++) {
                    if (ExperienceLevels[i].min <= years && ExperienceLevels[i].max > years) {
                        experience_level = ExperienceLevels[i].name
                        break
                    }
                }
                if (experience_level) {
                    highlights_list.push(<div className="RecruiterCardHighlight RecruiterCardHighlightExperience">{experience_level} Experience</div>)
                }
            }
        }
        const recruiter_data = this.props.recruiter

        let SimilarJobs
        if (this.state.job_list.results && this.state.job_list.results.length > 0){
          let SimilarJobsList = []
          let JobSearches = []
          let LocationSearches = []
  
        //   let text = `See All ${this.state.job_suggestions.count} ${this.state.selected_role} Roles on ArchJobs`
        //   let url = `/search/${this.state.selected_role}-Jobs-in-United-Kingdom`
        //   JobSearches.push(<Link to={url} className="SimilarJobLink" style={{textAlign: "left"}}>{text}</Link>)
  
          this.state.job_list.results.forEach(job => {
            SimilarJobsList.push(<SimilarJobSquare data={job} key={job.job_id}/>)
          })
          
      
          SimilarJobs = <div className="SalarySimilarJobsSection">
            <div className="SalaryGuideContentTitle">Active Roles:</div>
            <div className="SimilarJobsSectionList">{SimilarJobsList}</div>
          </div>
        }

        let company = this.props.recruiter.company_name
        if (this.props.recruiter.company_website && !this.props.isSearch){
            company = <a href={this.props.recruiter.company_website+"?utm_source=archjobs&utm_medium=archjobs&utm_campaign=find-a-recruiter"} target="_blank" className="Link RecruiterCompanyLink">{this.props.recruiter.company_name}</a>
        }
        
        return <div className="RecruiterCardContainer">
                {this.props.isSearch && <div className="RecruiterCardHeaderBanner">
                    Want access to more roles or expert advice? Try working with on of our trusted Recruitment Experts.
                </div>}
            <div className="RecruiterCard">

            <div className="RecruiterCardHeader">
                <img className="RecruiterCardImage" src={img_url} alt={this.props.recruiter.recruiter_name+" - Recruiter Profile Image"}/>
                <div className="RecruiterCardHeaderRight">
                    <div className="RecruiterCardHeaderRightTop">
                        <div className="RecruiterCardHeaderInfo">
                            <div className="RecruiterCardName">{this.props.recruiter.recruiter_name}</div>
                            <div className="RecruiterCardCompany">{this.props.recruiter.recruiter_title} - {company}</div>
                        </div>
                        <div className="RecruiterCardHeaderButtons">
                            {this.props.admin && <Button btnClassName="SmallButton" onClickFunc={() => this.props.EditRecruiter(this.props.recruiter)}>Edit</Button>}
                            <Button btnClassName="SmallButton" onClickFunc={() => this.setShareOpen(true)}>Share <BsFillShareFill className="SmallButtonIcon"/></Button>
                            <ShareComponent url={"https://archjobs.co.uk"+recruiter_url} open={this.state.shareOpen} setOpen = {this.setShareOpen.bind(this)} id={this.props.recruiter.recruiter_id} shortJobDesc={this.props.recruiter.recruiter_name+" - "+this.props.recruiter.recruiter_title+", "+this.props.recruiter.company_name} recruiter={true}/>
                            {this.props.isSearch ? 
                                <Link to={recruiter_url} state= {{recruiter: this.props.recruiter}}><Button btnClassName="SearchButton">Get in Touch</Button></Link> 
                                :<Link to="/recruiter-contact" state= {{recruiter: this.props.recruiter}} onClick={() => {this.recruiterEvent(this.props.recruiter, "click")}}><Button btnClassName="SearchButton">Get in Touch</Button></Link>} 
                        </div>
                    </div>
                    <div className="RecruiterCardHeaderRightBottom">
                        <div className="RecruiterCardHeaderInfo">
                            <div className="RecruiterCardHighlightText">Expertise: </div>
                            <div className="RecruiterCardHighlights">{highlights_list}</div>
                        </div>
                        {!this.props.recruiterDetails && <div className="ExpandButton"  onClick={this.toggleOpen}>
                            {this.state.isOpen ? <BsChevronUp className="ExpandArrow"/>:<BsChevronDown className="ExpandArrow"/>}
                        </div>}
                    </div>
                </div>
            </div>
            {!this.state.isOpen && <div className="RecruiterCardShortDescription">
                {short_description}
                <span className="SearchReadMore" onClick={this.toggleOpen}>{this.state.isOpen ? "Read Less": "Read More"}</span>
            </div>}
            <Collapse in={(this.state.isOpen || !this.props.isSearch)}>
                {this.state.isOpen ? 
                <div className="RecruiterCardExpandContainer">
                    <div className="RecruiterExpandHeader">
                        <div className="RecruiterHighlightGrid">
                            <div className="RecuiterHighlightRowHeader">Typical Roles</div>
                            <div className="RecruiterCardHighlights RecruiterCardHighlightRegion">{role_list}</div>

                            <div className="RecuiterHighlightRowHeader">Regions Covered</div>
                            <div className="RecruiterCardHighlights RecruiterCardHighlightRegion">{region_list}</div>

                            <div className="RecuiterHighlightRowHeader">Specialist Cities</div>
                            <div className="RecruiterCardHighlights RecruiterCardHighlightRegion">{city_list}</div>

                            <div className="RecuiterHighlightRowHeader">Job Types</div>
                            <div className="RecruiterCardHighlights RecruiterCardHighlightRegion">{type_list}</div>
                        </div>
                    </div>
                    <div className="RecruiterCardInfo" dangerouslySetInnerHTML={{__html: this.props.recruiter.profile_description.replaceAll('<p><br></p>', "")}}>
                    </div>
                    <div className="JobAdApplyCentered">
                        <Link to="/recruiter-contact" state= {{recruiter: this.props.recruiter}}><Button btnClassName="SearchButton">Get in Touch</Button></Link> 
                    </div>
                    {SimilarJobs}
                    {this.props.recruiter.banner == 1 && <div className="RecruiterCardBannerImgContainer">
                        <img src={banner_url} className="RecruiterCardBannerImg" alt={this.props.recruiter.recruiter_name+" - Recruiter Banner Image"}/>
                    </div>}
                    
                    
                </div> : null}
            </Collapse>

        </div>
    </div>}
}
        




class RecruiterFind extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            search: "",
            search_results: [],
            page: 1,
            hasMore: true,
            isLoading: false,
            selectedRegions: [],
            recruiterDetails: false,
        }
    }

    componentDidMount = () => {
        if (this.props.router.location.state && this.props.router.location.state.recruiter) {
            this.setState({data: [this.props.router.location.state.recruiter], recruiterDetails: true})
        } else {
            this.fetchData()
        }
        window.scrollTo(0, 0)
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.router.location.pathname !== prevProps.router.location.pathname) {
            this.fetchData()
        }
    }


    fetchData = () => {
        this.setState({isLoading: true})
        let full_search_url = search_url
        let recruiterDetails = false
        if (this.props.router.location.pathname.startsWith("/recruiters/")){
            const recruiter_id = this.props.router.location.pathname.split("-")[this.props.router.location.pathname.split("-").length-1]
            full_search_url = search_url + `?recruiter_id=${recruiter_id}`
            recruiterDetails = true
        }
        if (this.props.admin) {
            full_search_url = search_url + "?all_results=true"
        }
        axios.get(full_search_url).then(response => {
            var data = response.data.results
            if (this.props.setRecruiterList){
                this.props.setRecruiterList(data)
            }
            // shuffle randomly
            data.sort(() => Math.random() - 0.5);
            data.forEach(recruiter => {
                if (recruiter.location) {
                    recruiter.locations = recruiter.location.split(";").map(location => {return {city: location.split(",")[0], lat: location.split(",")[1], lng: location.split(",")[2]}})
                }
                recruiter.parsed_tags = {}
                if (recruiter.tags) {
                    recruiter.tag_list = recruiter.tags.split(";")
                    recruiter.tag_list.forEach(tag => {
                        const tag_data = tag.split(",")
                        if (!recruiter.parsed_tags[tag_data[1]]) {
                            recruiter.parsed_tags[tag_data[1]] = []
                        }
                        recruiter.parsed_tags[tag_data[1]].push(tag_data[0])
                    })
                }
            })
            this.setState({data: data, isLoading: false, recruiterDetails}, () => {
                const recruiter_ids = this.state.data.map(recruiter => recruiter.recruiter_id).join(",")
                axios.post(RecruiterAnalyticsUrl+`?recruiter_ids=${recruiter_ids}&event=impression&page=recruiters`).then(response => {
                    console.log("Analytics response: ", response.data)
                  }).catch(error => {
                      console.log(error)
                  })
            })

        }).catch(error => {
            console.log(error)
            this.setState({isLoading: false})
        })
    }

    selectRegion = (id, regions) => {
        this.setState({selectedRegions: regions})
    }

    BacktoSearch = () => {
        this.setState({recruiterDetails: false})
        
    }



    render() {

        const recruiterList = []
        console.log(this.state.recruiterDetails)
        this.state.data.forEach((recruiter, index) => {
            if (this.state.selectedRegions.length > 0) {
                if (recruiter.parsed_tags.region && !recruiter.parsed_tags.region.includes("UK (All Regions)") && !recruiter.parsed_tags.region.some(region => this.state.selectedRegions.includes(region))) return
            }
            recruiterList.push(<RecruiterCard key={`${recruiter.id}_${index}`} recruiter={recruiter} admin={this.props.admin} EditRecruiter={this.props.EditRecruiter} selectedRegions={this.state.selectedRegions} recruiterDetails={this.state.recruiterDetails}/>)
        })
        let style = {}
        if (this.props.hidden) {
            style = {display: "none"}
        }
        const default_title = "Architectural Recruiters"
        var helmet =  <Helmet>
        <meta charSet="utf-8" />
            <title>ArchJobs | {default_title}</title>
            <link rel="canonical" href={this.props.router.location.pathname} />
            <meta name="description" content={"Find the best " + default_title + " with ArchJobs" }/>
            <meta name="og:title" content={"ArchJobs | "+default_title}/>
            <meta name="og:type" content={"website"}/>
            <meta name="og:image" content={"https://cdn.archjobs.co.uk/file/ArchJobs/backgrounds/archjobs_banner.png"}/>
            <meta name="og:url" content={this.props.router.location.pathname}/>
        </Helmet>
      
        return (
            <div className="RecruiterPage" style={style}>
                {helmet}
                <div className="WhyWorkWithUsBanner WhyWorkWithUsBannerCoaching">
                    <div className="WhyWorkWithUsBannerTextContainer">
                        <h1 className="FindRecruiterTitle">Find an Architectural Recruiter</h1>
                        <div className="WhyWorkWithUsSectionSubtitle">Finding for a recruiter who understands the architectural sector, someone who has the right connections in your city, and is able to help you through the whole job search process can be difficult to find. ArchJobs has strong ties with some excellent recruiters and agencies who really know what they are doing. </div>
                        <div className="WhyWorkWithUsSectionSubtitle">If you need any recommendations, you can reach out to <a target="_blank" href="https://www.linkedin.com/in/aylin-round/">Aylin on LinkedIn</a> or email us at <a target="_blank" href="mailto:hello@archjobs.co.uk">hello@archjobs.co.uk</a>.</div>

                       {/* <div className="WhyWorkWithUsSection GreySection">
                            <div className="CoachingPackageTitle">How does it work?</div>
                            <div className="WhyWorkWithUsSectionReasons"> 
                                
                                    <div>1. Choose a Recruiter who specialises in your region/city</div>
                                    <div>2. Reach out to them via the 'Get in Touch' button</div>
                                    <div>3. Tell them what you are looking for</div>
                                    <div><b>Note:</b> Please ensure you have the right to work in the UK.</div>

                            </div>
                        </div> */}
                    </div>
                    <div className="WhyWorkWithUsBannerBG CoachingBG"> </div>
                </div>
                {/* {/* <h3 className="FindRecruiterSubtitle2">
                    <p>Whether you are a practice owner struggling to fill a specific architectural position or a job seeker looking to make your next architectural career move, contact one of our trusted Architectural Recruitment Experts.</p>
                    <p/> */}

                {/* <div className="RecruiterSearch">
                    {/* A dropdown to allow people to select a Region to filter the recruiters
                    {!this.state.recruiterDetails ?<div className="RecruiterSearchRegion">
                        <div className="RecruiterSearchRegionLabel">Filter by Region</div>
                        <MultipleSelectChip className="PostJobInput" values={Regions.sort()} id="Regions" updateMultiSelectValue={this.selectRegion.bind(this)} value = {this.state.selectedRegions}/>
                    </div>: <Link className="ResultsGoBack" to={"/recruiters"} onClick = {this.BacktoSearch} ><BsChevronLeft className="BackArrow"/>See all recruiters</Link>}
                    <div className="RecruiterSearchJoinUs">
                        <div className="RecruiterSearchRegionLabel">Are you a Recruiter?</div>
                        <Link to={`/recruiters/join-us`} className="RecruiterJoinUsButton">
                            <Button btnClassName="SearchButton">Join Us</Button>
                        </Link>
                    </div>
                </div> */}
                {/* <div className="RecruiterList">
                    {recruiterList}
                </div>
                <div className="FindRecruiterSubtitle" style={{paddingBottom: "20px"}}>
                    <p>Interested in joining our group of trusted Architectural Recruiters?</p>
                    <Link to={`/recruiters/join-us`} className="RecruiterJoinUsButton">
                        <Button btnClassName="SearchButton">Find out More</Button>
                    </Link>
                </div> */}
                <p/>
                <p/>
                <div className="ArticlePage">
                    <div className="ArticleContainer">
                        <h2 className="ArticleTitle">How to Work with an Architectural Recruiter</h2>
                        {articleContent}
                    </div>

                </div>
            </div>
        )
    }
}

export default withRouter(RecruiterFind)

