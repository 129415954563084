import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Button, Table, TableHead, TableBody, TableRow, TableCell, Link, CircularProgress, Box, Typography } from '@mui/material';

function UpdateSalaryButton() {
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');
  
    const handleClick = async () => {
      setIsLoading(true);
      setMessage('');
      try {
        const response = await axios.get('https://api.archjobs.co.uk/update_salary_data');
        setMessage(response.data.message);
      } catch (error) {
        setMessage('Error updating manual job pages');
      }
      setIsLoading(false);
    };
  
    return (
      <div>
        <Button onClick={handleClick} disabled={isLoading}  variant="contained" className="SubmitFormButton">
          Update Salary Data
        </Button>
        {isLoading && <CircularProgress sx={{ ml: 2 }} size={20} />}
        {message && (
          <Typography variant="body2" sx={{ ml: 2, color: message.startsWith('Error') ? 'error.main' : 'success.main' }}>
            {message}
          </Typography>
        )}
      </div>
    );
  }

function UpdateButton() {
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');
  
    const handleClick = async () => {
      setIsLoading(true);
      setMessage('');
      try {
        const response = await axios.get('https://api.archjobs.co.uk/update_manual_job_pages');
        setMessage(response.data.message);
      } catch (error) {
        setMessage('Error updating manual job pages');
      }
      setIsLoading(false);
    };
  
    return (
      <div>
        <Button onClick={handleClick} disabled={isLoading}  variant="contained" className="SubmitFormButton">
          Update Database
        </Button>
        {isLoading && <CircularProgress sx={{ ml: 2 }} size={20} />}
        {message && (
          <Typography variant="body2" sx={{ ml: 2, color: message.startsWith('Error') ? 'error.main' : 'success.main' }}>
            {message}
          </Typography>
        )}
      </div>
    );
  }
  


function ScraperDashboard(props) {
  const [data, setData] = useState({});
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const isLoadingRef = React.useRef(false);
  const pageRef = React.useRef(1);
  const hiddenRef = React.useRef(true)

  useEffect(() => {
    fetchData();
    window.onscroll = function(e) {
      // console.log(e, window.scrollY, window.innerHeight, document.body.offsetHeight, window.innerHeight + window.scrollY + 800 >= document.body.offsetHeight)
      // console.log(props.hidden, hasMore, isLoading)
      if (hiddenRef.current == true) return
      if (window.innerHeight + window.scrollY + 1000 >= document.body.offsetHeight) {
        if (hasMore && !isLoadingRef.current) {
          console.log("fetching more")
          fetchData();
        }
      }
    }
  }, []);

  useEffect(() => {
    hiddenRef.current = props.hidden
  }, [props.hidden]);

  const fetchData = async () => {
    if (isLoadingRef.current == true) return
    setIsLoading(true);
    isLoadingRef.current = true;
    let pageNum = pageRef.current;
    const response = await axios.get(`https://api.archjobs.co.uk/changes?page=${pageNum}`);
    const newData = response.data;
    setData(prevData => { return {...prevData, [pageRef.current]: newData}});
    setIsLoading(false);
    isLoadingRef.current = false;
    setPage(page + 1);
    pageRef.current = pageNum + 1;
    setHasMore(newData.length === 40);
  };

  const formatDate = (dateString) => {
    return moment.utc(dateString, 'ddd, DD MMM YYYY HH:mm:ss [GMT]').fromNow();
  };

  const handleScroll = (e) => {
    console.log(e)
    if (props.hidden) return
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && hasMore && !isLoading) {
      fetchData();
    }
  };

  const rowData = Object.values(data).flat();

  return (
    <div className="ScraperDashboard" hidden={props.hidden} style={props.hidden? {display: "none"}: {}} >
        <div className="ScraperDashboardHeader">
            <div className="ScraperDashboardTitle">Useful Links</div>
            <div className="LinkSectionHeader">Careers Page Urls</div>
            <div className="ScraperDashboardLinkList">
                <a href="https://docs.google.com/spreadsheets/d/1fYisNNFYeTKr_vJvIWMFdDYmmlJcZHhluwNDXdiqc5M/edit#gid=1128567642" className="ScraperLink Link">Add Urls to blacklist here</a>
                <a href="https://docs.google.com/spreadsheets/d/1fYisNNFYeTKr_vJvIWMFdDYmmlJcZHhluwNDXdiqc5M/edit#gid=1779835666" className="ScraperLink Link">Update Urls here</a>
                <div className="ScraperUpdateButton">
                    Update database only if you Update Urls (not blacklist):
                    <UpdateButton />
                </div>
            </div>
            <div className="LinkSectionHeader">Salary Data</div>
            <div className="ScraperDashboardLinkList">
                <a href="https://docs.google.com/spreadsheets/d/1wUcZ4aVLBK7CoJfin2COEZOgvDzDqGn6HEPEdpiyTPA/edit#gid=312495084" className="ScraperLink Link">Add Salary Data</a>
                <div className="ScraperUpdateButton">
                    Update Salary Guide:
                    <UpdateSalaryButton />
                </div>
            </div>
            <div className="LinkSectionHeader">Job Boards</div>
            <div className="ScraperDashboardLinkList">
                <a href="https://jobs.architecture.com/jobs/" className="ScraperLink Link">RIBA</a>
                <a href="https://www.dezeenjobs.com/location/uk/" className="ScraperLink Link">Dezeen</a>
                <a href="https://uk.indeed.com/jobs?q=architect&l=United+Kingdom&sc=0bf%3Aexrec%28%29%3B&fromage=3&vjk=6d60e98f6af1aef6" className="ScraperLink Link">Indeed</a>
                <a href="https://www.rias.org.uk/for-architects/jobs" className="ScraperLink Link">RIAS</a>
                <a href="https://architecturaltechnology.com/jobs/jobs.html" className="ScraperLink Link">ACIAT</a>
                
            </div>
            
        </div>
        <div className="ScraperDashboardTitle">Scraper Results: </div>
      <Table>
        <TableHead>
          <TableRow>
          <TableCell>Subtractions</TableCell>
            <TableCell>Additions</TableCell>
            <TableCell>Company</TableCell>
            <TableCell>Updated</TableCell>

          </TableRow>
        </TableHead>
        <TableBody >
          {rowData.map((row, index) => {
            if (row.Additions == row.Subtractions) return
            return (<TableRow key={row.CareersURL+String(row.UpdatedDate)}>
              <TableCell className="table-cell"><div className="ChangesContainer">{row.Subtractions}</div></TableCell>
              <TableCell className="table-cell"><div className="ChangesContainer">{row.Additions}</div></TableCell>
              <TableCell>
                <Link href={row.CareersURL} target="_blank" rel="noopener">
                  {row.Company}
                </Link>
              </TableCell>
              <TableCell className="table-cell">{row.UpdatedDate} <br/>({formatDate(row.UpdatedDate)})</TableCell>
            </TableRow>)
        })}
        </TableBody>
      </Table>
      {isLoading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', py: 4 }}>
          <CircularProgress />
        </Box>
      )}
      {!hasMore && (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', py: 4 }}>
          <Typography variant="subtitle1">End of results</Typography>
        </Box>
      )}
    </div>
  );
}

export default ScraperDashboard;