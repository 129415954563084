import ArticleFrame from "./ArticleFrame";
import React, { Component } from 'react';


//first go to ArticleData.js and add all the relavant data
//next, rename "YourArticle" below to what you named the article data section
const thisArticleData = "Linkedin"


//rename YourArticle below to your article name
export default class Linkedin extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
      };
    }

    componentDidMount = () => {
      window.scrollTo(0, 0)
    }

    render(){
        return <ArticleFrame ArticleDataName={thisArticleData}>
              <p><span >If you&rsquo;re struggling to find a job, it could be that your LinkedIn profile is letting you down. Maybe you&rsquo;re not sure how to optimise your profile, not sure what to write, or which sections to fill out? Whether you&rsquo;re looking for a new job or you want to grow your LinkedIn network, by going through these 10 easy steps, you will increase your visibility and help people find your profile.</span></p>
              <p><span >LinkedIn has a lot of benefits - not only is it free, you can use it to connect with industry professionals, get seen by employers, find a new job, grow your network and get advice from professionals in your sector.&nbsp;</span></p>
              <p><span ><strong>Not convinced? Here are some jaw-dropping stats:</strong></span></p>
              <ul>
              <li><span >There are over 58 million companies registered on LinkedIn</span></li>
              <li><span >LinkedIn has more than 830 million members</span></li>
              <li><span >49 million people use LinkedIn to find a new job every week</span></li>
              <li><span >95 job applications are submitted every second</span></li>
              <li><span >8640 people are hired through LinkedIn every day</span></li>
              <li><span >77% of recruiters use it to find new candidates&nbsp;</span></li>
              </ul>
              <p><span >Source: news.linkedin</span></p>
              <p><span >LinkedIn is a powerful platform and you want to use it to your advantage. Here are some simple steps you can get to use LinkedIn to help you in your professional goals.</span></p>
              <p/>
              <p/>
              <h3>Look at your Analytics stats</h3>
              <p><span >Before you begin, look at your current search appearances and see who is visiting your profile.&nbsp;</span></p>
              <p><span >Go to your profile page and view Analytics (see image below). Depending on your goal, you want recruiters, founders, architects etc. to show.&nbsp;</span></p>
              <p> <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/linkedin_stats_2024.PNG" alt="A screenshot of the LinkedIn Analytics section, displaying the user's search appearances and profile visitors. The image includes a prompt to review current stats before adjusting profile content to attract desired audiences, such as recruiters, founders, or architects. The Analytics section is accessed from the user's profile page"></img> </p>
              <p><span >Don&rsquo;t worry if your stats aren&rsquo;t high or you don&rsquo;t show up or appear in many searches&hellip; We will change this very soon. Below are the sections you should focus on to help you get noticed:</span></p>
              <ul>
              <li>Your headline</li>
              <li>Your profile picture</li>
              <li>Location and contact details</li>
              <li>LinkedIn banner photo</li>
              <li>About section</li>
              <li>Featured Section (optional)</li>
              <li>Experience</li>
              <li>Education</li>
              <li>Skills&nbsp;</li>
              <li>URL</li>
              </ul>
              <p>Please note, depending on your current LinkedIn profile, you might be able to skip a couple of these.&nbsp;</p>
              <p/>
              <p/>
              <h3>Step 1 - Write your Profile Headline</h3>
              <p><span >Your headline is very important. You have 120 characters to promote yourself and tell your network who you are.</span></p>
              <p><strong>Example 1:</strong></p>
              <p><span >You&rsquo;re an ARB registered Architect with excellent Revit skills and experience in the healthcare sector.&nbsp;</span></p>
              <p><span ><em>ARB Architect | Revit | Healthcare Sector&nbsp;</em></span></p>
              <p><span ><em>ARB Architect | Revit | Healthcare Sector | [Company Name]</em></span></p>
              <p/>
              <p><strong>Example 2:</strong></p>
              <p><span >You&rsquo;re a Revit using Architectural Technologist&nbsp;</span></p>
              <p><span ><em>Architectural Technologist MCIAT | Revit&nbsp;</em></span></p>
              <p><span ><em>Architectural Technologist MCIAT | Revit | [Company Name]</em></span></p>
              <p/>
              <p><strong>Example 3:</strong></p>
              <p><span >You&rsquo;re a Part 2 Architectural Assistant with 1 year post qualification experience and use Vectorworks</span></p>
              <p><span ><em>Part 2 Architectural Assistant | 1 year PQE | Vectorworks</em></span></p>
              <p><span ><em>Part 2 Architectural Assistant | 1 year PQE | [Company Name]</em></span></p>
              <p/>
              <p><span >Remember, whenever you comment or share a post, your name and headline will show. So don&rsquo;t use made up titles like &ldquo;Tech Wizard&rdquo; or &ldquo;Jack of All Trades&rdquo; as people won&rsquo;t know if you&rsquo;re in IT, Architecture or on a different planet.&nbsp;&nbsp;</span></p>
              <p><span >Also, avoid using &ldquo;Currently Seeking Employment&rdquo; as it doesn&rsquo;t answer what sort of work you do.</span></p>
              <p><span >Below is an example where I commented on someone's post. It shows my profile photo, name and headline.</span></p>
              <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/linkedin_comment_2024.png" alt="A screenshot of the comments section on a LinkedIn post, displaying a comment made by Aylin Round. The image shows Aylin's profile photo, name, and headline, as well as her comment in text format. The comment section is located below the post and allows users to engage with the content by leaving comments."></img>
              <p/>
              <p/>
              <h3>Step 2 -&nbsp; Choose your Profile Image</h3>
              <p><span >Using the right profile picture can help you to stand out and leave a great first impression. Remember, hiring managers and recruiters will most likely look you up on LinkedIn. You want to look friendly and approachable.&nbsp;</span></p>
              <p><span >Here are a few other things to consider:</span></p>
              <ul>
              <li><span >Use an up-to-date photo&nbsp;</span></li>
              <li><span >Use a headshot&nbsp;</span></li>
              <li><span >Be the only person on the image</span></li>
              <li><span >Look approachable (smile)</span></li>
              <li><span >Good quality photo</span></li>
              <li><span >Avoid a selfie image&nbsp;</span></li>
              </ul>
              <p><span >In case you don&rsquo;t have the right image yet, ask someone to take a couple of photos.&nbsp;</span></p>
              <p/>
              <p/>
              <h3>Step 3 - Location, Contact and Industry</h3>
              <p>Fill out your location/city, and contact details and make sure you choose the right industry. I would recommend selecting &ldquo;Architecture and Planning&rdquo; as this is another way people can search for good candidates for a job.</p>
              <p>You can amend those fields if you click on the top right edit button - see below.&nbsp;</p>
              <p>
                <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/linkedin_location_edit_image_2024.png" alt="A screenshot of a LinkedIn profile which shows where the edit button is."></img>
                <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/linkedin_industry_image.png" alt="A screenshot of a LinkedIn profile editing page, displaying the selection of industry as 'Architecture and Planning.' The image includes a prompt to fill the appropriate industry to improve visibility for job opportunities. The user interface displays a dropdown menu with various industry options, including 'Architecture and Planning,' which is highlighted in the screenshot."></img>
              </p>
              <p/>
              <h3>Step 4 - Choose a Background Cover Photo</h3>
              <p><span >This is a good way to show visitors your personality and maybe design skills. A lot of architectural people upload hand or technical drawings. You can also use </span><a href="https://www.canva.com/templates/?query=linkedin%20banner" target="_blank" rel="noopener"><span >Canva.com</span></a><span > to create a banner for free.&nbsp;</span></p>
              <p/>
              <p/>
              <h3>Step 5 - Write your About Section</h3>
              <p><span >Do not skip this section! This is your chance to tell everyone more about you, your experience, your skill set and what you&rsquo;re looking for. I view hundreds of profiles every week and I&rsquo;m always surprised how many don&rsquo;t use this section in their profile.&nbsp;</span></p>
              <p><span >You should provide a summary of your work experience, education, what you&rsquo;re passionate about and what you are looking for/working towards professionally. This is also a great spot to include relevant keywords.&nbsp;</span></p>
              <ul>
              <li><span >Write in first person (use &ldquo;I&rdquo;)</span></li>
              <li><span >Use architectural-specific words - this will show your knowledge</span></li>
              <li><span >Include sector-specific keywords i.e. Revit, Planning Applications, Tender &amp; Construction Packages, BIM Level 2 etc.</span></li>
              <li><span >Tell them what you&rsquo;re passionate about i.e. sustainable design, concept design&nbsp;</span></li>
              <li><span >What are you looking for? If you&rsquo;re looking for a new role, why not say &ldquo;I&rsquo;m looking to join a practice where I can improve my skills and grow as an Architect.&rdquo;</span></li>
              <li><span >Have a &ldquo;call to action&rdquo; - which means, tell them how they can get hold of you i.e. you can email me at&hellip;</span></li>
              <li><span >Aim for at least 250 words.&nbsp;</span></li>
              </ul>
              <p><span >&nbsp;Trust me, it is worth the effort and keep it up-to-date.</span></p>
              <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/linkedin_about_section_image.JPG" alt="A screenshot of Aylin Round's LinkedIn profile, showing the 'About' section."></img>
              <p/>
              <p/>
              <h3>Step 6 - Featured Section (optional)&nbsp;</h3>
              <p><span >Some people use the featured section to show their Portfolio, specific work examples, articles they wrote or even their CVs.&nbsp;</span></p>
              <p><span ><strong>Tip:</strong></span><span > I&rsquo;m all for uploading your Portfolio/Work Samples to showcase your design or technical skills, however don&rsquo;t upload your CV. Not only can anyone download your CV but you have no control over it anymore. This could have big implications when you&rsquo;re applying for jobs.&nbsp;</span></p>
              <p><span >You ALWAYS want to be in full control of your CV!</span></p>
              <p><span >My featured sections include articles I&rsquo;ve written and big personal news - see the image below.</span></p>
              <p><img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/linkedin_featured_section_aylin_round_2024.PNG" alt="A screenshot of the featured section on a LinkedIn profile, showcasing three posts chosen by Aylin Round to highlight. The posts are displayed as large images with accompanying captions."></img></p>
              <p/>
              <p/>
              <h3>Step 7 - Experience</h3>
              <p><span >The Experience section is your Work History/CV. In case you&rsquo;re looking for a new job, make sure your LinkedIn profile and </span><a href="https://www.archjobs.co.uk/articles/how-to-write-and-structure-your-cv" target="_blank" rel="noopener"><span >CV</span></a><span > are up to date and matching. What I mean by that is, double check that the companies, dates and job titles are the same across both.</span></p>
              <p><span >Summarise your responsibilities and transferable skills, and mention any work highlights&nbsp;</span></p>
              <p><strong>Example</strong></p>
              <ul>
              <li><span >Experience across Mixed-Use, Education and Large Scale Residential schemes</span></li>
              <li><span >Supported the team with producing tender packages (Revit)</span></li>
              <li><span >Attended site meetings and solved any potential issues</span></li>
              <li><span >Liaised with Clients, Contractors and Subcontractors</span></li>
              <li><span >Submitted planning applications</span></li>
              <li><span >Job Running multiple education projects</span></li>
              <li><span >Worked across RIBA Stages 2-6</span></li>
              </ul>
              <p><span >You can also use the </span><a href="https://www.archjobs.co.uk/articles/how-to-prepare-an-architectural-job-interview" target="_blank"><span >STAR method</span></a><span > to explain your responsibilities and make sure to use bullet points (it&rsquo;s easier to read). If you can add any quantifiable impact of your work it can go a long way.</span></p>
              <p/>
              <p/>
              <h3>Step 8 - Education</h3>
              <p>A quick and easy way for employers to see your level of qualifications.&nbsp;</p>
              <p>How to update your education section:</p>
              <p>Click on the edit button on your profile page (top right) and update/add new education. - see the image below.&nbsp;</p>
              <p>Select your degree, grades and where you studied. You&rsquo;re still studying? No problem, select your current university and future end date/year.</p>
              <p><img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/linkedin_education_image.JPG" alt="A screenshot of the LinkedIn education section editing page, displaying options to update and add new education information. The image includes a prompt to click the edit button on the profile page and select the degree, grades, and institution for past or current education. The user interface displays a form for adding education details, including a dropdown menu for degree selection and fields for grades and institution information. There is also an option to select a future end date/year for current education."></img></p>
              <p/>
              <p/>
              <h3>Step 9 - Your Skills</h3>
              <p>Select at least 5 skills or more. This will help you to appear in searches and your network can also endorse you on those skills. It's a win-win situation.&nbsp;</p>
              <p>How to add skills: click on the + sign&nbsp;</p>
              <p><img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/linkedin_skills_plus_image.png" alt="A screenshot of the Linkedin Skills section, it shows where you can add new skills by clicking the plus button on the right. "></img></p>
              <p/>
              <p>And search for suitable skills</p>
              <p><img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/linkedin_add_skills_image.JPG" alt="A screenshot of the Linkedin Add Skill section, it shows where you can select and enter relevant skills. It comes with a drop down and comes up with suitable skills."></img></p>
              <p>Think about which skill sets are required in your job</p>
              <p>I.e. Communication Skills, Presentation Skills, Time Management Skills, Client Facing Skills, Revit Skills, BIM Level 2, Team Leading etc.&nbsp;</p>
              <p>FYI - You can select up to 50 skills - so don&rsquo;t be afraid to add some.</p>
              <p/>
              <p/>
              <h3>Step 10 - Customise your URL</h3>
              <p><span >Say goodbye to a long and generic URL. If you like, you can customise your URL and use your full name and remove any random numbers.&nbsp;</span></p>
              <ol>
              <li><span >Go to your profile&nbsp;</span></li>
              <li><span >Click on Edit public profile &amp; URL (top right)</span></li>
              <li><span >Choose your new LinkedIn URL&nbsp;</span></li>
              </ol>
              <p><img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/linkedin_url_image.png" alt="A screenshot of the path on how to change your LinkedIn URL. First you will need to click on your profile and secondly click on Edit public profile & URL"></img></p>
              <p>I changed my URL to <a href="https://www.linkedin.com/in/aylin-round/" target="_blank" rel="nofollow noopener">https://www.linkedin.com/in/aylin-round/</a></p>
              <p/>
              <p/>
              <h2>Conculsion</h2>
              <p>If you have followed the 10 steps above, I&rsquo;m certain your LinkedIn profile will not only look more professional but it will also perform better in searches and will give you the foundation to grow your network.&nbsp;</p>
              <p>Remember, this is only the foundation, now it&rsquo;s time to connect, follow, post, like, share and comment on posts. In short - be active and don&rsquo;t abandon your profile. If not your activity section will look very empty, which might make Hiring Managers and the community less inclined to contact you.&nbsp;</p>
              <p>If you need any support or would like assistance in optimising your LinkedIn profile, <a href="https://www.archjobs.co.uk/architectural-career-coaching" target="_blank" rel="noopener">please don't hesitate to book a tailored coaching session with me</a>. I'll ensure that your network and hiring managers can find your profile by using relevant keywords and offer advice on building a stronger personal brand.</p>
            </ArticleFrame>
    }
}

// Now, go to the App.js file
// add this line at the top, rename {YourArticle} to whatever you called it. You also need to copy and rename this file to "{YourArticle}.js"
// import YourArticle from './articles/YourArticle'
// Add another line in the Routes section, renaming "YourArticle" again:
// <Route path="/articles/YourArticle" element={<YourArticle/>} />


// now in TERMINAL See top navbar) open New Terminal and enter the following command:
// npm run-script dev
// head to the article page and review the html to make sure its properly formatted
//add <p/> between rows where you want a larger space
//add any images that were missed out (will need to upload these to article_images folder too) eg. <img className="ArticleImage" src="https://cdn.archjobs.co.uk/file/ArchJobs/article_images/example_keywords.png" alt="Archjobs Keywords when applying"></img>
//add rel="nofollow noopener" to any links so it doesnt impact our SEO eg.  <a href="https://dictionary.cambridge.org/dictionary/english/cv" target="_blank" rel="nofollow noopener">Cambridge Dictionary</a>





// finally, publish the changes to github
// When its live, go to search console and inspect the new url: https://search.google.com/search-console?resource_id=sc-domain%3Aarchjobs.co.uk
// Might need a couple of attempts for it to get cached and display properly
// Make sure the rich text snippet is working properly
// If it is, request indexing from google to speed up the process