import { useState } from 'react';
import {MdClose} from 'react-icons/md'

const MAX_COUNT = 5;

function humanFileSize(bytes, si=false, dp=1) {
    const thresh = si ? 1000 : 1024;
  
    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }
  
    const units = si 
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10**dp;
  
    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
  
  
    return bytes.toFixed(dp) + ' ' + units[u];
  }

function MultipleUpload(props) {

	const [uploadedFiles, setUploadedFiles] = useState([])
    const [fileLimit, setFileLimit] = useState(false);


    const handleUploadFiles = files => {
        const uploaded = [...uploadedFiles];
        let limitExceeded = false;
        var file_sizes = 0
        files.some((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                console.log(file)
                file_sizes += file.size
                if (file_sizes > 15000000){
                    alert(`Your files cannot exceed 15 MB in total, please remove a file or downsize to continue`);
                    setFileLimit(false);
                    limitExceeded = true;
                    return true;
                }
                if (uploaded.length > MAX_COUNT) {
                    alert(`You can only add a maximum of ${MAX_COUNT} files`);
                    setFileLimit(false);
                    limitExceeded = true;
                    return true;
                }
                uploaded.push(file);
                if (uploaded.length === MAX_COUNT) setFileLimit(true);


            }
        })
        if (!limitExceeded) {
            setUploadedFiles(uploaded)
            props.updateFiles(uploaded)
        }
    }

    const handleFileEvent =  (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files)
        handleUploadFiles(chosenFiles);
    }

    const cancelUpload = (file_name) => {
        var uploaded = [...uploadedFiles]
        uploaded = uploaded.filter(f => f.name !== file_name )
        setUploadedFiles(uploaded)
        props.updateFiles(uploaded)
        if (fileLimit){
            setFileLimit(false)
        }
    }

    return (
		<div className="MultipleUpload">

			<input id='fileUpload' type='file' multiple
					accept='application/pdf, image/png, application/msword,
                    application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                    onChange={handleFileEvent}
                    name='fileupload'
                    disabled={fileLimit}
			/>

			<label htmlFor='fileUpload'>
				<a  className={`Button FileUploadButton ${!fileLimit ? '' : 'disabled' } `}>Upload Files</a>
			</label>

			<div className="uploaded-files-list">
				{uploadedFiles.map(file => (
                    <div className="uploadedFile">
                        {file.name} ({humanFileSize(file.size)}) <MdClose className="CancelUpload" onClick ={() => cancelUpload(file.name)}></MdClose>
                    </div>
                ))}
			</div>

		</div>
	);
}

export default MultipleUpload;