import React, { Component } from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import JobSubmission from './JobSubmission'
import SearchPage from './SearchPage'
import CreateNewsletter from './CreateNewsletter'
import ScraperDashboard from './ScraperDashboard'
import RecruiterAdd from './RecruiterAdd'
import RecruiterFind from './RecruiterFind'
import '../css/admin.css'

const auth_url = "https://api.archjobs.co.uk/admin_auth"

class AdminDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 0,
      job_data: {},
      recruiter_data: {},
      recruiter_list: []
    };  
}    

setRecruiterList = (recruiter_list) => {
  this.setState({recruiter_list: recruiter_list})
}

EditJob = (job_data) => {
  this.setState({job_data: job_data, tab: 0})
} 

EditRecruiter = (recruiter_data) => {
  this.setState({recruiter_data: recruiter_data, tab: 4})
} 

handleChange = (event, newValue) => {
  this.setState({tab: newValue})
}

  render() {
    console.log(this.state.tab)
    return <div className="AdminPage">
      <Tabs value={this.state.tab} onChange={this.handleChange} centered style={{width:"100%"}}>
        <Tab label="Add a Job" />
        <Tab label="Edit Jobs" />
        <Tab label="Create Newsletter" />
        <Tab label="Scraper" />
        <Tab label="Add a Recruiter" />
        <Tab label="Edit Recruiters" />
      </Tabs>
      <JobSubmission hidden={this.state.tab != 0} password={this.props.password} job_data={this.state.job_data} recruiter_list={this.state.recruiter_list}/>
      <SearchPage hidden={this.state.tab != 1} password={this.props.password} admin={true} EditJob={this.EditJob.bind(this)}/>
      <CreateNewsletter hidden={this.state.tab != 2} password={this.props.password}/>
      <ScraperDashboard hidden={this.state.tab != 3} password={this.props.password}/>
      <RecruiterAdd hidden={this.state.tab != 4} password={this.props.password} recruiter_data={this.state.recruiter_data}/>
      <RecruiterFind hidden={this.state.tab != 5} password={this.props.password} admin={true} EditRecruiter={this.EditRecruiter.bind(this)} setRecruiterList={this.setRecruiterList.bind(this)}/>
    </div>
  }
}






class AdminPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      authenticated: false,
    };  
}    

componentDidMount = () => {
  var pass = localStorage.getItem("archjobs_admin")
  if(pass){
    this.setState({password: pass})
  }
}

checkPassword = async() => {
  var auth_response = await axios.get(auth_url, {headers:  {"Authorization": 'Bearer ' + this.state.password}})
  if (("auth" in auth_response.data) && (auth_response.data.auth == true)){
    this.setState({authenticated: true})
    localStorage.setItem("archjobs_admin", this.state.password)
  }
}

keyPress = (e) => {
  if(e.keyCode == 13){
    this.checkPassword()
 }
}

handleChange = (e) => {
  this.setState({password: e.target.value})
}

  render() {
    if (this.state.authenticated){
      return  <AdminDetails password={this.state.password}/>
    } else {
      return <div className="AdminPage">
        <div className="AdminAuth">
          <div className="AdminPortalText">Log in to the Admin Portal</div>
        <TextField
            id="admin_pass"
            label="Password"
            value={this.state.password}
            onChange={this.handleChange}
            onKeyDown={this.keyPress}
            type="password"
            autoComplete="current-password"
          />
        </div>
      </div>
    }

  }
}

/* export the component to be used in other components */
export default AdminPage