import ArticleFrame from "./ArticleFrame";
import React, { Component } from 'react';


//first go to ArticleData.js and add all the relavant data
//next, rename "YourArticle" below to what you named the article data section
const thisArticleData = "ATStudy"


//rename YourArticle below to your article name
export default class ATStudy extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
      };
    }

    componentDidMount = () => {
      window.scrollTo(0, 0)
    }
    render(){
        return <ArticleFrame ArticleDataName={thisArticleData}>
          <p>If you have decided that you want to study and become an Architectural Technologist, but you&rsquo;re not sure where to study, then this guide is for you. As the architectural landscape continues to evolve with advancements in technology and sustainable design, pursuing a career in architectural technology has become an exciting and rewarding path. I have listed all 31 universities that offer the BSc Architectural Technology course, as well as those that offer the apprenticeship route, and also those universities that have stopped or halted the course. You can also see how much it will cost per year (for UK/International Students), as well as the course length, study options, and start dates.</p>
          <p>Whether you're a local student seeking a world-class education or an international enthusiast aiming to broaden your horizons, this article will hopefully help you but before we proceed, I want to clarify the difference between CIAT Accredited and a course that is not Accredited by CIAT.</p>
          <p/>
          <p/>
          <h3>What is CIAT Accreditation?</h3>
          <p><em>&ldquo;CIAT Accredits Honours and Masters degree level programmes. The Accreditation process ensures that the programmes have been assessed for their content, structure and resources against standards set by the Institute and the processes are an assurance that you will develop your academic, analytical, communication and employability skills required for a career in the industry.</em></p>
          <p><em>Some educational establishments offering both Accredited Honours and Masters degrees and have demonstrated a robust culture of research and knowledge exchange in Architectural Technology may be awarded the accolade of CIAT Centre of Excellence.&rdquo; </em>Source <a href="https://architecturaltechnology.com/learning/study.html" target="_blank" rel="nofollow noopener">CIAT</a></p>
          <p/>
          <p/>
          <h3>What if my programme/qualification isn&rsquo;t Accredited by CIAT?</h3>
          <p><em>&ldquo;It is not a mandatory requirement for your educational establishment to achieve Accreditation for its Architectural Technology qualification from CIAT, and it is for the individual organisation to apply. If your qualification is not Accredited by CIAT, then it will not have been examined against the Institute&rsquo;s standards.</em></p>
          <p><em>You would still be eligible to join CIAT but you will be required to demonstrate all underpinning knowledge requirements for membership, as we cannot verify that your programme has met the necessary standards for any exemptions.&rdquo;</em> Source <a href="https://architecturaltechnology.com/learning/study.html" target="_blank" rel="nofollow noopener">CIAT</a></p>
          <p/>
          <p/>
          <h3>What's the Average Cost of Studying in the UK?</h3>
          <p>The average cost for a UK student to study in the United Kingdom is approximately &pound;9,250 per year, while international students pay around &pound;15,898 per year. If you are a Scottish resident and wish to study in Scotland, the tuition fee is &pound;1,820 per year.</p>
          <p/>
          <p/>
          <h3>List of UK Universities Offering the Architectural Technology Course</h3>
          <p>Please note that the list below is only for the UK. Additionally, there are overseas universities that have been <a href="https://architecturaltechnology.com/learning/study.html" target="_blank" rel="nofollow noopener">accredited by CIAT,</a> such as Dubai, Horsens (Denmark), Republic of Ireland, Cairo and Barcelona.</p>
          <p>Disclaimer: If I have missed any universities or if any of the information is incorrect, please let me know via <a href="https://www.linkedin.com/in/aylin-round/" target="_blank" rel="nofollow noopener">LinkedIn</a>, and I will promptly make the necessary amendments. All details are sourced from the current university pages as of August 2023, and it's possible that figures and dates have undergone changes. I am committed to maintaining the accuracy of this guide and will keep it continuously updated.</p>
          <p/>
          <p/>
          <h2>London</h2>
          <div><a href="https://uel.ac.uk/undergraduate/courses/bsc-hons-architectural-design-technology-accredited-ciat" target="_blank" rel="nofollow noopener"><strong>BSc (Hons) Architectural Design Technology</strong></a></div>
          <div>University of East London</div>
          <div>
          <p/>
          <table>
          <tbody>
          <tr>
          <td>Start</td>
          <td>September 2023</td>
          </tr>
          <tr>
          <td>Course Length</td>
          <td>3-5 years</td>
          </tr>
          <tr>
          <td>Study Options</td>
          <td>Full time and Part time</td>
          </tr>
          <tr>
          <td>UK Student</td>
          <td>&pound;9,250 per year&nbsp;&nbsp;</td>
          </tr>
          <tr>
          <td>International Student</td>
          <td>&pound;13,920 per year&nbsp;&nbsp;</td>
          </tr>
          </tbody>
          </table>
          </div>
          <p/>
          <p/>
          <div><a href="https://www.uwl.ac.uk/course/undergraduate/bsc-architectural-design-and-technology?start=646&amp;option=33" target="_blank" rel="nofollow noopener"><strong>BSc (Hons) Architectural Design and Technology</strong></a></div>
          <div>University of West London</div>
          <p/>
          <div>
          <table>
          <tbody>
          <tr>
          <td>Start</td>
          <td>September 2023 or September 2024</td>
          </tr>
          <tr>
          <td>Course Length</td>
          <td>3 years or 5 years</td>
          </tr>
          <tr>
          <td>Study Options</td>
          <td>Full time and Part time</td>
          </tr>
          <tr>
          <td>UK Student</td>
          <td>&pound;9,250 per year&nbsp;&nbsp;</td>
          </tr>
          <tr>
          <td>International Student</td>
          <td>&pound;14,250 per year&nbsp;&nbsp;</td>
          </tr>
          </tbody>
          </table>
          </div>
          <p/>
          <p/>
          <div><a href="https://www.uel.ac.uk/Undergraduate/Courses/BSc-Hons-Architectural-Design-Technology" target="_blank" rel="nofollow noopener"><strong>BSc (Hons) Architectural Design Technology</strong></a></div>
          <div>University of East London</div>
          <p/>
          <div>
          <table>
          <tbody>
          <tr>
          <td>Start</td>
          <td>September 2023</td>
          </tr>
          <tr>
          <td>Course Length</td>
          <td>3 - 5 years (full time) or up to 6 years (part time)</td>
          </tr>
          <tr>
          <td>Study Options</td>
          <td>Full time, Part time or Foundation Year</td>
          </tr>
          <tr>
          <td>UK Student</td>
          <td>&pound;9,250 per year (2023/24)&nbsp;</td>
          </tr>
          <tr>
          <td>International Student</td>
          <td>&pound;13,920 per year (2023/24)</td>
          </tr>
          </tbody>
          </table>
          </div>
          <p/>
          <p/>
          <div><a href="https://www.lsbu.ac.uk/study/course-finder/architectural-technology-2022-23" target="_blank" rel="nofollow noopener"><strong>BSc (Hons) Architectural Technology</strong></a></div>
          <div>London South Bank University</div>
          <p/>
          <div>
          <table>
          <tbody>
          <tr>
          <td>Start</td>
          <td>September 2023</td>
          </tr>
          <tr>
          <td>Course Length</td>
          <td>3 years (full time), 4 years (sandwich year) and 5 years (part time)</td>
          </tr>
          <tr>
          <td>Study Options</td>
          <td>Full time or Part time</td>
          </tr>
          <tr>
          <td>UK Student</td>
          <td>&pound;9,250 per year (2023/24)&nbsp;</td>
          </tr>
          <tr>
          <td>International Student</td>
          <td>&pound;15,900 per year (2023/24)</td>
          </tr>
          </tbody>
          </table>
          </div>
          <p/>
          <p/>
          <div><a href="https://www.westminster.ac.uk/architecture-interiors-and-urban-design-courses/2023-24/september/full-time/architectural-technology-bsc-honours" target="_blank" rel="nofollow noopener"><strong>BSc (Hons) Architectural Technology</strong></a></div>
          <div>University of Westminster</div>
          <p/>
          <div>
          <table>
          <tbody>
          <tr>
          <td>Start</td>
          <td>September 2023 or September 2024</td>
          </tr>
          <tr>
          <td>Course Length</td>
          <td>3 or 4 years&nbsp;</td>
          </tr>
          <tr>
          <td>Study Options</td>
          <td>Full time</td>
          </tr>
          <tr>
          <td>UK Student</td>
          <td>&pound;9,250 per year (2023/24)&nbsp;</td>
          </tr>
          <tr>
          <td>International Student</td>
          <td>&pound;14,800 per year (2023/24)</td>
          </tr>
          </tbody>
          </table>
          </div>
          <p/>
          <p/>
          <div><a href="https://www.mdx.ac.uk/courses/undergraduate/architectural-technology" target="_blank" rel="nofollow noopener"><strong>BSc (Hons) Architectural Technology</strong></a></div>
          <div>Middlesex University</div>
          <p/>
          <div>
          <table>
          <tbody>
          <tr>
          <td>Start</td>
          <td>September 2023</td>
          </tr>
          <tr>
          <td>Course Length</td>
          <td>3 years (full time), 4 years (placement year) or 6 years (part time)</td>
          </tr>
          <tr>
          <td>Study Options</td>
          <td>Full time, Placement year, part time</td>
          </tr>
          <tr>
          <td>UK Student</td>
          <td>&pound;9,250 per year (2023/24)&nbsp;</td>
          </tr>
          <tr>
          <td>International Student</td>
          <td>&pound;15,100 per year (2023/24)&nbsp;</td>
          </tr>
          </tbody>
          </table>
          </div>
          <p/>
          <p/>
          <h2>North West</h2>
          <div><a href="https://www.salford.ac.uk/courses/undergraduate/architectural-design-and-technology" target="_blank" rel="nofollow noopener"><strong>BSc (Hons) Architectural Design and Technology</strong></a></div>
          <div>University of Salford</div>
          <p/>
          <div>
          <table>
          <tbody>
          <tr>
          <td>Start</td>
          <td>September 2023 or September 2024</td>
          </tr>
          <tr>
          <td>Course Length</td>
          <td>3 years or 4 years</td>
          </tr>
          <tr>
          <td>Study Options</td>
          <td>Full time and Part time</td>
          </tr>
          <tr>
          <td>UK Student</td>
          <td>&pound;9,250 per year (2023/24)&nbsp;</td>
          </tr>
          <tr>
          <td>International Student</td>
          <td>&pound;15,120 per year (2023/24) and &pound;17,040 per year (2024/25)</td>
          </tr>
          </tbody>
          </table>
          </div>
          <p/>
          <p/>
          <div><a href="https://www.ljmu.ac.uk/study/courses/undergraduates/2023/32811-architectural-technology-bsc-hons" target="_blank" rel="nofollow noopener"><strong>BSc (Hons) Architectural Technology</strong></a></div>
          <div>Liverpool John Moores University</div>
          <p/>
          <div>
          <table>
          <tbody>
          <tr>
          <td>Start</td>
          <td>September 2023 or September 2024</td>
          </tr>
          <tr>
          <td>Course Length</td>
          <td>3 years (full time) or 4 years (placement)</td>
          </tr>
          <tr>
          <td>Study Options</td>
          <td>Full time, Placement year</td>
          </tr>
          <tr>
          <td>UK Student</td>
          <td>&pound;9,250 per year (2023/24)&nbsp;</td>
          </tr>
          <tr>
          <td>International Student</td>
          <td>&pound;17,400 per year (2023/24) and &pound;18,250 per year (2024/25)</td>
          </tr>
          </tbody>
          </table>
          </div>
          <p/>
          <p/>
          <h2>Yorkshire</h2>
          <div><a href="https://www.leedsbeckett.ac.uk/courses/architectural-technology-bsc/" target="_blank" rel="nofollow noopener"><strong>BSc (Hons) Architectural Technology</strong></a></div>
          <div>Leeds Beckett University</div>
          <p/>
          <div>
          <table>
          <tbody>
          <tr>
          <td>Start</td>
          <td>September 2023</td>
          </tr>
          <tr>
          <td>Course Length</td>
          <td>3 years&nbsp;</td>
          </tr>
          <tr>
          <td>Study Options</td>
          <td>Full time&nbsp;</td>
          </tr>
          <tr>
          <td>UK Student</td>
          <td>&pound;9,250 per year (2023/24)&nbsp;</td>
          </tr>
          <tr>
          <td>International Student</td>
          <td>&pound;14,000 per year (2023/24)</td>
          </tr>
          </tbody>
          </table>
          </div>
          <p/>
          <p/>
          <div><a href="https://courses.hud.ac.uk/full-time/undergraduate/architectural-technology-bsc-hons#:~:text=The%20University%20of%20Huddersfield%20was,is%20valid%20for%205%20years." target="_blank" rel="nofollow noopener"><strong>BSc (Hons) Architectural Technology</strong></a></div>
          <div>University of Huddersfield</div>
          <p/>
          <div>
          <table>
          <tbody>
          <tr>
          <td>Start</td>
          <td>September 2023 or September 2024</td>
          </tr>
          <tr>
          <td>Course Length</td>
          <td>3 years (full time) or 4 years (placement)</td>
          </tr>
          <tr>
          <td>Study Options</td>
          <td>Full time, Placement year</td>
          </tr>
          <tr>
          <td>UK Student</td>
          <td>&pound;9,250 per year (2023/24)&nbsp;</td>
          </tr>
          <tr>
          <td>International Student</td>
          <td>&pound;17,600 per year (2023/24)</td>
          </tr>
          </tbody>
          </table>
          </div>
          <p/>
          <p/>

          <div><a href="https://www.bradford.ac.uk/courses/ug/architectural-technology-bsc/" target="_blank" rel="nofollow noopener"><strong>BSc (Hons) Architectural Technology</strong></a><strong> (not accredited)</strong></div>
          <div>University of Bradford</div>
          <p/>
          <div>
          <table>
          <tbody>
          <tr>
          <td>Start</td>
          <td>September 2023 or September 2024</td>
          </tr>
          <tr>
          <td>Course Length</td>
          <td>3 years (full time), 4 years (sandwich)&nbsp;</td>
          </tr>
          <tr>
          <td>Study Options</td>
          <td>Full time or Sandwich Year</td>
          </tr>
          <tr>
          <td>UK Student</td>
          <td>&pound;9,250 per year (2023/24)&nbsp;</td>
          </tr>
          <tr>
          <td>International Student</td>
          <td>&pound;22,180 per year (2023/24)</td>
          </tr>
          </tbody>
          </table>
          </div>
          <p/>
          <p/>
          <div><a href="https://www.leedsbeckett.ac.uk/courses/architectural-technology-design-msc/" target="_blank" rel="nofollow noopener"><strong>MSc Architectural Technology and Design</strong></a></div>
          <div>Leeds Beckett University</div>
          <p/>
          <div>
          <table>
          <tbody>
          <tr>
          <td>Start</td>
          <td>September 2023, January 2024, September 2024 or January 2025</td>
          </tr>
          <tr>
          <td>Course Length</td>
          <td>2 years</td>
          </tr>
          <tr>
          <td>Study Options</td>
          <td>Full time</td>
          </tr>
          <tr>
          <td>UK Student</td>
          <td>&pound;8,500 per year (2023/24)&nbsp;</td>
          </tr>
          <tr>
          <td>International Student</td>
          <td>&pound;9,000 per year (2023/24)&nbsp;&nbsp;</td>
          </tr>
          </tbody>
          </table>
          </div>
          <p/>
          <p/>
          <h2>Midlands</h2>
          <div><a href="https://www.wlv.ac.uk/courses/bsc-hons-architectural-design-technology/" target="_blank" rel="nofollow noopener"><strong>BSc (Hons) Architectural Design Technology</strong></a></div>
          <div>University of Wolverhampton</div>
          <p/>
          <div>
          <table>
          <tbody>
          <tr>
          <td>Start</td>
          <td>September 2023</td>
          </tr>
          <tr>
          <td>Course Length</td>
          <td>3 years (full time) or 6 years (part time)</td>
          </tr>
          <tr>
          <td>Study Options</td>
          <td>Full time or Part time&nbsp;</td>
          </tr>
          <tr>
          <td>UK Student</td>
          <td>&pound;9,250 per year (2023/24)&nbsp;</td>
          </tr>
          <tr>
          <td>International Student</td>
          <td>&pound;14,450 per year (2023/24)</td>
          </tr>
          </tbody>
          </table>
          </div>
          <p/>
          <p/>
          <div><a href="https://www.coventry.ac.uk/course-structure/ug/eec/architectural-technology-bsc-hons/" target="_blank" rel="nofollow noopener"><strong>BSc (Hons) Architectural Technology</strong></a></div>
          <div>Coventry University</div>
          <p/>
          <div>
          <table>
          <tbody>
          <tr>
          <td>Start</td>
          <td>September 2023 or September 2024</td>
          </tr>
          <tr>
          <td>Course Length</td>
          <td>3 years (full time) or 5 years (part time)&nbsp;</td>
          </tr>
          <tr>
          <td>Study Options</td>
          <td>Full time, Part time or Foundation Year</td>
          </tr>
          <tr>
          <td>UK Student</td>
          <td>&pound;9,250 per year (2023/24)&nbsp;</td>
          </tr>
          <tr>
          <td>EU Student</td>
          <td>&pound;9,250 per year with <a href="https://www.coventry.ac.uk/international-students-hub/apply-for-a-scholarship/eu-support-bursary/" target="_blank" rel="nofollow noopener">EU support bursary</a> &pound;19,850 per year without <a href="https://www.coventry.ac.uk/international-students-hub/apply-for-a-scholarship/eu-support-bursary/" target="_blank" rel="nofollow noopener">EU support bursary</a></td>
          </tr>
          <tr>
          <td>International Student</td>
          <td>&pound;19,850 per year (2023/24)</td>
          </tr>
          </tbody>
          </table>
          <p><strong>Please note:</strong> Foundation Year is not available for EU or International Students</p>
          </div>
          <p/>
          <p/>
          <div><a href="https://www.northampton.ac.uk/courses/architectural-technology-bsc-hons/" target="_blank" rel="nofollow noopener"><strong>BSc (Hons) Architectural Technology</strong></a></div>
          <div>University of Northampton</div>
          <p/>
          <div>
          <table>
          <tbody>
          <tr>
          <td>Start</td>
          <td>September 2023</td>
          </tr>
          <tr>
          <td>Course Length</td>
          <td>3 years (full time), 4 years (foundation - full time) or 4- 6 years (part time)</td>
          </tr>
          <tr>
          <td>Study Options</td>
          <td>Full time, Part time or Foundation Year</td>
          </tr>
          <tr>
          <td>UK Student</td>
          <td>&pound;9,250 per year (2023/24)&nbsp;</td>
          </tr>
          <tr>
          <td>International Student</td>
          <td>&pound;14,750 per year (2023/24)</td>
          </tr>
          </tbody>
          </table>
          </div>
          <p/>
          <p/>
          <div><a href="https://www.ntu.ac.uk/course/architecture-design-and-the-built-environment/ug/bsc-hons-architectural-technology" target="_blank" rel="nofollow noopener"><strong>BSc (Hons) Architectural Technology</strong></a></div>
          <div>Nottingham Trent University</div>
          <p/>
          <div>
          <table>
          <tbody>
          <tr>
          <td>Start</td>
          <td>September 2023 or September 2024</td>
          </tr>
          <tr>
          <td>Course Length</td>
          <td>3 years (full time), 4 years (sandwich year)</td>
          </tr>
          <tr>
          <td>Study Options</td>
          <td>Full time or Sandwich time (full time)</td>
          </tr>
          <tr>
          <td>UK Student</td>
          <td>&pound;9,250 per year (2023/24)&nbsp;</td>
          </tr>
          <tr>
          <td>International Student</td>
          <td>&pound;16,800 per year (2023/24)</td>
          </tr>
          </tbody>
          </table>
          </div>
          <p/>
          <p/>
          <div><a href="https://www.bcu.ac.uk/courses/architectural-technology-bsc-hons-2023-24" target="_blank" rel="nofollow noopener"><strong>BSc (Hons) Architectural Technology</strong></a></div>
          <div>Birmingham City University</div>
          <p/>
          <div>
          <table>
          <tbody>
          <tr>
          <td>Start</td>
          <td>September 2023 or September 2024</td>
          </tr>
          <tr>
          <td>Course Length</td>
          <td>3 or 4 years&nbsp;</td>
          </tr>
          <tr>
          <td>Study Options</td>
          <td>Full time</td>
          </tr>
          <tr>
          <td>UK Student</td>
          <td>&pound;9,250 per year (2023/24)&nbsp;</td>
          </tr>
          <tr>
          <td>International Student</td>
          <td>&pound;13,980 per year (2023/24)</td>
          </tr>
          </tbody>
          </table>
          </div>
          <p/>
          <p/>
          <div><a href="https://www.dmu.ac.uk/study/courses/undergraduate-courses/architectural-technology-bsc-degree/architectural-technology-bsc-degree.aspx" target="_blank" rel="nofollow noopener"><strong>BSc (Hons) Architectural Technology</strong></a></div>
          <div>De Montfort University</div>
          <p/>
          <div>
          <table>
          <tbody>
          <tr>
          <td>Start</td>
          <td>September 2023&nbsp;</td>
          </tr>
          <tr>
          <td>Course Length</td>
          <td>3 years (full time), 4 years (placement) or up to 6 years (part time)</td>
          </tr>
          <tr>
          <td>Study Options</td>
          <td>Full time, Part time</td>
          </tr>
          <tr>
          <td>UK Student</td>
          <td>&pound;9,250 per year (2023/24)&nbsp;</td>
          </tr>
          <tr>
          <td>International Student</td>
          <td>&pound;15,250 per year (2023/24)</td>
          </tr>
          </tbody>
          </table>
          </div>
          <p/>
          <p/>
          <div><a href="https://www.derby.ac.uk/undergraduate/architecture-architectural-technology-courses/architectural-technology-and-practice-bsc-hons/" target="_blank" rel="nofollow noopener"><strong>BSc (Hons) Architectural Technology and Practice</strong></a><strong> - </strong><strong>Centre of Excellence</strong></div>
          <div>University of Derby</div>
          <p/>
          <div>
          <table>
          <tbody>
          <tr>
          <td>Start</td>
          <td>September 2023</td>
          </tr>
          <tr>
          <td>Course Length</td>
          <td>3 year (full time), 4 years (foundation year) or 5 years (part time)</td>
          </tr>
          <tr>
          <td>Study Options</td>
          <td>Full time, Part time, <a href="https://www.derby.ac.uk/undergraduate/architecture-architectural-technology-courses/architectural-technology-and-practice-bsc-hons-foundation/" target="_blank" rel="nofollow noopener">Foundation Year</a></td>
          </tr>
          <tr>
          <td>UK Student</td>
          <td>&pound;9,250 per year (2023/24)&nbsp;</td>
          </tr>
          <tr>
          <td>International Student</td>
          <td>&pound;14,900 per year (2023/24)&nbsp;</td>
          </tr>
          </tbody>
          </table>
          </div>
          <p/>
          <p/>
          <div><a href="https://www.derby.ac.uk/postgraduate/architecture-architectural-technology-courses/building-info-modelling-project-collab-msc/" target="_blank" rel="nofollow noopener"><strong>MSc Building Information Modelling and Project Collaboration</strong></a><strong> </strong>- <strong>Centre of Excellence</strong></div>
          <div>University of Derby</div>
          <p/>
          <div>
          <table>
          <tbody>
          <tr>
          <td>Start</td>
          <td>September 2023</td>
          </tr>
          <tr>
          <td>Course Length</td>
          <td>1 year (full time), 2 years (part time)</td>
          </tr>
          <tr>
          <td>Study Options</td>
          <td>Full time, Part time</td>
          </tr>
          <tr>
          <td>UK Student</td>
          <td>&pound;9,075 per year (2023/24)&nbsp;</td>
          </tr>
          <tr>
          <td>International Student</td>
          <td>&pound;14,900 per year (2023/24)&nbsp;</td>
          </tr>
          </tbody>
          </table>
          </div>
          <p/>
          <p/>
          <div><a href="https://www.derby.ac.uk/postgraduate/architecture-architectural-technology-courses/sustainable-architecture-healthy-buildings-msc/" target="_blank" rel="nofollow noopener"><strong>MSc Sustainable Architecture and Healthy Buildings</strong></a> - <strong>Centre of Excellence</strong></div>
          <div>University of Derby</div>
          <p/>
          <div>
          <table>
          <tbody>
          <tr>
          <td>Start</td>
          <td>September 2023</td>
          </tr>
          <tr>
          <td>Course Length</td>
          <td>1 year (full time), 2-3 years (part time)</td>
          </tr>
          <tr>
          <td>Study Options</td>
          <td>Full time, Part time</td>
          </tr>
          <tr>
          <td>UK Student</td>
          <td>&pound;8,525 per year (2023/24)&nbsp;</td>
          </tr>
          <tr>
          <td>International Student</td>
          <td>&pound;14,900 per year (2023/24)&nbsp;</td>
          </tr>
          </tbody>
          </table>
          </div>
          <p/>
          <p/>
          <h2>Scotland</h2>
          <div><a href="https://www.napier.ac.uk/courses/bscbsc-hons-architectural-technology-undergraduate-fulltime" target="_blank" rel="nofollow noopener"><strong>BSc (Hons) Architectural Technology</strong></a><strong> - </strong><strong>Centre of Excellence</strong></div>
          <div>Edinburgh Napier University</div>
          <p/>
          <div>
          <table>
          <tbody>
          <tr>
          <td>Start</td>
          <td>September 2023&nbsp;</td>
          </tr>
          <tr>
          <td>Course Length</td>
          <td>4 years (full time), 6 years (part time)</td>
          </tr>
          <tr>
          <td>Study Options</td>
          <td>Full time, Part time</td>
          </tr>
          <tr>
          <td>Scottish Student</td>
          <td>&pound;1,820 per year (2023/24)&nbsp;</td>
          </tr>
          <tr>
          <td>UK Students</td>
          <td>&pound;9,350 per year (2023/24)&nbsp;</td>
          </tr>
          <tr>
          <td>International Student</td>
          <td>&pound;17,580 per year (2023/24)</td>
          </tr>
          </tbody>
          </table>
          </div>
          <p/>
          <p/>
          <div><a href="https://www.rgu.ac.uk/study/courses/921-bsc-hons-architectural-technology" target="_blank" rel="nofollow noopener"><strong>BSc (Hons) Architectural Technology</strong></a> <strong>- </strong><strong>Centre of Excellence</strong></div>
          <div>Robert Gordon University</div>
          <p/>
          <div>
          <table>
          <tbody>
          <tr>
          <td>Start</td>
          <td>September 2023&nbsp;</td>
          </tr>
          <tr>
          <td>Course Length</td>
          <td>4 years&nbsp;</td>
          </tr>
          <tr>
          <td>Study Options</td>
          <td>Full time&nbsp;</td>
          </tr>
          <tr>
          <td>Scottish Student</td>
          <td>&pound;1,820 per year (2023/24)&nbsp;</td>
          </tr>
          <tr>
          <td>UK/EU Student</td>
          <td>&pound;6,750 per year (2023/24)&nbsp;</td>
          </tr>
          <tr>
          <td>International Student</td>
          <td>&pound;17,040 per year (2023/24)</td>
          </tr>
          </tbody>
          </table>
          </div>
          <p/>
          <p/>
          <div><a href="https://www.uhi.ac.uk/en/courses/bsc-hons-architectural-technology/#tabanchor" target="_blank" rel="nofollow noopener"><strong>BSc (Hons) Architectural Technology</strong></a><strong>&nbsp; (Not accredited)</strong></div>
          <div>University of Highlands and Islands</div>
          <p/>
          <div>
          <table>
          <tbody>
          <tr>
          <td>Start</td>
          <td>September 2023</td>
          </tr>
          <tr>
          <td>Course Length</td>
          <td>3 years (full time), 4 years (foundation/placement) or up to 5 years (part time)</td>
          </tr>
          <tr>
          <td>Study Options</td>
          <td>Full time, Part time or Foundation Year</td>
          </tr>
          <tr>
          <td>Scottish Student</td>
          <td>&pound;1,820 per year (2023/24)&nbsp;</td>
          </tr>
          <tr>
          <td>UK Student</td>
          <td>&pound;9,250 per year (2023/24)&nbsp;</td>
          </tr>
          <tr>
          <td>International Student&nbsp;</td>
          <td>&pound;15,360 per year (2023/24)</td>
          </tr>
          </tbody>
          </table>
          </div>
          <p/>
          <p/>
          <div><a href="https://www.napier.ac.uk/courses/msc-architectural-technology--building-performance-postgraduate-fulltime" target="_blank" rel="nofollow noopener"><strong>MSc Architectural Technology and Building Performance</strong></a></div>
          <div>Edinburgh Napier University</div>
          <p/>
          <div>
          <table>
          <tbody>
          <tr>
          <td>Start</td>
          <td>September 2023 or September 2024</td>
          </tr>
          <tr>
          <td>Course Length</td>
          <td>1 year (full time), 2 years (part time)</td>
          </tr>
          <tr>
          <td>Study Options</td>
          <td>Full time, Part time</td>
          </tr>
          <tr>
          <td>Scottish/UK Student</td>
          <td>&pound;6,930 per year (2023/24)&nbsp;</td>
          </tr>
          <tr>
          <td>International Student</td>
          <td>&pound;17,090 per year (2023/24)&nbsp;</td>
          </tr>
          </tbody>
          </table>
          </div>
          <p/>
          <p/>
          <h2>Wales</h2>
          <div><a href="https://www.cardiffmet.ac.uk/artanddesign/courses/Pages/hndbscadt.aspx" target="_blank" rel="nofollow noopener"><strong>BSc (Hons) Architectural Design and Technology</strong></a></div>
          <div>Cardiff Metropolitan University</div>
          <p/>
          <div>
          <table>
          <tbody>
          <tr>
          <td>Start</td>
          <td>September 2023 or September 2024</td>
          </tr>
          <tr>
          <td>Course Length</td>
          <td>3 - 4 years (full time) or up to 6 years (part time)</td>
          </tr>
          <tr>
          <td>Study Options</td>
          <td>Full time and Part time</td>
          </tr>
          <tr>
          <td>UK Student</td>
          <td>&pound;9,000 per year (2023/24)&nbsp;</td>
          </tr>
          <tr>
          <td>International Student</td>
          <td>&pound;15,000 per year (2023/24)</td>
          </tr>
          </tbody>
          </table>
          </div>
          <p/>
          <p/>
          <div><a href="https://glyndwr.ac.uk/courses/Undergraduate-courses/architectural-design-technology/" target="_blank" rel="nofollow noopener"><strong>BSc (Hons) Architectural Design Technology</strong></a></div>
          <div>Glyndwr University</div>
          <p/>
          <div>
          <table>
          <tbody>
          <tr>
          <td>Start</td>
          <td>September 2023 or September 2024</td>
          </tr>
          <tr>
          <td>Course Length</td>
          <td>3 years (full time) or 4 years (part time)</td>
          </tr>
          <tr>
          <td>Study Options</td>
          <td>Full time or Part time&nbsp;</td>
          </tr>
          <tr>
          <td>UK Student</td>
          <td>&pound;9,000 per year (2023/24)&nbsp;</td>
          </tr>
          <tr>
          <td>International Student</td>
          <td>&pound;11,750 per year (2023/24)</td>
          </tr>
          </tbody>
          </table>
          </div>
          <p/>
          <p/>
          <div><a href="https://www.uwtsd.ac.uk/bsc-architectural-technology/#:~:text=This%20Architectural%20Technology%20degree%20will,problem%2Dsolving%20and%20collaborative%20working.#" target="_blank" rel="nofollow noopener"><strong>(BSc, HND, HNC) Architectural Technology</strong></a><strong> (not accredited)</strong></div>
          <div>University of Wales&nbsp;</div>
          <p/>
          <div>
          <table>
          <tbody>
          <tr>
          <td>Start</td>
          <td>September 2023 or September 2024</td>
          </tr>
          <tr>
          <td>Course Length</td>
          <td>3 years (full time)</td>
          </tr>
          <tr>
          <td>Study Options</td>
          <td>Full time</td>
          </tr>
          <tr>
          <td>UK/EU Student</td>
          <td>&pound;9,000 per year (2023/24)&nbsp;</td>
          </tr>
          <tr>
          <td>International Student</td>
          <td>&pound;13,500 per year (2023/24)</td>
          </tr>
          </tbody>
          </table>
          </div>
          <p/>
          <p/>
          <h2>South</h2>
          <div><a href="https://www.brighton.ac.uk/courses/study/architectural-technology-bsc-hons.aspx" target="_blank" rel="nofollow noopener"><strong>BSc (Hons) Architectural Technology</strong></a></div>
          <div>University of Brighton</div>
          <p/>
          <div>
          <table>
          <tbody>
          <tr>
          <td>Start</td>
          <td>September 2023</td>
          </tr>
          <tr>
          <td>Course Length</td>
          <td>3 years (full time), 4 years (placement year) or 4-6 years (part time)</td>
          </tr>
          <tr>
          <td>Study Options</td>
          <td>Full time, Placement year, part time</td>
          </tr>
          <tr>
          <td>UK Student</td>
          <td>&pound;9,250 per year (2023/24)&nbsp;</td>
          </tr>
          <tr>
          <td>International Student</td>
          <td>&pound;15,300 per year (2023/24)&nbsp;</td>
          </tr>
          </tbody>
          </table>
          </div>
          <p/>
          <p/>
          <div><a href="https://www.solent.ac.uk/courses/undergraduate/architectural-design-and-technology-ba#finance" target="_blank" rel="nofollow noopener"><strong>BA (Hons) Architectural Design and Technology</strong></a></div>
          <div>Solent University</div>
          <p/>
          <div>
          <table>
          <tbody>
          <tr>
          <td>Start</td>
          <td>September 2023 or September 2024</td>
          </tr>
          <tr>
          <td>Course Length</td>
          <td>3 years to 5 years</td>
          </tr>
          <tr>
          <td>Study Options</td>
          <td>Full time and Part time</td>
          </tr>
          <tr>
          <td>UK, EU (settled status) Students</td>
          <td>&pound;9,250 per year&nbsp;&nbsp;</td>
          </tr>
          <tr>
          <td>International Student</td>
          <td>&pound;15,000 per year&nbsp;&nbsp;</td>
          </tr>
          </tbody>
          </table>
          </div>
          <p/>
          <p/>
          <div><a href="https://www.solent.ac.uk/courses/postgraduate/sustainable-building-design-msc" target="_blank" rel="nofollow noopener"><strong>MSc Sustainable Building Design</strong></a></div>
          <div>Solent University</div>
          <p/>
          <div>
          <table>
          <tbody>
          <tr>
          <td>Start</td>
          <td>September 2023 or September 2024</td>
          </tr>
          <tr>
          <td>Course Length</td>
          <td>1 year (full time), 2 years (part time)</td>
          </tr>
          <tr>
          <td>Study Options</td>
          <td>Full time, Part time</td>
          </tr>
          <tr>
          <td>UK Student</td>
          <td>&pound;9,000 per year (2023/24)&nbsp;</td>
          </tr>
          <tr>
          <td>International Student</td>
          <td>&pound;15,300 per year (2023/24)&nbsp;</td>
          </tr>
          </tbody>
          </table>
          </div>
          <p/>
          <p/>
          <h2>South East</h2>
          <div><a href="https://www.uca.ac.uk/study/courses/bsc-architectural-technology/#d.en.173160" target="_blank" rel="nofollow noopener"><strong>BSc (Hons) Architectural Technology</strong></a><strong> </strong><strong> (not accredited)</strong></div>
          <div>UCA Canterbury&nbsp;</div>
          <p/>
          <div>
          <table>
          <tbody>
          <tr>
          <td>Start</td>
          <td>September 2023 or September 2024</td>
          </tr>
          <tr>
          <td>Course Length</td>
          <td>3 years (full time), 4 years (foundation/placement)</td>
          </tr>
          <tr>
          <td>Study Options</td>
          <td>Full time or Foundation Year</td>
          </tr>
          <tr>
          <td>UK/EU Student</td>
          <td>&pound;9,250 per year (2023/24)&nbsp;</td>
          </tr>
          <tr>
          <td>International Student</td>
          <td>&pound;16,950 per year (2023/24)</td>
          </tr>
          </tbody>
          </table>
          </div>
          <p/>
          <p/>
          <h2>South West&nbsp;</h2>
          <div><a href="https://www.falmouth.ac.uk/study/undergraduate/architectural-design-technology" target="_blank" rel="nofollow noopener"><strong>BSc(Hons) Architectural Design &amp; Technology</strong></a><strong> </strong><strong> (not accredited)&nbsp;</strong></div>
          <div>Falmouth University&nbsp;</div>
          <p/>
          <div>
          <table>
          <tbody>
          <tr>
          <td>Start</td>
          <td>September <strong>2024&nbsp;</strong></td>
          </tr>
          <tr>
          <td>Course Length</td>
          <td>3 years (full time), 4 years (professional placement)</td>
          </tr>
          <tr>
          <td>Study Options</td>
          <td>Full time or Professional Placement</td>
          </tr>
          <tr>
          <td>UK Student</td>
          <td>&pound;9,250 per year (2024/25)&nbsp;</td>
          </tr>
          <tr>
          <td>International Student</td>
          <td>&pound;17,000 per year (2024/25)</td>
          </tr>
          </tbody>
          </table>
          </div>
          <p/>
          <p/>
          <div><a href="https://courses.uwe.ac.uk/K236/architectural-technology-and-design" target="_blank" rel="nofollow noopener"><strong>BSc (Hons) Architectural Technology and Design</strong></a></div>
          <div>University of the West of England, Bristol</div>
          <p/>
          <div>
          <table>
          <tbody>
          <tr>
          <td>Start</td>
          <td>September 2023</td>
          </tr>
          <tr>
          <td>Course Length</td>
          <td>3 year (full time), 4 years (sandwich)&nbsp;</td>
          </tr>
          <tr>
          <td>Study Options</td>
          <td>Full time, Sandwich</td>
          </tr>
          <tr>
          <td>UK Student</td>
          <td>&pound;9,250 per year (2023/24)&nbsp;</td>
          </tr>
          <tr>
          <td>International Student</td>
          <td>&pound;15,250 per year (2023/24)&nbsp;</td>
          </tr>
          </tbody>
          </table>
          </div>
          <p/>
          <p/>
          <h2>East of England</h2>
          <div><a href="https://www.aru.ac.uk/study/undergraduate/architectural-technology" target="_blank" rel="nofollow noopener"><strong>BSc (Hons) Architectural Technology</strong></a></div>
          <div>Anglia Ruskin University</div>
          <p/>
          <div>
          <table>
          <tbody>
          <tr>
          <td>Start</td>
          <td>September 2023 or September 2024</td>
          </tr>
          <tr>
          <td>Course Length</td>
          <td>3 years (full time), 4 years (foundation/placement) or up to 5 years (part time)</td>
          </tr>
          <tr>
          <td>Study Options</td>
          <td>Full time, Part time or Foundation Year</td>
          </tr>
          <tr>
          <td>UK Student</td>
          <td>&pound;9,250 per year (2023/24)&nbsp;</td>
          </tr>
          <tr>
          <td>International Student</td>
          <td>&pound;15,000 per year (2023/24)</td>
          </tr>
          </tbody>
          </table>
          </div>
          <p/>
          <p/>
          <div><a href="https://www.colchester.ac.uk/course/bsc-hons-construction-management-architectural-technology/" target="_blank" rel="nofollow noopener"><strong>BSc (Hons) Construction Management (Architectural Technology)</strong></a><strong> </strong><strong> (not accredited)</strong></div>
          <div>University Centre Colchester</div>
          <p/>
          <div>
          <table>
          <tbody>
          <tr>
          <td>Start</td>
          <td>September 2023&nbsp;</td>
          </tr>
          <tr>
          <td>Course Length</td>
          <td>3 years (full time), 4 years (part time)</td>
          </tr>
          <tr>
          <td>Study Options</td>
          <td>Full time, Part time</td>
          </tr>
          <tr>
          <td>UK Student</td>
          <td>Uncertain about costs due to confusing website. TBC</td>
          </tr>
          <tr>
          <td>International Student</td>
          <td>Uncertain about costs due to confusing website. TBC</td>
          </tr>
          </tbody>
          </table>
          <p>Note: Seriously, if anyone knows how much it costs, <a href="https://www.linkedin.com/in/aylin-round/" target="_blank" rel="nofollow noopener">please get in touch.</a> Thank you.</p>
          </div>
          <p/>
          <p/>
          <div><a href="https://www.beds.ac.uk/howtoapply/courses/undergraduate/next-year/architectural-technology/" target="_blank" rel="nofollow noopener"><strong>BSc (Hons) Architectural Technology</strong></a><strong> </strong><strong> (not accredited)</strong></div>
          <div>University of Bedfordshire&nbsp;&nbsp;</div>
          <div>
          <table>
          <tbody>
          <tr>
          <td>Start</td>
          <td>October 2023&nbsp;</td>
          </tr>
          <tr>
          <td>Course Length</td>
          <td>3 years</td>
          </tr>
          <tr>
          <td>Study Options</td>
          <td>Full time</td>
          </tr>
          <tr>
          <td>UK Student</td>
          <td>&pound;9,250 per year (2023/24)&nbsp;</td>
          </tr>
          <tr>
          <td>International Student</td>
          <td>&pound;14,500 per year (2023/24)</td>
          </tr>
          </tbody>
          </table>
          </div>
          <p/>
          <p/>
          <div><a href="https://www.bucks.ac.uk/courses/undergraduate/bsc-hons-architectural-design-and-technology" target="_blank" rel="nofollow noopener"><strong>BSc (Hons) Architectural Design and Technology</strong></a><strong> (not accredited)</strong></div>
          <div>Buckinghamshire New University</div>
          <div>
          <table>
          <tbody>
          <tr>
          <td>Start</td>
          <td>September 2023&nbsp;</td>
          </tr>
          <tr>
          <td>Course Length</td>
          <td>3 years (full time), 4 years (foundation)</td>
          </tr>
          <tr>
          <td>Study Options</td>
          <td>Full time or Foundation Year</td>
          </tr>
          <tr>
          <td>UK Student</td>
          <td>&pound;9,250 per year (2023/24)&nbsp;</td>
          </tr>
          <tr>
          <td>International Student</td>
          <td>&pound;15,000 per year (2023/24)</td>
          </tr>
          </tbody>
          </table>
          </div>
          <p/>
          <p/>
          <h2>Apprenticeships&nbsp;</h2>
          <div><a href="https://www.lsbu.ac.uk/study/course-finder/design-and-construction-management-degree-apprenticeship" target="_blank" rel="nofollow noopener"><strong>BSc (Hons) Architectural Technology (Design and Construction Management Degree Apprenticeship)</strong></a></div>
          <div>London South Bank University</div>
          <p/>
          <p/>
          <div><a href="https://www.napier.ac.uk/study-with-us/apprenticeships/bsc-hons-construction-and-the-built-environment-ga" target="_blank" rel="nofollow noopener"><strong>BSc (Hons) Construction &amp; the Built Environment (Graduate Apprenticeship)</strong></a><strong> - </strong><strong>Centre of Excellence</strong></div>
          <div>Edinburgh Napier University</div>
          <p/>
          <p/>
          <div><a href="https://www.uclan.ac.uk/degree-apprenticeships/courses/architectural-technology-bsc" target="_blank" rel="nofollow noopener"><strong>BSc (Hons) Architectural Technology Design and Construction Management - Level 6</strong></a></div>
          <div>University of Central Lancashire</div>
          <p/>
          <div><strong>Note:</strong> University of Central Lancashire have changed the course to an Apprenticeship course</div>
          <p/>
          <p/>
          <h2>Discontinued BSc Courses</h2>
          <p/>
          <p/>
          <div><strong>BSc (Hons) Architectural Technology</strong></div>
          <div>Sheffield Hallam University</div>
          <p/>
          <div><strong>BSc (Hons) Architectural Technology</strong></div>
          <div>Guernsey College of Further Education</div>
          <p/>
          <p/>
          <h2>Summary</h2>
          <p>Writing this guide has indeed highlighted that London and the Midlands offer the most opportunities for those deciding to study to become an Architectural Technologist. However, the North region, excluding Scotland, is notably underrepresented, and I really hope there will be a positive shift in the upcoming years. From my network, I am aware that the University of Salford in Manchester is thriving, with its course gaining popularity. Moreover, Manchester, as a vibrant city, presents a lot of opportunities for future Architectural Technologists.</p>
          <p>In terms of costs, the tuition fee for a UK student is &pound;9,250 per year, and the highest tuition fee for an international student is &pound;22,180 per year. On average, it amounts to around &pound;15,898 per year for our international friends. If you are a Scottish resident and want to study in Scotland, the tuition fee is &pound;1,820 per year. Additionally, keep in mind that there will be other course-related costs on top, such as materials, printing, binding, and more.</p>
          <p>In case you do not want to study at a university or maybe the costs are too much &ndash; I can't blame you &ndash; you should consider going down the apprenticeship route.</p>
          <p>If you want to read more on Architectural Technology, why not visit the <a href="https://architecturaltechnology.com/" target="_blank" rel="nofollow noopener">Chartered Institute of Architectural Technologists (CIAT)</a> website?</p>
          <p>I wish you good luck on your journey!</p>
            </ArticleFrame>
    }
}